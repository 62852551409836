<template>
    <CModal :visible="isGlobal ? store.state.isVisiblePrintReceiptModal : isVisible" @close="close" backdrop="static">
        <CModalHeader>{{ t("model.print") }}</CModalHeader>

        <CModalBody>
            <div class="border-bottom pb-3">
                <CRow class="mb-2">
                    <CCol>
                        <span>{{ t("generic.selectPrinter") }}</span>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CFormSelect @change="selectPrinter($event)">
                            <option v-for="printer in allPrinters" :key="printer.productId" :value="printer.productId">{{ printer.productName }}</option>
                        </CFormSelect>
                    </CCol>
                </CRow>
            </div>

            <CRow class="mt-3">
                <CCol>
                    <CFormCheck :label="t('generic.customerInfo')" v-model:model-value="preferences.customerInfo">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.productionTag')" v-model:model-value="preferences.productionTag">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.cutScheme')" v-model:model-value="preferences.cutScheme">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.croppedAmount')" v-model:model-value="preferences.croppedAmount">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.hemmingDetails')" v-model:model-value="preferences.hemmingDetails">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.greaterFontSize')" v-model:model-value="preferences.greaterFontSize">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.specialInfo')" v-model:model-value="preferences.specialInfo">
                    </CFormCheck>
                    <CFormLabel class="text-info cursor-pointer mx-4" @click="openPreferencesModal">{{
                    t("generic.preferencesToProduction") }}</CFormLabel>
                    <CFormCheck :label="t('generic.qrCodeForTissueImage')"
                        v-model:model-value="preferences.qrCodeForTissueImage"></CFormCheck>
                    <CFormCheck :label="t('generic.qrCodeForPrintPatternImage')"
                        v-model:model-value="preferences.qrCodeForPrintPatternImage"></CFormCheck>
                </CCol>
            </CRow>
        </CModalBody>

        <CModalFooter>
            <CButton type="button" variant="outline" color="secondary" @click="close">{{ t("generic.undo") }}</CButton>
            <CButton type="button" color="primary" @click="submitUpdate">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>

        <PreferencesModal v-model:is-visible="isPreferencesModalVisible"></PreferencesModal>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import PreferencesModal from "@/components/modals/PreferencesModal";
import { useStore } from "vuex";
import { user } from "@/user";

export default {
    name: "PrintReceiptModal",
    components: { PreferencesModal, },
    emits: [ "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        isGlobal: {
            type: Boolean,
            default: false,
        },
        productionId: {
            type: String,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const store = useStore();

        const isPreferencesModalVisible = ref(false);

        let printer = null;
        const allPrinters = ref([]);

        const preferences = ref({
            customerInfo: false,
            productionTag: false,
            croppedAmount: false,
            cutScheme: false,
            hemmingDetails: false,
            greaterFontSize: false,
            specialInfo: false,
            qrCodeForTissueImage: false,
            qrCodeForPrintPatternImage: false,
        });

        function selectPrinter (event) {
            printer = allPrinters.value.find((p) => p.productId === event.target.value);
            console.log(printer);
        }

        function close () {
            if (!props.isGlobal) {
                emit("update:isVisible", false);
            }
            else {
                store.commit("closePrintReceiptModal");
            }
        }

        function openPreferencesModal () {
            isPreferencesModalVisible.value = true;
        }

        async function init () {
            const printReceiptPreference = await user.getPrintReceiptPreference();
            if (!printReceiptPreference) {
                await user.updatePrintReceiptPreference(JSON.stringify(preferences.value));
            }
            else {
                preferences.value = JSON.parse(printReceiptPreference.preference);
            }
        }

        async function setupDevice (device) {
            await device.open();
            await device.selectConfiguration(1);
            await device.claimInterface(0);
        }

        async function print (device, buffer) {
            console.log(buffer);
            await device.transferOut(1, new Uint8Array(buffer.printer.data));
        }

        async function submitUpdate () {
            await user.updatePrintReceiptPreference(JSON.stringify(preferences.value));

            const productionId = props.isGlobal ? store.state.selectedProductionId : props.productionId;
            const printBuffer = await user.getPrintBuffer({
                productionId,
                printerName: "",
            });

            if (!printer) {
                printer = await navigator.usb.requestDevice({ filters: [], }); // { filters: [ { vendorId: 1155, }, ], }
            }

            await setupDevice(printer);
            await print(printer, printBuffer);
            close();
        }

        if (!props.isGlobal) {
            watch(() => props.isVisible, async (newValue) => {
                if (newValue) {
                    await init();
                    allPrinters.value = (await navigator.usb.getDevices())?.filter((dev) => dev.vendorId === 1155);
                    printer = allPrinters.value[0];
                    console.log(printer);
                }
            });
        }
        else {
            watch(() => store.state.isVisiblePrintReceiptModal, async (newValue) => {
                if (newValue) {
                    await init();
                    store.commit("deleteNotificationByFunctionName", { name: "generic.print", });
                    allPrinters.value = (await navigator.usb.getDevices())?.filter((dev) => dev.vendorId === 1155);
                    printer = allPrinters.value[0];
                    console.log(printer);
                }
            });
        }

        return {
            t,
            store,
            close,
            openPreferencesModal,
            isPreferencesModalVisible,
            preferences,
            submitUpdate,
            allPrinters,
            selectPrinter,
        };
    },
};
</script>

<style scoped>

</style>
