<template>
    <CSidebar
        class="fs-5 md"
        position="fixed"
        :unfoldable="sidebarUnfoldable"
        :visible="sidebarVisible"
        @visible-change="
      (event) =>
        store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
    >
        <CSidebarBrand>
            <div class="sidebar-brand-full d-flex align-items-center">
                <!-- <CImage
                class="sidebar-brand-full"
                :src="companyLogoSrc"
                style="height: 40px; aspect-ratio: 1;"
                /> -->
                <span class="sidebar-brand-full ms-2 fs-4">{{companyName}}</span>
            </div>
            <!-- <CImage
                class="sidebar-brand-narrow"
                :src="companyLogoSrc"
                style="height: 40px; aspect-ratio: 1;"
            /> -->
        </CSidebarBrand>
        <AppSidebarNav ref="appSidebarNav"></AppSidebarNav>
        <CSidebarToggler
            class="d-none d-lg-flex"
            @click="store.commit('toggleUnfoldable')"
        />
    </CSidebar>
</template>

<script>
import {
    computed,
    onMounted,
    ref,
} from "vue";
import { useStore } from "vuex";
import { logoNegative } from "@/assets/brand/logo-negative";
import { sygnet } from "@/assets/brand/sygnet";
import {
    CNavGroup,
    CNavItem,
} from "@coreui/vue-pro";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { AppSidebarNav } from "@/components/AppSidebarNav";
import { hasPermission } from "@/views/utilities";
import { user } from "@/user";

export default {
    name: "AppSidebar",
    components: {
        AppSidebarNav,
    },
    setup () {
        const store = useStore();
        const { t, locale, } = useI18n();
        const route = useRoute();
        const appSidebarNav = ref(null);
        const companyLogoSrc = `https://www.wetex.me:3000/images/tiny/company_${user.descriptor.companyId}.jpg`;
        const companyName = "WeTex Demo";

        function findSidebarItemGroupElByName (name) {
            const sidebarEl = appSidebarNav.value.$el;
            const links = sidebarEl.querySelectorAll("a.nav-link.nav-group-toggle");

            for (let i = 0; i < links.length; i++) {
                if (links[i].textContent.includes(name)) {
                    return links[i];
                }
            }
        }

        function createBtnElement (id) {
            const symbol = document.createElement("div");
            symbol.className = "button_plus position-absolute end-0 me-5";
            symbol.id = id;
            return symbol;
        }

        onMounted(() => {
            const preorderList = findSidebarItemGroupElByName(t("sidebar.preorderList"));
            const productList = findSidebarItemGroupElByName(t("sidebar.productList"));
            if (preorderList && hasPermission("permission.action.preorderList.set" || user.descriptor.isAdmin)) {
                const createPreorderBtn = createBtnElement("createPreorderBtn");
                createPreorderBtn.addEventListener("click", (e) => {
                    e.stopPropagation();
                    store.commit("openPreorderCreateModal");
                });
                preorderList.appendChild(createPreorderBtn);
            }
            if (productList && hasPermission("permission.action.productList.set") || user.descriptor.isAdmin) {
                const createProductBtn = createBtnElement("createProductBtn");
                createProductBtn.addEventListener("click", (e) => {
                    e.stopPropagation();
                    store.commit("openProductAddModal");
                });
                productList.appendChild(createProductBtn);
            }
        });
        return {
            t,
            locale,
            store,
            appSidebarNav,
            logoNegative,
            sygnet,
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
            companyLogoSrc,
            companyName,
        };
    },
};
</script>
