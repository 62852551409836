<template>
    <CModal scrollable :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <!-- Lightbox Row-->
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="0"
            :images="lightboxImages"
            @hide="closeLightbox"
        ></BaseLightbox>

        <CModalHeader>
            <CModalTitle>
                <CRow>
                    <CCol>
                        {{ t("ingredient.accessory.addNew") }}
                    </CCol>
                </CRow>
                <CRow class="py-3 ps-4">
                    <div class="d-flex flex-nowrap w-auto ps-0">
                        <CFormSelect
                            v-model:model-value="searchField"
                            class="t-col-2"
                        >
                            <option value="">{{ t("generic.all") }}</option>
                            <option v-for="field in Object.keys(accessoriesSearchFields)" :key="field" :value="field">{{ t(accessoriesSearchFields[field]) }}</option>
                        </CFormSelect>
                        <CFormInput
                            class="t-col-4"
                            v-model:model-value="searchValue"
                            type="search"
                            :placeholder="t('generic.search')"
                            @input="onInputSearchValue"
                        />
                    </div>
                </CRow>
            </CModalTitle>
        </CModalHeader>

        <CModalBody @scroll="getNewElements">
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem v-if="accessories.length === 0 && searchValue !== ''" component="button" @click="createNew">
                        <span>
                            {{ t("ingredient.accessory.addNew") }} {{ searchValue }}
                        </span>
                    </CListGroupItem>
                    <CListGroupItem component="button" v-for="accessory in accessories" :key="accessory.id" @click="selectAccessory(accessory)">
                        <VMenu placement="right">
                            <CRow class="ps-0 pb-0">
                                <CCol>
                                    <CImage
                                        class="t-col-photo rounded cursor-pointer"
                                        v-if="accessory.images.length > 0" :src="accessory.images[0].tiny"
                                        @click.stop="openLightbox(accessory)"
                                    ></CImage>
                                    <div v-else class="t-col-photo">
                                        <CIcon size="4xl" name="cil-image"></CIcon>
                                    </div>
                                </CCol>
                                <CCol>
                                    <CRow>
                                        {{ accessory.name }} ({{ getCategoryName(accessory.category)}})
                                    </CRow>
                                    <CRow>
                                        {{ getSupplierName(accessory.supplier) }}
                                    </CRow>
                                </CCol>
                            </CRow>
                            <template #popper>
                                <CContainer class="p-2 popover-container">
                                    <CRow class="m-0 d-inline-flex flex-nowrap">
                                        <CCol class="d-flex my-2 me-2 p-0">
                                            <CImage class="t-col-photo rounded cursor-pointer" v-if="accessory.images.length > 0" :src="accessory.images[0].tiny"></CImage>
                                        </CCol>
                                        <CCol xs="auto" class="mt-1 mb-2 border-start my-2">
                                            <CRow>
                                                <CCol class="d-flex">
                                                    <span class="fs-4 fw-bold text-start text-nowrap">{{ accessory.name ?? "-" }}</span>
                                                </CCol>
                                            </CRow>
                                            <CRow class="flex-nowrap">
                                                <CCol>
                                                    <CRow class="mt-2">
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.category") }}:</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.supplier") }}:</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.lastUpdate") }}:</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.accessoryPrice") }}:</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.unitOfMeasurement") }}:</span>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol>
                                                    <CRow class="mt-2">
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start text-nowrap">{{ getCategoryName(accessory.category) }}</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start text-nowrap">{{ getSupplierName(accessory.supplier) }}</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start text-nowrap">{{ getTimeDifferenceText(accessory.lastUpdateTimestamp) }}</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start text-nowrap">{{ accessory.price ?? "0" }}</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol class="d-flex">
                                                            <span class="fs-5 text-start text-nowrap">{{ accessory.unitOfMeasurement ?? "-" }}</span>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CContainer>
                            </template>
                        </VMenu>
                    </CListGroupItem>
                    <CListGroupItem v-if="isLoadingNewContent" class="d-flex justify-content-center p-5">
                        <CSpinner color="primary"></CSpinner>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
            <CRow class="justify-content-center align-content-center">
                <span class="text-center text-nowrap" v-if="accessories.length === totalItems && !isLoadingNewContent">{{ t("generic.noMoreResults") }}</span>
            </CRow>
        </CModalBody>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    ref, watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";
import BaseLightbox from "@/components/base/BaseLightbox";
import { getTimeDifferenceText } from "@/views/utilities";

export default {
    name: "AccessoryPicker",
    emits: [
        "selectAccessory",
        "update:isVisible",
        "createNew",
    ],
    components: { BaseLightbox, },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const isLightboxVisible = ref(false);
        const selectedImageIndex = ref(0);
        const lightboxImages = ref([]);
        const accessories = ref([]);
        const accessoriesSearchFields = Object.freeze({
            name: "generic.name",
            ingredientAndNote: "generic.notes",
            supplier: "generic.suppliers",
        });
        const searchValue = ref("");
        const searchField = ref("");

        const currentPage = ref(0);
        const totalItems = ref(0);
        const isLoadingNewContent = ref(false);
        const previousScrollTop = ref(0);

        function closeModal () {
            searchValue.value = "";
            searchField.value = "";
            emit("update:isVisible", false);
        }

        function openLightbox (accessory) {
            selectedImageIndex.value = 0;
            lightboxImages.value = accessory.images.map((i) => i.compress);
            isLightboxVisible.value = true;
        }

        function closeLightbox () {
            lightboxImages.value = [];
            isLightboxVisible.value = false;
        }

        function getCategoryName (category) {
            if (category.isDefault) {
                return t(category.name);
            }

            return category.name;
        }

        function getSupplierName (supplier) {
            if (supplier.isDefault) {
                return t(supplier.name);
            }

            return supplier.name;
        }

        function selectAccessory (accessory) {
            emit("selectAccessory", accessory);

            closeModal();
        }

        function createNew () {
            const name = searchValue.value;
            emit("createNew", name);
            closeModal();
        }

        async function getNewElements (event) {
            if (event.target.scrollTop > previousScrollTop.value) {
                previousScrollTop.value = event.target.scrollTop;

                if (event.target.scrollTop + event.target.offsetHeight + 1 >= event.target.scrollHeight) {
                    isLoadingNewContent.value = true;
                    await newElementsRequest();
                }
            }
        }

        const newElementsRequest = debounce(async () => {
            currentPage.value ++;
            accessories.value = [ ...accessories.value, ...await getAllFilteredElements(), ];
            isLoadingNewContent.value = false;
        }, 50);

        async function getAllFilteredElements () {
            const response = await user.getAllAccessories({
                archived: false,
                filterField: "",
                filterValue: searchValue.value,
                page: currentPage.value,
                size: 20,
            });
            totalItems.value = response.totalItems;

            return response.accessories ?? [];
        }

        watch(async () => props.isVisible, async (newValue) => {
            if (newValue) {
                currentPage.value = 0;
                previousScrollTop.value = 0;
                accessories.value = await getAllFilteredElements();
            }
        });

        const onInputSearchValue = debounce(async () => {
            currentPage.value = 0;
            previousScrollTop.value = 0;
            accessories.value = await getAllFilteredElements();
        }, 800);

        return {
            t,
            accessories,
            searchValue,
            searchField,
            isLightboxVisible,
            lightboxImages,
            accessoriesSearchFields,
            onInputSearchValue,
            totalItems,
            isLoadingNewContent,
            getNewElements,
            closeModal,
            openLightbox,
            closeLightbox,
            getCategoryName,
            getSupplierName,
            selectAccessory,
            createNew,
            getTimeDifferenceText,
        };
    },
};
</script>

<style scoped>

</style>
