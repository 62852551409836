<template>
    <base-multi-select
        :options="formattedOptions"
        :max-one-selection="true"
        :clear-all="false"
        @change="selectOption"
    ></base-multi-select>
</template>

<script>
import BaseMultiSelect from "@/components/base/BaseMultiSelect";
import {
    computed,
    onMounted,
    ref,
    watch,
} from "vue";

export default {
    name: "SearchSelect",
    emits: [ "update:modelValue", ],
    props: {
        modelValue: {
            type: String,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        BaseMultiSelect,
    },
    setup (props, { emit, }) {
        const formattedOptions = computed(() => {
            let options = [];

            props.options.forEach((option) => {
                options = [ ...options, {
                    text: option.name,
                    value: option.id,
                    isDefault: option.isDefault,
                    selected: props.modelValue ? JSON.parse(props.modelValue) === option.id : false,
                }, ];
            });

            return options;
        });

        function selectOption (option) {
            console.log("EMIT");
            if (option) {
                console.log(option);
                emit("update:modelValue", option.value);
            }
        }

        return {
            formattedOptions,
            selectOption,
        };
    },
};
</script>

<style scoped>

</style>
