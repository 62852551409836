<template>
    <CToaster placement="top-end">
        <template v-for="notification in notifications" :key="notification.timestamp">
            <CToast class="bg-white" @mouseover="onHoverNotification(notification)" @mouseleave="onLeaveNotification(notification)" :autohide = false>
                <CToastHeader>
                    <svg class="rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
                        <rect width="100%" height="100%" :fill="getColorByType(notification.type)"></rect>
                    </svg>
                    <span class="me-auto fw-bold">{{ notification.header}}</span>
                    <small>{{ notification.timestamp }}</small>
                </CToastHeader>
                <CToastBody>
                    <span>{{ notification.body }}</span>
                    <CLoadingButton size="sm" class="ms-2" v-if="notification.functionName"
                        :loading="notification.isConfirmButtonLoading" :disabled="notification.isConfirmButtonLoading" type="button"
                        color="primary" @click="() => { notification.function(); notification.isConfirmButtonLoading = true; }">{{
                        notification.functionName }}</CLoadingButton>
                </CToastBody>
            </CToast>
        </template>
    </CToaster>
</template>

<script>
export default {
    name: "AppToaster",
    computed: {
        notifications () {
            return this.$store.state.globalNotifications;
        },
    },
    methods: {
        getColorByType (type) {
            switch (type) {
                case "warning":
                    return "red";
                default:
                    return "blue";
            }
        },
        onHoverNotification (notification) {
            notification.onHover = true;
        },
        onLeaveNotification (notification) {
            notification.onHover = false;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
