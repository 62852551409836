<template>
    <VMenu :placement="placement">
        <div ref="popoverToggler">
            <slot name="toggler"></slot>
        </div>
        <template #popper>
            <CContainer class="p-2 popover-container" v-if="product"  ref="popoverContainer">
                <CRow class="m-0 d-inline-flex flex-nowrap">
                    <CCol class="d-flex my-2 me-2 p-0">
                        <CImage
                        @click="store.commit('openLightbox', { images: product.images.map((i) => i.compress), index: 0, })"
                         class="t-col-photo rounded cursor-pointer"
                          v-if="product.images?.length > 0"
                           :src="product.images[0].tiny"
                           >
                        </CImage>
                        <CIcon v-else icon="cil-image" size="4xl"></CIcon>
                    </CCol>
                    <CCol xs="auto" class="mt-1 mb-2 my-2">
                        <div>
                            <span class="fs-5 text-info text-start text-nowrap">{{ product.productTemplateId ?? "-" }}</span><br>
                            <span class="fs-5 fw-semibold text-start text-nowrap">{{ product.name ?? "-" }}</span>
                        </div>
                        <CRow class="flex-nowrap">
                            <CCol>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start fw-light text-nowrap">{{ t("generic.unitPrice") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start fw-light text-nowrap">{{ t("generic.category") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start fw-light text-nowrap">{{ t("generic.tags") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start fw-light text-nowrap">{{ t("generic.creationDate") }}:</span>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start text-nowrap">{{ product.cost?.price ?? "-"}}</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start text-nowrap">
                                            {{ product.category.isDefault ? t(product.category.name) : product.category.name }}
                                        </span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start text-nowrap" v-if="product.tags?.length === 0">-</span>
                                        <span class="fs-6 text-start text-nowrap pe-1" v-for="tag in product.tags" :key="tag">
                                            {{ tag.isDefault ? t(tag.name) : tag.name }}
                                        </span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-6 text-start text-nowrap">
                                            {{ creationDate(product.creationTimestamp) }}
                                        </span>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <div class="flex-nowrap mt-2 pt-2 border-top" style="max-width: 300px">
                            <div v-for="(descriptor, index) in product.tissues" :key="descriptor.id">
                                <span class="text-info fs-5">
                                    {{ index + 1 }}.{{ descriptor.tissue.name }} ({{ descriptor.tissue.category.isDefault ? t(descriptor.tissue.category.name) : descriptor.tissue.category.name }})
                                    ({{ descriptor.tissue.supplier.isDefault ? t(descriptor.tissue.supplier.name) : descriptor.tissue.supplier.name }})
                                </span>
                                <br>
                                <div v-if="descriptor.printPattern">
                                    <span class="fs-6">
                                        {{ t("generic.printPattern") }}: {{ descriptor.printPattern.name }}
                                    </span>
                                    <br>
                                </div>
                                <span class="fs-6">
                                    {{ t("model.cropNotes") }}: {{ descriptor.cropNotes ?? "-" }}
                                </span>
                                <br>
                                <span class="fs-6">
                                    {{ t("generic.colorRule") }}: {{ getTissueColorRule(descriptor) }}
                                </span>
                                <div v-if="descriptor.printPattern">
                                    <span class="fs-6">
                                        {{ t("generic.printPatternColorRule") }}: {{ getPrintPatternColorRule(descriptor) }}
                                    </span>
                                    <br>
                                </div>
                            </div>
                            <div v-for="(descriptor) in product.accessories" :key="descriptor.accessory.id">
                                <span class="text-info fs-5">
                                    {{ descriptor.accessory.name }} ({{ descriptor.accessory.category.isDefault ? t(descriptor.accessory.category.name) : descriptor.accessory.category.name  }})
                                    ({{ descriptor.accessory.supplier.isDefault ? t(descriptor.accessory.supplier.name) : descriptor.accessory.supplier.name }})
                                </span>
                                <br>
                                <span class="fs-6">
                                    {{ t("generic.colorRule") }}: {{ getAccessoryColorRule(descriptor) }}
                                </span>
                            </div>
                        </div>
                        <div class="mt-2 pt-2 border-top text-container">
                            <span class="text-wrap fs-6">{{ getProcedureList(product.procedures) }}</span>
                        </div>
                        <div v-if="product.note?.length > 0" class="mt-2 pt-2 border-top text-container">
                            <span class="text-wrap fs-6">{{ product.note }}</span>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </template>
    </VMenu>
</template>

<script>
import { useI18n } from "vue-i18n";
import { getFormattedDate } from "@/views/utilities";
import {
    onMounted,
    ref, watch,
} from "vue";
import { useStore } from "vuex";
import { user } from "@/user";

export default {
    name: "ProductPopover",
    props: {
        product: {
            type: Object,
        },
        colorsArray: {
            type: Array,
            default: undefined,
        },
        hoverble: {
            type: Boolean,
            default: true,
        },
        placement: {
            type: String,
            default: "right",
        },
    },
    setup (props) {
        const { t, } = useI18n();
        const colors = ref([]);
        const contentMouseover = ref(false);
        const popoverContainer = ref(null);
        const popoverToggler = ref(null);
        const store = useStore();
        const element = ref(null);

        function getPrintPatternColorRule (descriptor) {
            let string = "";
            if (descriptor.printPatternUsePreorderColor) {
                string += t("model.followOrderColor");
            }
            else if (descriptor.printPatternNoColor) {
                string += t("model.noColor");
            }
            else if (descriptor.printPatternColorId !== null) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(descriptor.printPatternColorId, 10));
                if (color) {
                    string += color.isDefault ? t(color.name) : color.name;
                }
            }

            if (descriptor.printPatternColorRules?.length > 0) {
                string += ` (${ t("generic.hasColorRules")})`;
            }

            return string;
        }
        function getAccessoryColorRule (accessory) {
            if (accessory.usePreorderColor) {
                return t("model.followOrderColor");
            }
            else if (accessory.noColor) {
                return t("model.noColor");
            }
            else if (accessory.colorId !== null) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(accessory.colorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR ID";
            }
            return "ERROR NO MATCH";
        }
        function getTissueColorRule (descriptor) {
            let string = "";
            if (descriptor.tissue.category.name === "generic.whiteEmbryo") {
                string += t("ingredient.tissue.noColorForWhiteEmbryo");
            }
            else if (descriptor.tissueUsePreorderColor) {
                string += t("model.followOrderColor");
            }
            else if (descriptor.tissueColorId !== null) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(descriptor.tissueColorId, 10));
                if (color) {
                    string += color.isDefault ? t(color.name) : color.name;
                }
            }

            if (descriptor.tissueColorRules?.length > 0) {
                string += ` (${ t("generic.hasColorRules")})`;
            }

            return string;
        }

        function getProcedureList (procedures) {
            let list = "";
            for (const p of procedures) {
                list += p.procedure.isDefault ? t(p.procedure.name) : p.procedure.name;
                if (p.note && p.note.length > 0) {
                    list += `(${p.note})`;
                }
                if (p !== procedures[procedures.length - 1]) {
                    list += ", ";
                }
            }

            return list;
        }

        function showPopover (e) {
            if (props.hoverble && !contentMouseover.value && e.target.id === "popoverToggler" && !element.value) {
                e.target.click();
                element.value = e.target;
            }
        }

        function hidePopover (e) {
            if (props.hoverble && e.fromElement === popoverToggler.value) {
                setTimeout(() => {
                    if (!contentMouseover.value && element.value) {
                        element.value.click();
                        element.value = null;
                    }
                }, 50);
            }
        }

        function holdPopover () {
            if (props.hoverble) {
                contentMouseover.value = true;
            }
        }

        function leavePopoverContent (e) {
            if (props.hoverble && e.fromElement === popoverContainer.value.$el && element.value) {
                contentMouseover.value = false;
                element.value.click();
                element.value = null;
            }
        }

        watch(element, async () => {
            if (props.colorsArray) {
                colors.value = props.colorsArray;
            }
            else {
                colors.value = await user.getAllColors();
            }
        });

        return {
            t,
            creationDate: getFormattedDate,
            getTissueColorRule,
            getPrintPatternColorRule,
            getAccessoryColorRule,
            getProcedureList,
            contentMouseover,
            showPopover,
            hidePopover,
            holdPopover,
            leavePopoverContent,
            popoverContainer,
            popoverToggler,
            store,
            colors,
        };
    },
};
</script>

<style scoped>
.text-container {
    width: 200px;
}
</style>
