<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <CForm @submit.prevent="submitCreate">
            <CModalHeader :close-button="false">
                <CModalTitle class="ms-3">{{ t("procedure.addNew") }}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CRow class="pt-3 ps-3 pe-3">
                    <CFormLabel class="fs-5 ps-0">{{ t("procedure.type") }}</CFormLabel>
                    <CFormSelect v-model:model-value="newElement.procedureTypeId">
                        <option v-for="type in procedureTypes" :key="type.id.toString()" :value="type.id.toString()">{{ t(type.name) }}</option>
                    </CFormSelect>
                </CRow>
                <CRow class="pt-3 ps-3 pe-3">
                    <CFormLabel class="fs-5 ps-0">{{ t("generic.name") }} *</CFormLabel>
                    <CFormInput type="text" v-model:model-value="newElement.name" :invalid="errorMessage.length > 0" required/>
                    <CFormFeedback invalid>
                        {{ errorMessage }}
                    </CFormFeedback>
                </CRow>
                <CRow class="pt-3 ps-3 pe-3">
                    <CFormLabel class="fs-5 ps-0">{{ t("procedure.pricingMethod") }}</CFormLabel>
                    <CFormCheck type="radio" value="fixed" :label="t('procedure.fixedPrice')" name="pricingMethod" @change="getSelectedPricingMethod" :model-value="true"></CFormCheck>
                    <CFormCheck type="radio" value="variable" :label="t('procedure.variablePrice')" name="pricingMethod" @change="getSelectedPricingMethod"></CFormCheck>
                </CRow>
                <CRow class="pt-3 ps-3 pe-3">
                    <CFormLabel class="fs-5 ps-0">{{ t("procedure.price") }}</CFormLabel>
                    <CFormInput type="text" v-model:model-value="newElement.price" :disabled="!newElement.isFixedPrice"/>
                </CRow>
                <CRow class="pt-3 ps-3 pe-3">
                    <CFormLabel class="fs-5 ps-0">{{ t("procedure.unitPrice") }}</CFormLabel>
                    <CFormCheck type="radio" value="per piece" :label="t('procedure.pricePerPiece')" name="unitPrice" @change="getSelectedUnitPrice" :model-value="true"></CFormCheck>
                    <CFormCheck type="radio" value="per kg" :label="t('procedure.pricePerKg')" name="unitPrice" @change="getSelectedUnitPrice"></CFormCheck>
                    <CFormCheck type="radio" value="per single" :label="t('procedure.pricePerUnit')" name="unitPrice" @change="getSelectedUnitPrice"></CFormCheck>
                </CRow>
                <CCol class="mt-3 p-1 bg-body bg-gradient">
                    <h5 class="text-info">{{ t("generic.requiredFieldMsg") }}</h5>
                </CCol>
            </CModalBody>
            <CModalFooter>
                <CButton type="button" variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
                <CLoadingButton :loading="isConfirmButtonLoading" :disabled="isConfirmButtonLoading" type="submit" color="primary">{{ t("generic.confirm") }}</CLoadingButton>
            </CModalFooter>
        </CForm>
    </CModal>
</template>

<script>
import {
    onMounted,
    ref,
    watch,
} from "vue";
import { user } from "@/user";
import { useI18n } from "vue-i18n";

export default {
    name: "ProcedureAddModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        procedureTypesArray: {
            type: Array,
        },
    },
    emits: [ "submit", "update:isVisible", ],
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const procedureTypes = ref([]);
        const errorMessage = ref("");
        const newElement = ref({
            name: "",
            price: "",
            isFixedPrice: true,
            unitOfMeasurement: "per piece",
            creationTimestamp: "",
            procedureTypeId: "1",
        });

        const isConfirmButtonLoading = ref(false);

        function closeModal () {
            emit("update:isVisible", false);
        }

        function resetNewElement () {
            for (const key of Object.keys(newElement.value)) {
                if (Array.isArray(newElement[key])) {
                    newElement.value[key] = [];
                }
                else {
                    newElement.value[key] = "";
                }
            }

            newElement.value.unitOfMeasurement = "per piece";
            newElement.value.isFixedPrice = true;
            newElement.value.procedureTypeId = "1";
        }

        async function submitCreate () {
            isConfirmButtonLoading.value = true;

            try {
                const response = await user.createNewProcedure(newElement.value);
                if (response) {
                    emit("submit", response);
                    resetNewElement();
                    closeModal();
                }
            }
            catch {}

            isConfirmButtonLoading.value = false;
        }

        function getSelectedPricingMethod (event) {
            if (event.target.value === "fixed") {
                newElement.value.isFixedPrice = true;
            }
            else {
                newElement.value.isFixedPrice = false;
                newElement.value.price = "";
            }
        }

        function getUnitPriceText (unit) {
            if (unit === "per piece") {
                return t("procedure.pricePerPiece");
            }

            if (unit === "per kg") {
                return t("procedure.pricePerKg");
            }

            return t("procedure.pricePerUnit");
        }

        function getSelectedUnitPrice (event) {
            newElement.value.unitOfMeasurement = event.target.value;
        }

        async function init () {
            procedureTypes.value = props.procedureTypesArray ? props.procedureTypesArray : await user.getAllProcedureTypes();
        }

        watch(() => props.isVisible, async () => {
            await init();
            resetNewElement();
        });

        return {
            t,
            newElement,
            procedureTypes,
            errorMessage,
            isConfirmButtonLoading,
            closeModal,
            resetNewElement,
            submitCreate,
            getSelectedPricingMethod,
            getUnitPriceText,
            getSelectedUnitPrice,
        };
    },
};
</script>

<style scoped>

</style>
