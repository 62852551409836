<template>
    <router-view />
    <AppLightbox :is-lightbox-visible="store.state.isLightboxVisible" :selected-image-index="store.state.selectedImageIndex" :images="store.state.lightboxImages"></AppLightbox>
</template>
<script>
import AppLightbox from "@/components/AppLightbox";
import { useStore } from "vuex";
import { user } from "@/user";
export default {
    name: "App",
    components: {
        AppLightbox,
    },
    setup () {
        const store = useStore();

        if (user.descriptor) {
            let link = document.querySelector("link[rel~='icon']");
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
            link.href = `https://www.wetex.me:3000/images/tiny/company_${user.descriptor.companyId}.ico`;
        }
        window.onfocus = async () => {
            await store.dispatch("reloadPage");
        };
        // Reload if user no action
        // let oldTime = new Date().getTime();
        // let currentTime;
        // const checkTimeout = async (timeout) => {
        //     currentTime = new Date().getTime();
        //     if (currentTime - oldTime >= timeout) {
        //         await store.dispatch("reloadPage");
        //     }
        // };
        // window.onload = async () => {
        //     window.setInterval(() => checkTimeout(10*60*1000), 1000);

        //     window.document.onmouseover = () => {
        //         oldTime = new Date().getTime();
        //     };
        // };

        return {
            store,
        };
    },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
