import { h, resolveComponent } from "vue";
import {
    createRouter,
    createWebHashHistory,
} from "vue-router";
import { i18n } from "@/i18n";
const { t, } = i18n.global;

import DefaultLayout from "@/layouts/DefaultLayout";
import { user } from "@/user";
const routes = [ {
    path: "/",
    name: "brandName",
    component: DefaultLayout,
    redirect: "/todo-list",
    children: [
        {
            path: "/account",
            name: "account.management",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            children: [ {
                path: "/setting/permission-control",
                name: "sidebar.permissionControl",
                component: () => import("@/views/account/PermissionControl"),
            }, ],
        },
        {
            path: "/todo-list",
            name: "sidebar.todoList",
            component: () => import("@/views/todoList/TodoList.vue"),
        },
        {
            path: "/analytics",
            name: "sidebar.analytics",
            component: () => import("@/views/analytics/Analytics.vue"),
        },
        {
            path: "/preorder",
            name: "sidebar.preorderList",
            redirect: "/preorder/product",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            children: [
                {
                    path: "/preorder/product",
                    name: "sidebar.byProduct",
                    component: () => import("@/views/preorder/PreorderByProduct.vue"),
                },
                {
                    path: "/preorder/order",
                    name: "sidebar.byOrder",
                    component: () => import("@/views/preorder/PreorderByOrder.vue"),
                },
                {
                    path: "/preorder/stock",
                    name: "sidebar.storage",
                    component: () => import("@/views/preorder/ProductStock.vue"),
                },
                {
                    path: "/preorder/product-page/:id",
                    name: "sidebar.preorderByProductPage",
                    component: () => import("@/views/preorder/PreorderByProductPage.vue"),
                    props: true,
                },
                {
                    path: "/preorder/order-page/:id",
                    name: "sidebar.preorderByOrderPage",
                    component: () => import("@/views/preorder/PreorderByOrderPage.vue"),
                    props: true,
                },
            ],
        },
        {
            path: "/production",
            name: "sidebar.productionPlanning",
            redirect: "/production/waiting-production",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            children: [
                {
                    path: "/production/waiting-production",
                    name: "sidebar.productionOrderWaitingOrderList",
                    component: () => import("@/views/production/WaitingProductionOrderList.vue"),
                },
                {
                    path: "/production/planned-production",
                    name: "sidebar.productionOrderPlannedOrderList",
                    component: () => import("@/views/production/PlannedProductionOrderList.vue"),
                },
                {
                    path: "/production/purchase",
                    name: "sidebar.purchaseHelper",
                    component: () => import("@/views/production/PurchaseHelper.vue"),
                },
                {
                    path: "/production/:id",
                    name: "sidebar.production",
                    component: () => import("@/views/production/ProductionPage.vue"),
                    props: true,
                },
            ],
        },
        {
            path: "/dyeing",
            name: "sidebar.dyeingOrder",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            redirect: "/dyeing/waiting-dyeing",
            children: [ {
                path: "/dyeing/waiting-dyeing",
                name: "sidebar.dyeingOrderWaitingOrderList",
                component: () => import("@/views/dyeing/WaitingDyeingOrderList.vue"),
            }, {
                path: "/dyeing/planned-dyeing",
                name: "sidebar.dyeingOrderPlannedOrderList",
                component: () => import("@/views/dyeing/PlannedDyeingOrderList.vue"),
            }, ],
        },
        {
            path: "/front-behind-print",
            name: "sidebar.frontBehindPrint",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            redirect: "/front-behind-print/waiting",
            children: [ {
                path: "/front-behind-print/waiting",
                name: "sidebar.frontBehindPrintOrderWaitingList",
                component: () => import("@/views/frontBehindPrint/WaitingOrderList.vue"),
            }, {
                path: "/front-behind-print/planned",
                name: "sidebar.frontBehindPrintOrderPlannedList",
                component: () => import("@/views/frontBehindPrint/PlannedOrderList.vue"),
            }, ],
        },
        {
            path: "/shipping",
            name: "sidebar.shipping",
            component: () => import("@/views/shipping/Shipping.vue"),
        },
        {
            path: "/product-list",
            name: "sidebar.productList",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            redirect: "/product-list/product",
            children: [
                {
                    path: "/product-list/product",
                    name: "sidebar.products",
                    component: () => import("@/views/productList/Product.vue"),
                    props: true,
                },
                {
                    path: "/product-list/template",
                    name: "sidebar.productTemplates",
                    component: () => import("@/views/productList/Template.vue"),
                },
                {
                    path: "/product-list/product/:id",
                    name: "sidebar.product",
                    component: () => import("@/views/productList/ProductPage.vue"),
                    props: true,
                },
            ],
        },
        {
            path: "/ingredient",
            name: "sidebar.ingredients",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            redirect: "/ingredient/tissue",
            children: [
                {
                    path: "/ingredient/tissue",
                    name: "sidebar.tissues",
                    component: () => import("@/views/ingredient/Tissue.vue"),
                },
                {
                    path: "/ingredient/accessory",
                    name: "sidebar.accessories",
                    component: () => import("@/views/ingredient/Accessory.vue"),
                },
                {
                    path: "/ingredient/print-pattern",
                    name: "sidebar.printPatterns",
                    component: () => import("@/views/ingredient/PrintPattern.vue"),
                },
                {
                    path: "/ingredient/print-picture",
                    name: "sidebar.printPictures",
                    component: () => import("@/views/ingredient/PrintPicture.vue"),
                },
            ],
        },
        {
            path: "/procedure",
            name: "sidebar.procedures",
            component: () => import("@/views/procedure/Procedure.vue"),
        },
        {
            path: "/contact",
            name: "sidebar.contacts",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            redirect: "/contact/supplier",
            children: [ {
                path: "/contact/supplier",
                name: "sidebar.suppliers",
                component: () => import("@/views/contact/Supplier.vue"),
            }, {
                path: "/contact/customer",
                name: "sidebar.customers",
                component: () => import("@/views/contact/Customer.vue"),
            }, ],
        },
        {
            path: "/setting",
            name: "sidebar.settings",
            component: {
                render () {
                    return h(resolveComponent("router-view"));
                },
            },
            redirect: "/setting/product-property",
            children: [
                {
                    path: "/setting/product-property",
                    name: "sidebar.productProperties",
                    component: () => import("@/views/setting/ProductProperty.vue"),
                },
                {
                    path: "/setting/category",
                    name: "sidebar.categories",
                    component: () => import("@/views/setting/Category.vue"),
                },
                {
                    path: "/setting/tag",
                    name: "sidebar.tags",
                    component: () => import("@/views/setting/Tag.vue"),
                },
                {
                    path: "/setting/wifi-printer",
                    name: "sidebar.wifiPrinters",
                    component: () => import("@/views/setting/WifiPrinter.vue"),
                },
            ],
        },
    ],
}, {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
        render () {
            return h(resolveComponent("router-view"));
        },
    },
    children: [
        {
            path: "404",
            name: "Page404",
            component: () => import("@/views/pages/Page404"),
        },
        {
            path: "500",
            name: "Page500",
            component: () => import("@/views/pages/Page500"),
        },
        {
            path: "login",
            name: "Login",
            component: () => import("@/views/pages/Login"),
        },
        {
            path: "register",
            name: "Register",
            component: () => import("@/views/pages/Register"),
        },
        {
            path: "/print-pattern/:productionId",
            name: "PrintPatternPage",
            component: () => import("@/views/pages/PrintPatternPage"),
            props: true,
        },
        {
            path: "/tissue/:productionId",
            name: "TissuePage",
            component: () => import("@/views/pages/TissuePage"),
            props: true,
        },
        {
            path: "/iron-factory",
            name: "IronFactory",
            component: () => import("@/views/shipping/IronFactoryTable"),
        },
    ],
}, ];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior () {
        return { top: 0, };
    },
});

router.beforeEach(async (to, from) => {
    document.title = `${t(to.name) } - WeTEX`;
    if (!user.isLoggedIn) {
        if (to.name !== "Login" && to.name !== "TissuePage" && to.name !== "PrintPatternPage") {
            return { name: "Login", };
        }
    }
    else if(!user?.descriptor.isAdmin && !isSpecialPage(to.name) && !canGoToIronFactory(to.name)) {
        if (!user.descriptor?.permissions.includes(`permission.access.${to.name}`) && to.name !== "Page500") {
            return { name: "Page500", };
        }
    }
});

function isSpecialPage (pageName) {
    return pageName === "sidebar.product"
        || pageName === "sidebar.preorderByProductPage"
        || pageName === "sidebar.preorderByOrderPage"
        || pageName === "sidebar.production";
}

function canGoToIronFactory (pageName) {
    if (pageName !== "IronFactory") {
        return false;
    }

    return user.descriptor?.permissions.includes("permission.access.sidebar.shipping");
}

export default router;
