<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">

        <CModalHeader>
            <CModalTitle>{{ t("generic.importHistoricalColors") }}</CModalTitle>
        </CModalHeader>

        <CContainer>
            <CRow class="py-1 px-2">
                <CFormLabel>{{ t("generic.template") }}:</CFormLabel>
                <BaseMultiSelect :options="formattedTemplates" @change="selectTemplate"></BaseMultiSelect>
            </CRow>
            <CRow class="py-1 px-2">
                <CFormLabel>{{ t("generic.models") }}:</CFormLabel>
                <BaseMultiSelect :options="formattedProducts" @change="selectProduct"></BaseMultiSelect>
            </CRow>
            <CRow class="py-1 px-2">
                <CFormLabel>{{ t("generic.customers") }}:</CFormLabel>
                <BaseMultiSelect :options="formattedCustomers" @change="selectCustomer"></BaseMultiSelect>
            </CRow>
            <CRow class="py-3 ps-4 pe-2">
                <CListGroup class="overflow-auto color-list">
                    <CListGroupItem component="button" v-for="color in colors" :key="color.id" @click="toggleSelectColor(color)">
                        <CRow class="ps-0 pb-0">
                            <CCol class="ps-0">
                                <CRow class="ps-3">
                                    <span class="fs-4 py-2">{{ color.isDefault ? t(color.name) : color.name }}</span>
                                </CRow>
                            </CCol>
                            <CCol class="d-flex align-items-center justify-content-end pe-3">
                                <CIcon v-if="isSelectedColor(color.id)" size="xl" icon="cil-check-alt" class="text-info"></CIcon>
                            </CCol>
                        </CRow>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
            <CRow class="p-3">
                <CCol class="p-0">
                    <CButton color="info" @click="selectAll">{{ t("generic.selectAll")}}</CButton>
                </CCol>
                <CCol class="d-flex justify-content-end p-0">
                    <CButton class="me-3" color="secondary" variant="outline" @click="closeModal">{{ t("generic.undo") }}</CButton>
                    <CButton color="primary" @click="confirmSelectedColors">{{ t("generic.confirm") }}</CButton>
                </CCol>
            </CRow>
        </CContainer>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    computed,
    onMounted,
    ref, watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";
import BaseMultiSelect from "@/components/base/BaseMultiSelect";

export default {
    name: "HistoricalColorMultiSelect",
    components: {
        BaseMultiSelect,
    },
    emits: [ "selectColors", "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        selectedColors: {
            type: Array,
        },
        selectedProduct: {
            type: Object,
        },
        selectedCustomer: {
            type: Object,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const allColors = ref([]);
        const colors = ref([]);
        const customers = ref([]);
        const products = ref([]);
        const productTemplates = ref([]);

        const selectedColorsCopy = ref([]);
        const selectedProductTemplates = ref([]);
        const selectedProducts = ref([]);
        const selectedCustomers = ref([]);

        const formattedTemplates = ref([]);
        const formattedProducts = ref([]);
        const formattedCustomers = ref([]);

        function closeModal () {
            emit("update:isVisible", false);
        }

        function toggleSelectColor (color) {
            if (!isSelectedColor(color.id)) {
                selectedColorsCopy.value = [ ...selectedColorsCopy.value, color, ];
            }
            else {
                selectedColorsCopy.value = selectedColorsCopy.value.filter((p) => p.id !== color.id);
            }

        }

        function isSelectedColor (colorId) {
            return Boolean(selectedColorsCopy.value.find((p) => p.id === colorId));
        }

        function confirmSelectedColors () {
            emit("selectColors", selectedColorsCopy.value.filter((sc) => colors.value.find((c) => sc.id === c.id)));
            closeModal();
        }

        watch(async () => props.isVisible, async (newValue, oldValue) => {
            if (newValue) {
                const presentation = await user.historicalColorMultiSelect();
                productTemplates.value = presentation.productTemplateIds;
                customers.value = presentation.customers;
                products.value = presentation.products;
                allColors.value = presentation.colors;

                const preselectedProductTemplate = props.selectedProduct.productTemplateId;
                const preselectedProductId = props.selectedProduct.id;
                const preselectedCustomerId = props.selectedCustomer.id;
                if (preselectedProductTemplate !== undefined) {
                    selectedProductTemplates.value = [ ...productTemplates.value, ];
                    selectedProductTemplates.value.forEach((spt) => {
                        spt.value = spt.id;
                        spt.selected = spt.id === preselectedProductTemplate;
                    });
                    selectedProducts.value = [ ...products.value, ];
                    selectedProducts.value.forEach((sp) => {
                        sp.value = sp.id;
                        sp.selected = sp.id === preselectedProductId;
                    });
                    selectedCustomers.value = [ ...customers.value, ];
                    selectedCustomers.value.forEach((sc) => {
                        sc.value = sc.id;
                        sc.selected = sc.id === preselectedCustomerId;
                    });
                }

                formattedTemplates.value = selectedProductTemplates.value.map((pt) => ({
                    value: pt.id,
                    text: pt.id,
                    selected: pt.selected,
                }));

                formattedProducts.value = selectedProducts.value.map((p) => ({
                    value: p.id,
                    text: `${p.productTemplateId}(${p.name})`,
                    selected: p.selected,
                }));

                formattedCustomers.value = selectedCustomers.value.map((c) => ({
                    value: c.id,
                    text: c.isDefault ? t(c.fullName) : c.fullName,
                    selected: c.selected,
                }));

                selectedColorsCopy.value = [ ...props.selectedColors, ];
                await getColors();
            }
        });

        let firstTimeSelectCustomer = true;
        async function selectCustomer (change) {
            if (firstTimeSelectCustomer) {
                firstTimeSelectCustomer = false;
                return;
            }
            selectedCustomers.value = change;
            await getColors();
        }

        let firstTimeSelectProduct = true;
        async function selectProduct (change) {
            if (firstTimeSelectProduct) {
                firstTimeSelectProduct = false;
                return;
            }
            selectedProducts.value = change;
            await getColors();
        }

        let firstTimeSelectTemplate = true;
        async function selectTemplate (change) {
            if (firstTimeSelectTemplate) {
                firstTimeSelectTemplate = false;
                return;
            }
            selectedProductTemplates.value = change;
            await getColors();
        }

        function selectAll () {
            selectedColorsCopy.value = [ ...colors.value, ];
        }

        async function getColors () {
            colors.value = await user.getHistoricalOrderColors({
                templates: selectedProductTemplates.value.filter((spt) => spt.selected).map((spt) => spt.value),
                products: selectedProducts.value.filter((sp) => sp.selected).map((sp) => sp.value),
                customers: selectedCustomers.value.filter((sc) => sc.selected).map((sc) => sc.value),
            });
            colors.value.sort((c1, c2) => allColors.value.findIndex((c) => c.id === c1.id) - allColors.value.findIndex((c) => c.id === c2.id));
        }

        async function init () {
        }

        onMounted(async () => {
            await init();
        });

        return {
            t,
            colors,
            formattedTemplates,
            formattedProducts,
            formattedCustomers,
            closeModal,
            toggleSelectColor,
            isSelectedColor,
            confirmSelectedColors,
            selectCustomer,
            selectProduct,
            selectTemplate,
            selectAll,
        };
    },
};
</script>

<style scoped>

.color-list {
    max-height: 400px;
}
</style>
