<template>
    <Datepicker
        :model-value="value"
        :enable-time-picker="false"
        model-type="timestamp"
        :locale="locale"
        :format="format"
        :disabled="disabled"
        :auto-apply="true"
        :placeholder="placeholder"
        :select-text="t('generic.select')"
        :cancel-text="t('generic.cancel')"
        ref="datePicker"
    ></Datepicker>
</template>

<script>
import { useI18n } from "vue-i18n";
import Datepicker from "@vuepic/vue-datepicker";
import {
    computed,
    ref,
    watch,
} from "vue";

export default {
    name: "BaseDatePicker",
    emits: [ "update:modelValue", ],
    components: {
        Datepicker,
    },
    props: {
        modelValue: {
            type: [ Date, Number, ],
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
    },
    setup (props, { emit, }) {
        const { t, locale, } = useI18n();
        const datePicker = ref(null);
        const value = ref(props.modelValue);
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        };
        watch(() => props.modelValue, (newValue) => {
            value.value = newValue;
        });

        return {
            t,
            locale,
            datePicker,
            value,
            format,
        };
    },
};
</script>

<style scoped>

</style>
