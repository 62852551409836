<template>
    <div>
        <AppToaster></AppToaster>
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader class="d-print-none" />
            <div class="body flex-grow-1 p-0 std-container bg-body border">
                <template v-if="isLoading" >
                    <AppLoadingScreen :isLoading="isLoading" :on-error="onError"/>
                </template>
                <router-view />
                <PreorderCreateModal
                    :is-global="true"
                ></PreorderCreateModal>
                <ProductAddModal
                    :is-global="true"
                >
                </ProductAddModal>
                <CropPlanCreateModal :is-global="true"></CropPlanCreateModal>
                <PrintReceiptModal :is-global="true"></PrintReceiptModal>
            </div>
<!--            <AppFooter />-->
        </div>
    </div>
</template>
<script>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";
import AppLoadingScreen from "@/components/AppLoadingScreen";
import AppToaster from "@/components/AppToaster";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { user } from "@/user";
import PreorderCreateModal from "@/components/modals/PreorderCreateModal";
import ProductAddModal from "@/components/modals/ProductAddModal";
import CropPlanCreateModal from "@/components/modals/CropPlanCreateModal.vue";
import PrintReceiptModal from "@/components/modals/PrintReceiptModal.vue";

export default {
    name: "DefaultLayout",
    components: {
        ProductAddModal,
        AppToaster,
        // AppFooter,
        AppHeader,
        AppSidebar,
        AppLoadingScreen,
        PreorderCreateModal,
        CropPlanCreateModal,
        PrintReceiptModal,
    },
    setup () {
        const store = useStore();
        const { locale, } = useI18n();
        const isLoading = computed(() => store.state.tabLoading);
        const onError = computed(() => store.state.onError);
        if (store.state.locale && store.state.locale !== locale.value) {
            locale.value = store.state.locale;
        }
        else if (!store.state.locale) {
            store.dispatch("setLanguage", navigator.languages).then(() => {
                locale.value = store.state.locale ?? "zh";
            });
        }
        onMounted(async () => {
            if (user.isLoggedIn) {
                await store.dispatch("connectWs");
            }
        });

        return {
            store,
            isLoading,
            onError,
        };
    },
};
</script>
