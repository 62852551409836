<template>
    <CFormInput
        :type="type"
        :multiple="multiple"
        @input="onInput"
        :placeholder="placeholder"
        v-model:model-value="value"
        :readonly="readonly"
        :required="required"
        :feedbackInvalid="feedbackInvalid"
        :invalid="invalid"
        @click="selectAll"
    ></CFormInput>
</template>

<script>
import {
    onMounted,
    ref,
    watch,
} from "vue";
export default {
    name: "BaseInput",
    emits: [ "update:modelValue", "input", ],
    props: {
        type: {
            type: String,
        },
        multiple: {
            type: Boolean,
        },
        placeholder: {
            type: String,
        },
        modelValue: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        feedbackInvalid: {
            type: String,
        },
        invalid: {
            type: Boolean,
        },
        readonly: {
            type: Boolean,
        },
    },
    setup (props, { emit, }) {
        const value = ref("");

        function onInput (event) {
            emit("input", event.target.value);
            emit("update:modelValue", event.target.value);
        }

        function selectAll (event) {
            event.target.select();
        }

        watch(() => props.modelValue, (newValue) => {
            value.value = newValue;
        });

        onMounted(() => {
            value.value = props.modelValue;
        });

        return {
            value,
            onInput,
            selectAll,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
