<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <CForm @submit.prevent="submitUpdate">
            <CModalHeader :close-button="false">
                <CModalTitle class="ms-3">{{ t("user.update") }}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CInputGroup class="mb-3">
                    <CInputGroupText>#</CInputGroupText>
                    <CFormInput :placeholder="t('user.username')" autocomplete="username" v-model="elementToUpdate.username" disabled/>
                </CInputGroup>
                <CInputGroup class="mb-3">
                    <CInputGroupText>
                        <CIcon icon="cil-user" />
                    </CInputGroupText>
                    <CFormInput :placeholder="t('user.fullName')" autocomplete="fullName" v-model="elementToUpdate.fullName" />
                </CInputGroup>
                <CInputGroup class="mb-3">
                    <CInputGroupText>
                        <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                        type="password"
                        :placeholder="t('user.newPassword')"
                        v-model="password"
                    />
                </CInputGroup>
                <CInputGroup class="mb-4">
                    <CInputGroupText>
                        <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                        type="password"
                        :placeholder="t('user.repeatPassword')"
                        autocomplete="new-password"
                        v-model="repeatPassword"
                    />
                </CInputGroup>
                <span class="fs-5 text-info"> * {{ t("user.updateNote") }}</span>
            </CModalBody>
            <CModalFooter>
                <CCol class="d-flex justify-content-end">
                    <CButton type="button" variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
                    <CButton class="ms-2" type="submit" color="primary">{{ t("generic.confirm") }}</CButton>
                </CCol>
            </CModalFooter>
        </CForm>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { user } from "@/user";
import { useStore } from "vuex";
export default {
    name: "UserUpdateModal",
    props: {
        isVisible: {
            type: Boolean,
        },
    },
    emits: [ "update:isVisible", ],
    setup (props, { emit, }) {
        const store = useStore();
        const { t, } = useI18n();
        const elementToUpdate = ref({
            username: "",
            fullName: "",
        });
        const elementFields = Object.keys(elementToUpdate.value);
        const elementRef = ref(null);
        const password = ref("");
        const repeatPassword = ref("");
        function bindElementToRef (element) {
            elementRef.value = element;
            elementFields.forEach((field) => {
                elementToUpdate.value[field] = Number.isInteger(element[field]) ? element[field].toString() : element[field];
            });
            elementToUpdate.value.id = element.id;
        }
        function closeModal () {
            elementToUpdate.value = {
                username: "",
                fullName: "",
            };
            password.value = "";
            repeatPassword.value = "";
            emit("update:isVisible", false);
        }
        async function submitUpdate () {
            if (password.value !== repeatPassword.value) {
                return;
            }

            if (password.value.length === 0) {
                password.value = undefined;
            }

            const response = await user.updateUser({
                userId: elementToUpdate.value.id,
                password: password.value,
                fullName: elementToUpdate.value.fullName,
            });

            if (response.status === "ok") {
                store.dispatch("sendNotification", {
                    header: t("user.update"),
                    body: t("generic.updatedSuccessfully"),
                }).then();
            }

            closeModal();
        }
        return {
            t,
            elementToUpdate,
            password,
            repeatPassword,
            submitUpdate,
            bindElementToRef,
            closeModal,
        };
    },
};
</script>
