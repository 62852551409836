<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <CForm @submit.prevent="submitUpdate">
            <CModalHeader :close-button="false">
                <CModalTitle class="ms-3">{{ t("ingredient.accessory.update") }}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CNav variant="pills" layout="justified" role="tablist">
                    <CNavItem>
                        <CNavLink
                            href="javascript:void(0);"
                            :active="activeModalTab===0"
                            @click="activeModalTab=0"
                        >
                            {{ t("generic.essentialInformation") }}
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink
                            href="javascript:void(0);"
                            :active="activeModalTab===1"
                            @click="activeModalTab=1"
                        >
                            {{ t("generic.photo") }}
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane role="tabpanel" aria-labelledby="essentials-information-tab" :visible="activeModalTab === 0">
                        <CRow>
                            <CCol :xs="8">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.name") }} *</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="elementToUpdate.name" :invalid="errorMessage.length > 0" required/>
                                    <CFormFeedback invalid>
                                        {{ errorMessage }}
                                    </CFormFeedback>
                                </CInputGroup>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol :xs="8">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.unitPrice") }}</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="elementToUpdate.price"/>
                                </CInputGroup>
                            </CCol>
                            <CCol :xs="4">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.unitOfMeasurement") }}</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="elementToUpdate.unitOfMeasurement"/>
                                </CInputGroup>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol :xs="6">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.suppliers") }}</CFormLabel>
                                    <search-select
                                        :options="suppliers"
                                        v-model:model-value="elementToUpdate.supplierId"
                                    ></search-select>
<!--                                    <CFormSelect v-model:model-value="elementToUpdate.supplierId">-->
<!--                                        <option v-for="supplier in suppliers" :key="supplier.id.toString()" :value="supplier.id.toString()" :selected="isPreselectedSupplierOnCreate(supplier)">{{ supplier.isDefault ? t(supplier.name) : supplier.name }}</option>-->
<!--                                    </CFormSelect>-->
                                </CInputGroup>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol :xs="6">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.category") }}</CFormLabel>
                                    <CFormSelect v-model:model-value="elementToUpdate.categoryId">
                                        <option v-for="category in accessoryCategories" :key="category.id.toString()" :value="category.id.toString()" :selected="isPreselectedCategoryOnCreate(category)">{{ category.isDefault ? t(category.name) : category.name }}</option>
                                    </CFormSelect>
                                </CInputGroup>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("ingredient.ingredientAndNote") }}</CFormLabel>
                                    <CFormTextarea type="text" v-model:model-value="elementToUpdate.ingredientAndNote" />
                                </CInputGroup>
                            </CCol>
                        </CRow>
                        <CCol class="mt-3 p-1 bg-body bg-gradient">
                            <h5 class="text-info">{{ t("generic.requiredFieldMsg") }}</h5>
                        </CCol>
                    </CTabPane>
                    <CTabPane role="tabpanel" aria-labelledby="upload-photos-tab" :visible="activeModalTab === 1">
                        <CCol class="mt-3 p-1 bg-body bg-gradient">
                            <CFormLabel class="w-100 fs-5 mb-1">{{ t("ingredient.accessory.images") }}</CFormLabel>
                            <base-image-uploader @upload="saveImagesToUpdate"></base-image-uploader>
                            <CRow class="mt-4">
                                <CCol v-for="image in images" :key="image">
                                    <div class="t-col-4 cursor-pointer" style="position: relative;">
                                        <CImage class="t-col-4" thumbnail align="start" :src="image" @click="openLightbox(image)"></CImage>
                                        <button class="button-remove-image" @click.prevent="deleteImage(image)">
                                            <span class="icon-cross"></span>
                                        </button>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CTabPane>
                </CTabContent>
            </CModalBody>
            <CModalFooter>
                <CCol>
                    <CButton type="button" variant="outline" v-if="!elementToUpdate.isArchived" color="danger" @click="submitArchive">{{ t("contact.supplier.archive") }}</CButton>
                    <CButton type="button" variant="outline" v-else color="success" @click="submitUnarchive">{{ t("contact.supplier.unarchive") }}</CButton>
                </CCol>
                <CCol class="d-flex justify-content-end">
                    <CButton type="button" variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
                    <CLoadingButton :loading="isConfirmButtonLoading" :disabled="isConfirmButtonLoading" class="ms-3" type="submit" color="primary">{{ t("generic.confirm") }}</CLoadingButton>
                </CCol>
            </CModalFooter>
        </CForm>
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="selectedImageIndex"
            :images="lightBoxImages"
            @hide="closeLightbox"
        ></BaseLightbox>
    </CModal>
</template>

<script>
import {
    onMounted,
    ref,
    watch,
} from "vue";
import { user } from "@/user";
import { readImage } from "@/views/utilities";
import { useI18n } from "vue-i18n";
import BaseLightbox from "@/components/base/BaseLightbox";
import SearchSelect from "@/components/pickers/SearchSelect";
import BaseImageUploader from "@/components/base/BaseImageUploader";

export default {
    name: "AccessoryUpdateModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        suppliersArray: {
            type: Array,
            default: null,
        },
        categories: {
            type: Array,
            default: null,
        },
    },
    emits: [
        "close",
        "submit",
        "archive",
        "unarchive",
        "update:isVisible",
    ],
    components: {
        BaseLightbox,
        SearchSelect,
        BaseImageUploader,
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const errorMessage = ref("");
        const activeModalTab = ref(0);
        const elementRef = ref(null);
        const elementToUpdate = ref({
            name: "",
            price: "",
            unitOfMeasurement: "",
            ingredientAndNote: "",
            lastUpdateTimestamp: "",
            creationTimestamp: "",
            images: [],
            categoryId: "",
            supplierId: "",
            isArchived: false,
        });

        const elementFields = Object.keys(elementToUpdate.value);
        const imagesToUpdate = ref([]);
        const images = ref([]);
        const imagesToDelete = ref([]);
        const lightBoxImages = ref([]);
        const selectedImageIndex = ref(0);
        const isLightboxVisible = ref(false);
        const accessoryCategories = ref([]);
        const suppliers = ref([]);

        const isConfirmButtonLoading = ref(false);

        function closeModal () {
            images.value = [];
            imagesToUpdate.value = [];
            activeModalTab.value = 0;
            emit("update:isVisible", false);
        }
        function openLightbox (image) {
            selectedImageIndex.value = images.value.findIndex((base64ToFind) => base64ToFind === image);
            lightBoxImages.value = images.value;
            isLightboxVisible.value = true;
        }
        function closeLightbox () {
            lightBoxImages.value = [];
            isLightboxVisible.value= false;
        }
        function bindElementToUpdate (element) {
            init().then(() => {
                elementRef.value = element;
                imagesToUpdate.value = [];
                activeModalTab.value = 0;
                elementFields.forEach((field) => {
                    elementToUpdate.value[field] = Number.isInteger(element[field]) ? element[field].toString() : element[field];
                });
                elementToUpdate.value.id = element.id;
                elementToUpdate.value.categoryId = String(element.category.id);
                elementToUpdate.value.supplierId = String(element.supplier.id);

                for (const image of element.images) {
                    images.value = [ ...images.value, image.url, ];
                }
            });
        }
        async function submitUpdate () {
            isConfirmButtonLoading.value = true;

            const formData = new FormData();

            for (const image of imagesToUpdate.value) {
                formData.append("images", image);
            }

            for (const image of imagesToDelete.value) {
                formData.append("imagesToDelete", image);
            }

            for (const [ key, value, ] of Object.entries(elementToUpdate.value)) {
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            }

            try {
                const response = await user.updateAccessory(formData);
                if (response) {
                    emit("submit", { response, element: elementRef.value, });
                    closeModal();
                }
            }
            catch {}

            isConfirmButtonLoading.value = false;
        }

        async function deleteImage (imageDataUrl) {
            if (imageDataUrl.startsWith("http")) {
                const id = elementToUpdate.value.images.find((image) => image.url === imageDataUrl).id;
                imagesToDelete.value = [ ...imagesToDelete.value, id, ];
            }
            imagesToUpdate.value = imagesToUpdate.value.filter((imageToRemove) => imageToRemove.url !== imageDataUrl);
            elementToUpdate.value.images = elementToUpdate.value.images.filter((imageToRemove) => imageToRemove.url !== imageDataUrl);

            images.value = images.value.filter((imageToRemove) => imageToRemove !== imageDataUrl);
        }

        async function saveImagesToUpdate (files) {
            imagesToUpdate.value = [ ...imagesToUpdate.value, ...files, ];

            images.value = elementToUpdate.value.images.map((image) => image.url);

            for (let i=0; i<imagesToUpdate.value.length; i++) {
                imagesToUpdate.value[i].url = await readImage(imagesToUpdate.value[i]);
                images.value = [ ...images.value, imagesToUpdate.value[i].url, ];
            }
        }

        function isPreselectedCategoryOnCreate (category) {
            return category.id.toString() === elementToUpdate.value.categoryId;
        }

        function isPreselectedSupplierOnCreate (supplier) {
            return supplier.id.toString() === elementToUpdate.value.supplierId;
        }

        async function submitUnarchive () {
            try {
                const response = await user.unarchiveAccessories({
                    ids: [ elementToUpdate.value.id, ],
                });
                if (response) {
                    emit("unarchive", { response, element: elementRef.value, });
                    closeModal();
                }
            }
            catch {}
        }

        async function submitArchive () {
            try {
                const response = await user.archiveAccessories({
                    ids: [ elementToUpdate.value.id, ],
                });
                if (response) {
                    emit("archive", { response, element: elementRef.value, });
                    closeModal();
                }
            }
            catch {}
        }

        watch(() => props.suppliersArray, async (newValue) => {
            suppliers.value = props.suppliersArray ? props.suppliersArray : (await user.getAllSuppliers({
                archived: false,
            }))?.suppliers ?? [];
        });

        watch(() => props.accessoryCategories, async (newValue) => {
            accessoryCategories.value = props.categories ? props.categories : await user.getAllAccessoryCategories();
        });

        async function init () {
            accessoryCategories.value = props.categories ? props.categories : await user.getAllAccessoryCategories();
            suppliers.value = props.suppliersArray ? props.suppliersArray : (await user.getAllSuppliers({
                archived: false,
            }))?.suppliers ?? [];
        }

        return {
            t,
            images,
            elementToUpdate,
            imagesToUpdate,
            isLightboxVisible,
            lightBoxImages,
            selectedImageIndex,
            errorMessage,
            activeModalTab,
            accessoryCategories,
            suppliers,
            isConfirmButtonLoading,
            submitUpdate,
            closeModal,
            openLightbox,
            closeLightbox,
            deleteImage,
            saveImagesToUpdate,
            bindElementToUpdate,
            isPreselectedCategoryOnCreate,
            isPreselectedSupplierOnCreate,
            submitArchive,
            submitUnarchive,
        };
    },
};
</script>

<style scoped>

</style>
