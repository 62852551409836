<template>
    <CModal scrollable :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <!-- Lightbox Row-->
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="0"
            :images="lightboxImages"
            @hide="closeLightbox"
        ></BaseLightbox>

        <CModalHeader>
            <CModalTitle>
                <CRow>
                    <CCol>
                        {{ t("ingredient.tissue.addNew") }}
                    </CCol>
                </CRow>
                <CRow class="py-3 ps-4">
                    <div class="d-flex flex-nowrap w-auto ps-0">
                        <CFormSelect
                            v-model:model-value="searchField"
                            class="t-col-2"
                        >
                            <option value="">{{ t("generic.all") }}</option>
                            <option v-for="field in Object.keys(tissuesSearchFields)" :key="field" :value="field">{{ t(tissuesSearchFields[field]) }}</option>
                        </CFormSelect>
                        <CFormInput
                            class="t-col-4"
                            v-model:model-value="searchValue"
                            type="search"
                            :placeholder="t('generic.search')"
                            @input="onInputSearchValue"
                        />
                    </div>
                </CRow>
            </CModalTitle>
        </CModalHeader>

        <CModalBody @scroll="getNewElements">
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem v-if="tissues.length === 0 && searchValue !== ''" component="button" @click="createNew">
                        <span>
                            {{ t("ingredient.tissue.addNew") }} {{ searchValue }}
                        </span>
                    </CListGroupItem>
                    <CListGroupItem component="button" v-for="tissue in tissues" :key="tissue.id" @click="selectTissue(tissue)">
                        <TissuePopover :tissue="tissue" :hoverble="false">
                            <template #toggler="{ on, }">
                                <CRow v-on="on" class="ps-0 pb-0">
                                    <CCol>
                                        <CImage
                                            class="t-col-photo rounded cursor-pointer"
                                            v-if="tissue.images.length > 0" :src="tissue.images[0].tiny"
                                            @click.stop="openLightbox(tissue)"
                                        ></CImage>
                                        <div v-else class="t-col-photo">
                                            <CIcon size="4xl" name="cil-image"></CIcon>
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <CRow>
                                            {{ tissue.name }} ({{ getCategoryName(tissue.category)}})
                                        </CRow>
                                        <CRow>
                                            {{ getSupplierName(tissue.supplier) }}
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </template>
                        </TissuePopover>
                    </CListGroupItem>
                    <CListGroupItem v-if="isLoadingNewContent" class="d-flex justify-content-center p-5">
                        <CSpinner color="primary"></CSpinner>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
            <CRow class="justify-content-center align-content-center">
                <span class="text-center text-nowrap" v-if="tissues.length === totalItems && !isLoadingNewContent">{{ t("generic.noMoreResults") }}</span>
            </CRow>
        </CModalBody>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    ref, watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";
import BaseLightbox from "@/components/base/BaseLightbox";
import TissuePopover from "@/components/popovers/TissuePopover";

export default {
    name: "TissuePicker",
    emits: [
        "selectTissue",
        "update:isVisible",
        "createNew",
    ],
    components: {
        TissuePopover,
        BaseLightbox,
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const isLightboxVisible = ref(false);
        const selectedImageIndex = ref(0);
        const lightboxImages = ref([]);
        const tissues = ref([]);
        const tissuesSearchFields = Object.freeze({
            name: "generic.name",
            ingredientAndNote: "generic.notes",
            supplier: "generic.suppliers",
        });
        const searchValue = ref("");
        const searchField = ref("");

        const currentPage = ref(0);
        const totalItems = ref(0);
        const isLoadingNewContent = ref(false);
        const previousScrollTop = ref(0);

        function closeModal () {
            searchValue.value = "";
            searchField.value = "";
            emit("update:isVisible", false);
        }

        function openLightbox (tissue) {
            selectedImageIndex.value = 0;
            lightboxImages.value = tissue.images.map((i) => i.compress);
            isLightboxVisible.value = true;
        }

        function closeLightbox () {
            lightboxImages.value = [];
            isLightboxVisible.value = false;
        }

        function getCategoryName (category) {
            if (category.isDefault) {
                return t(category.name);
            }

            return category.name;
        }

        function getSupplierName (supplier) {
            if (supplier.isDefault) {
                return t(supplier.name);
            }

            return supplier.name;
        }

        function selectTissue (tissue) {
            emit("selectTissue", tissue);

            closeModal();
        }

        async function getNewElements (event) {
            if (event.target.scrollTop > previousScrollTop.value) {
                previousScrollTop.value = event.target.scrollTop;

                if (event.target.scrollTop + event.target.offsetHeight + 1>= event.target.scrollHeight) {
                    isLoadingNewContent.value = true;
                    await newElementsRequest();
                }
            }
        }

        const newElementsRequest = debounce(async () => {
            currentPage.value ++;
            tissues.value = [ ...tissues.value, ...await getAllFilteredElements(), ];
            isLoadingNewContent.value = false;
        }, 50);

        watch(async () => props.isVisible, async (newValue) => {
            if (newValue) {
                currentPage.value = 0;
                previousScrollTop.value = 0;
                tissues.value = await getAllFilteredElements();
            }
        });

        async function getAllFilteredElements () {
            const response = await user.getAllTissues({
                archived: false,
                filterField: "",
                filterValue: searchValue.value,
                page: currentPage.value,
                size: 20,
            });
            totalItems.value = response.totalItems;

            return response.tissues ?? [];
        }

        function createNew () {
            const name = searchValue.value;
            emit("createNew", name);
            closeModal();
        }

        const onInputSearchValue = debounce(async () => {
            currentPage.value = 0;
            previousScrollTop.value = 0;
            tissues.value = await getAllFilteredElements();
        }, 50);

        return {
            t,
            tissues,
            searchValue,
            searchField,
            isLightboxVisible,
            lightboxImages,
            tissuesSearchFields,
            totalItems,
            isLoadingNewContent,
            closeModal,
            openLightbox,
            closeLightbox,
            getCategoryName,
            getSupplierName,
            selectTissue,
            createNew,
            onInputSearchValue,
            getNewElements,
        };
    },
};
</script>

<style scoped>

</style>
