const it = {
    brandName: "WeTex",
    Italiano: "Italiano",
    中文: "中文",
    "---": "---",
    IronFactory: "Tabella stireria",
    PrintPatternPage: "Pagina stampa",
    TissuePage: "Pagina tessuto",
    placeholder: "Lorem ipsum dolor sit amet",
    notification: {
        enabled: "Notifiche abilitate",
        createOrder: "Nuovo ordine",
        cropPlanCreated: "Piano di taglio creato",
        updateOrder: "Variazione quantita' dell'ordine",
    },
    sidebar: {
        todoList: "Promemoria",
        analytics: "Analisi",
        preorderList: "Prenotazioni",
        preorderByProductPage: "Pagina modello",
        preorderByOrderPage: "Pagina ordine",
        byProduct: "Modelli",
        productList: "Modelli",
        production: "Piano di produzione",
        byOrder: "Ordini",
        storage: "Inventario",
        productionPlanning: "Produzione",
        productionOrderWaitingOrderList: "Ordini in attesa",
        dyeingOrderWaitingOrderList: "Ordini in attesa",
        frontBehindPrintOrderWaitingList: "Ordini in attesa",
        productionOrderPlannedOrderList: "Produzione in corso",
        dyeingOrderPlannedOrderList: "Elenco tinture",
        frontBehindPrintOrderPlannedList: "Produzione in corso",
        purchaseHelper: "Preparazione materie",
        dyeingOrder: "Tintura",
        frontBehindPrint: "Stampa sulla maglia",
        shipping: "Consegna",
        products: "Modelli",
        productTemplates: "Carta modelli",
        ingredients: "Materie prime",
        tissues: "Tessuti",
        accessories: "Accessori",
        printPatterns: "Stampe tessuti",
        printPictures: "Stampe maglie",
        procedures: "Lavorazioni",
        contacts: "Rubrica - Contatti",
        suppliers: "Fornitori",
        customers: "Clienti",
        settings: "Impostazioni",
        productProperties: "Composizione modello",
        categories: "Classificazioni",
        tags: "Etichette",
        wifiPrinters: "Stampanti WiFi",
        permissionControl: "Controllo permessi",
        languages: "Lingua 语言",
        product: "Modello",
    },
    component: {
        confirmProductionModal: "Conferma produzione",
        customerUpdateModal: "Modifica dati cliente",
        productCopyModal: "Copia modello",
        productionTablePreferences: "Tabella ordini per fasonisti",
    },
    order: {
        creationDate: "Data di creazione",
        creationUser: "Utente di creazione",
        addNewSample: "Ordine di magazzino/campione",
        customerComposition: "Composizione",
        customerCompositionImages: "Foto di composizione",
        customerOrder: "Ordine di cliente",
        customerTag: "Etichetta",
        deliveredTimestamp: "Data di consegna",
        deliveredVolume: "Totale consengato",
        deliveryTime: "Data di consegna",
        editNote: "Aggiorna",
        lastUpdateDate: "Data ultima modifica",
        lastUpdateUser: "Utente ultima modifica",
        orderType: "Tipo ordine",
        placeDate: "Data di creazione",
        preprocessing: "Prelavorazione",
        production: "Piano di produzione",
        receivedTimestamp: "Data di ricezione",
        restore: "Ripristina",
        shippingState: "Stato consegnamento",
        tissuesComposite: "Combinazione tessuti",
        total: "Totali",
        customerLogo: "Logo",
        unpaid: "Da pagare",
        updateOrder: "Modifiche ordine",
        stockVolume: "Q. preparati",
        belongsToOrder: "Appartiene all'ordine",
        addNew: "Crea ordine per cliente",
        customers: "Cliente",
        preorderVolume: "Q. prenotata",
        preorderPrice: "Prezzo di prenot.",
        productionChanges: "Note di produzione",
        endDelivery: "Termine consegna",
        deliveryNote: "Note di consegna",
        state: "Stato",
        recentActivities: "Ultime attività",
        update: "Modifica l'ordine",
        changeState: "Cambia lo stato dell'ordine",
        viewProductionPlan: "Mostra il piano di produzione",
        cancel: "Annulla l'ordine",
        delete: "Cancella l'ordine",
        note: "Note di prenotazione",
        noCustomerSelected: "Nessun cliente selezionato",
        dateOrder: "Data d'ordinazione",
        logo: "Logo di prenotazione",
        tag: "Etichetta di prenotazione",
        composition: "Composizione",
        compositionImage: "Immagine di composizione",
        lastWeekTotalCount: "Ordini totali dell'ultima settimana",
        produce: "Produzione",
        toProduce: "Da produrre",
        fromInventory: "Dal magazzino",
        noProduce: "Non produrre",
        tbd: "Da decidere",
        copyInfoTo: "Copia informazioni in",
        copyModel: "Aggiungi copia del modello",
        clear: "Svuota tabella",
        choseWhatYouWantToCopy: "Scegli cosa copiare",
        copyAll: "Copia tutto",
        copyJustTable: "Copia solo le informazioni della tabella",
        copyJustColors: "Copia solo i colori senza quantità",
        copyNone: "Non copiare niente",
        copyOnModel: "Copia sui modelli...",
        copyInfoOnOtherModels: "Copia le informazioni su altri modelli",
        fillRow: "Riempi riga",
        fillColumn: "Riempi colonna",
        deleteRow: "Rimuovi riga",
        deleteColumn: "Rimuovi colonna",
        details: "Dettagli",
        inventoryOrder: "Crea ordine per magazzino",
        templateList: "Carta modelli",
        quantityTemplates: "Q. carta modelli",
        quantityModels: "Q. modelli",
        customerNote: "Note di ordine",
        orderQuantity: "Q. ordinati",
        totalPrice: "Somma tot.",
        deliveryDate: "Data di consegna",
        expirationDate: "Data di scadenza",
        orderState: "Stato",
        orderId: "Id ordine",
        productTemplateId: "Carta modello",
        mainTissue: "Tessuto principlae",
        customer: "Cliente",
        printPattern: "Stampa tessuto",
        accessory: "Accessorio",
        printPicture: "Stampa maglia",
        productionNote: "Note di produzione",
        placeTimestamp: "Data di creazione",
        inProductionTimestamp: "Data in produzione",
        createCropPlanTimestamp: "Data piano di taglio",
        completeCropTimestamp: "Data taglio completato",
        createColorPlanTimestamp: "Data piano colore stampato",
        createPrintPictureTimestamp: "Data piano stampa maglia stampato",
        deliveryTimestamp: "Data di consegna",
        expirationTimestamp: "Data di scadenza",
        insertTag: "Inserisci etichetta",
        closeToExpiration: "Vicino alla scadenza",
        productionFlow: "Produzione",
        importColors: "Importa i colori predefiniti",
        importHistoricalColors: "Importa i colori storici",
        clearColors: "Elimina tutti i colori",
        options: "Opzioni",
        copy: "Copia",
        settingTag: "Imposta etichetta",
        changeProductionMode: "Cambia lo stato di produzione",
    },
    model: {
        updateModel: "Modifica modello",
        mainTissue: "Tessuto principale",
        allTissues: "Tutti i tessuti",
        classification: "Classificazione",
        listPrice: "Prezzo di listino",
        tag: "Etichetta",
        addNew: "Aggiungi un nuovo modello",
        update: "Modifica",
        waste: "Consumo",
        hemming: "Orlatura",
        noteForCutPlaceholder: "Scrivi informazioni utili per il taglio",
        hotGlueRequest: "Richiede colla calda",
        primaryTissue: "Tessuto principale",
        secondaryTissue: "Tessuto secondario",
        followOrderColor: "Segue il colore dell'ordinazione",
        noColor: "Nessun colore",
        separateFromStorage: "Separato dall'inventario",
        images: "Immagine di modello",
        costManager: "Gestione prezzo",
        cropNotes: "Note del taglio",
        copy: "Copia",
        archive: "Archivia il modello",
        unarchive: "Rimuovi il modello dall'archivio",
        print: "Stampa",
        setVisibleOnOrder: "Rendi visibile ai nuoi ordini",
        setNotVisibleOnOrder: "Rendi non visibile ai nuovi ordini",
        insertTag: "Inserisci etichetta",
        lastUpdateTime: "L'ultimo aggiornamento",
        create: "Crea un nuovo modello",
        availableStockVolume: "Quantità disponibile in magazzino",
        delete: "Rimuovi modello",
        note: "Note del modello",
        info: "Informazioni sul modello",
        specialColorRule: "Regola speciale",
    },
    ingredient: {
        ingredientAndNote: "Ingredienti e note",
        tissue: {
            addNew: "Aggiungi un nuovo tessuto",
            images: "Foto del tessuto",
            category: "Tipo di tessuto",
            updateCategory: "Modifica tipo di tessuto",
            update: "Modifica tessuto",
            new: "Crea un nuovo tessuto",
            color: "Colore del tessuto",
            change: "Sostituisci tessuto",
            delete: "Rimuovi tessuto",
            noColorForWhiteEmbryo: "Embrione bianco non ha colori",
        },
        accessory: {
            addNew: "Aggiungi un nuovo accessorio",
            images: "Foto dell'accessorio",
            update: "Modifica accessorio",
            new: "Crea un nuovo accessorio",
            delete: "Rimuovi accessorio",
        },
        printPattern: {
            addNew: "Aggiungi una nuova stampa per tessuti",
            images: "Foto della stampa per tessuti",
            update: "Modifica la stampa per tessuti",
            new: "Crea una nuova stampa per tessuti",
            change: "Cambia la stampa per tessuti",
            delete: "Rimuovi la stampa per tessuti",
            color: "Colore della stampa",
        },
        printPicture: {
            addNew: "Aggiungi una nuova stampa per vestiti",
            images: "Foto della stampa per vestiti",
            update: "Modifica stampa per vestiti",
        },
    },
    procedure: {
        type: "Tipo di lavorazione",
        price: "Prezzo di lavorazione",
        pricingMethod: "Metodo di associazione del prezzo",
        fixedPrice: "Prezzo fisso",
        variablePrice: "Prezzo variabile in base al modello",
        unitPrice: "Unità di misura per il prezzo",
        pricePerPiece: "€ / pezzo",
        pricePerKg: "€ / Kg",
        pricePerUnit: "€ / unità",
        addNew: "Aggiungi una nuova lavorazione",
        update: "Aggiorna la lavorazione",
        new: "Crea una nuova lavorazione",
    },
    contact: {
        notes: "Note",
        creationDate: "Data di creazione",
        category: "Categoria",
        options: "Opzioni",
        updateCategory: "Modifica categoria",
        supplier: {
            name: "Nome",
            addNew: "Aggiungi un nuovo fornitore",
            archive: "Sposta in cestino",
            unarchive: "Ripristina",
            update: "Modifica fornitore",
        },
        customer: {
            fullName: "Nome completo",
            companyName: "Compagnia",
            mobile: "Numero di telefono",
            defaultLogo: "Logo di prenotazione predefinito",
            defaultTag: "Tag di prenotazione predefinito",
            defaultComposition: "Composizione predefinita",
            defaultCompositionImage: "Immagine composizione predefinita",
            addNew: "Aggiungi un nuovo cliente",
            archive: "Sposta in cestino",
            unarchive: "Ripristina",
            update: "Modifica cliente",
            isPreorderDefaultCustomer: "Cliente predefinito di prenotazione",
            isStockOrderDefaultCustomer: "Cliente predefinito di ordine dall'inventario",
            create: "Crea un nuovo cliente",
        },
    },
    settings: {
        productProperty: {
            color: "Colore",
            size: "Dimensione",
            preorderDefault: "Predefinito per le nuove prenotazioni",
            updateColor: "Modifica colore",
            updateSize: "Modifica taglia",
            addNewColor: "Aggiungi un nuovo colore",
            addNewSize: "Aggiungi una nuova taglia",
            addNew: "Aggiungi...",
            name: "Nome",
        },
        category: {
            models: "Modelli",
            accessories: "Accessori",
            tissuePrint: "Stampa tessuti",
            addNewCategory: "Aggiungi una nuova categoria...",
            categoryUpdate: "Aggiorna la categoria",
            name: "Nome",
        },
        tag: {
            productTag: "Modello",
            orderTag: "Ordine",
            productionTag: "Produzione",
            addNewTag: "Crea nuova etichetta",
            name: "Etichetta",
        },
    },
    color: {
        whiteEmbryo: "Embrione bianco",
        red: "Rosso",
        blue: "Blu",
        yellow: "Giallo",
        grey: "Grigio",
        black: "Nero",
        purple: "Viola",
        addNew: "Aggiungi un nuovo colore",
    },
    size: {
        uniqueSize: "Taglia unica",
        XS: "XS",
        S: "S",
        M: "M",
        L: "L",
        XL: "XL",
        XXL: "XXL",
        addNew: "Aggiungi una nuova taglia",
    },
    tag: {
        addNew: "Aggiungi una nuova etichetta",
    },
    unitOfMeasurement: {
        meter: "Metri",
    },
    generic: {
        accessoryPrice: "Prezzo(€/unita')",
        addColorRule: "crea regole di colori",
        addProductToProduction: "Aggiungere altri modelli",
        amountPerLayer: "Num. per strato",
        analyticsTable: "Analisi",
        archive: "Sposta al cestino",
        arrived: "Arrivati",
        arrivedAmounts: "Quantita' arrivati",
        arriveDate: "Data di arrivo",
        arrivedProductsModal: "Informazioni pezzi arrivati",
        arrivedToShip: "Arrivati",
        arriving: "Dati arrivati",
        auto: "auto",
        backToWaitingProduction: "Ripianificare",
        browserFiles: "Sfoglia le immagini",
        byDays: "Per giorni",
        byMonths: "Per mesi",
        calculatedFinalProducedAmount1: "Calcola pezzi prodotti finali",
        calculatedFinalProducedAmount2: "(leggerlo verticalmente, carta modello + combinazioni di tessuti, combinazioni di colori, taglie)",
        cancel: "Annulla",
        cancelFixTop: "Annulla top",
        changeColor: "Cambia colori",
        completeAll: "Completato",
        completeCrop: "Tagliato",
        completeCropDate: "Data di taglio",
        completeCropTimestamp: "Data di taglio",
        consumption: "Consumo (metri)",
        createColorPlanTimestamp: "Crea piano di tintura",
        createCropPlan: "Crea piano di produzione",
        createDyeingOrderDate: "Data tintura",
        createPrintPictureOrderDate: "Data stampa maglie",
        createPrintPicturePlanTimestamp: "Data piano di stampa maglie",
        cropNotes: "Note di taglio",
        croppedAmount: "Pezzi tagliati",
        cropPlan: "Piano di taglio",
        customerInfo: "Info cliente",
        customerNote: "Note d'ordine",
        customerOrderCancelledLog: "La prenotazione {orderId} e' stata annullata, rimanente +{amount}",
        customerOrderLog: "La prenotazione {orderId} è stata presa dall'inventario, rimanente -{amount}",
        customerTag: "Etichetta di ordine",
        cutScheme: "Schema di taglio",
        deliveryInAClick: "Imposta arrivati",
        deliveryNote: "Note di spedizione",
        deliveryTimestamp: "Data di spedizione",
        detailedProductionAmount: "Ulteriori informazioni",
        deviation: "Deviazione",
        deviationPercentage: "Perc. deviazione",
        difference: "Differenze",
        dragAndDrop: "Trascina le immagini qui",
        dyeingOrderId: "N. tinture",
        excludeArrived: "Non mostra ordini arrivati",
        expand: "Espandi",
        expirationTimestamp: "Data di scadenza",
        finishedPiecesNumber: "Tot. pezzi tagliati",
        fixTop: "Top",
        graphic: "Grafico",
        greaterFontSize: "Font maggiore",
        hasModels: "Ci sono {number} modelli",
        hasPrintPattern: "Ha le stampe",
        hemmingDetails: "orlatura",
        hide: "Nasconde",
        hotGlue: "colla a caldo",
        ifUseColor: "Colore dell'ordine:",
        incomplete: "Incompleto",
        inProduction: "In produzione",
        inProductionTimestamp: "Data di produzione",
        infoForProduction: "Info per fasonisti",
        inventoryOrderCancelledLog: "Ordine di inventario {orderId} e' stato annullato, rimanenti -{amount}",
        inventoryOrderLog: "Ordine di inventario {orderId}, rimanenti +{amount}",
        ironFactoryTable: "Tabella per stireria",
        layer: "N. strati",
        loadDraft: "Ordine incompleto",
        main: "Principale",
        modelInfo: "Info modelli",
        modelName: "Modelli",
        none: "Niente",
        noRecord: "Non ci sono dati",
        notArrived: "Non arrivati",
        notCropped: "Non tagliati",
        notPlanned: "Non pianificati",
        or: "oppure",
        orderAmount: "Pezzi ordinati",
        orderArrived: "Pezzi arrivati",
        orderExpirationInNDays: "Mancano {days} giorni",
        orderExpiredForNdays: "Scaduto da {days} giorni",
        orderTag: "Etichetta di ordine",
        pattern: "pattern",
        patternCode: "N. pattern",
        placeOrder: "Crea ordine",
        placeTimestamp: "Data di creazione",
        preferencesToProduction: "Impostazioni preferenze produzione",
        preorderAmounts: "Totale ordinati",
        preorderColors: "Colori ordinati",
        preorderComposition: "Composizione",
        preorderLogo: "Logo",
        preorderProductProductionNote: "Note di produzione",
        print: "Stampa",
        printCropPlan: "Stampa piano di taglio",
        printIronFactoryTable: "Tabella di stireria completa",
        printPatternColor: "Colore di stampa",
        printPatternColorRule: "Regola di colore di stampa",
        printPatternImages: "Immagini di stampa",
        printPatternName: "Nome stampa",
        printPatternPhoto: "Foto stampa",
        printPatternsAndColors: "Colori o stampe",
        printPictureOrderId: "N. stampe maglie",
        producedAmount: "Pezzi prodotti",
        productionId: "N. produzione",
        productionInfo: "Info produzione",
        productionNote: "Note produzione",
        productionTag: "Etichetta produzione",
        progress: "Progresso",
        qrCodeForPrintPatternImage: "QR code per stampe",
        qrCodeForTissueImage: "QR code per tessuti",
        quickAccess: "Tasto rapido",
        replan: "Ripianifica",
        save: "Salva",
        secondary: "Secondaria",
        selectPreorderModel: "Pianificazione",
        selectPrinter: "Scegli la stampante",
        sepcialRequestsNote: "Richieste speciali",
        setLog: "Rimanenti viene impostato a {amount}",
        setTag: "Imposta etichetta",
        shipped: "Spedito",
        shippingAndArriving: "Dettagli ordini",
        shippingDate: "Data di spedizione",
        specialColorRules: "Regole di colori",
        specialInfo: "Tabella di produzione (Mostra solo se ci sono delle richieste)",
        supportFileExtension: "Supporta le estensioni",
        thanUseColor: "Usa il colore:",
        tissueCode: "Codice tessuto",
        tissueColor: "Colore tessuto",
        tissueColorRule: "Regole di colore tessuto",
        tissueCombination: "Combinazione tessuto",
        tissueInfo: "Info tessuto",
        tissuePavingMeters: "Metri di pavimentazione del tessuto",
        tissuePavingPlan: "Pavimentazione del tessuto",
        toShip: "Da spedire",
        totalAmounts: "Totale",
        totalPreorderAmount: "Totale ordinati",
        unarchive: "Ripristina",
        unionProductionAmount: "Pezzi prodotti raggruppati",
        unshipped: "Non spedito",
        updateCategory: "Modifica categoria",
        urgentPreorder: "Urgente",
        waitingTally: "In attesa di conteggio",
        waittingToShipAmounts: "Da consengare",
        warning: "Attenzione",
        wastedAmount: "Pezzi sprecati",
        paid: "Pagato",
        unpaid: "Manca",
        customerToPay: "Da pagare",
        customerPaid: "Pagato",
        orderPage: "Informazione",
        skipProcedures: "Aggiornare direttamente l'inventario",
        importHistoricalColors: "Importa colori storici",
        need: "Richiede",
        dontNeed: "Non richiede",
        stockDetailsUpdate: "Modifica inventario",
        stockDetails: "Inventario",
        hasStock: "Disponibili",
        logo: "LOGO",
        all: "Tutti",
        default: "Predefinito",
        close: "Chiudi",
        undo: "Annulla",
        confirm: "Conferma",
        selected: "Selezionati",
        search: "Cerca",
        pages: "Pagine",
        total: "Totale",
        trash: "Cestino",
        requiredFieldMsg: "Il campo con * è obbligatorio",
        uneditable: "Non modificabile",
        suppliers: "Fornitori",
        supplier: "Fornitore",
        customers: "Clienti",
        updatedSuccessfully: "La modifica è andata a buon fine",
        createdSuccessfully: "La creazione è andata a buon fine",
        deletedSuccessfully: "L'eliminazione è andata a buon fine",
        restoredSuccessfully: "Il ripristino è andato a buon fine",
        name: "Nome",
        type: "Tipo",
        photo: "Foto",
        unitPrice: "Prezo unitario (€/m)",
        weight: "Peso",
        width: "Larghezza",
        shrinkage: "Restringimento",
        lastUpdate: "Ultima modifica",
        whiteEmbryo: "Embrione bianco",
        yarnDyedFabric: "Tessuto tinto con filo",
        essentialInformation: "Informazioni essenziali",
        category: "Categoria",
        unitOfMeasurement: "Unità di misura",
        notes: "Note",
        startDate: "Data di inizio",
        endDate: "Data di fine",
        procedure: "Lavorazione",
        piece: "Pezzo",
        addNew: "Aggiungi nuovo",
        model: "Modello",
        tissuesAndAccessory: "Tessuti e accessori",
        required: "Obbligatorio",
        colorGroup: "Gruppi di colori",
        categories: "Classificazioni",
        productTemplates: "Carta modelli",
        emptyForAutomaticSelection: "Lasciare vuoto per selezione automatica",
        sizes: "Dimensioni",
        tags: "Etichette",
        visibleOnOrder: "Visibile alla creazione di un ordine",
        description: "Descrizione",
        tissues: "Tessuti",
        material: "Materiale",
        notesForCut: "Note per il taglio",
        printPattern: "Stampa tessuto",
        color: "Colore",
        colors: "Colori",
        accessories: "Accessori",
        ingredients: "Materie prime",
        preorderDefaultColor: "Colori predefiniti di prenotazione",
        clickToSelect: "Clicca per selezionare",
        selectAll: "Seleziona tutti",
        noResult: "Non ci sono risultati",
        noData: "Nessun dato",
        quantity: "Quantità",
        price: "Prezzo",
        subtotal: "Subtotale",
        otherCost: "Altri costi",
        totalCost: "Costi totali",
        scrapRate: "Tasso di scarto",
        profitRate: "Tasso di profitto",
        priceToSell: "Prezzo di vendita",
        pricing: "Imposta prezzo",
        profit: "Profitto",
        colorManager: "Gestisci colori",
        costManager: "Gestisci costi",
        procedures: "Lavorazioni",
        autoPriceToSell: "Prezzo di vendita automatico",
        useProductWeight: "Usare il peso del modello",
        operation: "Operazione",
        selectedColors: "Colori aggiunti",
        removedColors: "Colori rimossi",
        removeSelectedColors: "Rimuovi i colori selezionati",
        restoreSelectedColors: "Ripristina i colori selezionati",
        remove: "Rimuovi",
        restore: "Ripristina",
        models: "Modelli",
        tissuesAndAccessories: "Tessuti e accessori",
        cost: "Costo",
        other: "Altro",
        totalPrice: "Prezzo totale",
        loss: "Perdita",
        gain: "Guadagno",
        listPrice: "Prezzo di listino",
        select: "Seleziona",
        startsWith: "Dal",
        endsWith: "Fino al",
        noItem: "Non c'è niente",
        tissue: "Tessuto",
        printPatternAbbrv: "Stampa",
        tissueAbbrv: "Tessuto",
        importPreorderColor: "Importa i colori predefiniti",
        importPreorderSize: "Importa le misure predefinite",
        template: "Carta modello",
        number: "Numero",
        date: "Data",
        moreOptions: "Più opzioni",
        discountAmount: "Totale sconto",
        urgent: "Urgente",
        preorderPrice: "Prezzo di prenotazione",
        creationDate: "Data di creazione",
        colorRule: "Regola di colorazione",
        customer: "Cliente",
        create: "Creazione",
        read: "Lettura",
        update: "Modifiche",
        permission: "Permessi",
        allMatch: "Combacia",
        includeTags: "Include etichette",
        excludeTags: "Esclude etichette",
        noTag: "Senza etichetta",
        customerOrder: "Ordine di cliente",
        inventoryOrder: "Ordine di magazzino",
        otherFilter: "Altri criteri",
        stock: "Magazzino",
        last7DaysPreorders: "Ordinazioni ultimi 7 giorni",
        details: "Dettagli",
        reset: "Azzeramento",
        setAmount: "Impostazione",
        size: "Taglie",
        mainTissue: "Tessuto principale",
        tissuesCombination: "Combinazione di tessuti",
        preorderDate: "Data di prenotazione",
        deliveryDate: "Data di consegna",
        state: "Stato",
        createNew: "Crea nuovo",
        made: "Effettuato",
        pieces: "Pezzi",
        cancelled: "Annullato",
        productionOrder: "Ordine di produzione",
        preorder: "Prenotazione",
        accessoryColor: "Colore di accessorio",
        noColor: "No colore",
        usePreorderColor: "Segue il colore dell'ordinazione",
        accessoryConsumption: "Consumo",
        byColor: "Per colori",
        bySize: "Per taglie",
        continue: "Continua",
        draftRequest: "Hai un ordine in sospeso creato alle {datetime}, conferma per recuperare",
        setInProduction: "Metti in produzione",
        notProduce: "Non produrre",
        setWaitingProduction: "Metti in attesa di produzione",
        changeFromStock: "Prendi dall'inventario",
        setWaitingConfirmation: "Metti in attesa di accettazione",
        duplicateName: "Duplicato",
        noMoreResults: "Non ci sono più risultati",
        changeTag: "Cambia etichetta",
        changeNote: "Cambia note",
        redo: "Reimposta",
        backToWaitingCutPlan: "Torna all'attesa del piano di taglio",
        printCutPlan: "Stampa piano di taglio",
        options: "Opzioni",
        createCutPlan: "Crea piano di taglio",
        speeded: "Accelerato",
        customerReminder: "Promemoria per cliente",
        crop: "Taglio",
        add: "Aggiungi",
        delete: "Rimuovi",
        clearAll: "Rimuovi tutto",
        preorderColor: "Colore ordine",
        specialColor: "Colore speciale",
        noColorRules: "Nessuna regola",
        hasColorRules: "Ha regole speciali",
        orderedVolume: "Q. ordinata",
        delivered: "Spedito",
        customerWaiting: "In attesa",
        notDelivered: "Non spedito",
        partiallyDelivered: "Spedito parzialmente",
    },
    production: {
        addNew: "Ordine di magazzino/campione",
        calculatedConsumption: "Calcola consumo",
        completeCropTimestamp: "Data completato taglio",
        compositionImages: "Foto composizione",
        compositionTag: "Composizione",
        confirmImportedProducts: "Conferma la quantita'",
        creationTimestamp: "Data di creazione",
        cropDate: "Data taglio",
        croppedAmounts: "Tot. tagliati",
        deliveredTimestamp: "Data di consegna",
        inProductionTimestamp: "Data di pianificazione",
        delivery: "Consegna",
        finalCompleteAmounts: "Tot. prodotti",
        inProduction: "In produzione",
        lastExpirationTimestamp: "Scadenza piu' vicina",
        logo: "Logo",
        logoAndComposition: "LOGO & Tag di composizione",
        meaningOfOutstandingCutting: "Taglio in sospeso: il piano di taglio e il taglio sono stati pianificati prima di oggi",
        meaningOfTotalOutstandingPieces: "Totale in sospeso: l'ordine è stato creato prima di oggi {today} e il piano di taglio e il taglio sono stati pianificati prima di oggi.",
        number: "N. produzione",
        plannedProductionAmounts: "Tot. programmato",
        preorderAmounts: "Tot. ordinati",
        printCropPlan: "Stampa il piano di taglio",
        productionNote: "Note di produzione",
        productionTable: "Tabella di produzione",
        relatedOrders: "Ordini relativi",
        sepcialRequestsNote: "Richieste speciali",
        setProductionTag: "Imposta l'etichetta di produzione",
        timelineLogging: "Andamento",
        orderNote: "Note di ordine",
        byProduct: "Per modelli",
        byTemplate: "Per carte modelli",
        byMainTissue: "Per tessuto principale",
        byTissues: "Per tessuti",
        todayTotalOrders: "Pezzi totali di modelli pianificati (Oggi)",
        todayTotalCutPieces: "Pezzi totali di modelli tagliati (Oggi)",
        totalOutstandingPieces: "Pezzi totali sospesi",
        outstandingCutting: "sospesi per il taglio",
        needAccessories: "con accessori",
        includePrintPatternsNoDyeing: "con stampe tessuti no tinture",
        includePrintPatternsWithDyeing: "con stampe tessuti e tinture",
        includeColoredTissue: "con panno di colori",
        whiteEmbryo: "con embrione bianco",
        closeToExpiration: "Vicino alla scadenza",
        outstanding: "In sospeso",
        productProperties: "Proprietà modello",
        placeTimestamp: "Data di creazione",
        earliestExpirationTimestamp: "Prima data di scadenza",
        productTemplateId: "Carta modello",
        productName: "Nome modello",
        mainTissue: "Tessuto principale",
        printPattern: "Stampa tessuto",
        accessory: "Accessorio",
        customer: "Cliente",
        arrange: "Organizzazione",
        calculateConsumption: "Calcola consumo",
        tag: "Etichetta di produzione",
    },
    permissionControl: {
        permissionControl: "Controllo permessi",
        chooseAnAccount: "Scegli un account",
        id: "Id",
        username: "Username",
        fullName: "Nome Completo",
        email: "Email",
        operation: "Operazioni",
        changePassword: "Cambia credenziali",
        permission: "Permessi",
        granted: "Permessi attuali",
        update: "Cambia permessi",
        status: "Stato",
        refresh: "Aggiorna",
    },
    user: {
        updateNote: "Rifare login",
        username: "Username",
        fullName: "Nome completo",
        update: "Modifica utente",
        password: "Password",
        newPassword: "Nuova password",
        repeatPassword: "Ripeti password",
    },
    date: {
        today: "Oggi",
        yesterday: "Ieri",
        last7Days: "Ultimi 7 giorni",
        last14Days: "Ultimi 14 giorni",
        last30Days: "Ultimi 30 giorni",
        thisMonth: "Questo mese",
        lastMonth: "Mese scorso",
        xMinutesAgo: "Adesso | Un minuto fa | {time} minuti fa",
        xHoursAgo: "Un'ora fa | {time} ore fa",
        xDaysAgo: "Un giorno fa | {time} giorni fa",
        xMonthsAgo: "Un mese fa | {time} mesi fa",
        xYearsAgo: "Un anno fa | {time} anni fa",
        xDaysToExpiration: "{days} giorni alla scadenza",
        expiredXDaysAgo: "Scaduto {days} giorni fa",
    },
    error: {
        generic: {
            duplicateName: "Il nome esiste già",
            alreadyInUseError: "Impossibile cancellare, perché è in uso",
            timeout: "Timeout",
            duplicateProduct: "Modello esistente, si prega di modificare il modello esistente oppure cambiare i tessuti oppure le stampe",
            existProductTemplateId: "Carta modello esistente, si prega di cambiarla oppure duplicare un modello esistente",
            unknownError: "Errore sconosciuto",
        },
    },
    default: {
        yes: "Sì",
        no: "No",
        procedure: {
            cutting: "Taglio",
            fasoning: "Fason",
            dyeing: "Tintura",
            buttoning: "Bottone",
            ironing: "Stiro",
            other: "Altri",
            printing: "Stampa tessuto",
            clothePrinting: "Stampa maglia",
        },
        procedureType: {
            cutting: "Taglio",
            fasoning: "Fason",
            dyeing: "Tintura",
            buttoning: "Bottone",
            ironing: "Stiro",
            other: "Altri",
            printing: "Stampa tessuto",
            clothePrinting: "Stampa maglia",
        },
        tag: {
            noImage: "Senza foto",
            toComplete: "Mancano dati",
            emptyTissue: "Mancano tessuti",
            notVisibleOnOrder: "Non visibile durante la creazione dell'ordine",
            noConsumption: "Mancano consumi",
            noWeight: "Manca peso",
        },
        customer: "Cliente predefinito",
        supplier: "Fornitore predefinito",
        order: {
            state: {
                unshipped: "Non consegnato",
                partiallyShipped: "Parzialmente consegnato",
                shipped: "Consegnato",
                cancelled: "Annullato",
            },
        },
        orderProduct: {
            state: {
                amountChange: "Variazione quantita'",
                completeCropTimestamp: "Data di taglio",
                createCropPlanTimestamp: "Data di piano di taglio",
                deliveredTimestamp: "Data di spedizione",
                receivedTimestamp: "Data di ricezione",
                createdProduction: "Pronto per lavorazione",
                waitingConfirmation: "Accettazione in attesa",
                noProduction: "Non produrre",
                getFromInventory: "Dal magazzino",
                waitingProduction: "Produzione in attesa",
                waitingCropPlan: "Piano di taglio in attesa",
                waitingCutting: "Taglio in attesa",
                inProduction: "In produzione",
                waitingColorPlan: "Piano di tinture in attesa",
                waitingPrintPicturePlan: "Piano di stampa maglia in attesa",
                tallying: "Conteggio",
                waitingShipping: "Consegna in attesa",
                unshipped: "Non consegnato",
                partiallyShipped: "Parzialmente consegnato",
                shipped: "Consegnato",
                cancelled: "Annullato",
                cutProductFromStorage: "Taglio completo in magazzino",
                cutComplete: "Taglio completato",
            },
        },
    },
    dashboard: {
        welcome: "Benvenuto",
        workToDo: "Oggi c'è questo lavoro da fare",
        nothingToDo: "Niente da fare",

        shipping: "Spedizione",
        shippingExpiration: "{length} scadenze per ordini da spedire",
        waitingShipping: "{length} ordini da spedire",

        productionPlan: "Piano di produzione",
        waitingProductionExpired: "{length} ordini in attesa di produzione in ritardo",
        waitingCuttingExpired: "{length} ordini in attesa di taglio in ritardo",
        waitingCropPlanExpired: "{length} ordini in attesa di piano di taglio in ritardo",
        waitingProductionSuspended: "{length} ordini in attesa di produzione in sospeso",
        waitingCropPlanSuspended: "{length} ordini in attesa di piano di taglio in sospeso",
        waitingProduction: "{length} ordini in attesa di produzione",
        waitingCutting: "{length} ordini in attesa di taglio",
        waitingCropPlan: "{length} ordini in attesa di piano di taglio",
        waitingCropSuspended: "{length} ordini in attesa di taglio in sospeso",

        preorders: "Prenotazioni",
        unpaid: "{length} prenotazioni in attesa di pagamento",
        waitingConfirmationExpired: "{length} prenotazioni in attesa di accettazione in ritardo",
        waitingConfirmation: "{length} prenotazioni in attesa di accettazione",

        models: "Modelli",
        infoToUpdate: "{length} modelli con informazioni da aggiornare",
        noImage: "{length} modelli senza immagine",
    },
    analytics: {
        orderTimes: "n. prenotazioni",
        date: "Data di creazione",
        color: "colori",
        analyticsTable: "Tabella di analisi",
        moreThan1OrderFromSameCustomer: "Dallo stesso cliente",
        preorderTotalAmounts: "Totale pezzi ordinati",
        croppedTotalAmounts: "Totale pezzi tagliati",
        shippedTotalAmounts: "Totale pezzi spediti",
        wastedPercentage: "Percentuale di sprechi",
        byPreorderAmount: "Quantità ordinata",
        byShippedAmount: "Quantità spedita",
        byOrderTimes: "Numero di ordini",
        byPopularity: "Popolarità",
        graph: "Grafico",
        rank: "Posizione",
        trend: "Variazione",
        preorderAmount: "Q. ordinata",
        shippedAmount: "Q. spedita",
        orderedTimes: "N. di ordini",
        popularity: "Popolarità",
        photo: "Foto",
        model: "Modello",
        mainTissue: "Tessuto principale",
        creationdDate: "Data di creazione",
        price: "Prezzo",
        tags: "Etichette",
        trendTooltip: "Durante {startDate} - {endDate}, rispetto a {startPreqDate} - {endPreqDate}, il numero di ordini è {toward} del {trend}%",
        toward: "diminuito | aumentato",
        top100Products: "Top 100 prodotti",
        top20Colors: "Top 20 colori",
        creationDate: "Data di creazione",
    },

    login: {
        accountInfo: "Info credenziali",
        clickToLogin: "Login",
        close: "Chiudi",
        companyError: "Azienda inesistente",
        companyId: "Id azienda",
        credentialError: "Credenziali errate",
        error: "Errore",
        login: "Login",
        logout: "Logout",
        password: "Password",
        passwordError: "Password sbagliata",
        timeout: "Timeout",
        userId: "Id utente",
        welcome: "Benvenuto su WeTEX",
    },
};

module.exports = {
    it,
};
