<template>
    <vue-easy-lightbox
        :scroll-disabled="false"
        :move-disabled="false"
        :visible="isLightboxVisible"
        :imgs="images"
        :index="displayIndex"
        @hide="closeLightbox"
        @on-index-change="changeIndex"
        @wheel="zoom"
    >
        <template v-slot:toolbar="{ toolbarMethods }">
            <span class="lightbox-index">{{ displayIndex + 1 }} / {{ images.length }}</span>
            <div class="lightbox-toolbar">
                <CButton @click="toolbarMethods.zoomIn">
                    <CIcon name="cil-zoom-in"></CIcon>
                </CButton>
                <CButton @click="toolbarMethods.zoomOut">
                    <CIcon name="cil-zoom-out"></CIcon>
                </CButton>
                <CButton @click="toolbarMethods.rotateRight">
                    <CIcon name="cil-loop-circular"></CIcon>
                </CButton>
            </div>
            <div class="lightbox-preview">
                <CImage
                    class="lightbox-preview__image"
                    :class="selectedImageModifiers(image)"
                    v-for="image in previewImages" :key="image"
                    :src="image" @click="selectImage(image)"
                ></CImage>
            </div>
        </template>
    </vue-easy-lightbox>
</template>

<script>
export default {
    name: "BaseLightbox",
    emits: [ "hide", ],
    props: {
        isLightboxVisible: {
            type: Boolean,
            default: false,
        },
        images: {
            type: Array,
            default: () => [],
        },
        selectedImageIndex: {
            type: Number,
        },
    },
    data () {
        return {
            displayIndex: this.selectedImageIndex,
            image: document.getElementsByClassName("vel-img"),
        };
    },
    computed: {
        previewImages () {
            return this.indexRange.map((i) => this.images[i]);
        },
        indexRange () {
            const range = [];
            let start = this.displayIndex - 3 > 0 ? this.displayIndex - 3 : 0;
            const end = this.displayIndex + 3 < this.images.length - 1 ? this.displayIndex + 3 : this.images.length - 1;
            if (end <= start) {
                range.push(0);
            }
            else {
                while (start <= end) {
                    range.push(start);
                    start++;
                }
            }
            return range;
        },
        selectedImage () {
            return this.images[this.displayIndex] ?? null;
        },
    },
    methods: {
        closeLightbox (event) {
            this.$emit("hide", event);
            this.displayIndex = this.selectedImageIndex ?? 0;
        },
        changeIndex (oldIndex, newIndex) {
            this.displayIndex = newIndex;
        },
        selectImage (image) {
            this.displayIndex = this.images.findIndex((i) => i === image);
        },
        selectedImageModifiers (image) {
            return {
                "lightbox-preview__selected": image === this.selectedImage,
            };
        },
        zoom (event) {
            if (this.image[0].style.scale !== undefined) {
                const scale = this.image[0].style.scale.length > 0 ? this.image[0].style.scale : "1";
                if (event.deltaY < 0) {
                    this.image[0].style.scale = Math.min(12, JSON.parse(scale) + 0.2);
                }
                if (event.deltaY > 0) {
                    this.image[0].style.scale = Math.max(1, JSON.parse(scale) - 0.2);
                }
            }
        },
    },
    watch: {
        selectedImageIndex (newValue, oldValue) {
            this.displayIndex = newValue;
        },
    },
};
</script>

<style lang="scss" scoped>
.lightbox-index {
    position: absolute;
    top: 15px;
    left: 15px;
    color: white;
}
.lightbox-preview {
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    top: 8px;
    left: 50%;
    transform: translate(-50%);
    opacity: .9;
    display: flex;
    border-radius: 4px;
    padding: 0;
    user-select: none;
    cursor: pointer;
    &__image {
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin: 0 8px;
        padding: 0.2rem;
        background: rgb(245,245,245);
        border-radius: 2px;
    }
    &__selected {
        background: var(--cui-primary);
    }
}

.lightbox-toolbar {
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%);
    opacity: .9;
    display: flex;
    border-radius: 4px;
    padding: 0;
    user-select: none;
    cursor: pointer;
    background-color: white;
}
</style>
