import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCaretTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilFactory,
    cilFlagAlt,
    cilColorPalette,
    cilFlower,
    cilTruck,
    cilPenNib,
    cilEnvelopeLetter,
    cilTag,
    cilBriefcase,
    cilLanguage,
    cilAppsSettings,
    cilClipboard,
    cilTrash,
    cilPlus,
    cilArrowThickRight,
    cilArrowThickLeft,
    cilMinus,
    cilArrowThickTop,
    cilArrowThickBottom,
    cilArrowThickToTop,
    cilArrowThickToBottom,
    cilX,
    cilZoomIn,
    cilZoomOut,
    cilLoopCircular,
    cilImage,
    cilCheckAlt,
    cilPowerStandby,
    cilReload,
    cilBorderAll,
    cilCaretBottom,
    cilCircle,
    cilChartLine,
    cilSync,
} from "@coreui/icons";

export const iconsSet = {
    cilFactory,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cilFlagAlt,
    cilColorPalette,
    cilFlower,
    cilTruck,
    cilPenNib,
    cilEnvelopeLetter,
    cilTag,
    cilBriefcase,
    cilLanguage,
    cilAppsSettings,
    cilClipboard,
    cilTrash,
    cilPlus,
    cilArrowThickRight,
    cilArrowThickLeft,
    cilMinus,
    cilArrowThickTop,
    cilArrowThickBottom,
    cilArrowThickToTop,
    cilArrowThickToBottom,
    cilX,
    cilZoomIn,
    cilZoomOut,
    cilLoopCircular,
    cilImage,
    cilCheckAlt,
    cilPowerStandby,
    cilReload,
    cilBorderAll,
    cilCaretBottom,
    cilCircle,
    cilChartLine,
    cilCaretTop,
    cilSync,
};
