<template>
    <CModal scrollable :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">

        <CModalHeader>
            <CModalTitle>{{ t("contact.customer.addNew") }}</CModalTitle>
        </CModalHeader>

        <CModalBody @scroll="getNewElements">
            <CRow class="py-3 ps-4">
                <CCol xs="auto" class="p-0">
                    <CFormInput
                        class="t-col-4"
                        v-model:model-value="searchValue"
                        type="search"
                        :placeholder="t('generic.search')"
                        @input="onInputSearchValue"
                    />
                </CCol>
            </CRow>
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem v-if="customers.length === 0 && searchValue !== ''" component="button" @click="openCustomerAddModal">
                        <span>
                            {{ t("contact.customer.addNew") }} {{ searchValue }}
                        </span>
                    </CListGroupItem>
                    <CListGroupItem component="button" v-for="customer in customers" :key="customer.id" @click="selectCustomer(customer)">
                        <CRow class="ps-0 pb-0">
                            <CCol>
                                <CRow>
                                    {{ customer.isDefault ? t(customer.fullName) : customer.fullName }}
                                </CRow>
                            </CCol>
                        </CRow>
                    </CListGroupItem>
                    <CListGroupItem v-if="isLoadingNewContent" class="d-flex justify-content-center p-5">
                        <CSpinner color="primary"></CSpinner>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
            <CRow class="justify-content-center align-content-center">
                <span class="text-center text-nowrap" v-if="customers.length === totalItems && !isLoadingNewContent">{{ t("generic.noMoreResults") }}</span>
            </CRow>
        </CModalBody>

        <customer-add-modal v-model:is-visible="isCustomerAddModalVisible" :name="searchValue" @submit="selectCustomer"></customer-add-modal>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import {user} from "@/user";
import {debounce} from "debounce";
import CustomerAddModal from "@/components/modals/CustomerAddModal";

export default {
    name: "CustomerPicker",
    emits: [ "update:isVisible", "selectCustomer", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CustomerAddModal,
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const customers = ref([]);
        const searchValue = ref("");
        const searchField = ref([ "fullName", ]);
        const isCustomerAddModalVisible = ref(false);

        // Dynamic scroll
        const currentPage = ref(0);
        const totalItems = ref(0);
        const isLoadingNewContent = ref(false);
        const previousScrollTop = ref(0);

        function closeModal () {
            searchValue.value = "";
            searchField.value = [ "fullName", ];
            emit("update:isVisible", false);
        }

        function openCustomerAddModal () {
            isCustomerAddModalVisible.value = true;
        }

        function selectCustomer (customer) {
            emit("selectCustomer", customer);
            searchValue.value = "";

            closeModal();
        }

        async function getNewElements (event) {
            if (event.target.scrollTop > previousScrollTop.value) {
                previousScrollTop.value = event.target.scrollTop;

                if (Math.round(event.target.scrollTop + event.target.offsetHeight + 1) >= event.target.scrollHeight) {
                    isLoadingNewContent.value = true;
                    await newElementsRequest();
                }
            }
        }

        const newElementsRequest = debounce(async () => {
            currentPage.value ++;
            customers.value = [ ...customers.value, ...await getAllFilteredElements(), ];
            isLoadingNewContent.value = false;
        }, 50);

        async function getAllFilteredElements () {
            const response = await user.getAllCustomers({
                archived: false,
                filterField: "fullName",
                filterValue: searchValue.value,
                page: currentPage.value,
                size: 20,
            });
            totalItems.value = response.totalItems;

            return response.customers ?? [];
        }

        watch(async () => props.isVisible, async (newValue, oldValue) => {
            if (newValue) {
                currentPage.value = 0;
                previousScrollTop.value = 0;
                customers.value = await getAllFilteredElements();
            }
        });

        const onInputSearchValue = debounce(async () => {
            currentPage.value = 0;
            previousScrollTop.value = 0;
            customers.value = await getAllFilteredElements();
        }, 50);

        return {
            t,
            searchField,
            searchValue,
            customers,
            onInputSearchValue,
            isLoadingNewContent,
            totalItems,
            isCustomerAddModalVisible,
            selectCustomer,
            closeModal,
            getNewElements,
            openCustomerAddModal,
        };
    },
};
</script>

<style scoped>

</style>
