<template>
    <CModal :visible="isVisible" @close="close">
        <CModalHeader>
            <CModalTitle>
                {{ t("generic.loadDraft") }}
            </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <span v-if="draft?.creationTimestamp">{{ t("generic.draftRequest", { datetime: new Date(draft.creationTimestamp).toLocaleString() })}}</span>
        </CModalBody>
        <CModalFooter>
            <CButton variant="outline" color="secondary" @click="close">{{ t("generic.undo") }}</CButton>
            <CButton color="primary" @click="loadDraft">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    name: "DraftModal",
    emits: [ "update:isVisible", "loadDraft", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        draft: {
            type: Object,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();

        function close () {
            emit("update:isVisible", false);
        }

        function loadDraft () {
            emit("loadDraft");
        }

        return {
            t,
            close,
            loadDraft,
        };
    },
};
</script>

<style scoped>

</style>
