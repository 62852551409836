<template>
    <CModal backdrop="static" :visible="isGlobal ? store.state.isVisiblePreorderCreateModal : isVisible" fullscreen alignment="center" @close="closeModal" scrollable>
        <AppLoadingScreen :isLoading="isLoading"/>
        <CForm @submit.prevent="submitCreate">

            <CModalHeader :close-button="false" class="p-0 justify-content-start">
                <div class="image-bar-header">
                    <h3 class="fs-5 p-3">{{ t("generic.model") }}</h3>
                </div>
                <div class="d-flex form-container">
                    <CModalTitle class="me-auto">{{ type === "customer" ? t("order.addNew") : t("order.addNewSample") }}</CModalTitle>
                    <CLoadingButton class="me-2" :loading="isConfirmButtonLoading" type="button" variant="outline" color="secondary" @click="makeDraft">{{ t("generic.undo") }}</CLoadingButton>
                    <CLoadingButton class="me-2" :loading="isConfirmButtonLoading" :disabled="isConfirmButtonLoading" type="submit" color="primary">{{ t("generic.confirm") }}</CLoadingButton>
                </div>
            </CModalHeader>

            <CModalBody>
                <CRow class="modal-content-container flex-nowrap">

                    <div class="image-bar">
                        <CCol class="mt-3 bg-body bg-gradient ms-2" v-if="selectedProducts.length > 0">
                            <CRow class="mt-3 image-bar-gallery">
                                <CCol class="mb-3">
                                    <div class="t-col-4 cursor-pointer mb-2" style="position: relative;">
                                        <CImage
                                        v-if="selectedProducts[activeProductTab].images.length > 0"
                                        class="t-col-4"
                                        thumbnail
                                        align="start"
                                        :src="selectedProducts[activeProductTab].images[0].compress"
                                        @click="openLightbox(selectedProducts[activeProductTab])"
                                        ></CImage>
                                        <CIcon v-else icon="cil-image" size="8xl"></CIcon>
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow class="mt-2">
                                <CCol>
                                    <CButton color="primary" variant="outline" @click="showProductCopyModal(selectedProducts[activeProductTab])">{{ t("model.copy") }}</CButton>
                                </CCol>
                            </CRow>
                            <div class="pb-2 pt-2 border-bottom">
                                <CRow>
                                    <CCol>
                                        <CFormLabel class="mb-0 fs-4 text-info text-start text-nowrap">{{ selectedProducts[activeProductTab].productTemplateId }}</CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <CFormLabel class="fs-5 text-secondary text-start text-nowrap">{{ selectedProducts[activeProductTab].name }}</CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <CFormLabel class="fs-4 text-start text-nowrap">{{ t("model.listPrice") }}: {{ selectedProducts[activeProductTab].cost.price }}</CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <CFormLabel class="fs-4 text-start text-nowrap">{{ t("generic.lastUpdate") }}: {{ getTimeDifferenceText(selectedProducts[activeProductTab].lastUpdateTimestamp) }}</CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <CFormLabel class="fs-4 text-start text-nowrap">{{ t("generic.category") }}: {{ getElementName(selectedProducts[activeProductTab].category) }}</CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <div class="d-flex flex-wrap">
                                        <span class="w-100">{{ t("generic.tags") }}:</span>
                                        <div class="m-1 rounded-pill d-flex align-items-center bg-secondary" v-for="tag in selectedProducts[activeProductTab].tags?.sort((a,b)=> a-b)" :key="tag">
                                            <span class="text-nowrap px-2 py-1 text-white fs-5 w-100">{{ getElementName(tag) }}</span>
                                        </div>
                                    </div>
                                </CRow>
                            </div>

                            <div class="pb-2 pt-2 border-bottom">
                                <CRow class="flex-nowrap pb-2" v-for="(tissue, index) in selectedProducts[activeProductTab].tissues" :key="tissue">
                                    <CCol>
                                        <CRow>
                                            <CCol class="d-flex">
                                                <span class="fs-4 text-start text-info">{{ index + 1 }}. {{ tissue.tissue.name }} ({{ getElementName(tissue.tissue.category) }}) ({{ getElementName(tissue.tissue.supplier) }})</span>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol>
                                                <span class="fs-5 text-start text-secondary">{{ t("generic.colorRule") }}: {{ getTissueColorRule(tissue) }}</span>
                                            </CCol>
                                        </CRow>
                                        <CRow v-if="tissue.printPattern">
                                            <CCol class="d-flex">
                                                <span class="fs-5 text-start text-nowrap text-secondary">{{ t("generic.printPattern") }}: {{ tissue.printPattern.name }}</span>
                                            </CCol>
                                        </CRow>
                                        <CRow v-if="tissue.printPattern">
                                            <CCol>
                                                <span class="fs-5 text-start text-secondary">{{ t("generic.colorRule") }}: {{ getPrintPatternColorRule(tissue) }}</span>
                                            </CCol>
                                        </CRow>
                                        <CRow v-if="tissue.cropNotes?.length > 0">
                                            <CCol class="d-flex">
                                                <span class="fs-5 text-start text-nowrap text-secondary">{{ t("model.cropNotes") }}: {{ tissue.cropNotes }}</span>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>

                                <CRow class="flex-nowrap pb-2" v-for="(accessoryDescriptor, index) in selectedProducts[activeProductTab].accessories" :key="index">
                                    <CCol>
                                        <CRow>
                                            <CCol class="d-flex">
                                                <span class="fs-4 text-start text-info">{{ accessoryDescriptor.accessory.name }} ({{ getElementName(accessoryDescriptor.accessory.category) }}) ({{ getElementName(accessoryDescriptor.accessory.supplier) }})</span>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol>
                                                <span class="fs-5 text-start text-secondary">{{ t("generic.colorRule") }}: {{ getAccessoryColorRule(accessoryDescriptor) }}</span>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </div>

                            <div class="pt-2 d-flex flex-wrap">
                                <div v-for="procedureDescriptor in selectedProducts[activeProductTab].procedures?.sort((p1, p2) => p1.procedure.id - p2.procedure.id)" :key="procedureDescriptor.procedure.id">
                                    <div>
                                        <span class="fs-4 pe-2">{{ getElementName(procedureDescriptor.procedure) }}</span>
                                        <span v-if="procedureDescriptor.note?.length > 0">({{ procedureDescriptor.note }})</span>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </div>

                    <div class="px-0 form-container border-start form-box">
                        <CNav class="ps-3" variant="underline" role="tablist">
                            <CNavItem>
                                <CNavLink
                                href="javascript:void(0);"
                                :active="activeModalTab===0"
                                @click="activeModalTab=0"
                                >
                                {{ t("generic.customers") }}({{ selectedCustomer.isDefault ? t(selectedCustomer.fullName) : selectedCustomer.fullName}})
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink
                            href="javascript:void(0);"
                            :active="activeModalTab===1"
                            @click="activeModalTab=1"
                            >
                            {{ t("generic.models") }}({{ selectedProducts.length }})
                        </CNavLink>
                    </CNavItem>
                    <CNavItem v-if="type === 'customer'">
                        <CNavLink
                        href="javascript:void(0);"
                        :active="activeModalTab===2"
                        @click="activeModalTab=2"
                        >
                        {{ t("generic.total") }}({{ total }})
                    </CNavLink>
                </CNavItem>
                        </CNav>
                <CTabContent class="ps-3">

                <!-- Customers Tab -->
                <CTabPane role="tabpanel" aria-labelledby="essentials-information-tab" :visible="activeModalTab === 0">
                    <template v-if="type === 'customer'">
                        <CRow>
                            <CCol class="py-3 align-items-center d-flex" :xs="2">
                                <CButton color="primary" @click="openCustomerPicker">
                                    <h4 class="p-0 m-0">{{ selectedCustomer.isDefault ? t(selectedCustomer.fullName) : selectedCustomer.fullName}}</h4>
                                </CButton>
                            </CCol>
                            <CCol class="py-3 align-items-center d-flex">
                                <CFormLabel class="text-info cursor-pointer my-0" @click="openCustomerAddModal">{{ t("contact.customer.create") }}...</CFormLabel>
                            </CCol>
                        </CRow>
                        <CRow class="pt-3">
                            <CCol :xs="3">
                                <CFormLabel>{{ t("order.dateOrder") }}</CFormLabel>
                                <BaseDatePicker v-model:model-value="placeTimestamp" :placeholder="t('date.today')"></BaseDatePicker>
                            </CCol>
                            <CCol :xs="3">
                                <CFormLabel>{{ t("order.endDelivery") }}</CFormLabel>
                                <BaseDatePicker v-model:model-value="expirationTimestamp"></BaseDatePicker>
                            </CCol>
                        </CRow>
                        <CRow class="pt-3">
                            <CCol :xs="3">
                                <CFormLabel>{{ t("order.logo") }}</CFormLabel>
                                <CFormInput v-model="selectedCustomer.defaultLogo"></CFormInput>
                            </CCol>
                        </CRow>
                        <CRow class="pt-3">
                            <CCol :xs="3">
                                <CFormLabel>{{ t("order.tag") }}</CFormLabel>
                                <CFormInput v-model="selectedCustomer.defaultTag"></CFormInput>
                            </CCol>
                        </CRow>
                        <CRow class="pt-3">
                            <CCol :xs="3">
                                <CFormLabel>{{ t("order.composition") }}</CFormLabel>
                                <CFormInput v-model="selectedCustomer.defaultComposition"></CFormInput>
                            </CCol>
                        </CRow>
                        <CRow class="pt-3">
                            <CCol :xs="12">
                                <BaseImageUploader @upload="saveImages"></BaseImageUploader>
                                <CRow class="mt-2">
                                    <div v-for="base64 in customerImages" :key="base64" class="t-col-3 me-2">
                                        <div class="t-col-3" style="position: relative;">
                                            <CImage  class="t-col-3" thumbnail align="start" :src="base64" @click="openCustomerImagesLightbox"></CImage>
                                            <button class="button-remove-image" @click.prevent="deleteImage(base64)">
                                                <span class="icon-cross"></span>
                                            </button>
                                        </div>
                                    </div>
                                </CRow>
                            </CCol>
                        </CRow>
                    </template>
                    <CRow class="pt-3">
                        <CCol :xs="12">
                            <CFormLabel>{{ t("order.note") }}</CFormLabel>
                            <CFormTextarea v-model="selectedCustomer.note"></CFormTextarea>
                        </CCol>
                    </CRow>
                </CTabPane>
            </CTabContent>

            <!-- Models Tab -->
            <CTabContent class="ps-3">
                <CTabPane role="tabpanel" aria-labelledby="add-model-tab" :visible="activeModalTab === 1">
                    <CRow>
                        <CCol class="py-3 align-items-center d-flex">
                            <CButton color="primary" @click="openProductMultiSelect">{{ t("model.addNew") }}</CButton>
                            <CFormLabel class="text-info cursor-pointer my-0 ms-5" @click="openProductAddModal">{{ t("model.create") }}...</CFormLabel>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CNav variant="underline" role="tablist">
                                <CNavItem v-for="(product, index) in selectedProducts" :key="index">
                                    <CNavLink
                                    href="javascript:void(0);"
                                    :active="activeProductTab===index"
                                    @click="selectProductTab(index)"
                                    >
                                    {{ product.productTemplateId }} ({{ product.name }}) {{ getProductIndex(product, index) ? '-' + getProductIndex(product, index) : '' }}
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent v-for="(product, index) in selectedProducts" :key="index">
                            <CTabPane role="tabpanel" aria-labelledby="model-tab" :visible="activeProductTab === index">
                                <CRow class="py-3">
                                    <CCol>
                                        <CDropdown color="secondary">
                                            <CButton color="primary" @click="openColorMultiSelect">{{ t("color.addNew") }}</CButton>
                                            <CDropdownToggle color="primary" split>{{ t("color.addNew") }}</CDropdownToggle>
                                            <CDropdownMenu>
                                                <CDropdownItem @click="importDefaultColors">{{ t("order.importColors") }}</CDropdownItem>
                                                <CDropdownItem @click="showHistoricalColorsModal">{{ t("order.importHistoricalColors") }}</CDropdownItem>
                                                <CDropdownItem @click="clearColors">{{ t("order.clearColors") }}</CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                        <CButton class="ms-3" color="primary" @click="openSizeMultiSelect">{{ t("size.addNew") }}</CButton>
                                    </CCol>
                                    <CCol :xs="2" class="ms-auto d-flex justify-content-end me-3">
                                        <CDropdown color="secondary">
                                            <CDropdownToggle color="primary">{{ t("generic.moreOptions") }}</CDropdownToggle>
                                            <CDropdownMenu>
                                                <CDropdownItem @click="openCopyInfoModal">{{ t("order.copyInfoTo") }}...</CDropdownItem>
                                                <CDropdownItem @click="openCopyProductModal">{{ t("order.copyModel") }}</CDropdownItem>
                                                <CDropdownItem @click="clearTable">{{ t("order.clear") }}</CDropdownItem>
                                                <CDropdownDivider/>
                                                <CDropdownItem @click="removeProduct" class="text-danger">{{ t("model.delete") }}</CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </CCol>
                                </CRow>
                                <CRow class="pt-3">
                                    <CFormLabel>{{ t("order.preorderVolume") }}</CFormLabel>
                                </CRow>
                                <CRow>
                                    <CCol :xs="4">
                                        <CTable class="border" bordered>
                                            <CTableHead>
                                                <CTableRow>
                                                    <CTableHeaderCell class="t-col-3" scope="col" :colspan="product.showRules ? 2 : 1">
                                                        <div class="d-flex justify-content-between">
                                                            <span>{{ t("generic.color") }} ({{ getAmountTableTissueText(product.tissues[0]) }})</span>
                                                            <div
                                                                v-if="productHasColorRules(product)"
                                                                @click="toggleShowRules(product)"
                                                                class="ms-3 border-start border-start-2"
                                                            >
                                                                <CTooltip>
                                                                    <template #toggler="{ on, }">
                                                                        <div v-on="on">
                                                                            <span class="cursor-pointer user-select-none">{{ getShowRulesText(product.showRules) }}</span>
                                                                        </div>
                                                                    </template>
                                                                    <template #content>
                                                                        {{ t("generic.hasColorRules") }}
                                                                    </template>
                                                                </CTooltip>
                                                            </div>
                                                        </div>
                                                    </CTableHeaderCell>
                                                    <CTableHeaderCell v-for="(size, sizeIndex) in product.sizes" :key="size" class="t-col-2" scope="col">
                                                        <CDropdown class="d-flex" color="secondary">
                                                            <CDropdownToggle class="shadow-none p-0 d-flex w-100">
                                                                <span class="text-start w-100">{{ getElementName(getSize(size)) }}</span>
                                                            </CDropdownToggle>
                                                            <CDropdownMenu>
                                                                <CDropdownItem @click="openFillColumnModal(size)">{{ t("order.fillColumn") }}</CDropdownItem>
                                                                <CDropdownDivider/>
                                                                <CDropdownItem class="text-danger" @click="deleteSize(sizeIndex)">{{ t("order.deleteColumn") }}</CDropdownItem>
                                                            </CDropdownMenu>
                                                        </CDropdown>
                                                    </CTableHeaderCell>
                                                    <CTableHeaderCell class="t-col-2" scope="col">{{ t("generic.total") }}</CTableHeaderCell>
                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody>
                                                <CTableRow v-for="(color, y) in product.colors" :key="color.id">
                                                    <CTableDataCell>
                                                        <CDropdown class="d-flex w-100" color="secondary">
                                                            <CDropdownToggle class="shadow-none p-0 d-flex w-100"><span class="text-start w-100">{{ getElementName(getColor(color.id)) }}</span></CDropdownToggle>
                                                            <CDropdownMenu>
                                                                <CDropdownItem @click="openFillRowModal(color.id)">{{ t("order.fillRow") }}</CDropdownItem>
                                                                <CDropdownDivider/>
                                                                <CDropdownItem class="text-danger" @click="deleteColor(y)">{{ t("order.deleteRow") }}</CDropdownItem>
                                                            </CDropdownMenu>
                                                        </CDropdown>
                                                    </CTableDataCell>
                                                    <CTableDataCell v-if="productHasColorRules(product) && product.showRules">
                                                        <template v-for="(tissue, tissueIndex) in product.tissues" :key="tissue.id">
                                                            <div
                                                                v-if="tissueApplyRuleForCurrentColor(tissue.tissueColorRules, color)"
                                                                 class="d-flex justify-content-around align-content-center align-items-center flex-nowrap"
                                                            >
                                                                <span class="text-nowrap">
                                                                TS{{ tissueIndex+1 }}: {{ tissue.tissue.name }} ({{ tissue.printPattern?.name ?? getElementName(tissue.tissue.category) }})
                                                                </span>
                                                                <CIcon icon="cilArrowRight" size="sm"></CIcon>
                                                                <span class="text-nowrap">{{ getElementName(tissueApplyRuleForCurrentColor(tissue.tissueColorRules, color)) }}</span>
                                                            </div>
                                                        </template>
                                                    </CTableDataCell>
                                                    <CTableDataCell v-for="(size, x) in product.sizes" :key="size" class="p-1" :class="getSizeInputClass(size)">
                                                        <CFormInput
                                                            :disabled="isNonUniqueSizeDisabled(size) || isUniqueSizeDisabled(size)"
                                                            placeholder="0"
                                                            type="number"
                                                            :class="'amount-input-' + index + '-' + y + '-' + x + ' ' + getSizeInputClass(size)"
                                                            autocomplete="off"
                                                            class="border-0 rounded-0 p-1 text-start"
                                                            @input="inputSizeQuantity($event, color.id, size)"
                                                            :model-value="getInputValue(color.id, size)"
                                                            :invalid="isInputInvalid(getInputValue(color.id, size), isNonUniqueSizeDisabled(size) || isUniqueSizeDisabled(size))"
                                                        ></CFormInput>
                                                    </CTableDataCell>
                                                    <CTableDataCell class="text-end bg-light">{{ getTotal(color.id, activeProductTab) }}</CTableDataCell>
                                                </CTableRow>
                                                <CTableRow>
                                                    <CTableDataCell :colspan="product.showRules ? 2 : 1">{{ t("generic.total") }} ({{ product.colors.length }} {{ t("generic.colors") }})</CTableDataCell>
                                                    <CTableDataCell class="text-end bg-light" v-for="size in product.sizes" :key="size">{{ getAmount(size) }}</CTableDataCell>
                                                    <CTableDataCell class="text-end bg-light">{{ getSumOfTotals(activeProductTab) }}</CTableDataCell>
                                                </CTableRow>
                                            </CTableBody>
                                        </CTable>
                                    </CCol>
                                </CRow>

                                <CRow class="pt-3">

                                    <!-- First Popover -->
                                    <CCol :xs="2">
                                        <VMenu placement="right">
                                            <div id="popoverToggler" style="width: fit-content">
                                                <span>{{ t("model.availableStockVolume") }}</span><br>
                                                <span>{{ getTotalFromAmounts(product.inventory.amounts) }}</span>
                                            </div>
                                            <template #popper>
                                                <CContainer class="p-2 popover-container">
                                                    <CRow class="mx-1">
                                                        <CCol class="p-0">
                                                            <CFormLabel>{{ t("order.details") }}</CFormLabel>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow class="mx-1">
                                                        <CCol class="p-0 border-bottom">
                                                            <CTable class="border align-middle" bordered small>
                                                                <CTableHead>
                                                                    <CTableRow>
                                                                        <CTableHeaderCell class="t-col-3" scope="col">{{ t("generic.color") }}</CTableHeaderCell>
                                                                        <CTableHeaderCell v-for="size in getSizesFromAmounts(product.inventory.amounts)" :key="size" class="t-col-1 text-center" scope="col">
                                                                            {{ getElementName(getSize(size)) }}
                                                                        </CTableHeaderCell>
                                                                        <CTableHeaderCell class="t-col-2 text-center" scope="col">{{ t("generic.total") }}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                </CTableHead>
                                                                <CTableBody>
                                                                    <CTableRow v-for="(color) in getColorsFromAmounts(product.inventory.amounts)" :key="color">
                                                                        <CTableDataCell>{{ getElementName(getColor(color)) }}</CTableDataCell>
                                                                        <CTableDataCell class="p-1 text-center" v-for="size in getSizesFromAmounts(product.inventory.amounts)" :key="size">
                                                                            {{ getQuantityFromAmounts(product?.inventory.amounts, color, size) }}
                                                                        </CTableDataCell>
                                                                        <CTableDataCell class="text-center bg-light">{{ getColorSubtotalFromAmounts(product.inventory.amounts, color)  }}</CTableDataCell>
                                                                    </CTableRow>
                                                                    <CTableRow>
                                                                        <CTableDataCell>{{ t("generic.total") }} ({{ getColorsFromAmounts(product.inventory.amounts).length }} {{ t("generic.colors") }})</CTableDataCell>
                                                                        <CTableDataCell class="text-center bg-light text-center" v-for="size in getSizesFromAmounts(product.inventory.amounts)" :key="size">{{ getSizeSubtotalFromAmounts(product.inventory.amounts, size) }}</CTableDataCell>
                                                                        <CTableDataCell class="text-center bg-light">{{ getTotalFromAmounts(product.inventory.amounts) }}</CTableDataCell>
                                                                    </CTableRow>
                                                                </CTableBody>
                                                            </CTable>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow class="mx-1 mt-1">
                                                        <CCol class="p-0">
                                                            <CFormLabel class="p-0 w-100">{{ t("generic.notes") }}</CFormLabel>
                                                            <span class="p-0 w-100">-</span>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow class="mx-1 mt-1">
                                                        <CCol class="p-0">
                                                            <CFormLabel class="p-0 w-100">{{ t("generic.tissues") }}</CFormLabel>
                                                            <span class="p-0 w-100" v-for="(tissue, index) in selectedProducts[activeProductTab].tissues" :key="tissue">TS{{ index+1 }}: {{ tissue.tissue.name }}</span>
                                                        </CCol>
                                                    </CRow>
                                                </CContainer>
                                            </template>
                                        </VMenu>
                                    </CCol>
                                    <CCol :xs="1" class="d-flex justify-content-center">
                                        <div class="h-100 border border-top-0 border-bottom-0 border-end-0"></div>
                                    </CCol>

                                    <!-- Second Popover -->
                                    <CCol :xs="2">
                                        <VMenu placement="right">
                                            <div id="popoverToggler" style="width: fit-content">
                                                <span>{{ t("order.lastWeekTotalCount") }}</span><br>
                                                <span>{{ getTotalFromAmounts(product.last7daysOrderAmounts) }}</span>
                                            </div>
                                            <template #popper>
                                                <CContainer class="p-2 popover-container">
                                                    <CRow class="mx-1">
                                                        <CCol class="p-0">
                                                            <CFormLabel>{{ t("order.details") }}</CFormLabel>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow class="mx-1">
                                                        <CCol class="p-0">
                                                            <CTable class="border align-middle" bordered small>
                                                                <CTableHead>
                                                                    <CTableRow>
                                                                        <CTableHeaderCell class="t-col-3" scope="col">{{ t("generic.color") }}</CTableHeaderCell>
                                                                        <CTableHeaderCell v-for="size in getSizesFromAmounts(product?.last7daysOrderAmounts)" :key="size" class="t-col-1 text-center" scope="col">
                                                                            {{ getElementName(getSize(size)) }}
                                                                        </CTableHeaderCell>
                                                                        <CTableHeaderCell class="t-col-2 text-center" scope="col">{{ t("generic.total") }}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                </CTableHead>
                                                                <CTableBody>
                                                                    <CTableRow v-for="(color) in getColorsFromAmounts(product?.last7daysOrderAmounts)" :key="color.id">
                                                                        <CTableDataCell>{{ getElementName(getColor(color)) }}</CTableDataCell>
                                                                        <CTableDataCell class="p-1 text-center" v-for="size in getSizesFromAmounts(product?.last7daysOrderAmounts)" :key="size">
                                                                            {{ getQuantityFromAmounts(product?.last7daysOrderAmounts, color, size) }}
                                                                        </CTableDataCell>
                                                                        <CTableDataCell class="text-center bg-light">{{ getColorSubtotalFromAmounts(product?.last7daysOrderAmounts, color)  }}</CTableDataCell>
                                                                    </CTableRow>
                                                                    <CTableRow>
                                                                        <CTableDataCell>{{ t("generic.total") }} ({{ getColorsFromAmounts(product?.last7daysOrderAmounts).length }} {{ t("generic.colors") }})</CTableDataCell>
                                                                        <CTableDataCell class="text-center bg-light text-center" v-for="size in getSizesFromAmounts(product?.last7daysOrderAmounts)" :key="size">{{ getSizeSubtotalFromAmounts(product?.last7daysOrderAmounts, size) }}</CTableDataCell>
                                                                        <CTableDataCell class="text-center bg-light">{{ getTotalFromAmounts(product?.last7daysOrderAmounts) }}</CTableDataCell>
                                                                    </CTableRow>
                                                                </CTableBody>
                                                            </CTable>
                                                        </CCol>
                                                    </CRow>
                                                </CContainer>
                                            </template>
                                        </VMenu>
                                    </CCol>
                                </CRow>

                                <CRow class="pt-3">
                                    <CCol :xs="3" v-if="type === 'customer'">
                                        <CFormLabel>{{ t("order.produce") }}</CFormLabel>
                                        <CFormSelect :model-value="product.production" @change="changeProduction($event, product)">
                                            <option value="toProduce">{{ t("order.toProduce") }}</option>
                                            <option value="fromInventory">{{ t("order.fromInventory") }}</option>
                                            <option value="noProduce">{{ t("order.noProduce") }}</option>
                                            <option value="tbd">{{ t("order.tbd") }}</option>
                                        </CFormSelect>
                                    </CCol>
                                    <CCol :xs="3" v-else>
                                        <CFormLabel>{{ t("order.produce") }}</CFormLabel><br>
                                        <CFormLabel class="me-2">{{ t("generic.skipProcedures") }}</CFormLabel>
                                        <CFormCheck v-model:model-value="product.skipProcedures"></CFormCheck><br>
                                        <span v-if="!product.skipProcedures">{{ getProductProceduresText(product)}}</span>
                                    </CCol>
                                </CRow>

                                <CRow class="pt-3">
                                    <CCol :xs="6">
                                        <CFormLabel>{{ t("order.productionChanges") }}</CFormLabel>
                                        <CFormTextarea v-model="product.productionNote"></CFormTextarea>
                                    </CCol>
                                </CRow>
                                <CRow class="pt-3" v-if="type === 'customer'">
                                    <CCol :xs="6">
                                        <CFormLabel>{{ t("order.deliveryNote") }}</CFormLabel>
                                        <CFormTextarea v-model="product.deliveryNote"></CFormTextarea>
                                    </CCol>
                                </CRow>
                            </CTabPane>
                        </CTabContent>
                    </CCol>
                </CRow>
            </CTabPane>
        </CTabContent>

        <!-- Total Tab -->
        <CTabContent class="ps-3" v-if="type === 'customer'">
            <CTabPane role="tabpanel" aria-labelledby="add-model-tab" :visible="activeModalTab === 2">
                <CRow class="m-3">
                    <CCol>
                        <div class="table-responsive">
                            <CTable class="p-3 mb-3 border border-light border-2" hover>
                                <!-- Table total box -->
                                <CTableCaption class="border border-top-0 text-black">
                                    <div class="d-flex">
                                        <div class="col-2 me-auto">
                                            <div class="py-1 ps-3">
                                                <span>{{ t("generic.subtotal") }}</span>
                                            </div>
                                            <div class="py-1 ps-3">
                                                <span>{{ t("generic.otherCost") }}</span>
                                            </div>
                                            <div class="py-1 ps-3">
                                                <span>{{ t("generic.discountAmount") }}</span>
                                            </div>
                                            <div class="py-1 ps-3">
                                                <span>{{ t("generic.total") }}</span>
                                            </div>
                                            <div class="py-1 ps-3">
                                                <span>{{ t("generic.customerPaid") }}</span>
                                            </div>
                                            <div class="py-1 ps-3">
                                                <span>{{ t("generic.customerToPay") }}</span>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="py-1 text-end pe-3">
                                                <span>{{ subtotal }}</span>
                                            </div>
                                            <div class="py-1 pe-3">
                                                <BaseInput class="text-end input-sm w-75 ms-auto price-input" v-model:model-value="otherCost" placeholder="0,000"></BaseInput>
                                            </div>
                                            <div class="py-1 pe-3">
                                                <BaseInput class="text-end input-sm w-75 ms-auto price-input" v-model:model-value="discountAmount" placeholder="0,000"></BaseInput>
                                            </div>
                                            <div class="py-1 text-end pe-3">
                                                <span>{{ total }}</span>
                                            </div>
                                            <div class="py-1 text-end pe-3" v-if="type !== 'inventory'">
                                                <BaseInput id="paid" class="text-end input-sm w-75 ms-auto price-input" v-model="paidAmount" placeholder="0,000"></BaseInput>
                                            </div>
                                            <div v-if="type !== 'inventory'" class="py-1 text-end pe-3">
                                                {{ getDoublesDiff(total, paidAmount) }}
                                            </div>
                                        </div>
                                    </div>
                                </CTableCaption>
                                <CTableHead>
                                    <CTableRow>
                                        <CTableHeaderCell class="ps-4 py-3 t-col-1" scope="col"></CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 ps-4 pe-3 t-col-2" scope="col">{{ t("generic.photo") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 ps-4 pe-3 t-col-2" scope="col">{{ t("generic.model") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 ps-3 pe-3 t-col-2" scope="col">{{ t("model.mainTissue") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 ps-3 pe-3 t-col-2" scope="col">{{ t("generic.urgent") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 pe-3 t-col-2 text-end" scope="col">{{ t("model.listPrice") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 pe-3 t-col-2 text-end" scope="col">{{ t("generic.preorderPrice") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 pe-3 t-col-2 text-end" scope="col">{{ t("generic.quantity") }}</CTableHeaderCell>
                                        <CTableHeaderCell class="py-3 pe-3 t-col-2 text-end" scope="col">{{ t("generic.subtotal") }}</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody class="align-middle">
                                    <CTableRow v-for="(product, spIndex) in selectedProducts" :key="spIndex" @click="gotoProduct($event, spIndex)">
                                        <CTableDataCell class="py-3 ps-4">
                                            <div @click.stop="unselectProduct(spIndex)" class="cursor-pointer h-100 w-100">
                                                <CIcon name="cil-X" size="xl"></CIcon>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell class="ps-4 pe-3 py-2">
                                            <CImage
                                            id="tableElementImage"
                                            class="t-col-photo rounded cursor-pointer"
                                            v-if="product.images.length > 0"
                                            :src="product.images[0].compress"
                                            @click.stop="openLightbox(product)"
                                            ></CImage>
                                            <div v-else class="t-col-photo">
                                                <CIcon size="4xl" name="cil-image"></CIcon>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell class="py-2 ps-4 pe-3">
                                            <VMenu placement="right">
                                                <div id="popoverToggler" style="width: fit-content">
                                                    <span class="text-info w-auto">
                                                        {{ product.productTemplateId }}
                                                    </span><br>
                                                    <span class="w-auto">
                                                        {{ product.name ?? "-" }}
                                                    </span>
                                                </div>
                                                <template #popper>
                                                    <CContainer class="p-2 popover-container">
                                                        <CRow class="mx-1">
                                                            <CCol class="p-0">
                                                                <CFormLabel>{{ t("order.details") }}</CFormLabel>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow class="mx-1">
                                                            <CCol class="p-0 border-bottom">
                                                                <CTable class="border align-middle" bordered small>
                                                                    <CTableHead>
                                                                        <CTableRow>
                                                                            <CTableHeaderCell class="t-col-3" scope="col">{{ t("generic.color") }} ({{ getAmountTableTissueText(product.tissues[0]) }})</CTableHeaderCell>
                                                                            <CTableHeaderCell v-for="size in getSizesFromAmounts(product.amounts)" :key="size" class="t-col-1 text-center" scope="col">
                                                                                {{ getElementName(getSize(size)) }}
                                                                            </CTableHeaderCell>
                                                                            <CTableHeaderCell class="t-col-2 text-center" scope="col">{{ t("generic.total") }}</CTableHeaderCell>
                                                                        </CTableRow>
                                                                    </CTableHead>
                                                                    <CTableBody>
                                                                        <CTableRow v-for="(color) in getColorsFromAmounts(product.amounts)" :key="color">
                                                                            <CTableDataCell>{{ getElementName(getColor(color)) }}</CTableDataCell>
                                                                            <CTableDataCell class="p-1 text-center" v-for="size in getSizesFromAmounts(product.amounts)" :key="size">
                                                                                {{ getQuantityFromAmounts(product?.amounts, color, size) }}
                                                                            </CTableDataCell>
                                                                            <CTableDataCell class="text-center bg-light">{{ getColorSubtotalFromAmounts(product.amounts, color)  }}</CTableDataCell>
                                                                        </CTableRow>
                                                                        <CTableRow>
                                                                            <CTableDataCell>{{ t("generic.total") }} ({{ getColorsFromAmounts(product.amounts).length }} {{ t("generic.colors") }})</CTableDataCell>
                                                                            <CTableDataCell class="text-center bg-light text-center" v-for="size in getSizesFromAmounts(product.amounts)" :key="size">{{ getSizeSubtotalFromAmounts(product.amounts, size) }}</CTableDataCell>
                                                                            <CTableDataCell class="text-center bg-light">{{ getTotalFromAmounts(product.amounts) }}</CTableDataCell>
                                                                        </CTableRow>
                                                                    </CTableBody>
                                                                </CTable>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow class="mx-1 mt-1">
                                                            <CCol class="p-0">
                                                                <CFormLabel class="p-0 w-100 fw-bolder">{{ t("order.produce") }}</CFormLabel>
                                                                <span class="p-0 w-100 fs-5">{{ t(`order.${product.production}`) }}</span>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow class="mx-1 mt-2">
                                                            <CCol class="p-0">
                                                                <CFormLabel class="p-0 w-100 fw-bolder">{{ t("order.productionChanges") }}</CFormLabel>
                                                                <span class="p-0 w-100 fs-5">{{ product.productionNote.length === 0 ? "-" : product.productionNote}}</span>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow class="mx-1 mt-2">
                                                            <CCol class="p-0">
                                                                <CFormLabel class="p-0 w-100 fw-bolder">{{ t("order.deliveryNote") }}</CFormLabel>
                                                                <span class="p-0 w-100 fs-5">{{ product.deliveryNote.length === 0 ? "-" : product.deliveryNote }}</span>
                                                            </CCol>
                                                        </CRow>
                                                    </CContainer>
                                                </template>
                                            </VMenu>
                                        </CTableDataCell>
                                        <CTableDataCell class="ps-4 pe-3 py-2">
                                            <TissuePopover
                                            v-if="product.tissues.length > 0"
                                            :suppliers="orderPresentation.suppliers"
                                            :categories="orderPresentation.tissueCategories"
                                            :tissue="product.tissues[0].tissue"
                                            >
                                            <template #toggler>
                                                <CFormLabel id="popoverToggler" class="text-info mb-0"> {{ product.tissues[0].tissue.name }}</CFormLabel>
                                            </template>
                                        </TissuePopover>
                                    </CTableDataCell>
                                    <CTableDataCell class="py-3 ps-4">
                                        <div v-if="product.urgent" @click.stop="product.urgent = false" class="cursor-pointer">
                                            <span class="fs-5 py-1 px-2 text-white bg-danger rounded-pill">
                                                {{ t("generic.urgent")}}
                                            </span>
                                        </div>
                                        <div v-else @click.stop="product.urgent = true" class="cursor-pointer">
                                            <span class="fs-5 py-1 px-2 text-white bg-dark rounded-pill">
                                                {{ t("generic.urgent")}}
                                            </span>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell class="py-2 pe-3 text-end">{{ product.cost.price ?? "-" }}</CTableDataCell>
                                    <CTableDataCell class="py-2 pe-3">
                                        <input id="priceToSell" class="form-control text-end input-sm w-75 ms-auto price-input-table" v-model="product.soldPrice" :placeholder="product.cost.price ?? '0,000'">
                                    </CTableDataCell>
                                    <CTableDataCell class="py-2 pe-3 text-end">
                                        x{{ getSumOfTotals(spIndex) }}
                                    </CTableDataCell>
                                    <CTableDataCell class="py-2 pe-3 text-end">
                                        {{ getProductSubtotal(spIndex) }}
                                    </CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        </CTable>
                    </div>
                </CCol>
            </CRow>
        </CTabPane>
    </CTabContent>
</div>
</CRow>
</CModalBody>
</CForm>

<customer-picker v-model:is-visible="isCustomerPickerVisible" @select-customer="selectCustomer"></customer-picker>
<customer-add-modal v-model:is-visible="isCustomerAddModalVisible" @submit="selectCustomer"></customer-add-modal>
<product-add-modal v-model:is-visible="isProductAddModalVisible" @submit="addNewProductToSelectedProduct"></product-add-modal>
<product-multi-select v-model:is-visible="isProductMultiSelectVisible" v-model:selected-products="selectedProducts" :required-params="productMultiSelectRequiredParams"></product-multi-select>
<size-multi-select v-model:is-visible="isSizeMultiSelectVisible" :selected-sizes="selectedSizes" @select-sizes="addSelectedSizes"></size-multi-select>
<color-multi-select v-model:is-visible="isColorMultiSelectVisible" :selected-colors="selectedColors" @select-colors="addSelectedColors"></color-multi-select>


<!-- Lightbox Row-->
<BaseLightbox
:is-lightbox-visible="isLightboxVisible"
:selected-image-index="0"
:images="lightboxImages"
@hide="closeLightbox"
></BaseLightbox>

<CModal backdrop="static" :visible="isCopyInfoModalVisible" @close="closeCopyInfoModal" alignment="center">
    <CModalHeader :close-button="false">{{ t("order.copyInfoOnOtherModels") }}</CModalHeader>

    <CModalBody style="height: auto">
        <CRow class="pt-3 ps-3 pe-3">
            <CCol>
                <CFormLabel>{{ t("order.copyOnModel") }}</CFormLabel>
            </CCol>
        </CRow>
        <CRow class="ps-3 pe-3 pt-2">
            <CCol>
                <CButton color="primary" @click="openCopyProductMultiselect">{{ t("model.addNew") }}</CButton>
            </CCol>
        </CRow>
        <CRow class="ps-3 pe-3 pt-2">
            <CCol>
                <CFormLabel>{{ t("order.choseWhatYouWantToCopy") }}</CFormLabel>
            </CCol>
        </CRow>
        <CRow class="ps-3 pe-3 pt-2">
            <CCol>
                <CFormCheck type="radio" name="copyInfoRadio" id="all" :label="t('order.copyAll')" :model-value="selectedCopyOption.all" @change="selectCopyOption"/>
                <CFormCheck type="radio" name="copyInfoRadio" id="table" :label="t('order.copyJustTable')" :model-value="selectedCopyOption.table" @change="selectCopyOption"/>
            </CCol>
        </CRow>
    </CModalBody>

    <CModalFooter>
        <CButton variant="outline" color="secondary" @click="closeCopyInfoModal">{{ t("generic.undo") }}</CButton>
        <CButton color="primary" @click="copyInfos" :disabled="selectedProductsToCopyOn.length === 0">{{ t("generic.confirm") }}</CButton>
    </CModalFooter>

    <product-multi-select
    v-model:is-visible="isCopyInfoMultiselectVisible"
    v-model:selected-products="selectedProductsToCopyOn"
    :required-params="productMultiSelectRequiredParams"
    :show-existing="false"
    ></product-multi-select>
</CModal>

<CModal :visible="isCopyProductModalVisible" @close="closeCopyProductModal" alignment="center">
    <CModalHeader :close-button="false">{{ t("order.copyModel") }} {{ selectedProducts[activeProductTab].productTemplateId }} ({{ selectedProducts[activeProductTab].name }})</CModalHeader>

    <CModalBody style="height: auto">
        <CRow class="pt-3 ps-3 pe-3">
            <CCol>
                <CFormLabel>{{ t("order.choseWhatYouWantToCopy") }}</CFormLabel>
            </CCol>
        </CRow>
        <CRow class="ps-3 pe-3 pt-2">
            <CCol>
                <CFormCheck type="radio" name="copyProductRadio" id="productAll" :label="t('order.copyAll')" :model-value="selectedCopyProductOption.all" @change="selectCopyProductOption"/>
                <CFormCheck type="radio" name="copyProductRadio" id="productTable" :label="t('order.copyJustTable')" :model-value="selectedCopyProductOption.table" @change="selectCopyProductOption"/>
                <CFormCheck type="radio" name="copyProductRadio" id="productColors" :label="t('order.copyJustColors')" :model-value="selectedCopyProductOption.colors" @change="selectCopyProductOption"/>
                <CFormCheck type="radio" name="copyProductRadio" id="productNone" :label="t('order.copyNone')" :model-value="selectedCopyProductOption.none" @change="selectCopyProductOption"/>
            </CCol>
        </CRow>
    </CModalBody>

    <CModalFooter>
        <CButton variant="outline" color="secondary" @click="closeCopyProductModal">{{ t("generic.undo") }}</CButton>
        <CButton color="primary" @click="addModelCopy">{{ t("generic.confirm") }}</CButton>
    </CModalFooter>
</CModal>

<CModal :visible="isInputModalVisible" @close="closeInputModal" alignment="center">
    <CModalHeader>{{ t("generic.number") }}</CModalHeader>
    <CModalBody style="height: auto">
        <CRow class="p-3">
            <CCol>
                <CFormInput type="number" v-model="fillInput.value" placeholder="0"></CFormInput>
            </CCol>
        </CRow>
    </CModalBody>

    <CModalFooter>
        <CButton variant="outline" color="secondary" @click="closeInputModal">{{ t("generic.undo") }}</CButton>
        <CButton color="primary" @click="fill">{{ t("generic.confirm") }}</CButton>
    </CModalFooter>
</CModal>

<DraftModal
v-model:is-visible="isDraftModalVisible"
@loadDraft="loadDraft"
:draft="draftBody"
></DraftModal>

<product-update-modal
v-model:is-visible="isProductCopyModalVisible"
:copy-mode="true"
:product-to-bind="elementToBind"
@submit="appendCopyToProducts"
></product-update-modal>

<HistoricalColorMultiSelect
v-model:is-visible="isHistoricalColorMultiSelectVisible"
:selected-product="selectedProducts[activeProductTab]"
:selected-customer="selectedCustomer"
:selected-colors="selectedColors"
@select-colors="addSelectedColors"
>
</HistoricalColorMultiSelect>
</CModal>
</template>

<script>
import {useI18n} from "vue-i18n";
import {
    computed,
    ref,
    watch,
} from "vue";
import CustomerPicker from "@/components/pickers/CustomerPicker";
import ProductAddModal from "@/components/modals/ProductAddModal";
import ProductMultiSelect from "@/components/pickers/ProductMultiSelect";
import {user} from "@/user";
import SizeMultiSelect from "@/components/pickers/SizeMultiSelect";
import ColorMultiSelect from "@/components/pickers/ColorMultiSelect";
import {
    doubleInputNormalization,
    doubleOutputNormalization,
    getTimeDifferenceText,
    normalization,
    readImage,
} from "@/views/utilities";
import AppLoadingScreen from "@/components/AppLoadingScreen";
import BaseLightbox from "@/components/base/BaseLightbox";
import CustomerAddModal from "@/components/modals/CustomerAddModal";
import BaseDatePicker from "@/components/base/BaseDatePicker";
import HistoricalColorMultiSelect from "@/components/pickers/HistoricalColorMultiSelect";
import DraftModal from "@/components/modals/DraftModal";
import { useStore } from "vuex";
import TissuePopover from "@/components/popovers/TissuePopover";
import ProductUpdateModal from "@/components/modals/ProductUpdateModal";
import BaseImageUploader from "@/components/base/BaseImageUploader";
import BaseInput from "@/components/base/BaseInput";
import { debounce } from "debounce";

export default {
    name: "PreorderCreateModal",
    components: {
        ProductUpdateModal,
        TissuePopover,
        DraftModal,
        BaseDatePicker,
        CustomerAddModal,
        BaseLightbox,
        ColorMultiSelect,
        SizeMultiSelect,
        ProductMultiSelect,
        ProductAddModal,
        CustomerPicker,
        AppLoadingScreen,
        HistoricalColorMultiSelect,
        BaseImageUploader,
        BaseInput,
    },
    emits: [ "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        requiredParams: {
            type: Object,
            default: null,
        },
        isGlobal: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "customer",
        },
    },
    setup (props, { emit, }) {
        const store = useStore();
        const { t, } = useI18n();
        const isLoading = ref(true);
        const activeModalTab = ref(0);
        const activeProductTab = ref(0);
        let stopWatch;

        const colors = ref([]);
        const sizes = ref([]);
        const products = ref([]);

        const selectedCustomer = ref({});

        const selectedProducts = ref([]);
        const selectedSizes = ref([]);
        const selectedColors = ref([]);

        const isCustomerAddModalVisible = ref(false);
        const isProductAddModalVisible = ref(false);
        const isCustomerPickerVisible = ref(false);
        const isProductMultiSelectVisible = ref(false);
        const isSizeMultiSelectVisible = ref(false);
        const isColorMultiSelectVisible = ref(false);
        const isLightboxVisible = ref(false);
        const lightboxImages = ref([]);
        const isCopyInfoModalVisible = ref(false);
        const isCopyInfoMultiselectVisible = ref(false);
        const isCopyProductModalVisible = ref(false);
        const isInputModalVisible = ref(false);
        const isDraftModalVisible = ref(false);

        const isConfirmButtonLoading = ref(false);

        const fillInput = ref({
            value: "",
            type: "",
            id: "",
        });

        const productMultiSelectRequiredParams = ref(null);

        const customerImages = computed(() => selectedCustomer.value?.defaultCompositionImages?.map((i) => i.compress) ?? []);

        const selectedProductsOptions = computed(() => {
            let products = [ ...selectedProducts.value, ];

            products = products.map((p, index) => ({
                value: index,
                text: p.name,
            }));

            products.splice(activeProductTab.value, 1);

            return products;
        });

        const selectedProductsToCopyOn = ref([]);
        const selectedCopyOption = ref({
            all: true,
            table: false,
        });

        const selectedCopyProductOption = ref({
            all: true,
            table: false,
            colors: false,
            none: false,
        });

        const otherCost = ref("");
        const discountAmount = ref("");
        const paidAmount = ref("");
        const placeTimestamp = ref(null);
        const expirationTimestamp = ref(null);
        const subtotal = computed(() => {
            let sum = 0;
            for (const p in selectedProducts.value) {
                sum += doubleInputNormalization(getProductSubtotal(p));
            }
            return doubleOutputNormalization(sum);
        });

        const total = computed(() => {
            const productsSubtotal = doubleInputNormalization(subtotal.value);
            const total = productsSubtotal + doubleInputNormalization(otherCost.value) - doubleInputNormalization(discountAmount.value);
            return doubleOutputNormalization(total);
        });

        const newImages = ref([]);

        const isProductCopyModalVisible = ref(false);
        const elementToBind = ref(null);

        const isHistoricalColorMultiSelectVisible = ref(false);
        const historicalColorMultiSelectRequiredParams = ref(null);

        const draft = ref({});
        const draftBody = ref(null);

        const toMakeDraft = computed(() => selectedCustomer.value.fullName !== "default.customer" ||
        selectedCustomer.value.defaultComposition !== "" ||
        selectedCustomer.value.defaultCompositionImages.length !== 0 ||
        selectedCustomer.value.defaultLogo !== "" ||
        selectedCustomer.value.defaultTag !== "" ||
        selectedCustomer.value.note !== "" ||
        selectedProducts.value.length !== 0 ||
        selectedSizes.value.length !== 0 ||
        selectedColors.value.length !== 0 ||
        otherCost.value !== "" ||
        discountAmount.value !== "");

        const isSizeInputDisabled = computed(() => {
            const uniqueSize = selectedSizes.value.find((s) => s.name === "size.uniqueSize");
            let isOneFilled = false;

            if (uniqueSize) {
                selectedProducts.value[activeProductTab.value].amounts.forEach((a) => {
                    if (a.sizeId === uniqueSize.id) {
                        if (a.amount !== 0) {
                            isOneFilled = true;
                        }
                    }
                });
            }

            return isOneFilled;
        });

        const isUniqueSizeInputDisabled = computed(() => {
            const uniqueSize = selectedSizes.value.find((s) => s.name === "size.uniqueSize");
            let isOneFilled = false;

            if (uniqueSize) {
                selectedProducts.value[activeProductTab.value].amounts.forEach((a) => {
                    if (a.sizeId !== uniqueSize.id) {
                        if (a.amount !== 0) {
                            isOneFilled = true;
                        }
                    }
                });
            }

            return isOneFilled;
        });

        function isNonUniqueSizeDisabled (sizeId) {
            return getSize(sizeId)?.name !== "size.uniqueSize" && isSizeInputDisabled.value;
        }

        function isUniqueSizeDisabled (sizeId) {
            return getSize(sizeId)?.name === "size.uniqueSize" && isUniqueSizeInputDisabled.value;
        }

        function getSizeInputClass (sizeId) {
            let style = "";

            if (isNonUniqueSizeDisabled(sizeId) || isUniqueSizeDisabled(sizeId)) {
                style = "bg-light";
            }

            return style;
        }

        function emptyAll () {
            activeModalTab.value = 0;
            activeProductTab.value = 0;
            colors.value = [];
            sizes.value = [];
            selectedCustomer.value.defaultLogo = "";
            selectedCustomer.value.defaultComposition = "";
            selectedCustomer.value.defaultCompositionImages = [];
            selectedCustomer.value.defaultTag = "";
            selectedCustomer.value.note = "";
            selectedCustomer.value = {};
            selectedProducts.value = [];
            selectedSizes.value = [];
            selectedColors.value = [];
            fillInput.value = {
                value: "",
                type: "",
                id: "",
            };
            otherCost.value = "";
            discountAmount.value = "";
            newImages.value = [];
            placeTimestamp.value = null;
            expirationTimestamp.value = null;
        }

        async function makeDraft () {
            stopWatch();
            if (toMakeDraft.value) {
                const order = {};
                const existImages = selectedCustomer.value.defaultCompositionImages?.filter((i) => i.id !== undefined).map((i) => i.id);

                order.expirationTimestamp = expirationTimestamp.value;
                order.placeTimestamp = placeTimestamp.value;
                order.customer = selectedCustomer.value;
                order.existImages = existImages;
                order.cost = {
                    otherCost: otherCost.value,
                    discountAmount: discountAmount.value,
                    total: total.value,
                };
                order.products = selectedProducts.value;
                order.images = newImages.value;
                isConfirmButtonLoading.value = true;
                await user.makeOrderDraft({
                    order: JSON.stringify(order),
                    type: props.type,
                });
                isConfirmButtonLoading.value = false;
            }

            closeModal();
        }

        async function autoSave () {
            if (toMakeDraft.value) {
                const order = {};
                const existImages = selectedCustomer.value.defaultCompositionImages?.filter((i) => i.id !== undefined).map((i) => i.id);

                order.expirationTimestamp = expirationTimestamp.value;
                order.placeTimestamp = placeTimestamp.value;
                order.customer = selectedCustomer.value;
                order.existImages = existImages;
                order.cost = {
                    otherCost: otherCost.value,
                    discountAmount: discountAmount.value,
                    total: total.value,
                };
                order.products = selectedProducts.value;
                order.images = newImages.value;

                await user.makeOrderDraft({
                    order: JSON.stringify(order),
                    type: props.type,
                });
            }
        }

        function openDraftModal () {
            isDraftModalVisible.value = true;
        }

        function closeDraftModal () {
            isDraftModalVisible.value = false;
        }

        function loadDraft () {
            selectedCustomer.value = draft.value.customer;
            selectedProducts.value = draft.value.products;
            expirationTimestamp.value = draft.value.expirationTimestamp;
            placeTimestamp.value = draft.value.placeTimestamp;
            otherCost.value = draft.value.cost.otherCost;
            discountAmount.value = draft.value.cost.discountAmount;

            closeDraftModal();
        }

        function closeModal () {
            emptyAll();

            if (!props.isGlobal) {
                emit("update:isVisible", false);
            }
            else {
                store.commit("closePreorderCreateModal");
            }
        }

        function openCustomerAddModal () {
            isCustomerAddModalVisible.value = true;
        }

        function openProductAddModal () {
            isProductAddModalVisible.value = true;
        }

        function openCustomerPicker () {
            isCustomerPickerVisible.value = true;
        }

        function selectCustomer (customer) {
            selectedCustomer.value = customer;
        }

        function openProductMultiSelect () {
            isProductMultiSelectVisible.value = true;
        }

        function openCopyProductMultiselect () {
            isCopyInfoMultiselectVisible.value = true;
        }

        function openSizeMultiSelect () {
            isSizeMultiSelectVisible.value = true;
        }

        function openColorMultiSelect () {
            isColorMultiSelectVisible.value = true;
        }

        function openLightbox (element) {
            lightboxImages.value = element.images.map((i) => i.compress);
            isLightboxVisible.value = true;
        }

        function openCustomerImagesLightbox () {
            lightboxImages.value = [ ...customerImages.value, ];
            isLightboxVisible.value = true;
        }

        function closeLightbox () {
            lightboxImages.value = [];
            isLightboxVisible.value = false;
        }

        function openCopyInfoModal () {
            isCopyInfoModalVisible.value = true;
        }

        function closeCopyInfoModal () {
            isCopyInfoModalVisible.value = false;
        }

        function openCopyProductModal () {
            isCopyProductModalVisible.value = true;
        }

        function closeCopyProductModal () {
            isCopyProductModalVisible.value = false;
        }

        function closeInputModal () {
            isInputModalVisible.value = false;
        }

        function openFillRowModal (colorId) {
            fillInput.value.type = "row";
            fillInput.value.id = colorId;
            isInputModalVisible.value = true;
        }

        function openFillColumnModal (sizeId) {
            fillInput.value.type = "col";
            fillInput.value.id = sizeId;
            isInputModalVisible.value = true;
        }

        function getColor (colorId) {
            return colors.value.find((c) => c.id === colorId);
        }

        function getSize (sizeId) {
            return sizes.value.find((s) => s.id === sizeId);
        }

        function selectProductTab (index) {
            activeProductTab.value = index;
            setSelectedSizes();
            setSelectedColors();
            setSoldPrice();
        }

        async function submitCreate () {
            let isOneEmpty = false;

            selectedProducts.value.forEach((p, index) => {
                if (getSumOfTotals(index) === 0) {
                    isOneEmpty = true;
                }
            });

            if (isOneEmpty) {
                return;
            }

            const formData = new FormData();

            isConfirmButtonLoading.value = true;

            formData.append("type", props.type);

            // Dates
            if (expirationTimestamp.value) {
                formData.append("expirationTimestamp", normalization(expirationTimestamp.value));
            }
            formData.append("placeTimestamp", normalization(placeTimestamp.value ? placeTimestamp.value : Date.now()));

            // Customer
            formData.append("customer", JSON.stringify(normalization({
                logo: selectedCustomer.value.defaultLogo,
                tag: selectedCustomer.value.defaultTag,
                isDefault: selectedCustomer.value.isDefault,
                note: selectedCustomer.value.note,
                id: selectedCustomer.value.id,
                composition: selectedCustomer.value.defaultComposition,
                fullName: selectedCustomer.value.fullName,
            })));

            const existImages = selectedCustomer.value.defaultCompositionImages.filter((i) => i.id !== undefined).map((i) => i.id);

            if (existImages.length > 0) {
                formData.append("existImages", JSON.stringify(existImages));
            }

            // Cost
            formData.append("cost", JSON.stringify({
                otherCost: normalization(otherCost.value),
                discountAmount: normalization(discountAmount.value),
                total: normalization(total.value),
                paidAmount: normalization(paidAmount.value),
            }));

            // Products
            const products = JSON.parse(JSON.stringify(selectedProducts.value));
            products.forEach((p) => {
                p.colors = p.colors.map((c) => c.id);
                p.soldPrice = p.soldPrice?.length > 0 ? p.soldPrice : p.cost.price;
            });
            formData.append("products", JSON.stringify(normalization(products)));

            for (const i of newImages.value) {
                formData.append("images", i);
            }
            stopWatch();
            const response = await user.createNewOrder(formData);

            if (response) {
                emptyAll();
                emit("submit", response);
                closeModal();

            }

            isConfirmButtonLoading.value = false;
        }

        function getElementName (element) {
            if (element.isDefault) {
                return t(element.name);
            }

            return element.name;
        }

        function setSelectedSizes () {
            if (selectedProducts.value.length > 0) {
                selectedSizes.value = selectedProducts.value[activeProductTab.value]
                    .sizes.map((size) => getSize(size)).filter((s) => s);
                setAmountBySize();
            }
        }

        function setSelectedColors () {
            if (selectedProducts.value.length > 0) {
                selectedColors.value = selectedProducts.value[activeProductTab.value]
                    .colors.map((color) => color.enable ? getColor(color.id) : null).filter((c) => c);
                setAmountByColor();
            }
        }

        function setSoldPrice () {
            if (selectedProducts.value.length > 0) {
                selectedProducts.value[activeProductTab.value].soldPrice = "";
            }
        }

        async function addSelectedSizes (newSizes) {
            sizes.value = await user.getAllSizes();
            selectedProducts.value[activeProductTab.value].sizes = sizes.value.map((s) => newSizes.find((ns) => s.id === ns.id)).filter((s) => s).map((s) => s.id);
            setSelectedSizes();
        }

        async function addSelectedColors (newColors) {
            colors.value = await user.getAllColors();
            selectedProducts.value[activeProductTab.value].colors = colors.value.map((c) => newColors.find((nc) => c.id === nc.id)).filter((c) => c).map((c) => ({
                id: c.id,
                enable: true,
            }));
            setSelectedColors();
        }

        function setAmountBySize () {
            const product = selectedProducts.value[activeProductTab.value];

            selectedSizes.value.forEach((size) => {
                if (!product.amounts.find((x) => x.sizeId === size.id)) {
                    selectedColors.value.forEach((color) => {
                        product.amounts = [ ...product.amounts, {
                            amount: 0,
                            colorId: color.id,
                            sizeId: size.id,
                        }, ];
                    });
                }
            });

            removeScratchBySize();
        }

        function setAmountByColor () {
            const product = selectedProducts.value[activeProductTab.value];
            selectedColors.value.forEach((color) => {
                if (!product.amounts.find((x) => x.colorId === color?.id)) {
                    selectedSizes.value.forEach((size) => {
                        product.amounts = [ ...product.amounts, {
                            amount: 0,
                            colorId: color.id,
                            sizeId: size.id,
                        }, ];
                    });
                }
            });

            removeScratchByColor();
        }

        function removeScratchBySize () {
            const product = selectedProducts.value[activeProductTab.value];
            let indexes = [];

            product.amounts.forEach((x, index) => {
                if (!selectedSizes.value.find((size) => size.id === x.sizeId)) {
                    indexes = [ index, ...indexes, ];
                }
            });

            indexes.forEach((index) => {
                product.amounts.splice(index, 1);
            });
        }

        function removeScratchByColor () {
            const product = selectedProducts.value[activeProductTab.value];
            let indexes = [];

            product.amounts.forEach((x, index) => {
                if (!selectedColors.value.find((color) => color.id === x.colorId)) {
                    indexes = [ index, ...indexes, ];
                }
            });

            indexes.forEach((index) => {
                product.amounts.splice(index, 1);
            });
        }

        function inputSizeQuantity (event, colorId, sizeId) {
            const product = selectedProducts.value[activeProductTab.value];

            const amount = product.amounts.find((x) => x.colorId === colorId && x.sizeId === sizeId);

            if (event.target.value === "0") {
                event.target.value = "";
            }

            if (event.target.value !== "") {
                amount.amount = event.target.value;
            }
            else {
                amount.amount = 0;
            }
        }

        function getAmount (sizeId) {
            let total = 0;

            selectedProducts.value[activeProductTab.value].amounts.forEach((x) => {
                if (x.sizeId === sizeId) {
                    total += Number(x.amount);
                }
            });

            return total;
        }

        function getTotal (colorId, index) {
            let total = 0;

            selectedProducts.value[index].amounts.forEach((x) => {
                if (x.colorId === colorId) {
                    total += Number(x.amount);
                }
            });

            return total;
        }

        function getSumOfTotals (index) {
            let total = 0;

            selectedProducts.value[index].colors.forEach((c) => {
                total += getTotal(c.id, index);
            });

            return total;
        }

        function unselectProduct (index) {
            activeProductTab.value = 0;
            selectedProducts.value.splice(index, 1);
        }

        function gotoProduct (event, index) {
            if (!event.target.id) {
                activeModalTab.value = 1;
                activeProductTab.value = index;
            }
        }

        function getProductSubtotal (index) {
            const product = selectedProducts.value[index];
            const quantity = getSumOfTotals(index);
            const price = doubleInputNormalization(product.soldPrice?.length > 0 ? product.soldPrice : product.cost.price);
            return doubleOutputNormalization(quantity * price);
        }

        function copyInfos () {
            const product = { ...selectedProducts.value[activeProductTab.value], };
            const products = selectedProductsToCopyOn.value.filter((p) => !p.existing);
            products.forEach((p) => {
                p.deliveryNote = "";
                p.productionNote = "";
                p.production = "toProduce";
                if (selectedCopyOption.value.all) {
                    p.deliveryNote = product.deliveryNote;
                    p.productionNote = product.productionNote;
                    p.production = product.production;
                }
                p.colors = [ ...product.colors, ];
                p.sizes = [ ...product.sizes, ];
                p.amounts = JSON.parse(JSON.stringify(product.amounts));
                p.existing = true;
                addSelectedProduct(p);
            });

            closeCopyInfoModal();
        }

        function getInputValue (colorId, sizeId) {
            const product = { ...selectedProducts.value[activeProductTab.value], };
            const amount = product.amounts.find((x) => x.colorId === colorId && x.sizeId === sizeId)?.amount;

            if (amount === 0) {
                return "";
            }

            return amount;
        }

        function addModelCopy () {
            const productCopy = JSON.parse(JSON.stringify(selectedProducts.value[activeProductTab.value]));

            if (selectedCopyProductOption.value.table) {
                productCopy.deliveryNote = "";
                productCopy.productionNote = "";
            }
            else if (selectedCopyProductOption.value.colors) {
                productCopy.deliveryNote = "";
                productCopy.productionNote = "";
                productCopy.amounts = [];
                productCopy.sizes = [];
            }
            else if (selectedCopyProductOption.value.none) {
                productCopy.deliveryNote = "";
                productCopy.productionNote = "";
                productCopy.amounts = [];
                productCopy.colors = [];
                productCopy.sizes = [];
                productCopy.existing = false;
            }

            addSelectedProduct(productCopy);
            selectedProducts.value.sort((a, b) => a.name - b.name);

            closeCopyProductModal();
        }

        function selectCopyOption (event) {
            if (event.target.id === "all") {
                selectedCopyOption.value.all = true;
                selectedCopyOption.value.table = false;
            }
            else if (event.target.id === "table") {
                selectedCopyOption.value.all = false;
                selectedCopyOption.value.table = true;
            }
        }

        function selectCopyProductOption (event) {
            if (event.target.id === "productAll") {
                selectedCopyProductOption.value.all = true;
                selectedCopyProductOption.value.table = false;
                selectedCopyProductOption.value.colors = false;
                selectedCopyProductOption.value.none = false;
            }
            else if (event.target.id === "productTable") {
                selectedCopyProductOption.value.all = false;
                selectedCopyProductOption.value.table = true;
                selectedCopyProductOption.value.colors = false;
                selectedCopyProductOption.value.none = false;
            }
            else if (event.target.id === "productColors") {
                selectedCopyProductOption.value.all = false;
                selectedCopyProductOption.value.table = false;
                selectedCopyProductOption.value.colors = true;
                selectedCopyProductOption.value.none = false;
            }
            else if (event.target.id === "productNone") {
                selectedCopyProductOption.value.all = false;
                selectedCopyProductOption.value.table = false;
                selectedCopyProductOption.value.colors = false;
                selectedCopyProductOption.value.none = true;
            }
            selectedCopyProductOption.value.soldPrice = "";
        }

        function clearTable () {
            selectedProducts.value[activeProductTab.value].amounts = [];
        }

        function removeProduct () {
            const selectedProductsCopy = [ ...selectedProducts.value, ];

            selectedProductsCopy.splice(activeProductTab.value, 1);

            selectedProducts.value = [ ...selectedProductsCopy, ];
            activeProductTab.value = activeProductTab.value > 0 ? activeProductTab.value -1 : 0;
        }

        function deleteSize (index) {
            selectedProducts.value[activeProductTab.value].sizes.splice(index, 1);
            setSelectedSizes();
        }

        function deleteColor (index) {
            selectedProducts.value[activeProductTab.value].colors.splice(index, 1);
            setSelectedColors();
        }

        function fill () {
            if (fillInput.value.type === "row") {
                const product = selectedProducts.value[activeProductTab.value];

                product.amounts.forEach((x) => {
                    if (x.colorId === fillInput.value.id) {
                        x.amount = fillInput.value.value !== "" ? fillInput.value.value : 0;
                    }
                });
            }
            else if (fillInput.value.type === "col") {
                const product = selectedProducts.value[activeProductTab.value];

                product.amounts.forEach((x) => {
                    if (x.sizeId === fillInput.value.id) {
                        x.amount = fillInput.value.value !== "" ? fillInput.value.value : 0;
                    }
                });
            }

            fillInput.value.value = "";
            fillInput.value.type = "";
            fillInput.value.id = "";

            closeInputModal();
        }

        async function saveImages (files) {
            selectedCustomer.value.defaultCompositionImages = [ ...selectedCustomer.value.defaultCompositionImages, ...files, ];
            newImages.value = [ ...newImages.value, ...files, ]; // I'm not sure
            for (let i=0; i<selectedCustomer.value.defaultCompositionImages.length; i++) {
                // The bug is caused by setting attributes of a file is not allowed. A file is still a file.
                const image = await readImage(selectedCustomer.value.defaultCompositionImages[i]);
                // Save id of image if it's already exist
                selectedCustomer.value.defaultCompositionImages[i] = {
                    url: image ? image : selectedCustomer.value.defaultCompositionImages[i].url,
                    compress: image ? image : selectedCustomer.value.defaultCompositionImages[i].compress,
                    tiny: image ? image : selectedCustomer.value.defaultCompositionImages[i].tiny,
                    ...selectedCustomer.value.defaultCompositionImages[i],
                };
            }

            event.target.value = "";
        }

        function deleteImage (imageDataUrl) {
            newImages.value = newImages.value.filter((i) => i !== imageDataUrl);
            selectedCustomer.value.defaultCompositionImages = selectedCustomer.value.defaultCompositionImages.filter((imageToRemove) => imageToRemove.url !== imageDataUrl);
        }

        const orderPresentation = ref(null);

        watch(() => store.state.toReloadPage, async (toReload) => {
            if (toReload) {
                await loadData();
            }
        });

        async function loadData () {
            const presentation = await user.preorderByProduct();
            orderPresentation.value = presentation;

            colors.value = presentation.colors;
            sizes.value = presentation.sizes;
            productMultiSelectRequiredParams.value = { ...presentation, };
            return presentation;
        }

        async function init () {
            const presentation = await loadData();
            selectedCustomer.value = presentation.customer;

            // not working
            historicalColorMultiSelectRequiredParams.value = {
                colors: presentation.colors,
                products: presentation.products,
                customers: presentation.customers,
            };

            draftBody.value = await user.getOrderDraft({ type: props.type, });
            const draftOrder = draftBody.value?.order;
            if (draftOrder) {
                draft.value = JSON.parse(draftOrder);
                if (draft.value !== {}) {
                    openDraftModal();
                }
            }

            const saveInTime = debounce(async () => {
                await autoSave();
            }, 50);
            stopWatch = watch([
                expirationTimestamp,
                placeTimestamp,
                selectedCustomer,
                otherCost,
                discountAmount,
                total,
                paidAmount,
                selectedProducts,
            ], () => {
                saveInTime();
            }, { deep: true, });
        }

        async function updatePresentation () {
            const presentation = await user.preorderByProduct();
            orderPresentation.value = presentation;

            colors.value = presentation.colors;
            sizes.value = presentation.sizes;

            productMultiSelectRequiredParams.value = { ...presentation, };
        }

        function showProductCopyModal (element) {
            elementToBind.value = element;
            isProductCopyModalVisible.value = true;
        }

        function appendCopyToProducts ({ response, }) {
            selectedProducts.value = [ ...selectedProducts.value, response, ];
        }

        function importDefaultColors () {
            const defaultColors = colors.value.filter((c) => c.isPreorderDefault);
            addSelectedColors(defaultColors);
        }

        function importHistoricalColors (newColors) {
            selectedProducts.value[activeProductTab.value].colors = newColors.map((c) => ({
                id: c.id,
                enable: true,
            }));
            setSelectedColors();
        }

        function clearColors () {
            selectedProducts.value[activeProductTab.value].amounts = [];
            selectedProducts.value[activeProductTab.value].colors = [];
            setSelectedColors();
        }

        function showHistoricalColorsModal () {
            isHistoricalColorMultiSelectVisible.value = true;
        }

        function getColorsFromAmounts (amounts) {
            if (amounts) {
                const amountsWithoutZeros = amounts.filter((a) => a.amount !== 0);
                return Array.from(new Set(amountsWithoutZeros.map((a) => a.colorId)))
                    .sort((c1, c2) => colors.value.findIndex((c) => c.id === c1) - colors.value.findIndex((c) => c.id === c2));
            }

            return [];
        }

        function getSizesFromAmounts (amounts) {
            if (amounts) {
                const amountsWithoutZeros = amounts.filter((a) => a.amount !== 0);
                return Array.from(new Set(amountsWithoutZeros.map((a) => a.sizeId))).sort((s1, s2) => s1 - s2);
            }

            return [];
        }

        function getQuantityFromAmounts (amounts, colorId, sizeId) {
            if (amounts) {
                return amounts.find((a) => a.sizeId === sizeId && a.colorId === colorId)?.amount ?? 0;
            }
            return 0;
        }

        function getSizeSubtotalFromAmounts (amounts, sizeId) {
            if (amounts) {
                return amounts.filter((a) => a.sizeId === sizeId).reduce((pre, cur) => pre + Number.parseInt(cur.amount, 10), 0);
            }
            return 0;
        }

        function getColorSubtotalFromAmounts (amounts, colorId) {
            if (amounts) {
                return amounts.filter((a) => a.colorId === colorId).reduce((pre, cur) => pre + Number.parseInt(cur.amount, 10), 0);
            }
            return 0;
        }

        function getTotalFromAmounts (amounts) {
            if (amounts) {
                return amounts.reduce((pre, cur) => pre + Number.parseInt(cur.amount, 10), 0);
            }
            return 0;
        }

        function getAccessoryColorRule (accessory) {
            if (accessory.noColor) {
                return t("generic.noColor");
            }
            if (accessory.usePreorderColor) {
                return t("generic.usePreorderColor");
            }
            else if (accessory.colorId) {
                const color = colors.value.find((c) => c.id === accessory.colorId);
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "-";
            }
            return "ERROR NO MATCH";
        }

        function getTissueColorRule (tissueDescriptor) {
            if (tissueDescriptor.tissue.category.name === "generic.whiteEmbryo") {
                return !tissueDescriptor.printPattern ? t("ingredient.tissue.noColorForWhiteEmbryo") : "-";
            }
            else if (tissueDescriptor.tissueUsePreorderColor) {
                return t("generic.usePreorderColor");
            }
            else if (tissueDescriptor.tissueColorId) {
                const color = colors.value.find((c) => c.id === tissueDescriptor.tissueColorId);
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "-";
            }
            return "-";
        }

        function getPrintPatternColorRule (tissue) {
            if (tissue.printPatternUsePreorderColor) {
                return t("generic.usePreorderColor");
            }
            else if (tissue.printPatternNoColor) {
                return t("generic.noColor");
            }
            else if (tissue.printPatternColorId) {
                const color = colors.value.find((c) => c.id === tissue.printPatternColorId);
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "-";
            }
            return "ERROR NO MATCH";
        }

        function addSelectedProduct (product) {
            selectedProducts.value = [ ...selectedProducts.value, product, ];
        }

        async function addNewProductToSelectedProduct (product) {
            await updatePresentation();
            activeProductTab.value = selectedProducts.value.length;
            addSelectedProduct(product);
        }

        function keydownCallback (e) {
            const current = document.activeElement.className.split(" ").find((className) => className.startsWith(`amount-input-${activeProductTab.value}`));
            const totalCurrent = document.activeElement.className.split(" ").find((className) => className.startsWith("price-input"));
            if (totalCurrent) {
                e.stopImmediatePropagation();
                const priceInputs = [ ...document.querySelectorAll("input.price-input-table"), ...document.querySelectorAll("input.price-input"), ];
                let found;
                for (let pos = 0; pos < priceInputs.length; pos++) {
                    if (priceInputs[pos] === document.activeElement) {
                        found = pos;
                        break;
                    }
                }
                if (found !== undefined) {
                    switch (e.keyCode) {
                        case 13:
                            e.preventDefault();
                            if (found < priceInputs.length - 1) {
                                priceInputs[found + 1]?.select();
                            }
                            break;
                        case 38:
                            e.preventDefault();
                            if (found > 0) {
                                priceInputs[found - 1]?.select();
                            }
                            break;
                        case 40:
                            e.preventDefault();
                            if (found < priceInputs.length - 1) {
                                priceInputs[found + 1]?.select();
                            }
                            break;
                    }
                }
            }
            if (current) {
                e.stopImmediatePropagation();
                const temp = current.split("-");
                const y = temp[3];
                const x = temp[4];
                let nextRowEl = null;
                switch (e.keyCode) {
                    case 13:
                        e.preventDefault();
                        nextRowEl = document.querySelector(`input.amount-input-${activeProductTab.value}-${Number.parseInt(y, 10)+1}-${x}`);
                        if (nextRowEl) {
                            nextRowEl.select();
                        }
                        else {
                            document.querySelector(`input.amount-input-${activeProductTab.value}-${0}-${Number.parseInt(x, 10)+1}`)?.select();
                        }
                        break;
                    case 38:
                        e.preventDefault();
                        document.querySelector(`input.amount-input-${activeProductTab.value}-${Number.parseInt(y, 10)-1}-${x}`)?.select();
                        break;
                    case 40:
                        e.preventDefault();
                        document.querySelector(`input.amount-input-${activeProductTab.value}-${Number.parseInt(y, 10)+1}-${x}`)?.select();
                        break;
                    case 37:
                        e.preventDefault();
                        document.querySelector(`input.amount-input-${activeProductTab.value}-${y}-${Number.parseInt(x, 10)-1}`)?.select();
                        break;
                    case 39:
                        e.preventDefault();
                        document.querySelector(`input.amount-input-${activeProductTab.value}-${y}-${Number.parseInt(x, 10)+1}`)?.select();
                        break;
                }
            }
        }

        function addEnterListener () {
            if (window.__completeKeydownBind__) {
                return;
            }
            window.addEventListener("keydown", keydownCallback);
            window.__completeKeydownBind__ = true;
        }

        function removeEnterListener () {
            window.removeEventListener("keydown", keydownCallback);
            window.__completeKeydownBind__ = false;
        }

        function isInputInvalid (inputValue, isDisabled) {
            return !isDisabled && inputValue === "";
        }

        // onUnmounted(() => {
        //     removeEnterListener();
        // });

        if (!props.isGlobal) {
            watch(() => props.isVisible, async (newValue) => {
                if (newValue) {
                    await init();
                    isLoading.value = false;
                    addEnterListener();
                    if (props.type !== "customer") {
                        selectedCustomer.value.note = "样品/备货";
                    }
                }
                else {
                    removeEnterListener();
                }
            });
        }
        else {
            watch(() => store.state.isVisiblePreorderCreateModal, async (newValue) => {
                if (newValue) {
                    await init();
                    isLoading.value = false;
                    addEnterListener();
                }
                else {
                    removeEnterListener();
                }
            });
        }

        // function removeDuplicates (objectArray) {
        //     let arrayCopy = [];
        //
        //     for (let i = 0; i < objectArray.length; i++) {
        //         let hasNoCopies = true;
        //
        //         if (i !== objectArray.length-1) {
        //             for (let j = i; j < objectArray.length-1; j++) {
        //                 if (objectArray[i] === objectArray[j+1]) {
        //                     hasNoCopies = false;
        //                 }
        //             }
        //         }
        //
        //         if (hasNoCopies) {
        //             arrayCopy = [ ...arrayCopy, objectArray[i], ];
        //         }
        //     }
        //
        //     return arrayCopy;
        // }

        function getAmountTableTissueText (tissue) {
            if (tissue.printPattern) {
                return t("default.procedure.printing");
            }

            return getElementName(tissue.tissue.category);
        }

        watch(() => selectedProducts.value, (newValue, oldValue) => {
            if (oldValue.length > newValue.length) {
                activeProductTab.value = 0;
            }

            if (newValue.length >= oldValue.length) {
                activeProductTab.value = oldValue.length;
            }

            selectedProductsToCopyOn.value = [];
            newValue.forEach((a) => {
                if (!a.existing) {
                    let origin = products.value.find((p) => p.id === a.id);
                    if (!origin) {
                        products.value.push(JSON.parse(JSON.stringify(a)));
                        origin = products.value[products.value.length - 1];
                    }
                    a.existing = true;

                    if (a.colors.length > 0) {
                        a.colors = [ ...a.colors.map((c) => ({
                            id: c.id,
                            enable: true,
                        })), ];
                    }
                    else {
                        if (origin.colors?.length > 0) {
                            a.colors = origin.colors.map((c) => ({
                                id: c.id,
                                enable: true,
                            }));
                        }
                        else {
                            a.colors = colors.value.filter((c) => c.isPreorderDefault).map((c) => ({
                                id: c.id,
                                enable: true,
                            }));
                        }
                    }

                    if (a.sizes.length > 0) {
                        a.sizes = [ ...a.sizes.map((s) => s.id), ];
                    }
                    else {
                        if (origin.sizes?.length > 0) {
                            a.sizes = origin.sizes.map((s) => s.id);
                        }
                        else {
                            a.sizes = sizes.value.filter((s) => s.isPreorderDefault).map((s) => s.id);
                        }
                    }

                    a.sizes.sort((s1, s2) => s1 - s2);

                    a.amounts = [];
                    a.deliveryNote = "";
                    a.productionNote = "";
                    a.production = "toProduce";
                }
                selectedProductsToCopyOn.value = [ ...selectedProductsToCopyOn.value, a, ];
            });
            selectProductTab(activeProductTab.value);
        });

        function productHasColorRules (product) {
            return product.tissues.some((tissue) => tissue.tissueColorRules?.length > 0
                && tissue.tissueColorRules.some((rule) => selectedColors.value.find((c) => c.id === rule.orderColorId)));
        }

        function toggleShowRules (product) {
            product.showRules = !product.showRules;
        }

        function getShowRulesText (showRules) {
            if (!showRules) {
                return ">>";
            }

            return "<<";
        }

        function productTissuesWithColorRules (tissues) {
            return tissues.filter((t) => t.tissueColorRules?.length > 0);
        }

        function tissueApplyRuleForCurrentColor (rules, color) {
            const rule = rules?.find((rule) => rule.orderColorId === color.id);
            if (rule) {
                return colors.value.find((c) => c.id === rule.useColorId);
            }

            return false;
        }

        function getProductProceduresText (product) {
            let text = "";
            const cutting = product.procedures.find((pp) => pp.procedure.procedureType.name === "default.procedureType.cutting");
            const fasoning = product.procedures.find((pp) => pp.procedure.procedureType.name === "default.procedureType.fasoning");
            const ironing = product.procedures.find((pp) => pp.procedure.procedureType.name === "default.procedureType.ironing");
            const dyeing = product.procedures.find((pp) => pp.procedure.procedureType.name === "default.procedureType.dyeing");
            const clothePrinting = product.procedures.find((pp) => pp.procedure.procedureType.name === "default.procedureType.clothePrinting");
            if (cutting) {
                text += t("generic.need") + t(cutting.procedure.name);
            }
            else {
                text += t("generic.dontNeed") + t("default.procedureType.cutting");
            }
            if (dyeing) {
                text += t("generic.need") + t(dyeing.procedure.name);
            }
            if (clothePrinting) {
                text += t("generic.need") + t(clothePrinting.procedure.name);
            }
            if (fasoning) {
                text += `, ${ t("generic.need") }${t(fasoning.procedure.name)}`;
            }
            else {
                text += `, ${ t("generic.dontNeed") }${t("default.procedureType.fasoning")}`;
            }
            if (ironing) {
                text += `, ${ t("generic.need") }${t(ironing.procedure.name)}`;
            }
            else {
                text += `, ${ t("generic.dontNeed") }${t("default.procedureType.ironing")}`;
            }
            return text;
        }

        function getProductIndex (product, index) {
            const products = selectedProducts.value.filter((sp) => sp.id === product.id);
            return products.findIndex((p) => selectedProducts.value.findIndex((sp) => p === sp) === index);
        }

        function changeProduction (event, product) {
            const previous = product.production;
            product.production = event.target.value;
            if (product.production === "fromInventory") {
                const colors = getColorsFromAmounts(product.inventory.amounts);
                const sizes = getSizesFromAmounts(product.inventory.amounts);
                product.colors = colors.map((c) => ({
                    id: c,
                    enable: true,
                }));
                product.sizes = sizes;
            }
            else if (previous === "fromInventory") {
                const origin = products.value.find((p) => p.id === product.id);

                if (origin.colors?.length > 0) {
                    product.colors = origin.colors.map((c) => ({
                        id: c.id,
                        enable: true,
                    }));
                }
                else {
                    product.colors = colors.value.filter((c) => c.isPreorderDefault).map((c) => ({
                        id: c.id,
                        enable: true,
                    }));
                }

                if (origin.sizes?.length > 0) {
                    product.sizes = origin.sizes.map((s) => s.id);
                }
                else {
                    product.sizes = sizes.value.filter((s) => s.isPreorderDefault).map((s) => s.id);
                }

                product.sizes.sort((s1, s2) => s1 - s2);
            }
            setSelectedColors();
            setSelectedSizes();
        }

        function getDoublesDiff (stringA, stringB) {
            return doubleOutputNormalization(doubleInputNormalization(stringA) - doubleInputNormalization(stringB));
        }

        return {
            t,
            isLoading,
            activeModalTab,
            selectedProducts,
            activeProductTab,
            selectedCustomer,
            isCustomerPickerVisible,
            isCustomerAddModalVisible,
            isProductAddModalVisible,
            isProductMultiSelectVisible,
            isSizeMultiSelectVisible,
            isColorMultiSelectVisible,
            selectedColors,
            selectedSizes,
            productMultiSelectRequiredParams,
            isLightboxVisible,
            lightboxImages,
            isCopyInfoModalVisible,
            selectedProductsOptions,
            selectedCopyOption,
            selectedProductsToCopyOn,
            isCopyProductModalVisible,
            selectedCopyProductOption,
            isInputModalVisible,
            fillInput,
            customerImages,
            otherCost,
            discountAmount,
            expirationTimestamp,
            placeTimestamp,
            total,
            subtotal,
            paidAmount,
            isProductCopyModalVisible,
            isHistoricalColorMultiSelectVisible,
            historicalColorMultiSelectRequiredParams,
            isDraftModalVisible,
            isConfirmButtonLoading,
            elementToBind,
            openCustomerImagesLightbox,
            showProductCopyModal,
            closeModal,
            openCustomerAddModal,
            openProductAddModal,
            openCustomerPicker,
            selectCustomer,
            openProductMultiSelect,
            openSizeMultiSelect,
            getColor,
            getSize,
            selectProductTab,
            addSelectedSizes,
            addSelectedColors,
            openColorMultiSelect,
            submitCreate,
            getElementName,
            getTimeDifferenceText,
            inputSizeQuantity,
            getTotal,
            getSumOfTotals,
            getAmount,
            openLightbox,
            closeLightbox,
            unselectProduct,
            getProductSubtotal,
            openCopyInfoModal,
            closeCopyInfoModal,
            copyInfos,
            getInputValue,
            addModelCopy,
            openCopyProductModal,
            closeCopyProductModal,
            selectCopyOption,
            selectCopyProductOption,
            clearTable,
            removeProduct,
            deleteSize,
            deleteColor,
            openFillRowModal,
            openFillColumnModal,
            fill,
            closeInputModal,
            saveImages,
            deleteImage,
            appendCopyToProducts,
            importDefaultColors,
            importHistoricalColors,
            clearColors,
            showHistoricalColorsModal,
            getColorsFromAmounts,
            getSizesFromAmounts,
            getSizeSubtotalFromAmounts,
            getColorSubtotalFromAmounts,
            getQuantityFromAmounts,
            getTotalFromAmounts,
            getAccessoryColorRule,
            getTissueColorRule,
            getPrintPatternColorRule,
            makeDraft,
            loadDraft,
            draftBody,
            store,
            addSelectedProduct,
            orderPresentation,
            isNonUniqueSizeDisabled,
            isUniqueSizeDisabled,
            getSizeInputClass,
            openCopyProductMultiselect,
            isCopyInfoMultiselectVisible,
            isInputInvalid,
            getAmountTableTissueText,
            addNewProductToSelectedProduct,
            gotoProduct,
            productHasColorRules,
            toggleShowRules,
            getShowRulesText,
            tissueApplyRuleForCurrentColor,
            productTissuesWithColorRules,
            getProductProceduresText,
            getProductIndex,
            changeProduction,
            getDoublesDiff,
        };
    },
};
</script>

<style lang="scss" scoped>
$modal-height: 92vh;
.modal-body {
    min-height: $modal-height;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.modal-content-container {
    height: $modal-height;
}
.nav-underline {
    border-bottom: 1px solid var(--cui-nav-underline-border-color, #c4c9d0);
}
.image-bar-header {
    min-width: 50px * 4;
    width: 20vw;
    margin-left: 9px;
    overflow-y: auto;
}
.image-bar {
    min-width: 50px * 4;
    width: 20vw;
    margin-left: 9px;
    overflow-y: auto;
    min-height: $modal-height;
}
// .image-bar-gallery {

    //     // max-height: $modal-height;
    // }
.form-container {
    width: 80vw;
}
.form-box {
    height: 100%;
    min-height: 92vh;
}
.no-data-text {
    padding-left: 30vw;
}

.popover-container {
    max-height: 500px;
    overflow: auto;
    padding: 10px;
}
</style>

