<template>
  <CModal
    :visible="isGlobal ? store.state.isVisibleCreateCropPlanModal : isVisible"
    @close="close"
    fullscreen
  >
    <AppLoadingScreen :isLoading="isLoading" />
    <TagChangeModal
      :order="selectedOrder"
      v-model:is-visible="isTagPickerVisible"
      @submit="changeTags"
      :disable-production-tag="false"
      :disable-order-tag="true"
    >
    </TagChangeModal>
    <!-- Lightbox Row-->
    <BaseLightbox
      :is-lightbox-visible="isLightboxVisible"
      :selected-image-index="0"
      :images="lightboxImages"
      @hide="closeLightbox"
    ></BaseLightbox>

    <CModalHeader class="py-2 justify-content-start" :close-button="false">
      <div class="image-bar"></div>
      <div class="form-container">
        <div class="d-flex">
          <CModalTitle class="me-auto">{{ t("order.cutPlan") }}</CModalTitle>
          <CButton
            class="me-2"
            type="button"
            variant="outline"
            color="secondary"
            @click="closeModal"
            >{{ t("generic.undo") }}
          </CButton>
          <CLoadingButton
            class="me-1"
            @click="submitCreate"
            :loading="isConfirmButtonLoading"
            :disabled="isConfirmButtonLoading"
            type="button"
            color="primary"
            >{{ t("generic.confirm") }}</CLoadingButton
          >
        </div>
      </div>
    </CModalHeader>

    <CModalBody>
      <CRow class="modal-content-container flex-nowrap">
        <div class="border-end image-bar">
          <div class="mt-2">
            <CButton
              color="secondary"
              size="sm"
              @click="openProductionTagPicker"
            >
              <span class="text-white">{{
                t("production.setProductionTag")
              }}</span>
            </CButton>
          </div>
          <div class="d-flex flex-wrap gap-2 mt-3">
            <div
              class="rounded-pill d-flex align-items-center bg-danger"
              v-if="production?.urgent"
            >
              <span class="text-nowrap px-2 py-1 text-white fs-6">{{
                t("generic.urgent")
              }}</span>
            </div>
            <div
              class="rounded-pill d-flex align-items-center bg-secondary"
              v-for="tag in production?.tags?.sort((a, b) => a - b)"
              :key="tag.name"
            >
              <span class="text-nowrap px-2 py-1 text-white fs-6">{{
                tag.isDefault ? t(tag.name) : tag.name ?? "-"
              }}</span>
            </div>
          </div>
        </div>

        <CRow>
          <div class="px-0 form-container">
            <CCol>
              <!-- Model Info -->
              <CCard class="mx-3 mt-3">
                <CCardHeader>
                  <CRow class="cursor-pointer" @click="toggleModelInfoCollapse">
                    <CCol class="me-auto">
                      <span
                        >{{ t("generic.modelInfo") }} ({{
                          groupByProducts.length
                        }})</span
                      >
                    </CCol>
                    <CCol class="text-end text-info">
                      <span class="pe-1" v-if="!isModelInfoCollapseVisible">
                        {{ t("generic.expand") }}
                      </span>
                      <span class="pe-1" v-else>{{ t("generic.hide") }}</span>
                      <svg
                        class="icon cursor-pointer"
                        :class="arrows.modelInfo"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        role="img"
                        data-v-58d9d025=""
                        @click="toggleModelInfoCollapse"
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256.072,424l-11.421-11.313-85.808-85.809.053-.054L16,183.928l97.122-97.122,142.9,142.9,142.9-142.9,97.122,97.122L353.243,326.722l-11.361,11.469Zm-.107-45.254.054.053,51.835-51.835L319.2,315.511,450.783,183.928,398.915,132.06l-142.9,142.9-142.9-142.9L61.254,183.928l142.9,142.9-.053.054Z"
                          class="ci-primary"
                        ></path>
                      </svg>
                    </CCol>
                  </CRow>
                </CCardHeader>
                <CCardBody>
                  <CCollapse :visible="isModelInfoCollapseVisible">
                    <CTable bordered responsive class="w-auto fs-5">
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.template")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-3" scope="col">{{
                            t("generic.model")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-1" scope="col">{{
                            t("generic.tissueCode")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-3" scope="col">{{
                            t("generic.tissue")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.printPattern")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.tissueColorRule")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.printPatternColorRule")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.hotGlue")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("model.hemming")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("model.cropNotes")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col"
                            >{{ t("model.note") }}
                          </CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        <template
                          v-for="(productDescriptor, index) in groupByProducts"
                          :key="index"
                        >
                          <CTableRow class="align-middle text-nowrap">
                            <CTableDataCell
                              :rowspan="
                                productDescriptor.product.tissues.length
                              "
                              >{{ productDescriptor.product.productTemplateId }}
                            </CTableDataCell>
                            <CTableDataCell
                              :rowspan="
                                productDescriptor.product.tissues.length
                              "
                            >
                              <div class="d-flex align-items-center">
                                <div class="me-2">
                                  <CImage
                                    class="t-col-sm-image"
                                    v-if="
                                      productDescriptor.product.images.length >
                                      0
                                    "
                                    :src="
                                      productDescriptor.product.images[0]?.tiny
                                    "
                                    @click="
                                      openLightbox(
                                        productDescriptor.product.images
                                      )
                                    "
                                  >
                                  </CImage>
                                  <CIcon
                                    v-else
                                    icon="cilImage"
                                    size="lg"
                                  ></CIcon>
                                </div>
                                <span>{{
                                  productDescriptor.product.name
                                }}</span>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span>TS1</span>
                            </CTableDataCell>
                            <CTableDataCell>
                              <div class="d-flex">
                                <div class="me-2">
                                  <CImage
                                    class="t-col-sm-image"
                                    v-if="
                                      productDescriptor.product.tissues[0]
                                        .tissue.images.length > 0
                                    "
                                    :src="
                                      productDescriptor.product.tissues[0]
                                        .tissue.images[0]?.tiny
                                    "
                                    @click="
                                      openLightbox(
                                        productDescriptor.product.tissues[0]
                                          .tissue.images
                                      )
                                    "
                                  >
                                  </CImage>
                                  <CIcon
                                    v-else
                                    icon="cilImage"
                                    size="lg"
                                  ></CIcon>
                                </div>
                                <span
                                  >{{
                                    productDescriptor.product.tissues[0].tissue
                                      .name
                                  }}
                                  ({{
                                    t(
                                      productDescriptor.product.tissues[0]
                                        .tissue.category.name
                                    )
                                  }})</span
                                >
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>
                              <div
                                class="d-flex"
                                v-if="
                                  productDescriptor.product.tissues[0]
                                    .printPattern
                                "
                              >
                                <div class="me-2">
                                  <CImage
                                    class="t-col-sm-image"
                                    v-if="
                                      productDescriptor.product.tissues[0]
                                        .printPattern?.images.length > 0
                                    "
                                    :src="
                                      productDescriptor.product.tissues[0]
                                        .printPattern?.images[0]?.tiny
                                    "
                                    @click="
                                      openLightbox(
                                        productDescriptor.product.tissues[0]
                                          .printPattern.images
                                      )
                                    "
                                  >
                                  </CImage>
                                  <CIcon
                                    v-else
                                    icon="cilImage"
                                    size="lg"
                                  ></CIcon>
                                </div>
                                <div>
                                  <span>{{
                                    productDescriptor.product.tissues[0]
                                      .printPattern?.name
                                  }}</span>
                                </div>
                              </div>
                              <span v-else>-</span>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span>{{
                                getTissueColorRule(
                                  productDescriptor.product.tissues[0]
                                )
                              }}</span>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                v-if="
                                  productDescriptor.product.tissues[0]
                                    .printPattern
                                "
                              >
                                {{
                                  getPrintPatternColorRule(
                                    productDescriptor.product.tissues[0]
                                  )
                                }}
                              </span>
                              <span v-else>-</span>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span>
                                {{
                                  productDescriptor.product.tissues[0].hotGlue
                                    ? t("default.yes")
                                    : "-"
                                }}
                              </span>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span class="text-center">
                                {{
                                  productDescriptor.product.tissues[0]
                                    .hemming ?? "-"
                                }}
                              </span>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span class="text-wrap text-center">
                                {{
                                  productDescriptor.product.tissues[0]
                                    .cropNotes ?? "-"
                                }}
                              </span>
                            </CTableDataCell>
                            <CTableDataCell
                              class="text-wrap text-center"
                              :rowspan="
                                productDescriptor.product.tissues.length
                              "
                            >
                              <span class="text-center">{{
                                productDescriptor.note ?? "-"
                              }}</span>
                            </CTableDataCell>
                          </CTableRow>
                          <template
                            v-if="productDescriptor.product.tissues.length > 1"
                          >
                            <CTableRow
                              class="align-middle text-nowrap"
                              v-for="(
                                tissueDescriptor, tissueIndex
                              ) in productDescriptor.product.tissues.slice(
                                1,
                                productDescriptor.product.tissues.length
                              )"
                              :key="tissueIndex"
                            >
                              <CTableDataCell>
                                <span>TS{{ tissueIndex + 2 }}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div class="d-flex align-items-center">
                                  <div class="me-2">
                                    <CImage
                                      class="t-col-sm-image"
                                      v-if="
                                        tissueDescriptor.tissue.images.length >
                                        0
                                      "
                                      :src="
                                        tissueDescriptor.tissue.images[0]?.tiny
                                      "
                                      @click="
                                        openLightbox(
                                          tissueDescriptor.tissue.images
                                        )
                                      "
                                    >
                                    </CImage>
                                    <CIcon
                                      v-else
                                      icon="cilImage"
                                      size="lg"
                                    ></CIcon>
                                  </div>
                                  <span
                                    >{{ tissueDescriptor.tissue.name }} ({{
                                      t(tissueDescriptor.tissue.category.name)
                                    }})</span
                                  >
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div
                                  class="d-flex"
                                  v-if="tissueDescriptor.printPattern"
                                >
                                  <div class="me-2">
                                    <CImage
                                      class="t-col-sm-image"
                                      v-if="
                                        tissueDescriptor.printPattern?.images
                                          .length > 0
                                      "
                                      :src="
                                        tissueDescriptor.printPattern?.images[0]
                                          ?.tiny
                                      "
                                      @click="
                                        openLightbox(
                                          tissueDescriptor.printPattern.images
                                        )
                                      "
                                    >
                                    </CImage>
                                    <CIcon
                                      v-else
                                      icon="cilImage"
                                      size="lg"
                                    ></CIcon>
                                  </div>
                                  <span>{{
                                    tissueDescriptor.printPattern?.name
                                  }}</span>
                                </div>
                                <span v-else>-</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span>{{
                                  getTissueColorRule(tissueDescriptor)
                                }}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span v-if="tissueDescriptor.printPattern">{{
                                  getPrintPatternColorRule(tissueDescriptor)
                                }}</span>
                                <span v-else>-</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span>{{
                                  tissueDescriptor.hotGlue
                                    ? t("default.yes")
                                    : "-"
                                }}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span class="text-center">{{
                                  tissueDescriptor.hemming ?? "-"
                                }}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span class="text-center">{{
                                  tissueDescriptor.cropNotes ?? "-"
                                }}</span>
                              </CTableDataCell>
                            </CTableRow>
                          </template>
                        </template>
                      </CTableBody>
                    </CTable>
                  </CCollapse>

                  <CCollapse :visible="!isModelInfoCollapseVisible">
                    <div class="d-flex">
                      <div
                        v-for="(productDescriptor, index) in groupByProducts"
                        :key="index"
                        class="d-flex"
                        style="min-width: 100px"
                      >
                        <div class="me-2">
                          <CImage
                            class="t-col-photo"
                            v-if="productDescriptor.product.images.length > 0"
                            :src="productDescriptor.product.images[0]?.tiny"
                            @click="
                              openLightbox(
                                productDescriptor.product.images[0]?.tiny
                              )
                            "
                          >
                          </CImage>
                          <CIcon v-else icon="cilImage" size="3xl"></CIcon>
                        </div>
                        <ProductPopover
                          :product="productDescriptor.product"
                          :colors-array="colors"
                        >
                          <template #toggler="{ on }">
                            <div
                              id="popoverToggler"
                              class="
                                d-flex
                                flex-column
                                justify-content-center
                                w-auto
                                h-auto
                              "
                              v-on="on"
                            >
                              <span
                                id="popoverToggler"
                                class="text-info w-auto"
                                >{{
                                  productDescriptor.product.productTemplateId
                                }}</span
                              >
                              <span
                                id="popoverToggler"
                                class="fs-5 text-nowrap w-auto"
                                >{{ productDescriptor.product.name }}</span
                              >
                            </div>
                          </template>
                        </ProductPopover>
                        <div
                          class="mx-2"
                          v-if="index !== groupByProducts.length - 1"
                        >
                          <span class="fs-2">+</span>
                        </div>
                      </div>
                    </div>
                  </CCollapse>
                </CCardBody>
              </CCard>

              <!-- Tissue Info -->
              <CCard class="mx-3 mt-3">
                <CCardHeader>
                  <CRow
                    class="cursor-pointer"
                    @click="toggleTissueInfoCollapse"
                  >
                    <CCol class="me-auto">
                      <span
                        >{{ t("generic.tissueInfo") }} ({{
                          groupByTissues.length
                        }})</span
                      >
                    </CCol>
                    <CCol class="text-end text-info">
                      <span
                        v-if="!isTissueInfoCollapseVisible"
                        class="cursor-pointer pe-1"
                        >{{ t("generic.expand") }}</span
                      >
                      <span v-else class="cursor-pointer pe-1">{{
                        t("generic.hide")
                      }}</span>
                      <svg
                        class="icon cursor-pointer"
                        :class="arrows.tissueInfo"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        role="img"
                        data-v-58d9d025=""
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256.072,424l-11.421-11.313-85.808-85.809.053-.054L16,183.928l97.122-97.122,142.9,142.9,142.9-142.9,97.122,97.122L353.243,326.722l-11.361,11.469Zm-.107-45.254.054.053,51.835-51.835L319.2,315.511,450.783,183.928,398.915,132.06l-142.9,142.9-142.9-142.9L61.254,183.928l142.9,142.9-.053.054Z"
                          class="ci-primary"
                        ></path>
                      </svg>
                    </CCol>
                  </CRow>
                </CCardHeader>
                <CCardBody>
                  <CCollapse :visible="isTissueInfoCollapseVisible">
                    <CTable bordered responsive class="w-auto fs-5">
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell class="t-col-4" scope="col">{{
                            t("generic.tissue")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.printPattern")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.hotGlue")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.supplier")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.width")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-2" scope="col">{{
                            t("generic.shrinkage")
                          }}</CTableHeaderCell>
                          <CTableHeaderCell class="t-col-4" scope="col">{{
                            t("ingredient.ingredientAndNote")
                          }}</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        <template
                          v-for="(tissueDescriptor, index) in groupByTissues"
                          :key="index"
                        >
                          <CTableRow class="align-middle text-nowrap">
                            <CTableDataCell
                              :rowspan="tissueDescriptor.printPatterns.length"
                            >
                              <div class="d-flex">
                                <div class="me-3 t-col-sm-image">
                                  <CImage
                                    class="t-col-sm-image"
                                    v-if="
                                      tissueDescriptor.tissue.images.length > 0
                                    "
                                    :src="
                                      tissueDescriptor.tissue.images[0]
                                        ?.compress
                                    "
                                    @click="
                                      openLightbox(
                                        tissueDescriptor.tissue.images
                                      )
                                    "
                                  >
                                  </CImage>
                                  <CIcon
                                    v-else
                                    icon="cilImage"
                                    size="lg"
                                  ></CIcon>
                                </div>
                                <span
                                  >{{ tissueDescriptor.tissue.name }} ({{
                                    t(tissueDescriptor.tissue.category.name)
                                  }})</span
                                >
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>
                              <div
                                class="d-flex"
                                v-if="
                                  tissueDescriptor.printPatterns[0]
                                    ?.printPattern
                                "
                              >
                                <div class="me-3 t-col-sm-image">
                                  <CImage
                                    class="t-col-sm-image"
                                    v-if="
                                      tissueDescriptor.printPatterns[0]
                                        .printPattern?.images.length > 0
                                    "
                                    :src="
                                      tissueDescriptor.printPatterns[0]
                                        .printPattern.images[0]?.tiny
                                    "
                                    @click="
                                      openLightbox(
                                        tissueDescriptor.printPatterns[0]
                                          .printPattern.images
                                      )
                                    "
                                  >
                                  </CImage>
                                  <CIcon
                                    v-else
                                    icon="cilImage"
                                    size="lg"
                                  ></CIcon>
                                </div>
                                <span>{{
                                  tissueDescriptor.printPatterns[0]
                                    ?.printPattern?.name ?? "-"
                                }}</span>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>{{
                              tissueDescriptor.printPatterns[0].descriptor
                                .hotGlue
                                ? t("default.yes")
                                : "-"
                            }}</CTableDataCell>
                            <CTableDataCell>{{
                              getElementName(
                                tissueDescriptor.printPatterns[0].descriptor
                                  .tissue.supplier
                              )
                            }}</CTableDataCell>
                            <CTableDataCell
                              >{{ tissueDescriptor.tissue.width ?? "-" }}
                            </CTableDataCell>
                            <CTableDataCell>{{
                              tissueDescriptor.printPatterns[0].shrinkage ?? "-"
                            }}</CTableDataCell>
                            <CTableDataCell
                              >{{ tissueDescriptor.tissue.ingredientAndNote }}
                            </CTableDataCell>
                          </CTableRow>
                          <template
                            v-for="(
                              printPatternDescriptor, pIndex
                            ) in tissueDescriptor.printPatterns.slice(
                              1,
                              tissueDescriptor.printPatterns.length
                            )"
                            :key="pIndex"
                          >
                            <CTableRow class="align-middle text-nowrap">
                              <CTableDataCell>
                                <div class="d-flex">
                                  <div class="me-3">
                                    <CImage
                                      class="t-col-sm-image"
                                      v-if="
                                        printPatternDescriptor.printPattern
                                          .images.length > 0
                                      "
                                      :src="
                                        printPatternDescriptor.printPattern
                                          .images[0]?.tiny
                                      "
                                      @click="
                                        openLightbox(
                                          printPatternDescriptor.printPattern
                                            .images
                                        )
                                      "
                                    >
                                    </CImage>
                                    <CIcon
                                      v-else
                                      icon="cilImage"
                                      size="lg"
                                    ></CIcon>
                                  </div>
                                  <span>{{
                                    printPatternDescriptor.printPattern?.name ??
                                    "-"
                                  }}</span>
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>{{
                                printPatternDescriptor.descriptor.hotGlue
                                  ? t("default.yes")
                                  : "-"
                              }}</CTableDataCell>
                              <CTableDataCell
                                >{{
                                  getElementName(
                                    tissueDescriptor.tissue.supplier
                                  )
                                }}
                              </CTableDataCell>
                              <CTableDataCell
                                >{{ tissueDescriptor.tissue.width ?? "-" }}
                              </CTableDataCell>
                              <CTableDataCell>{{
                                tissueDescriptor.tissue.shrinkage ?? "-"
                              }}</CTableDataCell>
                              <CTableDataCell>{{
                                tissueDescriptor.tissue.ingredientAndNote
                              }}</CTableDataCell>
                            </CTableRow>
                          </template>
                        </template>
                      </CTableBody>
                    </CTable>
                  </CCollapse>
                </CCardBody>
              </CCard>

              <!-- Production Info -->
              <CCard class="mx-3 mt-3">
                <CCardHeader>
                  <div
                    class="cursor-pointer d-flex"
                    @click="toggleProductionInfoCollapse"
                  >
                    <div class="me-auto">
                      <span>{{ t("generic.productionInfo") }}</span>
                    </div>
                    <div class="text-end text-info me-1">
                      <span
                        v-if="!isProductionInfoCollapseVisible"
                        class="pe-1"
                        >{{ t("generic.expand") }}</span
                      >
                      <span v-else class="cursor-pointer pe-1">{{
                        t("generic.hide")
                      }}</span>
                      <svg
                        class="icon cursor-pointer"
                        :class="arrows.productionInfo"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        role="img"
                        data-v-58d9d025=""
                        @click="toggleProductionInfoCollapse"
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256.072,424l-11.421-11.313-85.808-85.809.053-.054L16,183.928l97.122-97.122,142.9,142.9,142.9-142.9,97.122,97.122L353.243,326.722l-11.361,11.469Zm-.107-45.254.054.053,51.835-51.835L319.2,315.511,450.783,183.928,398.915,132.06l-142.9,142.9-142.9-142.9L61.254,183.928l142.9,142.9-.053.054Z"
                          class="ci-primary"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </CCardHeader>
                <CCardBody>
                  <CCollapse :visible="isProductionInfoCollapseVisible">
                    <CNav variant="underline" role="tablist">
                      <CNavItem>
                        <CNavLink
                          href="javascript:void(0);"
                          :active="activeProductionTab === 0"
                          @click="activeProductionTab = 0"
                        >
                          {{ t("order.preorderVolume") }}
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          href="javascript:void(0);"
                          :active="activeProductionTab === 1"
                          @click="activeProductionTab = 1"
                        >
                          {{ t("generic.unionProductionAmount") }}
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          href="javascript:void(0);"
                          :active="activeProductionTab === 2"
                          @click="activeProductionTab = 2"
                        >
                          {{ t("generic.detailedProductionAmount") }}
                        </CNavLink>
                      </CNavItem>
                    </CNav>

                    <CTabContent>
                      <CTabPane
                        role="tabpanel"
                        aria-labelledby="preorders-tab"
                        :visible="activeProductionTab === 0"
                      >
                        <CTable class="mt-3 w-auto fs-5" responsive bordered>
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell class="t-col-2" scope="col">{{
                                t("generic.template")
                              }}</CTableHeaderCell>
                              <CTableHeaderCell class="t-col-2" scope="col">{{
                                t("generic.model")
                              }}</CTableHeaderCell>
                              <CTableHeaderCell class="t-col-2" scope="col">{{
                                t("generic.colors")
                              }}</CTableHeaderCell>
                              <CTableHeaderCell
                                class="t-col-2"
                                scope="col"
                                v-for="(size, index) in allProductsSizes"
                                :key="index"
                              >
                                {{ getElementName(size) }}
                              </CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>

                          <CTableBody>
                            <template
                              v-for="templateDescriptor in groupByTemplates"
                              :key="templateDescriptor.productTemplateId"
                            >
                              <CTableRow class="text-nowrap" align="middle">
                                <CTableDataCell
                                  :rowspan="
                                    getTotalAmountsColors(
                                      templateDescriptor.productTemplateId
                                    ).length
                                  "
                                >
                                  {{ templateDescriptor.productTemplateId }}
                                </CTableDataCell>
                                <CTableDataCell
                                  :rowspan="
                                    getAmountsColors(
                                      templateDescriptor.products[0].amounts
                                    ).length
                                  "
                                >
                                  <span>{{
                                    templateDescriptor.products[0].product.name
                                  }}</span>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>{{
                                    getElementName(
                                      getAmountsColors(
                                        templateDescriptor.products[0].amounts
                                      )[0]
                                    )
                                  }}</span>
                                </CTableDataCell>
                                <CTableDataCell
                                  v-for="size in allProductsSizes"
                                  :key="size.id"
                                >
                                  <span>{{
                                    getAmount(
                                      templateDescriptor.products[0].amounts,
                                      getAmountsColors(
                                        templateDescriptor.products[0].amounts
                                      )[0].id,
                                      size.id
                                    )
                                  }}</span>
                                </CTableDataCell>
                              </CTableRow>
                              <template
                                v-if="
                                  getAmountsColors(
                                    templateDescriptor.products[0].amounts
                                  ).length > 1
                                "
                              >
                                <CTableRow
                                  v-for="color in getAmountsColors(
                                    templateDescriptor.products[0].amounts
                                  ).slice(
                                    1,
                                    getAmountsColors(
                                      templateDescriptor.products[0].amounts
                                    ).length
                                  )"
                                  :key="color.id"
                                  class="text-nowrap"
                                >
                                  <CTableDataCell>
                                    <span>{{ getElementName(color) }}</span>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    v-for="size in allProductsSizes"
                                    :key="size.id"
                                  >
                                    <span>{{
                                      getAmount(
                                        templateDescriptor.products[0].amounts,
                                        color.id,
                                        size.id
                                      )
                                    }}</span>
                                  </CTableDataCell>
                                </CTableRow>
                              </template>
                              <template
                                v-for="productDescriptor in templateDescriptor.products.slice(
                                  1,
                                  templateDescriptor.products.length
                                )"
                                :key="productDescriptor.product.id"
                              >
                                <CTableRow class="text-nowrap" align="middle">
                                  <CTableDataCell
                                    :rowspan="
                                      getAmountsColors(
                                        productDescriptor.amounts
                                      ).length
                                    "
                                  >
                                    <span>{{
                                      productDescriptor.product.name
                                    }}</span>
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    <span>{{
                                      getElementName(
                                        getAmountsColors(
                                          productDescriptor.amounts
                                        )[0]
                                      )
                                    }}</span>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    v-for="size in allProductsSizes"
                                    :key="size.id"
                                  >
                                    <span>{{
                                      getAmount(
                                        productDescriptor.amounts,
                                        getAmountsColors(
                                          productDescriptor.amounts
                                        )[0].id,
                                        size.id
                                      )
                                    }}</span>
                                  </CTableDataCell>
                                </CTableRow>
                                <template
                                  v-if="
                                    getAmountsColors(productDescriptor.amounts)
                                      .length > 1
                                  "
                                >
                                  <CTableRow
                                    v-for="color in getAmountsColors(
                                      productDescriptor.amounts
                                    ).slice(
                                      1,
                                      getAmountsColors(
                                        productDescriptor.amounts
                                      ).length
                                    )"
                                    :key="color.id"
                                    class="text-nowrap"
                                  >
                                    <CTableDataCell>
                                      <span>{{ getElementName(color) }}</span>
                                    </CTableDataCell>
                                    <CTableDataCell
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <span>{{
                                        getAmount(
                                          productDescriptor.amounts,
                                          color.id,
                                          size.id
                                        )
                                      }}</span>
                                    </CTableDataCell>
                                  </CTableRow>
                                </template>
                              </template>
                            </template>
                            <CTableRow>
                              <CTableDataCell :colspan="3">
                                <span>{{ t("generic.total") }}:</span>
                              </CTableDataCell>
                              <CTableDataCell
                                v-for="size in allProductsSizes"
                                :key="size.id"
                              >
                                <span>{{ getTotalBySize(size.id) }}</span>
                              </CTableDataCell>
                            </CTableRow>
                          </CTableBody>
                        </CTable>
                      </CTabPane>

                      <CTabPane
                        role="tabpanel"
                        aria-labelledby="volume-tab"
                        :visible="activeProductionTab === 1"
                      >
                        <CTable class="mt-4 fs-5 w-auto" responsive bordered>
                          <CTableHead>
                            <CTableRow align="middle">
                              <CTableHeaderCell
                                rowspan="2"
                                class="t-col-2"
                                scope="col"
                                >{{ t("generic.template") }}</CTableHeaderCell
                              >
                              <CTableHeaderCell
                                rowspan="2"
                                class="t-col-4"
                                scope="col"
                                >{{
                                  t("generic.tissueCombination")
                                }}</CTableHeaderCell
                              >
                              <CTableHeaderCell
                                rowspan="2"
                                class="t-col-3"
                                scope="col"
                              >
                                {{ t("generic.printPatternsAndColors") }}
                              </CTableHeaderCell>
                              <template v-if="expandUnionProductionAmount">
                                <CTableHeaderCell
                                  rowspan="2"
                                  class="t-col-2 p-0"
                                  scope="col"
                                >
                                  <div
                                    class="
                                      position-relative
                                      d-flex
                                      w-100
                                      h-100
                                      p-3
                                      justify-content-end
                                    "
                                  >
                                    <div
                                      v-if="expandUnionProductionAmount"
                                      class="
                                        cursor-pointer
                                        d-flex
                                        align-items-center
                                        position-absolute
                                        top-0
                                        start-0
                                        h-100
                                        border-end border-end-1 border-light
                                      "
                                      style="padding-bottom: 5px"
                                      @click="
                                        expandUnionProductionAmount = false
                                      "
                                    >
                                      >>
                                    </div>
                                    <span class="p-2 text-center">{{
                                      t("generic.modelName")
                                    }}</span>
                                  </div>
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="2"
                                  class="t-col-2"
                                  scope="col"
                                >
                                  {{ t("generic.preorderColors") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  class="t-col-3 text-end"
                                  scope="col"
                                  :colspan="allProductsSizes.length"
                                >
                                  <span class="p-2 text-end">{{
                                    t("generic.preorderAmounts")
                                  }}</span>
                                </CTableHeaderCell>
                              </template>
                              <CTableHeaderCell
                                class="t-col-3 p-0 text-end"
                                scope="col"
                                :colspan="allProductsSizes.length"
                              >
                                <div
                                  class="
                                    position-relative
                                    d-flex
                                    w-100
                                    justify-content-end
                                  "
                                >
                                  <div
                                    v-if="!expandUnionProductionAmount"
                                    class="
                                      cursor-pointer
                                      d-flex
                                      align-items-center
                                      position-absolute
                                      top-0
                                      start-0
                                      h-100
                                      border-end border-end-1 border-light
                                    "
                                    style="padding-bottom: 5px"
                                    @click="expandUnionProductionAmount = true"
                                  >
                                    ...
                                  </div>
                                  <span class="p-2 text-end">{{
                                    t("generic.finishedPiecesNumber")
                                  }}</span>
                                </div>
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                class="t-col-2 border"
                                scope="col"
                                >{{
                                  t("generic.wastedAmount")
                                }}</CTableHeaderCell
                              >
                            </CTableRow>
                            <CTableRow>
                              <template v-if="expandUnionProductionAmount">
                                <CTableHeaderCell
                                  class="text-end"
                                  v-for="size in allProductsSizes"
                                  :key="size.id"
                                >
                                  {{ getElementName(size) }}
                                </CTableHeaderCell>
                              </template>
                              <CTableHeaderCell
                                class="text-end"
                                v-for="size in allProductsSizes"
                                :key="size.id"
                              >
                                {{ getElementName(size) }}
                              </CTableHeaderCell>
                              <CTableHeaderCell class="text-end text-danger">
                                {{ getProductWastedAmount() }}
                              </CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>

                          <CTableBody>
                            <template
                              v-for="templateDescriptor in groupByTemplatesAndTissues"
                              :key="templateDescriptor.productTemplateId"
                            >
                              <CTableRow class="text-nowrap" align="middle">
                                <CTableDataCell
                                  :rowspan="
                                    getTotalTemplateCombinationColors(
                                      templateDescriptor.productTemplateId
                                    ).length
                                  "
                                >
                                  {{ templateDescriptor.productTemplateId }}
                                </CTableDataCell>
                                <CTableDataCell
                                  :rowspan="
                                    getTotalCombinationColors(
                                      templateDescriptor.combinations[0]
                                    ).length
                                  "
                                >
                                  <div class="d-flex flex-column">
                                    <span
                                      v-for="combination in templateDescriptor
                                        .combinations[0].combination"
                                      :key="combination"
                                    >
                                      {{ combination }}
                                    </span>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>
                                    {{
                                      getTissueCombinationColor(
                                        templateDescriptor.combinations[0]
                                          .descriptor[0].product.tissues,
                                          templateDescriptor.combinations[0]
                                            .descriptor[0].colors[0]
                                      )
                                    }}
                                  </span>
                                </CTableDataCell>
                                <template v-if="expandUnionProductionAmount">
                                  <CTableDataCell>
                                    <div class="d-flex">
                                      <div class="me-2">
                                        <CImage
                                          class="t-col-sm-image"
                                          v-if="
                                            templateDescriptor.combinations[0]
                                              .descriptor[0].product.images
                                              .length > 0
                                          "
                                          :src="
                                            templateDescriptor.combinations[0]
                                              .descriptor[0].product.images[0]
                                              ?.tiny
                                          "
                                          @click="
                                            openLightbox(
                                              templateDescriptor.combinations[0]
                                                .descriptor[0].product.images
                                            )
                                          "
                                        >
                                        </CImage>
                                        <CIcon v-else icon="cilImage" size="lg">
                                        </CIcon>
                                      </div>
                                      <span>{{
                                        templateDescriptor.combinations[0]
                                          .descriptor[0].product.name
                                      }}</span>
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    {{
                                      getElementName(
                                        templateDescriptor.combinations[0]
                                          .descriptor[0].colors[0]
                                      )
                                    }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    class="text-end"
                                    v-for="size in allProductsSizes"
                                    :key="size.id"
                                  >
                                    <span>
                                      {{
                                        getAmount(
                                          templateDescriptor.combinations[0]
                                            .descriptor[0].amounts,
                                          templateDescriptor.combinations[0]
                                            .descriptor[0].colors[0].id,
                                          size.id
                                        )
                                      }}
                                    </span>
                                  </CTableDataCell>
                                </template>
                                <CTableDataCell
                                  class="text-end position-relative"
                                  v-for="size in allProductsSizes"
                                  :key="size.id"
                                >
                                  <div
                                    v-if="
                                      !production.createCropPlanTimestamp ||
                                      production.completeCropTimestamp
                                    "
                                    class="d-flex w-100"
                                  >
                                    <span
                                      class="me-auto"
                                      :class="
                                        textColor(
                                          getProductDeviation(
                                            templateDescriptor.combinations[0]
                                              .descriptor[0],
                                            templateDescriptor.combinations[0]
                                              .descriptor[0].colors[0].id,
                                            size.id
                                          )
                                        )
                                      "
                                      >{{
                                        getProductDeviation(
                                          templateDescriptor.combinations[0]
                                            .descriptor[0],
                                          templateDescriptor.combinations[0]
                                            .descriptor[0].colors[0].id,
                                          size.id
                                        )
                                      }}</span
                                    >
                                    <span class="ms-2 text-end">{{
                                      getProductProducedAmount(
                                        templateDescriptor.combinations[0]
                                          .descriptor[0],
                                        templateDescriptor.combinations[0]
                                          .descriptor[0].colors[0].id,
                                        size.id
                                      )
                                    }}</span>
                                  </div>
                                  <div
                                    class="
                                      text-end
                                      position-absolute
                                      top-0
                                      bottom-0
                                      start-0
                                      end-0
                                      fs-4
                                    "
                                    v-else-if="groupByProducts"
                                  >
                                    <input
                                      class="border-0 p-1 w-100 h-100 text-end"
                                      v-model="
                                        groupByProducts
                                          .find(
                                            (p) =>
                                              p.product.id ===
                                              templateDescriptor.combinations[0]
                                                .descriptor[0].product.id
                                          )
                                          .amounts.find(
                                            (a) =>
                                              a.colorId ===
                                                templateDescriptor
                                                  .combinations[0].descriptor[0]
                                                  .colors[0].id &&
                                              a.sizeId === size.id
                                          ).croppedAmount
                                      "
                                    />
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                              </CTableRow>
                              <template
                                v-for="color in templateDescriptor.combinations[0].descriptor[0].colors.slice(
                                  1,
                                  templateDescriptor.combinations[0]
                                    .descriptor[0].colors.length
                                )"
                                :key="color.id"
                              >
                                <CTableRow class="text-nowrap">
                                  <CTableDataCell>
                                    {{
                                      getTissueCombinationColor(
                                        templateDescriptor.combinations[0]
                                          .descriptor[0].product.tissues,
                                        color
                                      )
                                    }}
                                  </CTableDataCell>
                                  <template v-if="expandUnionProductionAmount">
                                    <CTableDataCell>
                                      <div class="d-flex">
                                        <div class="me-2">
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              templateDescriptor.combinations[0]
                                                .descriptor[0].product.images
                                                .length > 0
                                            "
                                            :src="
                                              templateDescriptor.combinations[0]
                                                .descriptor[0].product.images[0]
                                                ?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                templateDescriptor
                                                  .combinations[0].descriptor[0]
                                                  .product.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>{{
                                          templateDescriptor.combinations[0]
                                            .descriptor[0].product.name
                                        }}</span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {{ getElementName(color) }}
                                    </CTableDataCell>
                                    <CTableDataCell
                                      class="text-end"
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <span>
                                        {{
                                          getAmount(
                                            templateDescriptor.combinations[0]
                                              .descriptor[0].amounts,
                                            color.id,
                                            size.id
                                          )
                                        }}
                                      </span>
                                    </CTableDataCell>
                                  </template>
                                  <CTableDataCell
                                    class="text-end position-relative"
                                    v-for="size in allProductsSizes"
                                    :key="size.id"
                                  >
                                    <div
                                      class="d-flex w-100"
                                      v-if="
                                        !production.createCropPlanTimestamp ||
                                        production.completeCropTimestamp
                                      "
                                    >
                                      <span
                                        class="me-auto"
                                        :class="
                                          textColor(
                                            getProductDeviation(
                                              templateDescriptor.combinations[0]
                                                .descriptor[0],
                                              color.id,
                                              size.id
                                            )
                                          )
                                        "
                                        >{{
                                          getProductDeviation(
                                            templateDescriptor.combinations[0]
                                              .descriptor[0],
                                            color.id,
                                            size.id
                                          )
                                        }}</span
                                      >
                                      <span class="ms-2 text-end">{{
                                        getProductProducedAmount(
                                          templateDescriptor.combinations[0]
                                            .descriptor[0],
                                          color.id,
                                          size.id
                                        )
                                      }}</span>
                                    </div>
                                    <div
                                      class="
                                        text-end
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                      v-else-if="groupByProducts"
                                    >
                                      <input
                                        class="
                                          border-0
                                          p-1
                                          w-100
                                          h-100
                                          text-end
                                        "
                                        v-model="
                                          groupByProducts
                                            .find(
                                              (p) =>
                                                p.product.id ===
                                                templateDescriptor
                                                  .combinations[0].descriptor[0]
                                                  .product.id
                                            )
                                            .amounts.find(
                                              (a) =>
                                                a.colorId === color.id &&
                                                a.sizeId === size.id
                                            ).croppedAmount
                                        "
                                      />
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell></CTableDataCell>
                                </CTableRow>
                              </template>
                              <template
                                v-for="tissueDescriptor in templateDescriptor.combinations[0].descriptor.slice(
                                  1,
                                  templateDescriptor.combinations[0].descriptor
                                    .length
                                )"
                                :key="tissueDescriptor.id"
                              >
                                <CTableRow class="text-nowrap">
                                  <CTableDataCell>
                                    <span>
                                      {{
                                        getTissueCombinationColor(
                                          tissueDescriptor.product.tissues,
                                            tissueDescriptor.colors[0]
                                        )
                                      }}
                                    </span>
                                  </CTableDataCell>
                                  <template v-if="expandUnionProductionAmount">
                                    <CTableDataCell>
                                      <div class="d-flex">
                                        <div class="me-2">
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              tissueDescriptor.product.images
                                                .length > 0
                                            "
                                            :src="
                                              tissueDescriptor.product.images[0]
                                                ?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                tissueDescriptor.product.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>{{
                                          tissueDescriptor.product.name
                                        }}</span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {{
                                        getElementName(
                                          tissueDescriptor.colors[0]
                                        )
                                      }}
                                    </CTableDataCell>
                                    <CTableDataCell
                                      class="text-end"
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <span>
                                        {{
                                          getAmount(
                                            tissueDescriptor.amounts,
                                            tissueDescriptor.colors[0].id,
                                            size.id
                                          )
                                        }}
                                      </span>
                                    </CTableDataCell>
                                  </template>
                                  <CTableDataCell
                                    class="text-end position-relative"
                                    v-for="size in allProductsSizes"
                                    :key="size.id"
                                  >
                                    <div
                                      class="d-flex w-100"
                                      v-if="
                                        !production.createCropPlanTimestamp ||
                                        production.completeCropTimestamp
                                      "
                                    >
                                      <span
                                        class="me-auto"
                                        :class="
                                          textColor(
                                            getProductDeviation(
                                              tissueDescriptor,
                                              tissueDescriptor.colors[0].id,
                                              size.id
                                            )
                                          )
                                        "
                                        >{{
                                          getProductDeviation(
                                            tissueDescriptor,
                                            tissueDescriptor.colors[0].id,
                                            size.id
                                          )
                                        }}</span
                                      >
                                      <span class="ms-2 text-end">{{
                                        getProductProducedAmount(
                                          tissueDescriptor,
                                          tissueDescriptor.colors[0].id,
                                          size.id
                                        )
                                      }}</span>
                                    </div>
                                    <div
                                      class="
                                        text-end
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                      v-else-if="groupByProducts"
                                    >
                                      <input
                                        class="
                                          border-0
                                          p-1
                                          w-100
                                          h-100
                                          text-end
                                        "
                                        v-model="
                                          groupByProducts
                                            .find(
                                              (p) =>
                                                p.product.id ===
                                                tissueDescriptor.product.id
                                            )
                                            .amounts.find(
                                              (a) =>
                                                a.colorId ===
                                                  tissueDescriptor.colors[0]
                                                    .id && a.sizeId === size.id
                                            ).croppedAmount
                                        "
                                      />
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell></CTableDataCell>
                                </CTableRow>
                                <template
                                  v-for="color in tissueDescriptor.colors.slice(
                                    1,
                                    tissueDescriptor.colors.length
                                  )"
                                  :key="color.id"
                                >
                                  <CTableRow class="text-nowrap">
                                    <CTableDataCell>
                                      {{
                                        getTissueCombinationColor(
                                          tissueDescriptor.product.tissues,color
                                        )
                                      }}
                                    </CTableDataCell>
                                    <template
                                      v-if="expandUnionProductionAmount"
                                    >
                                      <CTableDataCell>
                                        <div class="d-flex">
                                          <div class="me-2">
                                            <CImage
                                              class="t-col-sm-image"
                                              v-if="
                                                tissueDescriptor.product.images
                                                  .length > 0
                                              "
                                              :src="
                                                tissueDescriptor.product
                                                  .images[0]?.tiny
                                              "
                                              @click="
                                                openLightbox(
                                                  tissueDescriptor.product
                                                    .images
                                                )
                                              "
                                            >
                                            </CImage>
                                            <CIcon
                                              v-else
                                              icon="cilImage"
                                              size="lg"
                                            ></CIcon>
                                          </div>
                                          <span>{{
                                            tissueDescriptor.product.name
                                          }}</span>
                                        </div>
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {{ getElementName(color) }}
                                      </CTableDataCell>
                                      <CTableDataCell
                                        class="text-end"
                                        v-for="size in allProductsSizes"
                                        :key="size.id"
                                      >
                                        <span>
                                          {{
                                            getAmount(
                                              tissueDescriptor.amounts,
                                              color.id,
                                              size.id
                                            )
                                          }}
                                        </span>
                                      </CTableDataCell>
                                    </template>
                                    <CTableDataCell
                                      class="text-end position-relative"
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <div
                                        class="d-flex w-100"
                                        v-if="
                                          !production.createCropPlanTimestamp ||
                                          production.completeCropTimestamp
                                        "
                                      >
                                        <span
                                          class="me-auto"
                                          :class="
                                            textColor(
                                              getProductDeviation(
                                                tissueDescriptor,
                                                color.id,
                                                size.id
                                              )
                                            )
                                          "
                                          >{{
                                            getProductDeviation(
                                              tissueDescriptor,
                                              color.id,
                                              size.id
                                            )
                                          }}</span
                                        >
                                        <span class="ms-2 text-end">{{
                                          getProductProducedAmount(
                                            tissueDescriptor,
                                            color.id,
                                            size.id
                                          )
                                        }}</span>
                                      </div>
                                      <div
                                        class="
                                          text-end
                                          position-absolute
                                          top-0
                                          bottom-0
                                          start-0
                                          end-0
                                          fs-4
                                        "
                                        v-else-if="groupByProducts"
                                      >
                                        <input
                                          class="
                                            border-0
                                            p-1
                                            w-100
                                            h-100
                                            text-end
                                          "
                                          v-model="
                                            groupByProducts
                                              .find(
                                                (p) =>
                                                  p.product.id ===
                                                  tissueDescriptor.product.id
                                              )
                                              .amounts.find(
                                                (a) =>
                                                  a.colorId === color.id &&
                                                  a.sizeId === size.id
                                              ).croppedAmount
                                          "
                                        />
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell></CTableDataCell>
                                  </CTableRow>
                                </template>
                              </template>
                              <template
                                v-for="(
                                  combinationDescriptor, cIndex
                                ) in templateDescriptor.combinations.slice(
                                  1,
                                  templateDescriptor.combinations.length
                                )"
                                :key="cIndex"
                              >
                                <CTableRow class="text-nowrap" align="middle">
                                  <CTableDataCell
                                    :rowspan="
                                      getTotalCombinationColors(
                                        combinationDescriptor
                                      ).length
                                    "
                                  >
                                    <div class="d-flex flex-column">
                                      <span
                                        v-for="combination in combinationDescriptor.combination"
                                        :key="combination"
                                      >
                                        {{ combination }}
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    <span>
                                      {{
                                        getTissueCombinationColor(
                                          combinationDescriptor.descriptor[0]
                                            .product.tissues,
                                            combinationDescriptor.descriptor[0]
                                              .colors[0]
                                        )
                                      }}
                                    </span>
                                  </CTableDataCell>
                                  <template v-if="expandUnionProductionAmount">
                                    <CTableDataCell>
                                      <div class="d-flex">
                                        <div class="me-2">
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              combinationDescriptor
                                                .descriptor[0].product.images
                                                .length > 0
                                            "
                                            :src="
                                              combinationDescriptor
                                                .descriptor[0].product.images[0]
                                                ?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                combinationDescriptor
                                                  .descriptor[0].product.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>{{
                                          combinationDescriptor.descriptor[0]
                                            .product.tissues[0].tissue.name
                                        }}</span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {{
                                        getElementName(
                                          combinationDescriptor.descriptor[0]
                                            .colors[0]
                                        )
                                      }}
                                    </CTableDataCell>
                                    <CTableDataCell
                                      class="text-end"
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <span>
                                        {{
                                          getAmount(
                                            combinationDescriptor.descriptor[0]
                                              .amounts,
                                            combinationDescriptor.descriptor[0]
                                              .colors[0].id,
                                            size.id
                                          )
                                        }}
                                      </span>
                                    </CTableDataCell>
                                  </template>
                                  <CTableDataCell
                                    class="text-end position-relative"
                                    v-for="size in allProductsSizes"
                                    :key="size.id"
                                  >
                                    <div
                                      class="d-flex w-100"
                                      v-if="
                                        !production.createCropPlanTimestamp ||
                                        production.completeCropTimestamp
                                      "
                                    >
                                      <span
                                        class="me-auto"
                                        :class="
                                          textColor(
                                            getProductDeviation(
                                              combinationDescriptor
                                                .descriptor[0],
                                              combinationDescriptor
                                                .descriptor[0].colors[0].id,
                                              size.id
                                            )
                                          )
                                        "
                                        >{{
                                          getProductDeviation(
                                            combinationDescriptor.descriptor[0],
                                            combinationDescriptor.descriptor[0]
                                              .colors[0].id,
                                            size.id
                                          )
                                        }}</span
                                      >
                                      <span class="ms-2 text-end">{{
                                        getProductProducedAmount(
                                          combinationDescriptor.descriptor[0],
                                          combinationDescriptor.descriptor[0]
                                            .colors[0].id,
                                          size.id
                                        )
                                      }}</span>
                                    </div>
                                    <div
                                      class="
                                        text-end
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                      v-else-if="groupByProducts"
                                    >
                                      <input
                                        class="
                                          border-0
                                          p-1
                                          w-100
                                          h-100
                                          text-end
                                        "
                                        v-model="
                                          groupByProducts
                                            .find(
                                              (p) =>
                                                p.product.id ===
                                                combinationDescriptor
                                                  .descriptor[0].product.id
                                            )
                                            .amounts.find(
                                              (a) =>
                                                a.colorId ===
                                                  combinationDescriptor
                                                    .descriptor[0].colors[0]
                                                    .id && a.sizeId === size.id
                                            ).croppedAmount
                                        "
                                      />
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell></CTableDataCell>
                                </CTableRow>
                                <template
                                  v-for="color in combinationDescriptor.descriptor[0].colors.slice(
                                    1,
                                    combinationDescriptor.descriptor[0].colors
                                      .length
                                  )"
                                  :key="color.id"
                                >
                                  <CTableRow class="text-nowrap">
                                    <CTableDataCell>
                                      {{
                                        getTissueCombinationColor(
                                          combinationDescriptor.descriptor[0]
                                            .product.tissues,
                                          color
                                        )
                                      }}
                                    </CTableDataCell>
                                    <template
                                      v-if="expandUnionProductionAmount"
                                    >
                                      <CTableDataCell>
                                        <div class="d-flex">
                                          <div class="me-2">
                                            <CImage
                                              class="t-col-sm-image"
                                              v-if="
                                                combinationDescriptor
                                                  .descriptor[0].product.images
                                                  .length > 0
                                              "
                                              :src="
                                                combinationDescriptor
                                                  .descriptor[0].product
                                                  .images[0]?.tiny
                                              "
                                              @click="
                                                openLightbox(
                                                  combinationDescriptor
                                                    .descriptor[0].product
                                                    .images
                                                )
                                              "
                                            >
                                            </CImage>
                                            <CIcon
                                              v-else
                                              icon="cilImage"
                                              size="lg"
                                            ></CIcon>
                                          </div>
                                          <span>{{
                                            combinationDescriptor.descriptor[0]
                                              .product.name
                                          }}</span>
                                        </div>
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {{ getElementName(color) }}
                                      </CTableDataCell>
                                      <CTableDataCell
                                        class="text-end"
                                        v-for="size in allProductsSizes"
                                        :key="size.id"
                                      >
                                        <span>
                                          {{
                                            getAmount(
                                              combinationDescriptor
                                                .descriptor[0].amounts,
                                              color.id,
                                              size.id
                                            )
                                          }}
                                        </span>
                                      </CTableDataCell>
                                    </template>
                                    <CTableDataCell
                                      class="text-end position-relative"
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <div
                                        class="d-flex w-100"
                                        v-if="
                                          !production.createCropPlanTimestamp ||
                                          production.completeCropTimestamp
                                        "
                                      >
                                        <span
                                          class="me-auto"
                                          :class="
                                            textColor(
                                              getProductDeviation(
                                                combinationDescriptor
                                                  .descriptor[0],
                                                color.id,
                                                size.id
                                              )
                                            )
                                          "
                                          >{{
                                            getProductDeviation(
                                              combinationDescriptor
                                                .descriptor[0],
                                              color.id,
                                              size.id
                                            )
                                          }}</span
                                        >
                                        <span class="ms-2 text-end">{{
                                          getProductProducedAmount(
                                            combinationDescriptor.descriptor[0],
                                            color.id,
                                            size.id
                                          )
                                        }}</span>
                                      </div>
                                      <div
                                        class="
                                          text-end
                                          position-absolute
                                          top-0
                                          bottom-0
                                          start-0
                                          end-0
                                          fs-4
                                        "
                                        v-else-if="groupByProducts"
                                      >
                                        <input
                                          class="
                                            border-0
                                            p-1
                                            w-100
                                            h-100
                                            text-end
                                          "
                                          v-model="
                                            groupByProducts
                                              .find(
                                                (p) =>
                                                  p.product.id ===
                                                  combinationDescriptor
                                                    .descriptor[0].product.id
                                              )
                                              .amounts.find(
                                                (a) =>
                                                  a.colorId === color.id &&
                                                  a.sizeId === size.id
                                              ).croppedAmount
                                          "
                                        />
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell></CTableDataCell>
                                  </CTableRow>
                                </template>
                                <template
                                  v-for="tissueDescriptor in combinationDescriptor.descriptor.slice(
                                    1,
                                    combinationDescriptor.descriptor.length
                                  )"
                                  :key="tissueDescriptor.id"
                                >
                                  <CTableRow class="text-nowrap">
                                    <CTableDataCell>
                                      <span>
                                        {{
                                          getTissueCombinationColor(
                                            tissueDescriptor.product.tissues,
                                            tissueDescriptor.colors[0]
                                          )
                                        }}
                                      </span>
                                    </CTableDataCell>
                                    <template
                                      v-if="expandUnionProductionAmount"
                                    >
                                      <CTableDataCell>
                                        <div class="d-flex">
                                          <div class="me-2">
                                            <CImage
                                              class="t-col-sm-image"
                                              v-if="
                                                tissueDescriptor.product.images
                                                  .length > 0
                                              "
                                              :src="
                                                tissueDescriptor.product
                                                  .images[0]?.tiny
                                              "
                                              @click="
                                                openLightbox(
                                                  tissueDescriptor.product
                                                    .images
                                                )
                                              "
                                            >
                                            </CImage>
                                            <CIcon
                                              v-else
                                              icon="cilImage"
                                              size="lg"
                                            ></CIcon>
                                          </div>
                                          <span>{{
                                            tissueDescriptor.product.name
                                          }}</span>
                                        </div>
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {{
                                          getElementName(
                                            tissueDescriptor.colors[0]
                                          )
                                        }}
                                      </CTableDataCell>
                                      <CTableDataCell
                                        class="text-end"
                                        v-for="size in allProductsSizes"
                                        :key="size.id"
                                      >
                                        <span>
                                          {{
                                            getAmount(
                                              tissueDescriptor.amounts,
                                              tissueDescriptor.colors[0].id,
                                              size.id
                                            )
                                          }}
                                        </span>
                                      </CTableDataCell>
                                    </template>
                                    <CTableDataCell
                                      class="text-end position-relative"
                                      v-for="size in allProductsSizes"
                                      :key="size.id"
                                    >
                                      <div
                                        class="d-flex w-100"
                                        v-if="
                                          !production.createCropPlanTimestamp ||
                                          production.completeCropTimestamp
                                        "
                                      >
                                        <span
                                          class="me-auto"
                                          :class="
                                            textColor(
                                              getProductDeviation(
                                                tissueDescriptor,
                                                tissueDescriptor.colors[0].id,
                                                size.id
                                              )
                                            )
                                          "
                                          >{{
                                            getProductDeviation(
                                              tissueDescriptor,
                                              tissueDescriptor.colors[0].id,
                                              size.id
                                            )
                                          }}</span
                                        >
                                        <span class="ms-2 text-end">{{
                                          getProductProducedAmount(
                                            tissueDescriptor,
                                            tissueDescriptor.colors[0].id,
                                            size.id
                                          )
                                        }}</span>
                                      </div>
                                      <div
                                        class="
                                          text-end
                                          position-absolute
                                          top-0
                                          bottom-0
                                          start-0
                                          end-0
                                          fs-4
                                        "
                                        v-else-if="groupByProducts"
                                      >
                                        <input
                                          class="
                                            border-0
                                            p-1
                                            w-100
                                            h-100
                                            text-end
                                          "
                                          v-model="
                                            groupByProducts
                                              .find(
                                                (p) =>
                                                  p.product.id ===
                                                  tissueDescriptor.product.id
                                              )
                                              .amounts.find(
                                                (a) =>
                                                  a.colorId ===
                                                    tissueDescriptor.colors[0]
                                                      .id &&
                                                  a.sizeId === size.id
                                              ).croppedAmount
                                          "
                                        />
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell></CTableDataCell>
                                  </CTableRow>
                                  <template
                                    v-for="color in tissueDescriptor.colors.slice(
                                      1,
                                      tissueDescriptor.colors.length
                                    )"
                                    :key="color.id"
                                  >
                                    <CTableRow class="text-nowrap">
                                      <CTableDataCell>
                                        {{
                                          getTissueCombinationColor(
                                            tissueDescriptor.product.tissues,
                                            color
                                          )
                                        }}
                                      </CTableDataCell>
                                      <template
                                        v-if="expandUnionProductionAmount"
                                      >
                                        <CTableDataCell>
                                          <div class="d-flex">
                                            <div class="me-2">
                                              <CImage
                                                class="t-col-sm-image"
                                                v-if="
                                                  tissueDescriptor.product
                                                    .images.length > 0
                                                "
                                                :src="
                                                  tissueDescriptor.product
                                                    .images[0]?.tiny
                                                "
                                                @click="
                                                  openLightbox(
                                                    tissueDescriptor.product
                                                      .images
                                                  )
                                                "
                                              >
                                              </CImage>
                                              <CIcon
                                                v-else
                                                icon="cilImage"
                                                size="lg"
                                              ></CIcon>
                                            </div>
                                            <span>{{
                                              tissueDescriptor.product.name
                                            }}</span>
                                          </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {{ getElementName(color) }}
                                        </CTableDataCell>
                                        <CTableDataCell
                                          class="text-end"
                                          v-for="size in allProductsSizes"
                                          :key="size.id"
                                        >
                                          <span>
                                            {{
                                              getAmount(
                                                tissueDescriptor.amounts,
                                                color.id,
                                                size.id
                                              )
                                            }}
                                          </span>
                                        </CTableDataCell>
                                      </template>
                                      <CTableDataCell
                                        class="position-relative"
                                        v-for="size in allProductsSizes"
                                        :key="size.id"
                                      >
                                        <div
                                          class="d-flex w-100"
                                          v-if="
                                            !production.createCropPlanTimestamp ||
                                            production.completeCropTimestamp
                                          "
                                        >
                                          <span
                                            class="me-auto"
                                            :class="
                                              textColor(
                                                getProductDeviation(
                                                  tissueDescriptor,
                                                  color.id,
                                                  size.id
                                                )
                                              )
                                            "
                                            >{{
                                              getProductDeviation(
                                                tissueDescriptor,
                                                color.id,
                                                size.id
                                              )
                                            }}</span
                                          >
                                          <span class="ms-2 text-end">{{
                                            getProductProducedAmount(
                                              tissueDescriptor,
                                              color.id,
                                              size.id
                                            )
                                          }}</span>
                                        </div>
                                        <div
                                          class="
                                            text-end
                                            position-absolute
                                            top-0
                                            bottom-0
                                            start-0
                                            end-0
                                            fs-4
                                          "
                                          v-else-if="groupByProducts"
                                        >
                                          <input
                                            class="
                                              border-0
                                              p-1
                                              w-100
                                              h-100
                                              text-end
                                            "
                                            v-model="
                                              groupByProducts
                                                .find(
                                                  (p) =>
                                                    p.product.id ===
                                                    tissueDescriptor.product.id
                                                )
                                                .amounts.find(
                                                  (a) =>
                                                    a.colorId === color.id &&
                                                    a.sizeId === size.id
                                                ).croppedAmount
                                            "
                                          />
                                        </div>
                                      </CTableDataCell>
                                      <CTableDataCell></CTableDataCell>
                                    </CTableRow>
                                  </template>
                                </template>
                              </template>
                            </template>
                            <CTableRow>
                              <CTableDataCell
                                :colspan="
                                  3 +
                                  (expandUnionProductionAmount
                                    ? 2 + allProductsSizes.length
                                    : 0)
                                "
                              >
                                <span>{{ t("generic.total") }}:</span>
                              </CTableDataCell>
                              <CTableDataCell
                                class="text-end"
                                v-for="size in allProductsSizes"
                                :key="size.id"
                              >
                                <div class="d-flex w-100">
                                  <span
                                    class="me-auto"
                                    :class="
                                      textColor(
                                        getProductDeviationBySize(size.id)
                                      )
                                    "
                                    >{{
                                      getProductDeviationBySize(size.id)
                                    }}</span
                                  >
                                  <span class="ms-2 text-end">{{
                                    getProducedTotalBySize(size.id)
                                  }}</span>
                                </div>
                              </CTableDataCell>
                              <CTableDataCell></CTableDataCell>
                            </CTableRow>
                          </CTableBody>
                        </CTable>
                      </CTabPane>

                      <CTabPane
                        role="tabpanel"
                        aria-labelledby="details-tab"
                        :visible="activeProductionTab === 2"
                      >
                        <div class="std-table overflow-auto">
                          <CTable class="mt-4 fs-6 w-auto" bordered>
                            <CTableHead class="h-100">
                              <CTableRow align="middle" class="h-100">
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-1"
                                  scope="col"
                                  >{{ t("generic.template") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-2"
                                  scope="col"
                                  >{{ t("generic.size") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-1"
                                  scope="col"
                                  >{{ t("generic.tissueCode") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-3"
                                  scope="col"
                                  >{{ t("generic.tissue") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-4"
                                  scope="col"
                                  >{{
                                    t("generic.printPatternsAndColors")
                                  }}</CTableHeaderCell
                                >
                                <template v-if="expandProductionDetailsAmount">
                                  <CTableHeaderCell
                                    rowspan="4"
                                    class="
                                      t-col-2
                                      text-nowrap
                                      p-0
                                      position-relative
                                      h-100
                                    "
                                    scope="col"
                                  >
                                    <div
                                      class="
                                        position-relative
                                        d-flex
                                        w-100
                                        h-100
                                        p-3
                                        justify-content-end
                                        align-items-center
                                      "
                                    >
                                      <div
                                        v-if="expandProductionDetailsAmount"
                                        class="
                                          cursor-pointer
                                          d-flex
                                          align-items-center
                                          position-absolute
                                          top-0
                                          start-0
                                          h-100
                                          border-end border-end-1 border-light
                                        "
                                        style="padding-bottom: 5px"
                                        @click="
                                          expandProductionDetailsAmount = false
                                        "
                                      >
                                        {{ "<<" }}
                                      </div>
                                      <span
                                        class="p-2 text-center text-nowrap"
                                        >{{ t("generic.modelName") }}</span
                                      >
                                    </div>
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    rowspan="4"
                                    class="t-col-1 text-nowrap"
                                    scope="col"
                                    >{{ t("generic.preorderColors") }}
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    rowspan="4"
                                    class="t-col-1 text-nowrap"
                                    scope="col"
                                    >{{ t("generic.preorderAmounts") }}
                                  </CTableHeaderCell>
                                </template>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-2 p-0 position-relative h-100"
                                  scope="col"
                                >
                                  <div
                                    class="
                                      position-relative
                                      S
                                      h-100
                                      p-3
                                      justify-content-end
                                      align-items-center
                                    "
                                  >
                                    <div
                                      v-if="!expandProductionDetailsAmount"
                                      class="
                                        cursor-pointer
                                        d-flex
                                        align-items-center
                                        position-absolute
                                        top-0
                                        start-0
                                        h-100
                                        border-end border-end-1 border-light
                                      "
                                      style="padding-bottom: 5px"
                                      @click="
                                        expandProductionDetailsAmount = true
                                      "
                                    >
                                      >>
                                    </div>
                                    <span class="p-2 text-center text-nowrap">{{
                                      t("generic.totalPreorderAmount")
                                    }}</span>
                                  </div>
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-2"
                                  scope="col"
                                  >{{ t("generic.patternCode") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-1"
                                  scope="col"
                                  >{{ t("generic.amountPerLayer") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-2"
                                  scope="col"
                                  >{{ t("generic.layer") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-1"
                                  scope="col"
                                  >{{ t("generic.producedAmount") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-1"
                                  scope="col"
                                  >{{ t("generic.deviation") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-2"
                                  scope="col"
                                  >{{ t("generic.deviationPercentage") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  :colspan="getTotalTemplateSizes().length"
                                  scope="col"
                                >
                                  {{
                                    t("generic.calculatedFinalProducedAmount1")
                                  }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  rowspan="4"
                                  class="t-col-2"
                                  scope="col"
                                  >{{ t("generic.wastedAmount") }}
                                </CTableHeaderCell>
                              </CTableRow>
                              <CTableRow>
                                <template
                                  v-for="(
                                    template, tIndex
                                  ) in groupByTemplatesAndTissues"
                                  :key="tIndex"
                                >
                                  <template
                                    v-for="(
                                      combinationDescriptor, cdIndex
                                    ) in template.combinations"
                                    :key="cdIndex"
                                  >
                                    <CTableHeaderCell
                                      class="text-center"
                                      :colspan="
                                        getCombinationDescriptorSizes(
                                          combinationDescriptor.descriptor
                                        ).length
                                      "
                                    >
                                      <div class="d-flex flex-column fs-6">
                                        <span>{{
                                          template.productTemplateId
                                        }}</span>
                                        <span
                                          v-for="combination in combinationDescriptor.combination"
                                          :key="combination"
                                          >{{ combination }}</span
                                        >
                                      </div>
                                    </CTableHeaderCell>
                                  </template>
                                </template>
                              </CTableRow>
                              <CTableRow align="middle">
                                <template
                                  v-for="(
                                    template, tIndex
                                  ) in groupByTemplatesAndTissues"
                                  :key="tIndex"
                                >
                                  <template
                                    v-for="(
                                      combinationDescriptor, cdIndex
                                    ) in template.combinations"
                                    :key="cdIndex"
                                  >
                                    <template
                                      v-for="(
                                        productDescriptor, pIndex
                                      ) in combinationDescriptor.descriptor"
                                      :key="pIndex"
                                    >
                                      <template
                                        v-for="color in productDescriptor.colors"
                                        :key="color.id"
                                      >
                                        <CTableHeaderCell
                                          class="text-center"
                                          :colspan="
                                            productDescriptor.sizes.length
                                          "
                                        >
                                          <div class="d-flex flex-column fs-6">
                                            <span>
                                              {{
                                                getTissueCombinationColor(
                                                  productDescriptor.product
                                                    .tissues,
                                                  color
                                                )
                                              }}
                                            </span>
                                          </div>
                                        </CTableHeaderCell>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </CTableRow>
                              <CTableRow>
                                <template
                                  v-for="(
                                    template, tIndex
                                  ) in groupByTemplatesAndTissues"
                                  :key="tIndex"
                                >
                                  <template
                                    v-for="(
                                      combinationDescriptor, cdIndex
                                    ) in template.combinations"
                                    :key="cdIndex"
                                  >
                                    <template
                                      v-for="(
                                        productDescriptor, pIndex
                                      ) in combinationDescriptor.descriptor"
                                      :key="pIndex"
                                    >
                                      <template
                                        v-for="color in productDescriptor.colors"
                                        :key="color.id"
                                      >
                                        <template
                                          v-for="size in productDescriptor.sizes"
                                          :key="size.id"
                                        >
                                          <CTableHeaderCell class="text-center">
                                            <div
                                              class="
                                                t-col-2
                                                d-flex
                                                flex-column
                                                fs-6
                                              "
                                            >
                                              <span>
                                                {{ getElementName(size) }}
                                              </span>
                                            </div>
                                          </CTableHeaderCell>
                                        </template>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </CTableRow>
                            </CTableHead>

                            <CTableBody>
                              <template
                                v-for="(
                                  descriptor, dIndex
                                ) in productionDetails"
                                :key="dIndex"
                              >
                                <CTableRow align="middle">
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                  >
                                    {{ descriptor.productTemplateId }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                  >
                                    {{ getElementName(descriptor.size) }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                  >
                                    TS{{ descriptor.tissueCode }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                  >
                                    {{ descriptor.tissue.name }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                  >
                                    <div class="d-flex">
                                      <div
                                        class="me-2"
                                        v-if="
                                          descriptor.colorOrPrintPattern
                                            .printPattern
                                        "
                                      >
                                        <CImage
                                          class="t-col-sm-image"
                                          v-if="
                                            descriptor.colorOrPrintPattern
                                              .printPattern.images.length > 0
                                          "
                                          :src="
                                            descriptor.colorOrPrintPattern
                                              .printPattern.images[0]?.tiny
                                          "
                                          @click="
                                            openLightbox(
                                              descriptor.colorOrPrintPattern
                                                .printPattern.images
                                            )
                                          "
                                        >
                                        </CImage>
                                        <CIcon v-else icon="cilImage" size="lg">
                                        </CIcon>
                                      </div>
                                      <span>
                                        {{
                                          descriptor.colorOrPrintPattern
                                            .printPattern
                                            ? `${
                                                descriptor.colorOrPrintPattern
                                                  .printPattern.name
                                              }
                                                                                (${getPrintPatternColorValue(
                                                                                  descriptor.tissueDescriptor,
                                                                                  getElementName(
                                                                                    descriptor
                                                                                      .colorOrPrintPattern
                                                                                      .color
                                                                                  )
                                                                                )})`
                                            : getTissueColorValue(
                                                descriptor.tissueDescriptor,
                                                getElementName(
                                                  descriptor.colorOrPrintPattern
                                                    .color
                                                )
                                              )
                                        }}
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                  <template
                                    v-if="expandProductionDetailsAmount"
                                  >
                                    <CTableDataCell
                                     :rowspan="descriptor.relatedProducts[0].amounts.length"
                                     >
                                      <div class="d-flex">
                                        <div class="me-2">
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              descriptor.relatedProducts[0]
                                                .product.images.length > 0
                                            "
                                            :src="
                                              descriptor.relatedProducts[0]
                                                .product.images[0]?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                descriptor.relatedProducts[0]
                                                  .product.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>
                                          {{
                                            descriptor.relatedProducts[0]
                                              .product.name
                                          }}
                                        </span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {{
                                        getElementName(
                                          getColor(
                                            descriptor.relatedProducts[0]
                                              .amounts[0].colorId
                                          )
                                        )
                                      }}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {{
                                        descriptor.relatedProducts[0].amounts[0]
                                          .amount
                                      }}
                                    </CTableDataCell>
                                  </template>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end"
                                  >
                                    {{ descriptor.amount }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-center"
                                  >
                                    #{{ descriptor.patternCode }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end"
                                  >
                                    {{ getAmountPerLayer(descriptor) }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end"
                                  >
                                    {{ getLayer(descriptor) }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end"
                                  >
                                    {{ getProducedAmount(descriptor) }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end"
                                  >
                                    {{ getDeviation(descriptor) }}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end"
                                  >
                                    {{ getDeviationPercentage(descriptor) }}%
                                  </CTableDataCell>
                                  <template
                                    v-for="(
                                      template, tIndex
                                    ) in groupByTemplatesAndTissues"
                                    :key="tIndex"
                                  >
                                    <template
                                      v-for="(
                                        combinationDescriptor, cdIndex
                                      ) in template.combinations"
                                      :key="cdIndex"
                                    >
                                      <template
                                        v-for="(
                                          productDescriptor, pIndex
                                        ) in combinationDescriptor.descriptor"
                                        :key="pIndex"
                                      >
                                        <template
                                          v-for="color in productDescriptor.colors"
                                          :key="color.id"
                                        >
                                          <template
                                            v-for="size in productDescriptor.sizes"
                                            :key="size.id"
                                          >
                                            <CTableDataCell
                                              :rowspan="
                                                expandProductionDetailsAmount
                                                  ? descriptor.relatedProducts.reduce(
                                                      (total, product) =>
                                                        total +
                                                        getAmountsColors(
                                                          product.amounts
                                                        ).length,
                                                      0
                                                    )
                                                  : 1
                                              "
                                              class="text-end"
                                            >
                                              <div
                                                class="d-flex flex-column fs-6"
                                              >
                                                <span
                                                    :class="Number(getProductDeviation(
                                                        productDescriptor,
                                                        color.id,
                                                        size.id
                                                    )) === 0 ? '' : textColor(Number(getProductDeviation(
                                                        productDescriptor,
                                                        color.id,
                                                        size.id
                                                    )))"
                                                  v-if="
                                                    template.productTemplateId ===
                                                      descriptor.productTemplateId &&
                                                    descriptor.combinations.find((c) => isEqualCombination(
                                                      combinationDescriptor.combination,
                                                      c
                                                    )) &&
                                                    getTissueDescriptorUseColor(colors, descriptor.tissueDescriptor, color).id ===
                                                      descriptor
                                                        .colorOrPrintPattern
                                                        .color.id &&
                                                    size.id ===
                                                      descriptor.size.id && productDescriptor.product.tissues.find((t) => t.printPattern?.name === descriptor.colorOrPrintPattern.printPattern?.name)
                                                  "
                                                >
                                                  {{
                                                    getProductProducedAmount(
                                                        productDescriptor,
                                                        color.id,
                                                        size.id
                                                    ) !== ""
                                                      ? getProductProducedAmount(
                                                        productDescriptor,
                                                        color.id,
                                                        size.id
                                                        )
                                                      : 0
                                                  }}
                                                </span>
                                                <span v-else></span>
                                              </div>
                                            </CTableDataCell>
                                          </template>
                                        </template>
                                      </template>
                                    </template>
                                  </template>
                                  <CTableDataCell
                                    :rowspan="
                                      expandProductionDetailsAmount
                                        ? descriptor.relatedProducts.reduce(
                                            (total, product) =>
                                              total +
                                              getAmountsColors(product.amounts)
                                                .length,
                                            0
                                          )
                                        : 1
                                    "
                                    class="text-end text-danger"
                                  >
                                    {{ getWastedAmount(descriptor) }}
                                  </CTableDataCell>
                                </CTableRow>
                                <template v-if="expandProductionDetailsAmount">
                                    <template
                                      v-for="(
                                        amountDescriptor, adIndex
                                      ) of descriptor.relatedProducts[0].amounts.slice(
                                        1,
                                        descriptor.relatedProducts[0].amounts.length
                                      )"
                                      :key="adIndex"
                                    >
                                      <CTableRow align="middle">
                                        <CTableDataCell>
                                          {{
                                            getElementName(
                                              getColor(amountDescriptor.colorId)
                                            )
                                          }}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {{ amountDescriptor.amount }}
                                        </CTableDataCell>
                                      </CTableRow>
                                    </template>
                                  <template
                                    v-for="(
                                      productDescriptor, pdIndex
                                    ) of descriptor.relatedProducts.slice(
                                      1,
                                      descriptor.relatedProducts.length
                                    )"
                                    :key="pdIndex"
                                  >
                                    <CTableRow align="middle">
                                        <CTableDataCell
                                        :rowspan="productDescriptor.amounts.length
                                    ">
                                        <div class="d-flex">
                                            <div class="me-2">
                                            <CImage
                                                class="t-col-sm-image"
                                                v-if="
                                                productDescriptor
                                                    .product.images.length > 0
                                                "
                                                :src="
                                                productDescriptor
                                                    .product.images[0]?.tiny
                                                "
                                                @click="
                                                openLightbox(
                                                    productDescriptor
                                                    .product.images
                                                )
                                                "
                                            >
                                            </CImage>
                                            <CIcon
                                                v-else
                                                icon="cilImage"
                                                size="lg"
                                            ></CIcon>
                                            </div>
                                            <span>
                                            {{
                                                productDescriptor
                                                .product.name
                                            }}
                                            </span>
                                        </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {{
                                            getElementName(
                                              getColor(productDescriptor.amounts[0].colorId)
                                            )
                                          }}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {{ productDescriptor.amounts[0].amount }}
                                        </CTableDataCell>
                                    </CTableRow>
                                    <template
                                      v-for="(
                                        amountDescriptor, adIndex
                                      ) of productDescriptor.amounts.slice(
                                        1,
                                        productDescriptor.amounts.length
                                      )"
                                      :key="adIndex"
                                    >
                                      <CTableRow align="middle">
                                        <CTableDataCell>
                                          {{
                                            getElementName(
                                              getColor(amountDescriptor.colorId)
                                            )
                                          }}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {{ amountDescriptor.amount }}
                                        </CTableDataCell>
                                      </CTableRow>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </CTableBody>
                          </CTable>
                        </div>
                      </CTabPane>
                    </CTabContent>
                  </CCollapse>
                </CCardBody>
              </CCard>

              <!-- Cut Info -->
              <CCard class="mx-3 mt-3">
                <CCardHeader
                  class="cursor-pointer"
                  @click="toggleCutInfoCollapse"
                >
                  <CRow>
                    <CCol class="me-auto">
                      <span>{{ t("generic.cutScheme") }}</span>
                    </CCol>
                    <CCol class="text-end text-info">
                      <span v-if="!isCutInfoCollapseVisible" class="pe-1">{{
                        t("generic.expand")
                      }}</span>
                      <span v-else class="pe-1">{{ t("generic.hide") }}</span>
                      <svg
                        class="icon"
                        :class="arrows.cutInfo"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        role="img"
                        data-v-58d9d025=""
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M256.072,424l-11.421-11.313-85.808-85.809.053-.054L16,183.928l97.122-97.122,142.9,142.9,142.9-142.9,97.122,97.122L353.243,326.722l-11.361,11.469Zm-.107-45.254.054.053,51.835-51.835L319.2,315.511,450.783,183.928,398.915,132.06l-142.9,142.9-142.9-142.9L61.254,183.928l142.9,142.9-.053.054Z"
                          class="ci-primary"
                        ></path>
                      </svg>
                    </CCol>
                  </CRow>
                </CCardHeader>
                <CCardBody class="overflow-auto">
                  <CCollapse :visible="isCutInfoCollapseVisible">
                    <div
                      class="d-flex flex-xl-nowrap w-auto"
                      v-if="productionPlan.length > 0"
                    >
                      <div class="me-auto">
                        <CTable class="w-auto fs-5" bordered caption="top">
                          <CTableCaption>
                            <span>{{ t("generic.pattern") }}</span>
                          </CTableCaption>
                          <CTableBody>
                            <CTableRow align="middle">
                              <CTableHeaderCell
                                class="t-col-1"
                                scope="col"
                                :rowspan="
                                  2 + productionPlan[0].patternDescriptor.length
                                "
                              >
                                #1</CTableHeaderCell
                              >
                              <CTableHeaderCell
                                class="t-col-2"
                                scope="col"
                                rowspan="2"
                                >{{ t("generic.template") }}</CTableHeaderCell
                              >
                              <CTableHeaderCell
                                class="t-col-2"
                                scope="col"
                                rowspan="2"
                                >{{ t("generic.tissueCode") }}</CTableHeaderCell
                              >
                              <CTableHeaderCell
                                class="t-col-2"
                                scope="col"
                                rowspan="2"
                                >{{
                                  t("generic.totalAmounts")
                                }}</CTableHeaderCell
                              >
                              <CTableHeaderCell
                                class="t-col-2 text-center"
                                scope="col"
                                :colspan="
                                  getPatternDescriptorSizes(
                                    productionPlan[0].patternDescriptor
                                  ).length
                                "
                              >
                                {{ t("generic.amountPerLayer") }}
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                class="t-col-2"
                                scope="col"
                                rowspan="2"
                                >{{
                                  t("generic.tissuePavingMeters")
                                }}</CTableHeaderCell
                              >
                            </CTableRow>
                            <CTableRow>
                              <CTableDataCell
                                class="t-col-1"
                                v-for="amount in getPatternDescriptorSizes(
                                  productionPlan[0].patternDescriptor
                                )"
                                :key="amount.size.id"
                              >
                                {{ getElementName(amount.size) }}
                              </CTableDataCell>
                            </CTableRow>
                            <CTableRow align="middle">
                              <CTableDataCell>
                                {{
                                  productionPlan[0].patternDescriptor[0]
                                    .productTemplateId
                                }}
                              </CTableDataCell>
                              <CTableDataCell>
                                TS{{ productionPlan[0].code }}
                              </CTableDataCell>
                              <CTableDataCell>
                                {{
                                  productionPlan[0].patternDescriptor[0]
                                    .totalAmounts
                                }}
                              </CTableDataCell>
                              <CTableDataCell
                                v-for="amountDescriptor of getPatternDescriptorSizes(
                                  productionPlan[0].patternDescriptor
                                )"
                                :key="amountDescriptor.size.id"
                                class="p-0 text-end position-relative"
                              >
                                <div
                                  v-if="creation"
                                  class="
                                    position-absolute
                                    top-0
                                    bottom-0
                                    start-0
                                    end-0
                                    fs-4
                                  "
                                >
                                  <input
                                    class="border-0 p-1 w-100 h-100 text-end"
                                    type="number"
                                    v-model="amountDescriptor.amount"
                                    @input="
                                      onInputAmountsPerLayers(
                                        $event,
                                        productionPlan[0],
                                        productionPlan[0].patternDescriptor[0],
                                        amountDescriptor
                                      )
                                    "
                                  />
                                </div>
                                <span class="p-2" v-else>
                                  {{ amountDescriptor.amount }}
                                </span>
                              </CTableDataCell>
                              <CTableDataCell
                                class="p-0 text-end position-relative"
                                :rowspan="
                                  productionPlan[0].patternDescriptor.length
                                "
                              >
                                <div
                                  v-if="creation"
                                  class="
                                    position-absolute
                                    top-0
                                    bottom-0
                                    start-0
                                    end-0
                                    fs-4
                                  "
                                >
                                  <input
                                    class="border-0 p-1 w-100 h-100 text-end"
                                    v-model="
                                      productionPlan[0].tissuePavingMeters
                                    "
                                  />
                                </div>
                                <span class="p-2" v-else>
                                  {{ productionPlan[0].tissuePavingMeters }}
                                </span>
                              </CTableDataCell>
                            </CTableRow>
                            <CTableRow
                              v-for="(
                                patternDescriptor, tpdIndex
                              ) in productionPlan[0].patternDescriptor.slice(
                                1,
                                productionPlan[0].patternDescriptor.length
                              )"
                              :key="tpdIndex"
                            >
                              <CTableDataCell>
                                {{ patternDescriptor.productTemplateId }}
                              </CTableDataCell>
                              <CTableDataCell>
                                TS{{ productionPlan[0].code }}
                              </CTableDataCell>
                              <CTableDataCell>
                                {{ patternDescriptor.totalAmounts }}
                              </CTableDataCell>
                              <CTableDataCell
                                class="p-0 text-end position-relative"
                                v-for="amountDescriptor of patternDescriptor.amountsPerLayers"
                                :key="amountDescriptor.size.id"
                              >
                                <div
                                  v-if="creation"
                                  class="
                                    position-absolute
                                    top-0
                                    bottom-0
                                    start-0
                                    end-0
                                    fs-4
                                  "
                                >
                                  <input
                                    class="border-0 p-1 w-100 h-100 text-end"
                                    type="number"
                                    v-model="amountDescriptor.amount"
                                    @input="
                                      onInputAmountsPerLayers(
                                        $event,
                                        productionPlan[0],
                                        patternDescriptor,
                                        amountDescriptor
                                      )
                                    "
                                  />
                                </div>
                                <span class="p-2" v-else>
                                  {{ amountDescriptor.amount }}
                                </span>
                              </CTableDataCell>
                            </CTableRow>
                          </CTableBody>
                        </CTable>
                      </div>
                      <div class="ms-4 me-5">
                        <CTable class="w-auto fs-5" bordered caption="top">
                          <CTableCaption>
                            <span>{{ t("generic.tissuePavingPlan") }}</span>
                          </CTableCaption>
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell class="t-col-3" scope="col">{{
                                t("generic.tissue")
                              }}</CTableHeaderCell>
                              <CTableHeaderCell class="t-col-4" scope="col"
                                >{{ t("generic.printPatternsAndColors") }}
                              </CTableHeaderCell>
                              <CTableHeaderCell class="t-col-2" scope="col">{{
                                t("generic.layer")
                              }}</CTableHeaderCell>
                              <CTableHeaderCell class="t-col-3" scope="col">{{
                                t("generic.cropNotes")
                              }}</CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>

                          <CTableBody>
                            <template
                              v-for="(
                                tissuePaving, tpIndex
                              ) in productionPlan[0].tissuePavingDescriptor"
                              :key="tpIndex"
                            >
                              <CTableRow align="middle">
                                <CTableDataCell
                                  :rowspan="
                                    tissuePaving.printPatterns.reduce(
                                      (total, c) =>
                                        c.layersPerColor.length + total,
                                      0
                                    )
                                  "
                                >
                                  <div class="d-flex">
                                    <div class="me-2">
                                      <CImage
                                        class="t-col-sm-image"
                                        v-if="
                                          tissuePaving.tissue.images.length > 0
                                        "
                                        :src="
                                          tissuePaving.tissue.images[0]?.tiny
                                        "
                                        @click="
                                          openLightbox(
                                            tissuePaving.tissue.images
                                          )
                                        "
                                      >
                                      </CImage>
                                      <CIcon v-else icon="cilImage" size="lg">
                                      </CIcon>
                                    </div>
                                    <span>
                                      {{ tissuePaving.tissue.name }}
                                    </span>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div class="d-flex">
                                    <div
                                      class="me-2"
                                      v-if="
                                        tissuePaving.printPatterns[0]
                                          .printPattern
                                      "
                                    >
                                      <CImage
                                        class="t-col-sm-image"
                                        v-if="
                                          tissuePaving.printPatterns[0]
                                            .printPattern.images.length > 0
                                        "
                                        :src="
                                          tissuePaving.printPatterns[0]
                                            .printPattern.images[0]?.tiny
                                        "
                                        @click="
                                          openLightbox(
                                            tissuePaving.printPatterns[0]
                                              .printPattern.images
                                          )
                                        "
                                      >
                                      </CImage>
                                      <CIcon v-else icon="cilImage" size="lg">
                                      </CIcon>
                                    </div>
                                    <span>
                                      {{
                                        tissuePaving.printPatterns[0]
                                          .printPattern
                                          ? `${
                                              tissuePaving.printPatterns[0]
                                                .printPattern.name
                                            }
                                                                            (${getElementName(
                                                                              tissuePaving
                                                                                .printPatterns[0]
                                                                                .layersPerColor[0]
                                                                                .color
                                                                            )})`
                                          : getElementName(
                                              tissuePaving.printPatterns[0]
                                                .layersPerColor[0].color
                                            )
                                      }}
                                    </span>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell
                                  class="p-0 text-end position-relative"
                                >
                                  <div
                                    v-if="creation"
                                    class="
                                      position-absolute
                                      top-0
                                      bottom-0
                                      start-0
                                      end-0
                                      fs-4
                                    "
                                  >
                                    <input
                                      class="border-0 p-1 w-100 h-100 text-end"
                                      type="number"
                                      v-model="
                                        tissuePaving.printPatterns[0]
                                          .layersPerColor[0].amount
                                      "
                                      @input="
                                        onInputLayers(
                                          $event,
                                          productionPlan[0],
                                          tissuePaving,
                                          tissuePaving.printPatterns[0],
                                          tissuePaving.printPatterns[0]
                                            .layersPerColor[0]
                                        )
                                      "
                                    />
                                  </div>
                                  <span class="p-2" v-else>
                                    {{
                                      tissuePaving.printPatterns[0]
                                        .layersPerColor[0].amount
                                    }}
                                  </span>
                                </CTableDataCell>
                                <CTableDataCell
                                  class="p-0 text-end position-relative"
                                  :rowspan="
                                    tissuePaving.printPatterns.reduce(
                                      (total, c) =>
                                        c.layersPerColor.length + total,
                                      0
                                    )
                                  "
                                >
                                  <div
                                    v-if="creation"
                                    class="
                                      position-absolute
                                      top-0
                                      bottom-0
                                      start-0
                                      end-0
                                      fs-4
                                    "
                                  >
                                    <textarea
                                      class="border-0 p-1 w-100 h-100"
                                      v-model="tissuePaving.cropNotes"
                                    >
                                    </textarea>
                                  </div>
                                  <span class="p-2" v-else>
                                    {{ tissuePaving.cropNotes }}
                                  </span>
                                </CTableDataCell>
                              </CTableRow>
                              <template
                                v-for="(
                                  layer, lIndex
                                ) in tissuePaving.printPatterns[0].layersPerColor.slice(
                                  1,
                                  tissuePaving.printPatterns[0].layersPerColor
                                    .length
                                )"
                                :key="lIndex"
                              >
                                <CTableRow align="middle">
                                  <CTableDataCell>
                                    <div class="d-flex">
                                      <div
                                        class="me-2"
                                        v-if="
                                          tissuePaving.printPatterns[0]
                                            .printPattern
                                        "
                                      >
                                        <CImage
                                          class="t-col-sm-image"
                                          v-if="
                                            tissuePaving.printPatterns[0]
                                              .printPattern.images.length > 0
                                          "
                                          :src="
                                            tissuePaving.printPatterns[0]
                                              .printPattern.images[0]?.tiny
                                          "
                                          @click="
                                            openLightbox(
                                              tissuePaving.printPatterns[0]
                                                .printPattern.images
                                            )
                                          "
                                        >
                                        </CImage>
                                        <CIcon v-else icon="cilImage" size="lg">
                                        </CIcon>
                                      </div>
                                      <span>
                                        {{
                                          tissuePaving.printPatterns[0]
                                            .printPattern
                                            ? `${
                                                tissuePaving.printPatterns[0]
                                                  .printPattern.name
                                              }
                                                                                (${getElementName(
                                                                                  layer.color
                                                                                )})`
                                            : getElementName(layer.color)
                                        }}
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    class="p-0 text-end position-relative"
                                  >
                                    <div
                                      v-if="creation"
                                      class="
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                    >
                                      <input
                                        type="number"
                                        class="
                                          border-0
                                          p-1
                                          w-100
                                          h-100
                                          text-end
                                        "
                                        v-model="layer.amount"
                                        @input="
                                          onInputLayers(
                                            $event,
                                            productionPlan[0],
                                            tissuePaving,
                                            tissuePaving.printPatterns[0],
                                            layer
                                          )
                                        "
                                      />
                                    </div>
                                    <span class="p-2" v-else>
                                      {{ layer.amount }}
                                    </span>
                                  </CTableDataCell>
                                </CTableRow>
                              </template>
                              <template
                                v-for="(
                                  printPatternDescriptor, ppdIndex
                                ) in tissuePaving.printPatterns.slice(
                                  1,
                                  tissuePaving.printPatterns.length
                                )"
                                :key="ppdIndex"
                              >
                                <CTableRow align="middle">
                                  <CTableDataCell>
                                    <div class="d-flex">
                                      <div
                                        class="me-2"
                                        v-if="
                                          printPatternDescriptor.printPattern
                                        "
                                      >
                                        <CImage
                                          class="t-col-sm-image"
                                          v-if="
                                            printPatternDescriptor.printPattern
                                              .images.length > 0
                                          "
                                          :src="
                                            printPatternDescriptor.printPattern
                                              .images[0]?.tiny
                                          "
                                          @click="
                                            openLightbox(
                                              printPatternDescriptor
                                                .printPattern.images
                                            )
                                          "
                                        >
                                        </CImage>
                                        <CIcon v-else icon="cilImage" size="lg">
                                        </CIcon>
                                      </div>
                                      <span>
                                        {{
                                          printPatternDescriptor.printPattern
                                            ? `${
                                                printPatternDescriptor
                                                  .printPattern.name
                                              }
                                                                                (${getElementName(
                                                                                  printPatternDescriptor
                                                                                    .layersPerColor[0]
                                                                                    .color
                                                                                )})`
                                            : getElementName(
                                                printPatternDescriptor
                                                  .layersPerColor[0].color
                                              )
                                        }}
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    class="p-0 text-end position-relative"
                                  >
                                    <div
                                      v-if="creation"
                                      class="
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                    >
                                      <input
                                        class="
                                          border-0
                                          p-1
                                          w-100
                                          h-100
                                          text-end
                                        "
                                        v-model="
                                          printPatternDescriptor
                                            .layersPerColor[0].amount
                                        "
                                        type="number"
                                        @input="
                                          onInputLayers(
                                            $event,
                                            productionPlan[0],
                                            tissuePaving,
                                            printPatternDescriptor,
                                            printPatternDescriptor
                                              .layersPerColor[0]
                                          )
                                        "
                                      />
                                    </div>
                                    <span class="p-2" v-else>
                                      {{
                                        printPatternDescriptor.layersPerColor[0]
                                          .amount
                                      }}
                                    </span>
                                  </CTableDataCell>
                                </CTableRow>
                                <template
                                  v-for="(
                                    layer, lIndex
                                  ) in printPatternDescriptor.layersPerColor.slice(
                                    1,
                                    printPatternDescriptor.layersPerColor.length
                                  )"
                                  :key="lIndex"
                                >
                                  <CTableRow align="middle">
                                    <CTableDataCell>
                                      <div class="d-flex">
                                        <div
                                          class="me-2"
                                          v-if="
                                            printPatternDescriptor.printPattern
                                          "
                                        >
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              printPatternDescriptor
                                                .printPattern.images.length > 0
                                            "
                                            :src="
                                              printPatternDescriptor
                                                .printPattern.images[0]?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                printPatternDescriptor
                                                  .printPattern.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>
                                          {{
                                            printPatternDescriptor.printPattern
                                              ? `${
                                                  printPatternDescriptor
                                                    .printPattern.name
                                                }
                                                                                    (${getElementName(
                                                                                      layer.color
                                                                                    )})`
                                              : getElementName(layer.color)
                                          }}
                                        </span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell
                                      class="p-0 text-end position-relative"
                                    >
                                      <div
                                        v-if="creation"
                                        class="
                                          position-absolute
                                          top-0
                                          bottom-0
                                          start-0
                                          end-0
                                          fs-4
                                        "
                                      >
                                        <input
                                          type="number"
                                          class="
                                            border-0
                                            p-1
                                            w-100
                                            h-100
                                            text-end
                                          "
                                          @input="
                                            onInputLayers(
                                              $event,
                                              productionPlan[0],
                                              tissuePaving,
                                              printPatternDescriptor,
                                              layer
                                            )
                                          "
                                          v-model="layer.amount"
                                        />
                                      </div>
                                      <span class="p-2" v-else>
                                        {{ layer.amount }}
                                      </span>
                                    </CTableDataCell>
                                  </CTableRow>
                                </template>
                              </template>
                            </template>
                          </CTableBody>
                        </CTable>
                      </div>
                    </div>
                    <template v-if="productionPlan.length > 1">
                      <div
                        class="d-flex flex-xl-nowrap mt-2"
                        v-for="plan in productionPlan.slice(
                          1,
                          productionPlan.length
                        )"
                        :key="plan.code"
                      >
                        <div class="me-auto">
                          <CTable
                            class="w-auto fs-5"
                            bordered
                            responsive
                            caption="top"
                          >
                            <CTableBody>
                              <CTableRow align="middle">
                                <CTableHeaderCell
                                  class="t-col-1"
                                  scope="col"
                                  :rowspan="2 + plan.patternDescriptor.length"
                                  >#{{ plan.code }}</CTableHeaderCell
                                >
                                <CTableHeaderCell
                                  class="t-col-2"
                                  scope="col"
                                  rowspan="2"
                                  >{{ t("generic.template") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  class="t-col-2"
                                  scope="col"
                                  rowspan="2"
                                  >{{ t("generic.tissueCode") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  class="t-col-2"
                                  scope="col"
                                  rowspan="2"
                                  >{{ t("generic.totalAmounts") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  class="t-col-2 text-center"
                                  scope="col"
                                  :colspan="
                                    plan.patternDescriptor[0].amountsPerLayers
                                      .length
                                  "
                                >
                                  {{ t("generic.amountPerLayer") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  class="t-col-2"
                                  scope="col"
                                  rowspan="2"
                                  >{{ t("generic.tissuePavingMeters") }}
                                </CTableHeaderCell>
                              </CTableRow>
                              <CTableRow>
                                <CTableDataCell
                                  v-for="amount in plan.patternDescriptor[0]
                                    .amountsPerLayers"
                                  :key="amount.size.id"
                                >
                                  {{ getElementName(amount.size) }}
                                </CTableDataCell>
                              </CTableRow>
                              <CTableRow>
                                <CTableDataCell>
                                  {{
                                    plan.patternDescriptor[0].productTemplateId
                                  }}
                                </CTableDataCell>
                                <CTableDataCell>
                                  TS{{ plan.code }}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {{ plan.patternDescriptor[0].totalAmounts }}
                                </CTableDataCell>
                                <CTableDataCell
                                  class="p-0 text-end position-relative"
                                  v-for="amountDescriptor of plan
                                    .patternDescriptor[0].amountsPerLayers"
                                  :key="amountDescriptor.size.id"
                                >
                                  <div
                                    v-if="creation"
                                    class="
                                      position-absolute
                                      top-0
                                      bottom-0
                                      start-0
                                      end-0
                                      fs-4
                                    "
                                  >
                                    <input
                                      type="number"
                                      class="border-0 p-1 w-100 h-100 text-end"
                                      @input="
                                        onInputAmountsPerLayers(
                                          $event,
                                          plan,
                                          plan.patternDescriptor[0],
                                          amountDescriptor
                                        )
                                      "
                                      v-model="amountDescriptor.amount"
                                    />
                                  </div>
                                  <span class="p-2" v-else>
                                    {{ amountDescriptor.amount }}
                                  </span>
                                </CTableDataCell>
                                <CTableDataCell
                                  class="p-0 text-end position-relative"
                                  :rowspan="plan.patternDescriptor.length"
                                >
                                  <div
                                    v-if="creation"
                                    class="
                                      position-absolute
                                      top-0
                                      bottom-0
                                      start-0
                                      end-0
                                      fs-4
                                    "
                                  >
                                    <input
                                      v-if="creation"
                                      class="border-0 p-1 w-100 h-100 text-end"
                                      v-model="plan.tissuePavingMeters"
                                    />
                                  </div>
                                  <span class="p-2" v-else>
                                    {{ plan.tissuePavingMeters }}
                                  </span>
                                </CTableDataCell>
                              </CTableRow>
                              <CTableRow
                                v-for="(
                                  patternDescriptor, tpdIndex
                                ) in plan.patternDescriptor.slice(
                                  1,
                                  plan.patternDescriptor.length
                                )"
                                :key="tpdIndex"
                              >
                                <CTableDataCell>
                                  {{ patternDescriptor.productTemplateId }}
                                </CTableDataCell>
                                <CTableDataCell>
                                  TS{{ plan.code }}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {{ patternDescriptor.totalAmounts }}
                                </CTableDataCell>
                                <CTableDataCell
                                  class="p-0 text-end position-relative"
                                  v-for="amountDescriptor of patternDescriptor.amountsPerLayers"
                                  :key="amountDescriptor.size.id"
                                >
                                  <div
                                    v-if="creation"
                                    class="
                                      position-absolute
                                      top-0
                                      bottom-0
                                      start-0
                                      end-0
                                      fs-4
                                    "
                                  >
                                    <input
                                      type="number"
                                      class="border-0 p-1 w-100 h-100 text-end"
                                      @input="
                                        onInputAmountsPerLayers(
                                          $event,
                                          plan,
                                          patternDescriptor,
                                          amountDescriptor
                                        )
                                      "
                                      v-model="amountDescriptor.amount"
                                    />
                                  </div>

                                  <span class="p-2" v-else>
                                    {{ amountDescriptor.amount }}
                                  </span>
                                </CTableDataCell>
                              </CTableRow>
                            </CTableBody>
                          </CTable>
                        </div>
                        <div>
                          <CTable
                            class="w-auto fs-5"
                            bordered
                            responsive
                            caption="top"
                          >
                            <CTableHead>
                              <CTableRow>
                                <CTableHeaderCell class="t-col-3" scope="col">{{
                                  t("generic.tissue")
                                }}</CTableHeaderCell>
                                <CTableHeaderCell class="t-col-3" scope="col"
                                  >{{ t("generic.printPatternsAndColors") }}
                                </CTableHeaderCell>
                                <CTableHeaderCell class="t-col-2" scope="col">{{
                                  t("generic.layer")
                                }}</CTableHeaderCell>
                                <CTableHeaderCell class="t-col-3" scope="col">{{
                                  t("generic.cropNotes")
                                }}</CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>

                            <CTableBody>
                              <template
                                v-for="(
                                  tissuePaving, tpIndex
                                ) in plan.tissuePavingDescriptor"
                                :key="tpIndex"
                              >
                                <CTableRow align="middle">
                                  <CTableDataCell
                                    :rowspan="
                                      tissuePaving.printPatterns.reduce(
                                        (total, c) =>
                                          c.layersPerColor.length + total,
                                        0
                                      )
                                    "
                                  >
                                    <div class="d-flex">
                                      <div class="me-2">
                                        <CImage
                                          class="t-col-sm-image"
                                          v-if="
                                            tissuePaving.tissue.images.length >
                                            0
                                          "
                                          :src="
                                            tissuePaving.tissue.images[0]?.tiny
                                          "
                                          @click="
                                            openLightbox(
                                              tissuePaving.tissue.images
                                            )
                                          "
                                        >
                                        </CImage>
                                        <CIcon v-else icon="cilImage" size="lg">
                                        </CIcon>
                                      </div>
                                      <span>
                                        {{ tissuePaving.tissue.name }}
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    <div class="d-flex">
                                      <div
                                        class="me-2"
                                        v-if="
                                          tissuePaving.printPatterns[0]
                                            .printPattern
                                        "
                                      >
                                        <CImage
                                          class="t-col-sm-image"
                                          v-if="
                                            tissuePaving.printPatterns[0]
                                              .printPattern.images.length > 0
                                          "
                                          :src="
                                            tissuePaving.printPatterns[0]
                                              .printPattern.images[0]?.tiny
                                          "
                                          @click="
                                            openLightbox(
                                              tissuePaving.printPatterns[0]
                                                .printPattern.images
                                            )
                                          "
                                        >
                                        </CImage>
                                        <CIcon v-else icon="cilImage" size="lg">
                                        </CIcon>
                                      </div>
                                      <span>
                                        {{
                                          tissuePaving.printPatterns[0]
                                            .printPattern
                                            ? `${
                                                tissuePaving.printPatterns[0]
                                                  .printPattern.name
                                              }
                                                                                (${getElementName(
                                                                                  tissuePaving
                                                                                    .printPatterns[0]
                                                                                    .layersPerColor[0]
                                                                                    .color
                                                                                )})`
                                            : getElementName(
                                                tissuePaving.printPatterns[0]
                                                  .layersPerColor[0].color
                                              )
                                        }}
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    class="p-0 text-end position-relative"
                                  >
                                    <div
                                      v-if="creation"
                                      class="
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                    >
                                      <input
                                        type="number"
                                        class="
                                          border-0
                                          p-1
                                          w-100
                                          h-100
                                          text-end
                                        "
                                        @input="
                                          onInputLayers(
                                            $event,
                                            plan,
                                            tissuePaving,
                                            tissuePaving.printPatterns[0],
                                            tissuePaving.printPatterns[0]
                                              .layersPerColor[0]
                                          )
                                        "
                                        v-model="
                                          tissuePaving.printPatterns[0]
                                            .layersPerColor[0].amount
                                        "
                                      />
                                    </div>

                                    <span class="p-2" v-else>
                                      {{
                                        tissuePaving.printPatterns[0]
                                          .layersPerColor[0].amount
                                      }}
                                    </span>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    class="p-0 text-end position-relative"
                                    :rowspan="
                                      tissuePaving.printPatterns.reduce(
                                        (total, c) =>
                                          c.layersPerColor.length + total,
                                        0
                                      )
                                    "
                                  >
                                    <div
                                      v-if="creation"
                                      class="
                                        position-absolute
                                        top-0
                                        bottom-0
                                        start-0
                                        end-0
                                        fs-4
                                      "
                                    >
                                      <textarea
                                        class="border-0 p-1 w-100 h-100"
                                        v-model="tissuePaving.cropNotes"
                                      >
                                      </textarea>
                                    </div>
                                    <span class="p-2" v-else>
                                      {{ tissuePaving.cropNotes }}
                                    </span>
                                  </CTableDataCell>
                                </CTableRow>
                                <template
                                  v-for="(
                                    layer, lIndex
                                  ) in tissuePaving.printPatterns[0].layersPerColor.slice(
                                    1,
                                    tissuePaving.printPatterns[0].layersPerColor
                                      .length
                                  )"
                                  :key="lIndex"
                                >
                                  <CTableRow align="middle">
                                    <CTableDataCell>
                                      <div class="d-flex">
                                        <div
                                          class="me-2"
                                          v-if="
                                            tissuePaving.printPatterns[0]
                                              .printPattern
                                          "
                                        >
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              tissuePaving.printPatterns[0]
                                                .printPattern.images.length > 0
                                            "
                                            :src="
                                              tissuePaving.printPatterns[0]
                                                .printPattern.images[0]?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                tissuePaving.printPatterns[0]
                                                  .printPattern.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>
                                          {{
                                            tissuePaving.printPatterns[0]
                                              .printPattern
                                              ? `${
                                                  tissuePaving.printPatterns[0]
                                                    .printPattern.name
                                                }
                                                                                    (${getElementName(
                                                                                      layer.color
                                                                                    )})`
                                              : getElementName(layer.color)
                                          }}
                                        </span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell
                                      class="p-0 text-end position-relative"
                                    >
                                      <div
                                        v-if="creation"
                                        class="
                                          position-absolute
                                          top-0
                                          bottom-0
                                          start-0
                                          end-0
                                          fs-4
                                        "
                                      >
                                        <input
                                          class="
                                            border-0
                                            p-1
                                            w-100
                                            h-100
                                            text-end
                                          "
                                          v-model="layer.amount"
                                          type="number"
                                          @input="
                                            onInputLayers(
                                              $event,
                                              plan,
                                              tissuePaving,
                                              tissuePaving.printPatterns[0],
                                              layer
                                            )
                                          "
                                        />
                                      </div>

                                      <span class="p-2" v-else>
                                        {{ layer.amount }}
                                      </span>
                                    </CTableDataCell>
                                  </CTableRow>
                                </template>
                                <template
                                  v-for="(
                                    printPatternDescriptor, ppdIndex
                                  ) in tissuePaving.printPatterns.slice(
                                    1,
                                    tissuePaving.printPatterns.length
                                  )"
                                  :key="ppdIndex"
                                >
                                  <CTableRow align="middle">
                                    <CTableDataCell>
                                      <div class="d-flex">
                                        <div
                                          class="me-2"
                                          v-if="
                                            printPatternDescriptor.printPattern
                                          "
                                        >
                                          <CImage
                                            class="t-col-sm-image"
                                            v-if="
                                              printPatternDescriptor
                                                .printPattern.images.length > 0
                                            "
                                            :src="
                                              printPatternDescriptor
                                                .printPattern.images[0]?.tiny
                                            "
                                            @click="
                                              openLightbox(
                                                printPatternDescriptor
                                                  .printPattern.images
                                              )
                                            "
                                          >
                                          </CImage>
                                          <CIcon
                                            v-else
                                            icon="cilImage"
                                            size="lg"
                                          ></CIcon>
                                        </div>
                                        <span>
                                          {{
                                            printPatternDescriptor.printPattern
                                              ? `${
                                                  printPatternDescriptor
                                                    .printPattern.name
                                                }
                                                                                    (${getElementName(
                                                                                      printPatternDescriptor
                                                                                        .layersPerColor[0]
                                                                                        .color
                                                                                    )})`
                                              : getElementName(
                                                  printPatternDescriptor
                                                    .layersPerColor[0].color
                                                )
                                          }}
                                        </span>
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell
                                      class="p-0 text-end position-relative"
                                    >
                                      <div
                                        v-if="creation"
                                        class="
                                          position-absolute
                                          top-0
                                          bottom-0
                                          start-0
                                          end-0
                                          fs-4
                                        "
                                      >
                                        <input
                                          class="
                                            border-0
                                            p-1
                                            w-100
                                            h-100
                                            text-end
                                          "
                                          v-model="
                                            printPatternDescriptor
                                              .layersPerColor[0].amount
                                          "
                                          type="number"
                                          @input="
                                            onInputLayers(
                                              $event,
                                              plan,
                                              tissuePaving,
                                              printPatternDescriptor,
                                              layersPerColor[0]
                                            )
                                          "
                                        />
                                      </div>

                                      <span class="p-2" v-else>
                                        {{
                                          printPatternDescriptor
                                            .layersPerColor[0].amount
                                        }}
                                      </span>
                                    </CTableDataCell>
                                  </CTableRow>
                                  <template
                                    v-for="(
                                      layer, lIndex
                                    ) in printPatternDescriptor.layersPerColor.slice(
                                      1,
                                      printPatternDescriptor.layersPerColor
                                        .length
                                    )"
                                    :key="lIndex"
                                  >
                                    <CTableRow align="middle">
                                      <CTableDataCell>
                                        <div class="d-flex">
                                          <div
                                            class="me-2"
                                            v-if="
                                              printPatternDescriptor.printPattern
                                            "
                                          >
                                            <CImage
                                              class="t-col-sm-image"
                                              v-if="
                                                printPatternDescriptor
                                                  .printPattern.images.length >
                                                0
                                              "
                                              :src="
                                                printPatternDescriptor
                                                  .printPattern.images[0]?.tiny
                                              "
                                              @click="
                                                openLightbox(
                                                  printPatternDescriptor
                                                    .printPattern.images
                                                )
                                              "
                                            >
                                            </CImage>
                                            <CIcon
                                              v-else
                                              icon="cilImage"
                                              size="lg"
                                            ></CIcon>
                                          </div>
                                          <span>
                                            {{
                                              printPatternDescriptor.printPattern
                                                ? `${
                                                    printPatternDescriptor
                                                      .printPattern.name
                                                  }
                                                                                        (${getElementName(
                                                                                          layer.color
                                                                                        )})`
                                                : getElementName(layer.color)
                                            }}
                                          </span>
                                        </div>
                                      </CTableDataCell>
                                      <CTableDataCell
                                        class="p-0 text-end position-relative"
                                      >
                                        <div
                                          v-if="creation"
                                          class="
                                            position-absolute
                                            top-0
                                            bottom-0
                                            start-0
                                            end-0
                                            fs-4
                                          "
                                        >
                                          <input
                                            class="
                                              border-0
                                              p-1
                                              w-100
                                              h-100
                                              text-end
                                            "
                                            v-model="layer.amount"
                                            type="number"
                                            @input="
                                              onInputLayers(
                                                $event,
                                                plan,
                                                tissuePaving,
                                                printPatternDescriptor,
                                                layer
                                              )
                                            "
                                          />
                                        </div>

                                        <span class="p-2" v-else>
                                          {{ layer.amount }}
                                        </span>
                                      </CTableDataCell>
                                    </CTableRow>
                                  </template>
                                </template>
                              </template>
                            </CTableBody>
                          </CTable>
                        </div>
                      </div>
                    </template>
                  </CCollapse>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CRow>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script>
import {
    computed, ref, toRaw, watch,
} from "vue";
import { useI18n } from "vue-i18n";
import BaseLightbox from "@/components/base/BaseLightbox";
import TagChangeModal from "@/components/modals/TagChangeModal";
import ProductPopover from "@/components/popovers/ProductPopover";
import { user } from "@/user";
import { useStore } from "vuex";
import AppLoadingScreen from "@/components/AppLoadingScreen";
import {
    doubleInputNormalization,
    getTissueDescriptorUseColor,
} from "@/views/utilities";

export default {
    name: "CropPlanCreateModal",
    components: {
        ProductPopover,
        BaseLightbox,
        TagChangeModal,
        AppLoadingScreen,
    },
    emits: [ "update:isVisible", "change", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        productionId: {
            type: Number,
        },
        isGlobal: {
            type: Boolean,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const isLoading = ref(true);
        const store = useStore();
        const creation = ref(true);
        const isModelInfoCollapseVisible = ref(false);
        const isTissueInfoCollapseVisible = ref(true);
        const isProductionInfoCollapseVisible = ref(true);
        const isCutInfoCollapseVisible = ref(true);
        const production = ref(null);
        const groupByProducts = ref([]);
        const sizes = ref([]);
        const colors = ref([]);
        const tags = ref([]);
        const groupByTissues = ref([]);
        const tissues = ref([]);
        const groupByTemplates = ref([]);
        const groupByTemplatesAndTissues = ref([]);
        const productionDetails = ref([]);
        const productionPlan = ref([]);
        const groupByTissueRank = ref([]);
        const expandProductionDetailsAmount = ref(false);
        const selectedOrder = ref(null);
        const isTagPickerVisible = ref(false);
        const hasAmountsPerLayer = ref(false);

        // ui
        const expandUnionProductionAmount = ref(false);
        const isConfirmButtonLoading = ref(false);
        function closeModal () {
            emptyAll();
            if (!props.isGlobal) {
                emit("update:isVisible", false);
            }
            else {
                store.commit("closeCreateCropPlanModal");
            }
        }
        function emptyAll () {
            groupByProducts.value = [];
            groupByTemplates.value = [];
            groupByTemplatesAndTissues.value = [];
            productionDetails.value = [];
            productionPlan.value = [];
            groupByTissueRank.value = [];
            isCutInfoCollapseVisible.value = true;
            isTissueInfoCollapseVisible.value = true;
            isModelInfoCollapseVisible.value = false;
            isLoading.value = true;
            isTagPickerVisible.value = false;
            hasAmountsPerLayer.value = false;
            selectedOrder.value = null;
            expandProductionDetailsAmount.value = false;
        }

        // lightbox
        const isLightboxVisible = ref(false);
        const imagesBase64 = ref([]);
        const lightboxImages = ref([]);
        const selectedImageIndex = ref(0);
        function openLightbox (images) {
            selectedImageIndex.value = 0;
            lightboxImages.value = images.map((i) => i.compress);
            isLightboxVisible.value = true;
        }
        function closeLightbox () {
            lightboxImages.value = [];
            isLightboxVisible.value = false;
        }

        const allProductsSizes = computed(() => {
            let allSizes = [];

            groupByProducts.value.forEach((p) => {
                p.amounts.forEach((a) => {
                    const size = allSizes.find((s) => s.id === a.sizeId);

                    if (!size) {
                        allSizes = [ ...allSizes, getSize(a.sizeId), ];
                    }
                });
            });

            return allSizes;
        });

        const arrows = computed(() => ({
            modelInfo: isModelInfoCollapseVisible.value ? "arrow-up" : "arrow-down",
            tissueInfo: isTissueInfoCollapseVisible.value ? "arrow-up" : "arrow-down",
            productionInfo: isProductionInfoCollapseVisible.value
                ? "arrow-up"
                : "arrow-down",
            cutInfo: isCutInfoCollapseVisible.value ? "arrow-up" : "arrow-down",
        }));

        const activeProductionTab = ref(1);

        function getSize (sizeId) {
            const size = sizes.value.find((s) => s.id === sizeId);

            if (size) {
                return size;
            }

            return {};
        }

        function getColor (colorId) {
            const color = colors.value.find((c) => c.id === colorId);

            if (color) {
                return color;
            }

            return {};
        }

        function getAmountsColors (amounts) {
            let amountColors = [];

            amounts.forEach((a) => {
                const color = amountColors.find((c) => c.id === a.colorId);

                if (!color) {
                    amountColors = [ ...amountColors, getColor(a.colorId), ];
                }
            });

            return amountColors;
        }

        function getCombinationDescriptorSizes (descriptor) {
            let sizes = [];

            descriptor.forEach((p) => {
                p.colors.forEach((c) => {
                    sizes = [ ...sizes, ...p.sizes, ];
                });
            });

            return sizes;
        }

        function getTemplateSizes (productTemplateId) {
            let sizes = [];

            groupByTemplatesAndTissues.value
                .find((pt) => String(pt.productTemplateId) === String(productTemplateId))
                .combinations.forEach((c) => {
                    sizes = [ ...sizes, ...getCombinationDescriptorSizes(c.descriptor), ];
                });

            return sizes;
        }

        function getTotalTemplateSizes () {
            let sizes = [];

            groupByTemplatesAndTissues.value.forEach((g) => {
                sizes = [ ...sizes, ...getTemplateSizes(g.productTemplateId), ];
            });

            return sizes;
        }

        function getTotalAmountsColors (productTemplateId) {
            let totalColors = [];

            groupByTemplates.value
                .find((pt) => String(pt.productTemplateId) === String(productTemplateId))
                ?.products.forEach((p) => {
                    totalColors = [ ...totalColors, ...p.colors, ];
                });

            return totalColors;
        }

        function getTotalBySize (sizeId) {
            let total = 0;

            groupByProducts.value.forEach((p) => {
                p.amounts.forEach((a) => {
                    if (a.sizeId === sizeId) {
                        total += a.amount;
                    }
                });
            });

            return total;
        }

        function getAmount (amounts, colorId, sizeId) {
            const amount = amounts.find((a) => a.colorId === colorId && a.sizeId === sizeId);

            if (amount) {
                return amount.amount;
            }

            return "0";
        }

        function close () {
            emit("update:isVisible", false);
        }

        function toggleModelInfoCollapse () {
            isModelInfoCollapseVisible.value = !isModelInfoCollapseVisible.value;
        }

        function toggleTissueInfoCollapse () {
            isTissueInfoCollapseVisible.value = !isTissueInfoCollapseVisible.value;
        }

        function toggleProductionInfoCollapse () {
            isProductionInfoCollapseVisible.value =
        !isProductionInfoCollapseVisible.value;
        }

        function toggleCutInfoCollapse () {
            isCutInfoCollapseVisible.value = !isCutInfoCollapseVisible.value;
        }

        function getTissueColorRule (tissue) {
            if (tissue.tissue.category.name === "generic.whiteEmbryo") {
                return t("generic.whiteEmbryo");
            }
            if (tissue.tissueUsePreorderColor) {
                return t("generic.usePreorderColor");
            }
            if (tissue.tissueColorId !== null) {
                const color = colors.value.find((c) => c.id === tissue.tissueColorId);

                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
            }
        }

        function getElementName (element) {
            if (element.isDefault) {
                return t(element.name);
            }

            return element.name;
        }

        function getPrintPatternColorRule (tissueDescriptor) {
            if (tissueDescriptor.printPatternUsePreorderColor) {
                return t("generic.usePreorderColor");
            }
            if (tissueDescriptor.printPatternNoColor) {
                return t("generic.noColor");
            }
            if (tissueDescriptor.printPatternColorId !== null) {
                const color = colors.value.find((c) => c.id === tissueDescriptor.printPatternColorId);

                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
            }
        }

        function getPrintPatternColorValue (selectedTissue, rowColor) {
            if (selectedTissue.printPatternUsePreorderColor) {
                return rowColor;
            }
            else if (selectedTissue.printPatternNoColor) {
                return "-";
            }
            else if (selectedTissue.printPatternColorId !== null) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) ===
          Number.parseInt(selectedTissue.printPatternColorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR ID";
            }
            return "ERROR NO MATCH";
        }

        function getTissueColorValue (selectedTissue, rowColor) {
            if (selectedTissue.tissue.category.name === t("color.whiteEmbryo")) {
                return t("color.whiteEmbryo");
            }
            else if (selectedTissue.tissueUsePreorderColor) {
                return rowColor;
            }
            else if (selectedTissue.tissueColorId !== null) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) ===
          Number.parseInt(selectedTissue.tissueColorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR";
            }
            // console.log(selectedTissue);
            return "ERROR NO MATCH";
        }

        function getTissueCombinationColor (tissueDescriptors, rawColor) {
            let str = "";
            for (const [ i, tissueDescriptor, ] of tissueDescriptors.entries()) {
                if (tissueDescriptor.printPattern) {
                    str += String(tissueDescriptor.printPattern.name);
                    str += ` (${getElementName(getTissueDescriptorUseColor(colors.value, tissueDescriptor, rawColor))}) `;
                }
                else {
                    str += `${getElementName(getTissueDescriptorUseColor(colors.value, tissueDescriptor, rawColor))} `;
                }
                if (i < tissueDescriptors.length - 1) {
                    str += " + ";
                }
            }

            return str;
        }

        function getTotalTemplateCombinationColors (productTemplateId) {
            let colors = [];

            groupByTemplatesAndTissues.value
                .find((p) => p.productTemplateId === productTemplateId)
                .combinations?.forEach((c) => {
                    colors = [ ...colors, ...getTotalCombinationColors(c), ];
                });

            return colors;
        }

        function getTotalCombinationColors (combination) {
            let colors = [];

            combination.descriptor.forEach((d) => {
                colors = [ ...colors, ...d.colors, ];
            });

            return colors;
        }

        function isEqualCombination (c1, c2) {
            if (c1.length !== c2.length) {
                return false;
            }
            for (let i = 0; i < c1.length; i++) {
                if (c1[i] !== c2[i]) {
                    return false;
                }
            }
            return true;
        }

        function onInputAmountsPerLayers (event,
            currentProductionPlan,
            patternDescriptor,
            amountDescriptor) {
            if (event.target.value.length > 0) {
                amountDescriptor.amount = event.target.value;
                for (const plan of productionPlan.value) {
                    for (const descriptor of plan.tissuePavingDescriptor) {
                        for (const printPatternDescriptor of descriptor.printPatterns) {
                            for (const pattern of plan.patternDescriptor) {
                                for (const amountPerLayer of pattern.amountsPerLayers) {
                                    // Make sure to get every subtotal of ppt's color amount
                                    for (const layer of printPatternDescriptor.layersPerColor) {
                                        const patternTotalAmounts = amountPerLayer.amount?.length === 0 ? 0 : doubleInputNormalization(amountPerLayer.amount);
                                        const amountByColor = printPatternDescriptor.descriptors.flatMap((d) => d.amounts
                                            .filter((a) => a.sizeId === amountPerLayer.size.id
                                                && getTissueDescriptorUseColor(colors.value, d, getColor(a.colorId)).name === layer.color.name
                                                && d.productTemplateId === pattern.productTemplateId))
                                            .reduce((total, a) => total + (Number.isFinite(a.amount) ? a.amount : 0), 0);
                                        const amount = Math.ceil(amountByColor / patternTotalAmounts);
                                        layer.amount = Number.isFinite(amount) ? amount : 0;
                                    }
                                }
                            }
                        }
                    }
                }
                calculateProduced();
            }
        }

        function onInputLayers (
            event,
            productionPlan,
            tissuePavingDescriptor,
            printPattern,
            amountDescriptor
        ) {
            if (event.target.value.length > 0) {
                amountDescriptor.amount = event.target.value;
                calculateProduced();
            }
        }

        // eslint-disable-next-line complexity
        function initCroppedAmounts () {
            // console.log(productionPlan.value);
            for (const plan of productionPlan.value) {
                // console.log("current tissue rank: ", plan.code);
                for (const pattern of plan.patternDescriptor) {
                    // console.log("current product template id: ", pattern.productTemplateId);
                    let total = 0;
                    for (const tissuePavingDescriptor of plan.tissuePavingDescriptor) {
                        // console.log("current tissue: ", tissuePavingDescriptor.tissue.name);
                        for (const printPatternDescriptor of tissuePavingDescriptor.printPatterns) {
                            // console.log("current print pattern: ", printPatternDescriptor.printPattern?.name);
                            for (const layer of printPatternDescriptor.layersPerColor) {
                                for (const amountPerLayer of pattern.amountsPerLayers) {
                                    // console.log("current color: ", layer.color.name, " current size: ", amountPerLayer.size.name);
                                    const colorAmount = layer.amount?.length === 0 ? 0 : Number.parseInt(layer.amount, 10);
                                    const sizeAmount = amountPerLayer.amount?.length === 0 ? 0 : doubleInputNormalization(amountPerLayer.amount);
                                    const produced = colorAmount * sizeAmount;
                                    const detail = getDetail(
                                        tissuePavingDescriptor.tissue, printPatternDescriptor.printPattern, pattern.productTemplateId, amountPerLayer.size.id, layer.color.id, plan.code - 1
                                    );
                                    if (detail) {
                                        detail.producedAmount = detail?.amount ?? 0;
                                        total += produced;
                                        detail.usedAmount = 0;
                                    }
                                    // console.log("next size");
                                }
                                // console.log("next color");
                            }
                            // console.log("next print pattern");
                        }
                        // console.log("next tissue");
                    }
                    pattern.totalAmounts = total;
                    // console.log(pattern.totalAmounts);
                    // console.log("next product template");
                }
                // console.log("next tissue rank");
            }

            for (const productRequest of groupByProducts.value) {
                productRequest.amounts.forEach((amountDescriptor) => {
                    amountDescriptor.croppedAmount = 0;
                });
            }
            let distributable = groupByProducts.value.length;
            do {
                distributable = groupByProducts.value.length;
                for (const productRequest of groupByProducts.value) {
                    let amountDistributable = productRequest.amounts.length;
                    productRequest.amounts.forEach((amountDescriptor) => {
                        const ingredients = [];
                        productRequest.product.tissues.forEach((tissueDescriptor, rank) => {
                            ingredients.push(getDetail(
                                tissueDescriptor.tissue, tissueDescriptor.printPattern, productRequest.product.productTemplateId, amountDescriptor.sizeId, getTissueDescriptorUseColor(colors.value, tissueDescriptor, getColor(amountDescriptor.colorId)).id, rank
                            ));
                        });
                        const hasAllIngredients = ingredients.every((d) => d.producedAmount >= d.usedAmount + 1);
                        // console.log(hasAllIngredients);
                        if (hasAllIngredients) {
                            ingredients.forEach((d) => {
                                d.usedAmount++;
                            });
                            amountDescriptor.croppedAmount += 1;
                        }
                        else {
                            amountDistributable--;
                        }
                        if (amountDistributable === 0) {
                            distributable--;
                        }
                    });
                }
            } while (distributable > 0);
        }
        // eslint-disable-next-line complexity
        function calculateProduced () {
            // console.log(productionPlan.value);
            for (const plan of productionPlan.value) {
                // console.log("current tissue rank: ", plan.code);
                for (const pattern of plan.patternDescriptor) {
                    // console.log("current product template id: ", pattern.productTemplateId);
                    let total = 0;
                    for (const tissuePavingDescriptor of plan.tissuePavingDescriptor) {
                        // console.log("current tissue: ", tissuePavingDescriptor.tissue.name);
                        for (const printPatternDescriptor of tissuePavingDescriptor.printPatterns) {
                            // console.log("current print pattern: ", printPatternDescriptor.printPattern?.name);
                            for (const layer of printPatternDescriptor.layersPerColor) {
                                for (const amountPerLayer of pattern.amountsPerLayers) {
                                    // console.log("current color: ", layer.color.name, " current size: ", amountPerLayer.size.name);
                                    const colorAmount = layer.amount?.length === 0 ? 0 : Number.parseInt(layer.amount, 10);
                                    const sizeAmount = amountPerLayer.amount?.length === 0 ? 0 : doubleInputNormalization(amountPerLayer.amount);
                                    const produced = colorAmount * sizeAmount;
                                    const detail = getDetail(
                                        tissuePavingDescriptor.tissue, printPatternDescriptor.printPattern, pattern.productTemplateId, amountPerLayer.size.id, layer.color.id, plan.code - 1
                                    );
                                    if (detail && layer.amount && amountPerLayer.amount) {
                                        detail.producedAmount = produced;
                                        total += produced;
                                        detail.usedAmount = 0;
                                    }
                                    else if (detail) {
                                        detail.producedAmount = detail?.amount ?? 0;
                                        detail.usedAmount = 0;
                                    }
                                    // console.log("next size");
                                }
                                // console.log("next color");
                            }
                            // console.log("next print pattern");
                        }
                        // console.log("next tissue");
                    }
                    pattern.totalAmounts = total;
                    // console.log(pattern.totalAmounts);
                    // console.log("next product template");
                }
                // console.log("next tissue rank");
            }

            for (const productRequest of groupByProducts.value) {
                productRequest.amounts.forEach((amountDescriptor) => {
                    amountDescriptor.croppedAmount = 0;
                });
            }
            let distributable = groupByProducts.value.length;
            do {
                distributable = groupByProducts.value.length;
                for (const productRequest of groupByProducts.value) {
                    let amountDistributable = productRequest.amounts.length;
                    productRequest.amounts.forEach((amountDescriptor) => {
                        const ingredients = [];
                        productRequest.product.tissues.forEach((tissueDescriptor, rank) => {
                            ingredients.push(getDetail(
                                tissueDescriptor.tissue, tissueDescriptor.printPattern, productRequest.product.productTemplateId, amountDescriptor.sizeId, getTissueDescriptorUseColor(colors.value, tissueDescriptor, getColor(amountDescriptor.colorId)).id, rank
                            ));
                        });
                        const hasAllIngredients = ingredients.every((d) => d.producedAmount >= d.usedAmount + 1);
                        // console.log(hasAllIngredients);
                        if (hasAllIngredients) {
                            ingredients.forEach((d) => {
                                d.usedAmount++;
                            });
                            amountDescriptor.croppedAmount += 1;
                        }
                        else {
                            amountDistributable--;
                        }
                        if (amountDistributable === 0) {
                            distributable--;
                        }
                    });
                }
            } while (distributable > 0);
            // console.log(groupByProducts.value);
        }

        function getDetail (
            tissue, printPattern, productTemplateId, sizeId, colorId, rank
        ) {
            return productionDetails.value.find((g) => g.productTemplateId === productTemplateId &&
                g.size.id === sizeId &&
                g.colorOrPrintPattern.color.id === colorId &&
                g.tissue.name === tissue.name &&
                g.colorOrPrintPattern.printPattern?.name ===
                printPattern?.name &&
                g.patternCode === rank + 1);
        }

        function getProductDeviationBySize (sizeId) {
            let total = 0;
            let preorderTotal = 0;

            for (const productDescriptor of groupByProducts.value) {
                for (const color of productDescriptor.colors) {
                    total += Number(getProductProducedAmount(productDescriptor, color.id, sizeId));
                }
                preorderTotal += productDescriptor.amounts
                    .filter((a) => a.sizeId === sizeId)
                    .reduce((subtotal, a) => a.amount + subtotal, 0);
            }

            return preorderTotal !== total
                ? (total > preorderTotal ? "+" : "-") + Math.abs(total - preorderTotal)
                : "";
        }

        function getProducedTotalBySize (sizeId) {
            let total = 0;

            for (const productDescriptor of groupByProducts.value) {
                for (const color of productDescriptor.colors) {
                    total += Number(getProductProducedAmount(productDescriptor, color.id, sizeId));
                }
            }

            return total;
        }

        function getProductDeviation (productDescriptor, colorId, sizeId) {
            const product = groupByProducts.value.find((p) => p.product.id === productDescriptor.product.id);
            const amount = product.amounts.find((a) => a.colorId === colorId && a.sizeId === sizeId);
            const diff = amount ? amount.croppedAmount - amount.amount : 0;
            return diff !== 0 ? (diff > 0 ? "+" : "-") + Math.abs(diff) : "";
        }

        function getProductProducedAmount (productDescriptor, colorId, sizeId) {
            const product = groupByProducts.value.find((p) => p.product.id === productDescriptor.product.id);
            const amount = product.amounts.find((a) => a.colorId === colorId && a.sizeId === sizeId);
            return amount?.croppedAmount ?? 0;
        }

        function getProductPreorderAmount (productDescriptor, colorId, sizeId) {
            const product = groupByProducts.value.find((p) => p.product.id === productDescriptor.product.id);
            const amount = product.amounts.find((a) => a.colorId === colorId && a.sizeId === sizeId);
            return amount?.amount ?? 0;
        }

        function getProductWastedAmount () {
            let wasted = 0;
            for (const d of productionDetails.value) {
                const wastedAmount = d.producedAmount - d.usedAmount;
                wasted += wastedAmount;
            }
            return Number.isNaN(wasted) ? 0 : wasted;
        }

        // function getPreorderAmount (detail) {
        //     for (const plan of productionPlan.value) {
        //         if (plan.code === detail.tissueCode) {
        //             for (const pd of plan.patternDescriptor) {
        //                 if (pd.productTemplateId === detail.productTemplateId) {
        //                     for (const tpd of plan.tissuePavingDescriptor) {
        //                         if (tpd.tissue.name === detail.tissue.name) {
        //                             for (const ppd of tpd.printPatterns) {
        //                                 if (
        //                                     ppd.printPattern?.name ===
        //               detail.colorOrPrintPattern.printPattern?.name
        //                                 ) {
        //                                     return ppd.preorderAmounts
        //                                         .filter((a) => a.productTemplateId === pd.productTemplateId &&
        //                   a.colorId === detail.colorOrPrintPattern.color.id &&
        //                   a.sizeId === detail.size.id)
        //                                         .reduce((total, a) => total + a.amount, 0);
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     return "0";
        // }

        function getAmountPerLayer (detail) {
            for (const plan of productionPlan.value) {
                if (plan.code === detail.tissueCode) {
                    for (const pd of plan.patternDescriptor) {
                        if (pd.productTemplateId === detail.productTemplateId) {
                            for (const tpd of plan.tissuePavingDescriptor) {
                                if (tpd.tissue.name === detail.tissue.name) {
                                    for (const ppd of tpd.printPatterns) {
                                        if (
                                            ppd.printPattern?.name ===
                      detail.colorOrPrintPattern.printPattern?.name
                                        ) {
                                            for (const apl of pd.amountsPerLayers) {
                                                if (apl.size.id === detail.size.id) {
                                                    return !Number.isFinite(apl.amount) &&
                            Number.isNaN(apl.amount)
                                                        ? ""
                                                        : apl.amount;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        function getLayer (detail) {
            let total = 0;
            for (const plan of productionPlan.value) {
                if (plan.code === detail.tissueCode) {
                    for (const pd of plan.patternDescriptor) {
                        if (pd.productTemplateId === detail.productTemplateId) {
                            for (const tpd of plan.tissuePavingDescriptor) {
                                if (tpd.tissue.name === detail.tissue.name) {
                                    for (const ppd of tpd.printPatterns) {
                                        if (
                                            ppd.printPattern?.name ===
                      detail.colorOrPrintPattern.printPattern?.name
                                        ) {
                                            for (const lpc of ppd.layersPerColor) {
                                                if (
                                                    lpc.color.id === detail.colorOrPrintPattern.color.id
                                                ) {
                                                    total +=
                            !Number.isFinite(lpc.amount) &&
                              Number.isNaN(lpc.amount)
                                ? ""
                                : lpc.amount;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return !Number.isFinite(total) && Number.isNaN(total)
                ? ""
                : Number.parseInt(total, 10);
        }

        function getDeviation (detail) {
            const producedAmount = getProducedAmount(detail);
            const preorderAmount = detail.amount;
            if (producedAmount === "" || !hasAmountsPerLayer.value) {
                return "";
            }
            return (
                (producedAmount >= preorderAmount ? "+" : "-") +
        Math.abs(preorderAmount - producedAmount)
            );
        }

        function getWastedAmount (detail) {
            return detail.producedAmount - detail.usedAmount;
        }

        function getDeviationPercentage (detail) {
            const producedAmount = getProducedAmount(detail);
            const preorderAmount = detail.amount;
            if (preorderAmount === 0) {
                return "+∞";
            }
            if (producedAmount === "" || !hasAmountsPerLayer.value) {
                return "";
            }
            return (
                (producedAmount >= preorderAmount ? "+" : "-") +
        (
            Math.abs(preorderAmount - producedAmount) / preorderAmount *
          100
        ).toFixed(3)
            );
        }

        function getProducedAmount (detail) {
            if (detail.producedAmount === 0) {
                const layer = getLayer(detail);
                const amountPerLayer = getAmountPerLayer(detail);
                if (!hasAmountsPerLayer.value) {
                    return detail.amount;
                }
                if (layer === "" || amountPerLayer === "") {
                    return "";
                }
                return getLayer(detail) * getAmountPerLayer(detail);
            }
            return detail.producedAmount;
        }

        function getCombinationText (product) {
            const tissuesCombination = [];
            for (let i = 0; i < product.tissues.length; i++) {
                const tis = product.tissues[i];
                let tissueCombination = `TS${i + 1}: ${tis.tissue.name} (${t(tis.tissue.category.name)})`;
                if (tis.printPattern) {
                    tissueCombination += `【${t("generic.hasPrintPattern")}】`;
                }
                tissuesCombination.push(tissueCombination);
            }
            return tissuesCombination;
        }

        function openProductionTagPicker () {
            selectedOrder.value = {
                ids: production.value.products.map((p) => p.id),
                orderIds: [],
                productionIds: [ production.value.id, ],

                selectedOrderTags: [],
                selectedProductionTags: production.value.tags ?? [],

                orderTags: undefined,
                productionTags: tags.value,

                urgent: production.value.urgent,
                customerReminder: production.value.customerReminder,
            };

            isTagPickerVisible.value = true;
        }

        // Only White embryo
        function isOnlyWhiteEmbryo (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
        tissueDescriptor.printPattern === null
            );
        }

        // White embryo with print pattern
        function isWhiteEmbryoWithPrintPatternUsePreorderColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.printPatternUsePreorderColor
            );
        }

        function isWhiteEmbryoWithPrintPatternFixedColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.printPatternColorId !== null
            );
        }

        function isWhiteEmbryoWithPrintPatternNoColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.printPatternNoColor
            );
        }

        function isWhiteEmbryoWithPrintPatternUsePreorderColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
        tissueDescriptor.printPattern !== null
            );
        }
        // Only Yarn Dyed Fabric
        function isOnlyYarnDyedFabricUsePreorderColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern === null &&
        tissueDescriptor.tissueUsePreorderColor
            );
        }
        function isOnlyYarnDyedFabricFixedColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern === null &&
        tissueDescriptor.tissueColorId !== null
            );
        }
        // Yarn Dyed Fabric with print pattern
        function isYarnDyedFabricUsePreorderColorWithPrintPatternUsePreorderColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.tissueUsePreorderColor &&
        tissueDescriptor.printPatternUsePreorderColor
            );
        }
        function isYarnDyedFabricUsePreorderColorWithPrintPatternNoColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.tissueUsePreorderColor &&
        tissueDescriptor.printPatternNoColor
            );
        }
        function isYarnDyedFabricUsePreorderColorWithPrintPatternFixedColorId (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern === null &&
        tissueDescriptor.tissueUsePreorderColor &&
        tissueDescriptor.printPatternColorId
            );
        }
        function isYarnDyedFabricFixedColorWithPrintPatternUsePreorderColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.tissueColorId !== null &&
        tissueDescriptor.printPatternUsePreorderColor
            );
        }
        function isYarnDyedFabricFixedColorWithPrintPatternNoColor (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern !== null &&
        tissueDescriptor.tissueColorId !== null &&
        tissueDescriptor.printPatternNoColor
            );
        }
        function isYarnDyedFabricFixedColorWithPrintPatternFixedColorId (tissueDescriptor) {
            return (
                tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
        tissueDescriptor.printPattern === null &&
        tissueDescriptor.tissueColorId !== null &&
        tissueDescriptor.printPatternColorId
            );
        }

        function getPatternDescriptorSizes (patternDescriptor) {
            if (patternDescriptor) {
                return patternDescriptor.reduce((array, pd) => {
                    pd.amountsPerLayers.forEach((apy) => {
                        const exist = array.find((a) => a.size.id === apy.size.id);
                        if (!exist) {
                            array.push(apy);
                        }
                    });
                    return array;
                }, []);
            }
            return 0;
        }

        async function changeTags ({ productionTags, urgent, }) {
            await initTags();
            production.value.tags = productionTags.map((id) => tags.value.find((t) => t.id === id));
            production.value.urgent = urgent;
        }

        async function initTags () {
            tags.value = await user.getAllProductionTags();
        }

        // eslint-disable-next-line complexity
        async function init (id) {
            hasAmountsPerLayer.value = true;
            colors.value = await user.getAllColors();
            sizes.value = await user.getAllSizes();
            await initTags();
            production.value = await user.getProductionById({
                id,
            });

            // initialize to avoid crash for color and size combination inexisting
            production.value.products.forEach((p) => {
                for (const c of p.colors) {
                    for (const s of p.sizes) {
                        if (
                            p.amounts.findIndex((a) => a.colorId === c.id && a.sizeId === s.id) === -1
                        ) {
                            p.amounts.push({
                                sizeId: s.id,
                                colorId: c.id,
                                amount: 0,
                                croppedAmount: 0,
                            });
                        }
                    }
                }
            });

            const productionCopy1 = JSON.parse(JSON.stringify(production.value));
            // console.log("production products ", production.value.products);
            groupByProducts.value = productionCopy1.products.reduce((group, p) => {
                if (group) {
                    const existProduct = group.find((g) => g.product.name === p.product.name &&
            g.product.productTemplateId === p.product.productTemplateId);
                    if (!existProduct) {
                        group.push({
                            ...p,
                        });
                    }
                    else {
                        const temp = [];
                        for (const a of p.amounts) {
                            const existAmount = existProduct.amounts.find((ep) => ep.sizeId === a.sizeId && ep.colorId === a.colorId);
                            if (existAmount) {
                                existAmount.amount += a.amount;
                            }
                            else {
                                temp.push(a);
                                const color = p.colors.find((c) => c.id === a.colorId);
                                const size = p.sizes.find((c) => c.id === a.sizeId);
                                if (
                                    existProduct.colors.findIndex((ep) => ep.id === a.colorId) ===
                  -1
                                ) {
                                    existProduct.colors = [ ...existProduct.colors, color, ];
                                }
                                if (
                                    existProduct.sizes.findIndex((ep) => ep.id === a.sizeId) ===
                  -1
                                ) {
                                    existProduct.sizes = [ ...existProduct.sizes, size, ];
                                }
                                existProduct.colors.sort((c1, c2) => colors.value.findIndex((c) => c.id === c1.id) - colors.value.findIndex((c) => c.id === c2.id));
                                existProduct.sizes.sort((s1, s2) => s1.id - s2.id);
                            }
                        }
                        existProduct.amounts = [ ...existProduct.amounts, ...temp, ];
                    }
                }
                return group;
            }, []);

            // console.log("group by products", groupByProducts.value);

            const productionCopy2 = JSON.parse(JSON.stringify(production.value));
            groupByTissues.value = productionCopy2.products.reduce((group, p) => {
                p.product.tissues.forEach((t) => {
                    const tissueDescriptor = group.find((g) => g.tissue.name === t.tissue.name &&
            t.tissue.category.name === g.tissue.category.name);
                    if (!tissueDescriptor) {
                        group.push({
                            tissue: t.tissue,
                            printPatterns: [ {
                                printPattern: t.printPattern,
                                descriptor: t,
                                product: p,
                            }, ],
                        });
                    }
                    else {
                        const printPattern = tissueDescriptor.printPatterns.find((pp) => pp.printPattern?.name === t.printPattern?.name);
                        if (!printPattern) {
                            tissueDescriptor.printPatterns.push({
                                printPattern: t.printPattern,
                                descriptor: t,
                                product: p,
                            });
                        }
                    }
                });
                return group;
            }, []);

            // console.log("group by tissue", groupByTissues.value);

            const groupByProductsCopy1 = JSON.parse(JSON.stringify(groupByProducts.value));
            groupByTemplates.value = groupByProductsCopy1.reduce((group, p) => {
                const existProductTemplate = group.find((g) => g.productTemplateId === p.product.productTemplateId);
                if (!existProductTemplate) {
                    group.push({
                        productTemplateId: p.product.productTemplateId,
                        products: [ toRaw(p), ],
                    });
                }
                else {
                    existProductTemplate.products = [ ...existProductTemplate.products, toRaw(p), ];
                }
                return group;
            }, []);

            const groupByProductsCopy2 = JSON.parse(JSON.stringify(groupByProducts.value));
            groupByTemplatesAndTissues.value = groupByProductsCopy2.reduce((group, p) => {
                const amountsCopy = JSON.parse(JSON.stringify(p.amounts));
                const existProductTemplate = group.find((g) => g.productTemplateId === p.product.productTemplateId);
                const tissuesCombination = [];
                for (let i = 0; i < p.product.tissues.length; i++) {
                    const tis = p.product.tissues[i];
                    let tissueCombination = `TS${i + 1}: ${tis.tissue.name} (${t(tis.tissue.category.name)})`;
                    if (tis.printPattern) {
                        tissueCombination += `【${t("generic.hasPrintPattern")}】`;
                    }
                    tissuesCombination.push(tissueCombination);
                }
                if (!existProductTemplate) {
                    const temp = [];
                    for (const a of amountsCopy) {
                        const amountColor = colors.value.find((c) => c.id === a.colorId);
                        const colorCombination = getTissueCombinationColor(p.product.tissues,
                            amountColor);
                        const existAmount = temp.find((ep) => ep.sizeId === a.sizeId &&
              ep.colorCombination === colorCombination);
                        if (existAmount) {
                            existAmount.amount += a.amount;
                        }
                        else {
                            temp.push({
                                ...a,
                                colorCombination,
                            });
                        }
                    }
                    group.push({
                        productTemplateId: p.product.productTemplateId,
                        combinations: [ {
                            combination: tissuesCombination,
                            descriptor: [ p, ],
                            amounts: temp,
                        }, ],
                    });
                }
                else {
                    const combinationDescriptor =
            existProductTemplate.combinations.find((c) => {
                if (c.combination.length !== tissuesCombination.length) {
                    return false;
                }
                for (let i = 0; i < c.combination.length; i++) {
                    if (c.combination[i] !== tissuesCombination[i]) {
                        return false;
                    }
                }
                return true;
            });
                    if (combinationDescriptor) {
                        combinationDescriptor.descriptor = [ ...combinationDescriptor.descriptor, p, ];
                        const temp = [];
                        for (const a of amountsCopy) {
                            const amountColor = colors.value.find((c) => c.id === a.colorId);
                            const colorCombination = getTissueCombinationColor(p.product.tissues,
                                amountColor);
                            const existAmount = temp.find((ep) => ep.sizeId === a.sizeId &&
                ep.colorCombination === colorCombination);
                            if (existAmount) {
                                existAmount.amount += a.amount;
                            }
                            else {
                                temp.push({
                                    ...a,
                                    colorCombination,
                                });
                            }
                        }

                        const merged = [ ...combinationDescriptor.amounts, ...temp, ];
                        combinationDescriptor.amounts = merged.reduce((result, amount) => {
                            const exist = result.find((a) => amount.sizeId === a.sizeId && amount.colorCombination === a.colorCombination);
                            if (exist) {
                                exist.amount += amount.amount;
                            }
                            else {
                                result.push(amount);
                            }
                            return result;
                        }, []);
                    }
                    else {
                        existProductTemplate.combinations = [ ...existProductTemplate.combinations, {
                            combination: tissuesCombination,
                            descriptor: [ p, ],
                            amounts: amountsCopy,
                            producedAmountsByCombination: p.product.tissues.map((t) => ({
                                tissue: t.tissue,
                                printPattern: t.printPattern,
                                amounts: [],
                            })),
                        }, ];
                    }
                }
                return group;
            },
            []);

            // console.log("groupByTemplatesAndTissues ", groupByTemplatesAndTissues.value);

            const groupByProductsCopy3 = JSON.parse(JSON.stringify(groupByProducts.value));
            groupByTissueRank.value = groupByProductsCopy3.reduce((group, p) => {
                for (const [ index, t, ] of p.product.tissues.entries()) {
                    const amountsCopy = JSON.parse(JSON.stringify(p.amounts));
                    const tissueDescriptor = group.find((g) => g.index === index + 1 &&
            g.tissue.name === t.tissue.name &&
            g.productTemplateId === p.product.productTemplateId);
                    if (!tissueDescriptor) {
                        group.push({
                            index: index + 1,
                            productTemplateId: p.product.productTemplateId,
                            tissue: t.tissue,
                            tissueUsePreorderColor: t.tissueUsePreorderColor,
                            tissueColorId: t.tissueColorId,
                            printPatterns: [ {
                                printPattern: t.printPattern,
                                descriptor: t,
                                product: p,
                                amounts: amountsCopy,
                                colors: p.colors,
                                printPatternUsePreorderColor: t.printPatternUsePreorderColor,
                                printPatternColorId: t.printPatternColorId,
                                printPatternNoColor: t.printPatternNoColor,
                                tissueUsePreorderColor: t.tissueUsePreorderColor,
                                tissueColorId: t.tissueColorId,
                                sizes: p.sizes,
                            }, ],
                        });
                    }
                    else {
                        const printPattern = tissueDescriptor.printPatterns.find((pp) => pp.printPattern?.name === t.printPattern?.name);
                        if (!printPattern) {
                            tissueDescriptor.printPatterns.push({
                                printPatternColorRule: getPrintPatternColorRule(t),
                                printPattern: t.printPattern,
                                descriptor: t,
                                product: p,
                                amounts: amountsCopy,
                                colors: p.colors,
                                printPatternUsePreorderColor: t.printPatternUsePreorderColor,
                                printPatternColorId: t.printPatternColorId,
                                printPatternNoColor: t.printPatternNoColor,
                                tissueUsePreorderColor: t.tissueUsePreorderColor,
                                tissueColorId: t.tissueColorId,
                                sizes: p.sizes,
                            });
                        }
                        else {
                            const temp = [];
                            for (const a of amountsCopy) {
                                const existAmount = printPattern.amounts.find((ep) => ep.sizeId === a.sizeId && ep.colorId === a.colorId);
                                if (existAmount) {
                                    existAmount.amount += a.amount;
                                }
                                else {
                                    temp.push(a);
                                    const color = p.colors.find((c) => c.id === a.colorId);
                                    const size = p.sizes.find((c) => c.id === a.sizeId);
                                    if (
                                        printPattern.colors.findIndex((ep) => ep.id === a.colorId) === -1
                                    ) {
                                        printPattern.colors = [ ...printPattern.colors, color, ];
                                    }
                                    if (
                                        printPattern.sizes.findIndex((ep) => ep.id === a.sizeId) ===
                    -1
                                    ) {
                                        printPattern.sizes = [ ...printPattern.sizes, size, ];
                                    }
                                    printPattern.colors.sort((c1, c2) => colors.value.findIndex((c) => c.id === c1.id) - colors.value.findIndex((c) => c.id === c2.id));
                                    printPattern.sizes.sort((s1, s2) => s1.id - s2.id);
                                }
                            }
                            printPattern.amounts = [ ...printPattern.amounts, ...temp, ];
                        }
                    }
                }
                return group;
            }, []);

            // console.log("rank ", groupByTissueRank.value);

            const groupByProductsCopy4 = JSON.parse(JSON.stringify(groupByProducts.value));
            productionDetails.value = groupByProductsCopy4
                .reduce((group, p) => {
                    for (const [ i, ppt, ] of p.product.tissues.entries()) {
                        for (const s of p.sizes) {
                            const orderColors = getAmountsColors(p.amounts.filter((a) => a.sizeId === s.id));
                            const tissuesCombination = [];
                            for (let i = 0; i < p.product.tissues.length; i++) {
                                const tis = p.product.tissues[i];
                                let tissueCombination = `TS${i + 1}: ${tis.tissue.name} (${t(tis.tissue.category.name)})`;
                                if (tis.printPattern) {
                                    tissueCombination += `【${t("generic.hasPrintPattern")}】`;
                                }
                                tissuesCombination.push(tissueCombination);
                            }
                            for (const orderColor of orderColors) {
                                const useColor = getTissueDescriptorUseColor(colors.value, ppt, orderColor);
                                // Create a unique raw to describe tissue inside a model
                                const temp = {
                                    productTemplateId: p.product.productTemplateId, // identifier
                                    combinations: [ tissuesCombination, ], // identifier
                                    size: s, // identifier
                                    tissueCode: i + 1, // identifier
                                    tissue: ppt.tissue, // identifier
                                    tissueDescriptor: ppt,
                                    colorOrPrintPattern: { // identifier
                                        printPattern: ppt.printPattern,
                                        color: useColor,
                                    },
                                    patternCode: i + 1, // identifier
                                    amountPerLayer: 0,
                                    layer: 0,
                                    producedAmount: 0,
                                    usedAmount: 0,
                                    deviation: 0,
                                    deviationPercentage: 0,
                                    relatedProducts: [ {
                                        requestId: p.id,
                                        product: p.product,
                                        amounts: p.amounts.filter((a) => a.sizeId === s.id
                                            && a.colorId === orderColor.id
                                            && getTissueDescriptorUseColor(colors.value, ppt, getColor(a.colorId)).id === useColor.id),
                                    }, ],
                                    amount: p.amounts.find((a) => a.sizeId === s.id && a.colorId === orderColor.id).amount,
                                };

                                const existRow = group.find((g) => g.productTemplateId === temp.productTemplateId &&
                  g.size.id === temp.size.id &&
                  g.colorOrPrintPattern.color.id ===
                  temp.colorOrPrintPattern.color.id &&
                  g.tissue.name === temp.tissue.name &&
                  g.colorOrPrintPattern.printPattern?.name ===
                  temp.colorOrPrintPattern.printPattern?.name &&
                  g.patternCode === i + 1);
                                if (existRow) {
                                    existRow.amount += temp.amount;
                                    if (!existRow.combinations.find((c) => isEqualCombination(c, temp.combinations[0]))) {
                                        existRow.combinations.push(temp.combinations[0]);
                                    }
                                    const existRelatedProduct = existRow.relatedProducts.find((rp) => rp.requestId === temp.relatedProducts[0].requestId);
                                    if (existRelatedProduct) {
                                        temp.relatedProducts[0].amounts.forEach((a) => {
                                            const existAmount = existRelatedProduct.amounts.find((ea) => ea.colorId === a.colorId);
                                            if (existAmount) {
                                                existAmount.amount += a.amount;
                                            }
                                            else {
                                                existRelatedProduct.amounts.push(a);
                                            }
                                        });
                                    }
                                    else {
                                        existRow.relatedProducts = [ ...existRow.relatedProducts, ...temp.relatedProducts, ];
                                    }
                                }
                                else {
                                    group.push(temp);
                                }
                            }
                        }
                    }

                    return group;
                }, [])
                ?.sort((c1, c2) => colors.value.findIndex((c) => c.id === c1.colorOrPrintPattern.color.id) - colors.value.findIndex((c) => c.id === c2.colorOrPrintPattern.color.id))
                .sort((c1, c2) => c1.productTemplateId.localeCompare(c2.productTemplateId))
                .sort((t1, t2) => t1.tissueCode - t2.tissueCode);

            // console.log("production details ", productionDetails.value);

            productionPlan.value = [];

            const groupByTissueRankCopy = JSON.parse(JSON.stringify(groupByTissueRank.value));

            for (const p of groupByTissueRankCopy) {
                const data = {
                    code: p.index,
                    patternDescriptor: [], // tissue paving descriptor
                    tissuePavingDescriptor: [], // pattern descriptor
                    tissuePavingMeters: "",
                };
                const tissuePaving = {
                    tissue: p.tissue,
                    printPatterns: [],
                    cropNotes: "",
                };
                const amountsTemp = [];
                let sizesTemp = [];
                for (const printPatternDescriptor of p.printPatterns) {
                    let appliedRuleColors = printPatternDescriptor.colors;
                    const ppt = printPatternDescriptor.descriptor;
                    switch (true) {
                        case isOnlyWhiteEmbryo(ppt):
                            appliedRuleColors = colors.value.filter((c) => c.name === "color.whiteEmbryo");
                            break;
                        case isOnlyYarnDyedFabricFixedColor(ppt):
                            if (ppt.tissueColorRules) {
                                const fixedColor = colors.value.filter((c) => c.id === ppt.tissueColorId);
                                const useColors = [];
                                for (const orderColor of appliedRuleColors) {
                                    const specialColor = ppt.tissueColorRules.find((rule) => rule.orderColorId === orderColor.id);
                                    if (specialColor) {
                                        const useColor = colors.value.find((c) => c.id === specialColor.useColorId);
                                        if (!useColors.find((c) => c.id === useColor.id)) {
                                            useColors.push(useColor);
                                        }
                                    }
                                    else if (!useColors.find((uc) => uc.id === fixedColor.id)) {
                                        useColors.push(fixedColor);
                                    }
                                }
                                appliedRuleColors = useColors;
                                break;
                            }
                            appliedRuleColors = colors.value.filter((c) => c.id === ppt.tissueColorId);
                            break;
                        case isOnlyYarnDyedFabricUsePreorderColor(ppt):
                            if (ppt.tissueColorRules) {
                                const useColors = [];
                                for (const orderColor of appliedRuleColors) {
                                    const specialColor = ppt.tissueColorRules.find((rule) => rule.orderColorId === orderColor.id);
                                    if (specialColor) {
                                        const useColor = colors.value.find((c) => c.id === specialColor.useColorId);
                                        if (!useColors.find((c) => c.id === useColor.id)) {
                                            useColors.push(useColor);
                                        }
                                    }
                                    else if (!useColors.find((uc) => uc.id === orderColor.id)) {
                                        useColors.push(orderColor);
                                    }
                                }
                                appliedRuleColors = useColors;
                                break;
                            }
                            appliedRuleColors = printPatternDescriptor.colors;
                            break;
                        case isWhiteEmbryoWithPrintPatternNoColor(ppt):
                            if (ppt.printPatternColorRules) {
                                const noColor = {
                                    name: "generic.noColor",
                                    id: 0,
                                    colorId: 0,
                                    isDefault: true,
                                };
                                const useColors = [];
                                for (const orderColor of appliedRuleColors) {
                                    const specialColor = ppt.printPatternColorRules.find((rule) => rule.orderColorId === orderColor.id);
                                    if (specialColor) {
                                        const useColor = colors.value.find((c) => c.id === specialColor.useColorId);
                                        if (!useColors.find((c) => c.id === useColor.id)) {
                                            useColors.push(useColor);
                                        }
                                    }
                                    else if (!useColors.find((uc) => uc.id === noColor.id)) {
                                        useColors.push(noColor);
                                    }
                                }
                                appliedRuleColors = useColors;
                                break;
                            }
                            appliedRuleColors = [ {
                                name: "generic.noColor",
                                id: 0,
                                colorId: 0,
                                isDefault: true,
                            }, ];
                            // console.log("5");
                            break;
                        case isWhiteEmbryoWithPrintPatternFixedColor(ppt):
                            if (ppt.printPatternColorRules) {
                                const fixedColor = colors.value.filter((c) => c.id === ppt.printPatternColorId);
                                const useColors = [];
                                for (const orderColor of appliedRuleColors) {
                                    const specialColor = ppt.printPatternColorRules.find((rule) => rule.orderColorId === orderColor.id);
                                    if (specialColor) {
                                        const useColor = colors.value.find((c) => c.id === specialColor.useColorId);
                                        if (!useColors.find((c) => c.id === useColor.id)) {
                                            useColors.push(useColor);
                                        }
                                    }
                                    else if (!useColors.find((uc) => uc.id === fixedColor.id)) {
                                        useColors.push(fixedColor);
                                    }
                                }
                                appliedRuleColors = useColors;
                                break;
                            }
                            appliedRuleColors = colors.value.filter((c) => c.id === ppt.printPatternColorId);
                            break;
                        case isWhiteEmbryoWithPrintPatternUsePreorderColor(ppt):
                            if (ppt.printPatternColorRules) {
                                const useColors = [];
                                for (const orderColor of appliedRuleColors) {
                                    const specialColor = ppt.printPatternColorRules.find((rule) => rule.orderColorId === orderColor.id);
                                    if (specialColor) {
                                        const useColor = colors.value.find((c) => c.id === specialColor.useColorId);
                                        if (!useColors.find((c) => c.id === useColor.id)) {
                                            useColors.push(useColor);
                                        }
                                    }
                                    else if (!useColors.find((uc) => uc.id === orderColor.id)) {
                                        useColors.push(orderColor);
                                    }
                                }
                                appliedRuleColors = useColors;
                                break;
                            }
                            appliedRuleColors = printPatternDescriptor.colors;
                            break;
                    }
                    appliedRuleColors.sort((c1, c2) => colors.value.findIndex((c) => c.id === c1.id) - colors.value.findIndex((c) => c.id === c2.id));
                    tissuePaving.printPatterns.push({
                        printPattern: printPatternDescriptor.printPattern,
                        descriptors: [ {
                            ...printPatternDescriptor.descriptor,
                            amounts: printPatternDescriptor.amounts,
                            productTemplateId: p.productTemplateId,
                        }, ],
                        layersPerColor: appliedRuleColors.map((c) => ({
                            color: c,
                            amount: "",
                        })),
                    });
                    for (const a of printPatternDescriptor.amounts) {
                        const existAmount = amountsTemp.find((at) => at.sizeId === a.sizeId && at.colorId === a.colorId);
                        if (existAmount) {
                            existAmount.amount += a.amount;
                        }
                        else {
                            amountsTemp.push({
                                sizeId: a.sizeId,
                                colorId: a.colorId,
                                amount: a.amount,
                                croppedAmount: a.croppedAmount,
                                deliveredAmount: a.deliveredAmount,
                            });
                        }
                    }
                    sizesTemp = [ ...sizesTemp, ...printPatternDescriptor.sizes, ];
                }
                data.patternDescriptor.push({
                    productTemplateId: p.productTemplateId,
                    totalAmounts: amountsTemp.reduce((total, a) => total + a.amount, 0),
                    amounts: amountsTemp,
                    amountsPerLayers: sizesTemp
                        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
                        .map((s) => ({
                            size: s,
                            amount: "",
                        })),
                });
                // console.log("tissue paving", tissuePaving);
                data.tissuePavingDescriptor.push(tissuePaving);
                // // console.log("tissue paving", tissuePaving);
                // data.tissuePavingDescriptor.push(tissuePaving);
                const existData = productionPlan.value.find((pp) => pp.code === data.code);
                if (existData) {
                    for (const pd of data.patternDescriptor) {
                        const existTemplate = existData.patternDescriptor.find((epd) => epd.productTemplateId === pd.productTemplateId);
                        if (existTemplate) {
                            existTemplate.amountsPerLayers = [ ...existTemplate.amountsPerLayers, ...pd.amountsPerLayers, ];
                            existTemplate.amountsPerLayers =
                existTemplate.amountsPerLayers.filter((value, index, self) => index === self.findIndex((t) => t.size.id === value.size.id));
                            existTemplate.totalAmounts += pd.totalAmounts;
                            for (const a of pd.amounts) {
                                const existAmountDescriptor = existTemplate.amounts.find((eta) => eta.sizeId === a.sizeId && eta.colorId === a.colorId);
                                if (existAmountDescriptor) {
                                    existAmountDescriptor.amount += a.amount;
                                }
                                else {
                                    existTemplate.amounts.push(a);
                                }
                            }
                        }
                        else {
                            existData.patternDescriptor.push(pd);
                        }
                    }
                    for (const tpd of data.tissuePavingDescriptor) {
                        const existTissue = existData.tissuePavingDescriptor.find((ept) => ept.tissue.name === tpd.tissue.name);
                        if (existTissue) {
                            for (const printPatternDescriptor of tpd.printPatterns) {
                                const existPrintPattern = existTissue.printPatterns.find((etpp) => etpp.printPattern?.name ===
                  printPatternDescriptor.printPattern?.name);
                                if (existPrintPattern) {
                                    existPrintPattern.descriptors = [ ...existPrintPattern.descriptors, ...printPatternDescriptor.descriptors, ];
                                    existPrintPattern.layersPerColor = [ ...existPrintPattern.layersPerColor, ...printPatternDescriptor.layersPerColor, ].filter((value, index, self) => index ===
                    self.findIndex((t) => t.color.id === value.color.id));
                                }
                                else {
                                    existTissue.printPatterns.push(printPatternDescriptor);
                                }
                            }
                        }
                        else {
                            existData.tissuePavingDescriptor.push(tpd);
                        }
                    }
                }
                else {
                    productionPlan.value.push(data);
                }
            }

            // console.log("production plan: ", productionPlan.value);
            initCroppedAmounts();

            creation.value =
        !production.value.createCropPlanTimestamp ||
        !production.value.completeCropTimestamp;
            if (production.value.createCropPlanTimestamp) {
                const cropPlanDescriptor = await user.getCropPlan({
                    id: production.value.id,
                });
                const cropPlan = cropPlanDescriptor.plan;
                const products = cropPlanDescriptor.products;
                hasAmountsPerLayer.value = false;
                if (cropPlan) {
                    for (const plan of productionPlan.value) {
                        const foundPlan = cropPlan.find((p) => p.code === plan.code);
                        // console.log("found plan: ", foundPlan);
                        if (foundPlan) {
                            plan.tissuePavingMeters = foundPlan.tissuePavingMeters;
                            for (const pattern of plan.patternDescriptor) {
                                // console.log(pattern);
                                const foundPattern = foundPlan.patternDescriptor.find((fp) => String(fp.productTemplateId) ===
                  String(pattern.productTemplateId));
                                // console.log("found pattern: ", foundPattern);
                                if (foundPattern) {
                                    for (const amountPerLayer of pattern.amountsPerLayers) {
                                        const foundAmountPerLayer =
                      foundPattern.amountsPerLayers.find((apl) => apl.sizeId === amountPerLayer.size.id);
                                        // console.log("found amountPerLayer: ", foundAmountPerLayer);
                                        amountPerLayer.amount = foundAmountPerLayer.amount;
                                        if (Number.isInteger(amountPerLayer.amount)) {
                                            hasAmountsPerLayer.value = true;
                                        }
                                    }
                                }
                            }
                            for (const tissuePavingDescriptor of plan.tissuePavingDescriptor) {
                                const foundTissuePaving = foundPlan.tissuePavingDescriptor.find((fp) => fp.tissueName
                                    ? fp.tissueName === tissuePavingDescriptor.tissue.name
                                    : String(fp.tissueId) ===
                  String(tissuePavingDescriptor.tissue.id));
                                // console.log("found tissuePavingDescriptor: ", foundTissuePaving);
                                if (foundTissuePaving) {
                                    tissuePavingDescriptor.cropNotes =
                    foundTissuePaving.cropNotes;
                                    for (const printPatternDescriptor of tissuePavingDescriptor.printPatterns) {
                                        // console.log(printPatternDescriptor);
                                        const foundPrintPattern =
                      foundTissuePaving.printPatterns.find((pp) => pp.printPatternId ===
                        printPatternDescriptor.printPattern ||
                        (pp.printPatternName
                            ? pp.printPatternName ===
                          printPatternDescriptor.printPattern?.name
                            : String(pp.printPatternId) ===
                          String(printPatternDescriptor.printPattern?.id)));
                                        // console.log("found printPatternDescriptor: ", foundPrintPattern);
                                        if (foundPrintPattern) {
                                            for (const layer of printPatternDescriptor.layersPerColor) {
                                                const foundLayer =
                          foundPrintPattern.layersPerColor.find((lpc) => lpc.colorId === layer.color.id);
                                                // console.log("found layer: ", foundLayer);
                                                layer.amount = foundLayer.amount;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (hasAmountsPerLayer.value) {
                        calculateProduced();
                    }
                }
                if (products) {
                    for (const productDescirptor of groupByProducts.value) {
                        const foundProduct = products.find((p) => productDescirptor.product.id === p.productId);
                        if (foundProduct) {
                            for (const amountDescriptor of productDescirptor.amounts) {
                                const foundAmount = foundProduct.croppedAmounts.find((a) => a.colorId === amountDescriptor.colorId &&
                  a.sizeId === amountDescriptor.sizeId);
                                if (foundAmount) {
                                    amountDescriptor.croppedAmount = foundAmount.amount;
                                }
                            }
                        }
                    }
                }
            }
        }

        function textColor (number) {
            return number > 0 ? "text-success" : "text-danger";
        }

        async function submitCreate () {
            if (!production.value.createCropPlanTimestamp) {
                isConfirmButtonLoading.value = true;
                const cropPlan = await user.createCropPlan({
                    productionId: production.value.id,
                    cropPlans: productionPlan.value,
                    wastedAmounts: getProductWastedAmount(),
                });
                if (cropPlan) {
                    isConfirmButtonLoading.value = false;
                    store
                        .dispatch("sendNotification", {
                            header: t("generic.quickAccess"),
                            body: t("notification.cropPlanCreated"),
                            function: () => {
                                store.commit("openPrintReceiptModal", production.value.id);
                            },
                            functionName: t("generic.print"),
                        })
                        .then();
                    closeModal();
                }
            }
            else {
                isConfirmButtonLoading.value = true;
                const cropPlan = await user.completeCropPlan({
                    productionId: production.value.id,
                    products: groupByProducts.value,
                });
                if (cropPlan) {
                    isConfirmButtonLoading.value = false;
                    closeModal();
                }
            }
            emit("change");
        }

        if (!props.isGlobal) {
            watch(() => props.isVisible,
                async (newValue) => {
                    if (newValue) {
                        isLoading.value = true;
                        await init(props.productionId);
                        isLoading.value = false;
                    }
                });
        }
        else {
            watch(() => store.state.isVisibleCreateCropPlanModal,
                async (newValue) => {
                    if (newValue) {
                        isLoading.value = true;
                        await init(store.state.selectedProductionId);
                        isLoading.value = false;
                        store.commit("deleteNotificationByFunctionName", {
                            name: "generic.createCropPlan",
                        });
                    }
                });
        }

        return {
            t,
            store,
            production,
            colors,
            sizes,
            productionPlan,
            groupByTissues,
            groupByTemplates,
            groupByTemplatesAndTissues,
            groupByTissueRank,
            productionDetails,
            isTissueInfoCollapseVisible,
            isCutInfoCollapseVisible,
            isModelInfoCollapseVisible,
            isProductionInfoCollapseVisible,
            arrows,
            activeProductionTab,
            groupByProducts,
            tissues,
            allProductsSizes,
            getSize,
            toggleCutInfoCollapse,
            toggleModelInfoCollapse,
            toggleTissueInfoCollapse,
            toggleProductionInfoCollapse,
            close,
            getTissueColorRule,
            getPrintPatternColorRule,
            getElementName,
            getAmountsColors,
            getTotalBySize,
            getProducedTotalBySize,
            getProductDeviationBySize,
            getAmount,
            getTotalAmountsColors,
            getTissueCombinationColor,
            getTotalTemplateCombinationColors,
            getTotalCombinationColors,
            getCombinationDescriptorSizes,
            getTotalTemplateSizes,
            getTemplateSizes,
            getDeviation,
            getAmountPerLayer,
            getDeviationPercentage,
            getLayer,
            getProducedAmount,
            getProductProducedAmount,
            getProductDeviation,
            getTissueDescriptorUseColor,
            getProductPreorderAmount,
            // getPreorderAmount,
            getPrintPatternColorValue,
            getTissueColorValue,
            getWastedAmount,
            getProductWastedAmount,
            getPatternDescriptorSizes,
            getColor,
            // lightbox
            imagesBase64,
            selectedImageIndex,
            lightboxImages,
            isLightboxVisible,
            openLightbox,
            closeLightbox,
            // ui
            expandUnionProductionAmount,
            expandProductionDetailsAmount,
            isEqualCombination,
            isConfirmButtonLoading,
            closeModal,
            creation,
            isLoading,
            textColor,
            // event handler
            onInputAmountsPerLayers,
            onInputLayers,
            submitCreate,
            // tags
            isTagPickerVisible,
            openProductionTagPicker,
            changeTags,
            tags,
            selectedOrder,
        };
    },
};
</script>

<style lang="scss" scoped>
$modal-height: 85vh;
.modal-body {
  height: $modal-height;
  padding: 0;
  overflow-x: hidden;
}
.modal-content-container {
  min-height: $modal-height;
}
.nav-underline {
  border-bottom: 1px solid var(--cui-nav-underline-border-color, #c4c9d0);
}
.image-bar {
  min-width: 50px * 4;
  width: 15vw;
  margin-left: 9px;
  overflow-y: auto;
  overflow-x: hidden;
}
.image-bar-gallery {
  max-height: 85vh;
}
.form-container {
  width: 85vw;
}
.no-data-text {
  padding-left: 30vw;
}

.arrow-up {
  transform: rotate(180deg);
  transition: 0.3s;
}

.arrow-down {
  transform: rotate(0);
  transition: 0.3s;
}

.t-col-sm-image {
  max-width: 30px;
  max-height: 30px;
}

.t-col-md-image {
  max-width: 50px;
  max-height: 50px;
}

input {
  background: rgb(233, 233, 233);
}
</style>
