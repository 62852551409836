const zh = {
    brandName: "威特克斯",
    Italiano: "Italiano",
    中文: "中文",
    "---": "---",
    IronFactory: "烫厂沟通表",
    PrintPatternPage: "印花页面",
    TissuePage: "布料页面",
    sidebar: {
        todoList: "待办事项",
        analytics: "总览",
        preorderList: "预定记录",
        productList: "模特",
        preorderByProductPage: "模特页面",
        preorderByOrderPage: "订单页面",
        production: "生产计划页面",
        byProduct: "按模特",
        byOrder: "按订单",
        storage: "备货余量",
        productionPlanning: "生产计划",
        productionOrderWaitingOrderList: "等待安排",
        dyeingOrderWaitingOrderList: "等待安排",
        frontBehindPrintOrderWaitingList: "等待安排",
        productionOrderPlannedOrderList: "已安排",
        dyeingOrderPlannedOrderList: "已开颜色单",
        frontBehindPrintOrderPlannedList: "已安排",
        purchaseHelper: "采购助手",
        dyeingOrder: "染洗颜色单",
        frontBehindPrint: "前后排印单",
        shipping: "发货助手",
        products: "按模特",
        productTemplates: "按版型",
        ingredients: "原材料",
        tissues: "布料",
        accessories: "辅料",
        printPatterns: "印布花版",
        printPictures: "排印图案",
        procedures: "工艺",
        contacts: "关系",
        suppliers: "供应商",
        customers: "客户",
        settings: "设置",
        productProperties: "模特属性",
        categories: "分类",
        tags: "标签",
        wifiPrinters: "WiFi打印机",
        permissionControl: "权限管理",
        languages: "语言 Lingua",
        product: "模特",
    },
    order: {
        unpaid: "待付款",
        updateOrder: "修改订单",
        stockVolume: "备货量",
        addNewSample: "新建备货/样品单",
        addNew: "新建预订单",
        customers: "客户",
        preorderVolume: "预订量",
        preorderPrice: "预订价",
        productionChanges: "生产要求",
        endDelivery: "交货期限",
        deliveryNote: "发货备注",
        deliveryTime: "交货期限",
        state: "状态",
        recentActivities: "时间记录",
        update: "编辑",
        changeState: "更改发货状态",
        viewProductionPlan: "显示生产计划",
        cancel: "取消订单",
        delete: "永久删除",
        note: "订单备注",
        noCustomerSelected: "无选中客户",
        dateOrder: "下单日期",
        logo: "LOGO",
        tag: "吊牌",
        composition: "成分标",
        compositionImage: "成分标图",
        lastWeekTotalCount: "近7天预订量",
        produce: "生产",
        toProduce: "需生产",
        fromInventory: "从备货取",
        noProduce: "不生产",
        tbd: "待定",
        copyInfoTo: "复制信息到",
        copyModel: "再次添加该模特",
        clear: "清零表格",
        choseWhatYouWantToCopy: "复制的内容",
        copyAll: "复制所有信息（包含数量、备注）",
        copyJustTable: "仅复制颜色和数量",
        copyJustColors: "仅复制颜色",
        copyNone: "不复制任何内容",
        copyOnModel: "复制信息到...",
        copyInfoOnOtherModels: "复制信息到其他模特",
        fillRow: "填充行",
        fillColumn: "填充列",
        deleteRow: "删除行",
        deleteColumn: "删除列",
        details: "详情",
        inventoryOrder: "新建备货单",
        templateList: "下单版型",
        quantityTemplates: "版型数",
        quantityModels: "模特数",
        customerNote: "订单备注",
        orderQuantity: "订单量",
        totalPrice: "总金额",
        deliveryDate: "发货日期",
        expirationDate: "交货期限",
        orderState: "状态",
        orderId: "单号",
        productTemplateId: "版型",
        mainTissue: "主布料",
        customer: "客户",
        printPattern: "印花",
        accessory: "辅料",
        printPicture: "排印",
        productionNote: "生产备注",
        placeTimestamp: "下单日期",
        inProductionTimestamp: "生产日期",
        receivedTimestamp: "到货日期",
        createCropPlanTimestamp: "开裁剪单",
        completeCropTimestamp: "完成裁剪",
        createColorPlanTimestamp: "打颜色单",
        createPrintPictureTimestamp: "打排印单",
        deliveryTimestamp: "发货日期",
        deliveredTimestamp: "发货日期",
        expirationTimestamp: "截止日期",
        insertTag: "添加标签",
        closeToExpiration: "交期将至",
        productionFlow: "生产流程",
        importColors: "导入常用颜色",
        importHistoricalColors: "导入历史订单颜色",
        clearColors: "移除所有颜色",
        options: "操作",
        copy: "复制",
        preprocessing: "提前加工（区分辅料，工艺）",
        tissuesComposite: "布料组合",
        orderType: "订单类型",
        customerOrder: "客户订单",
        belongsToOrder: "所属订单号",
        production: "生产计划",
        placeDate: "下单日期",
        customerLogo: "LOGO",
        customerTag: "吊牌",
        customerComposition: "成分标",
        customerCompositionImages: "成分标照片",
        shippingState: "预订单发货状态",
        creationDate: "创建时间",
        creationUser: "创建人",
        lastUpdateDate: "最近修改日期",
        lastUpdateUser: "最近修改人",
        editNote: "修改备注",
        deliveredVolume: "发货数量",
        restore: "复原订单",
        total: "汇总",
        settingTag: "设置标签",
        changeProductionMode: "设置是否生产",
    },
    model: {
        updateModel: "修改模特",
        mainTissue: "主布料",
        allTissues: "全部布料",
        classification: "分类",
        listPrice: "价格",
        tag: "标签",
        addNew: "添加模特",
        update: "修改",
        waste: "用料",
        hemming: "包边",
        noteForCutPlaceholder: "裁剪备注",
        hotGlueRequest: "含烫胶部分",
        primaryTissue: "主要布料",
        secondaryTissue: "次要布料",
        followOrderColor: "跟随下单色",
        noColor: "不区分颜色",
        separateFromStorage: "区分备货",
        images: "模特照片",
        costManager: "成本管理",
        copy: "复制",
        archive: "移到垃圾桶",
        unarchive: "复原",
        print: "打印",
        setVisibleOnOrder: "下单时可见",
        setNotVisibleOnOrder: "下单时不可见",
        insertTag: "添加标签",
        cropNotes: "裁剪备注",
        lastUpdateTime: "最后更新时间",
        create: "新建模特",
        availableStockVolume: "可用备货余量",
        delete: "移除模特",
        note: "模特备注",
        specialColorRule: "特殊颜色规则",
    },
    ingredient: {
        ingredientAndNote: "成分和备注",
        tissue: {
            addNew: "添加布料",
            images: "照片",
            category: "类型",
            updateCategory: "修改布料类型",
            update: "编辑布料",
            new: "新建布料",
            color: "布料色",
            change: "替换",
            delete: "移除",
            noColorForWhiteEmbryo: "白胚不区分颜色",
        },
        accessory: {
            addNew: "添加辅料",
            images: "辅料图片",
            update: "编辑辅料",
            new: "新建辅料",
            delete: "移除辅料",
        },
        printPattern: {
            addNew: "添加印花",
            images: "印花图片",
            update: "编辑印花",
            new: "新建印花",
            change: "更换",
            delete: "移除",
            color: "印布色",
        },
        printPicture: {
            addNew: "添加排印图案",
            images: "排印图案图片",
            update: "修改排印图案",
        },
    },
    procedure: {
        type: "工艺种类",
        price: "价格",
        pricingMethod: "计量方式",
        fixedPrice: "固定价格",
        variablePrice: "浮动价格（随模特）",
        unitPrice: "单位",
        pricePerPiece: "€/件",
        pricePerKg: "€/kg",
        pricePerUnit: "€/颗",
        addNew: "添加工艺",
        update: "修改工艺",
        new: "新建工艺",
    },
    contact: {
        notes: "备注",
        creationDate: "创建时间",
        category: "分类",
        options: "设置",
        updateCategory: "修改分类",
        supplier: {
            name: "名称",
            addNew: "添加新的供应商",
            archive: "移到回收站",
            unarchive: "恢复",
            update: "修改供应商",
        },
        customer: {
            fullName: "姓名",
            companyName: "公司名",
            mobile: "手机号",
            defaultLogo: "预订单默认 LOGO",
            defaultTag: "预订单默认吊牌",
            defaultComposition: "预订单默认成分标",
            defaultCompositionImage: "预订单默认成分标图片",
            addNew: "添加新的客户",
            archive: "移到回收站",
            unarchive: "恢复",
            update: "修改客户",
            isPreorderDefaultCustomer: "预订单默认客户",
            isStockOrderDefaultCustomer: "备货单默认客户",
            create: "新建客户",
        },
    },
    settings: {
        productProperty: {
            color: "颜色",
            size: "尺码",
            preorderDefault: "预订单默认添加",
            updateColor: "修改颜色",
            updateSize: "修改尺码",
            addNewColor: "添加新颜色",
            addNewSize: "添加新尺码",
            addNew: "添加...",
            name: "名称",
        },
        category: {
            models: "模特",
            accessories: "辅料",
            tissuePrint: "花版",
            addNewCategory: "添加新类别...",
            categoryUpdate: "修改类别",
            name: "名称",
        },
        tag: {
            productTag: "模特标签",
            orderTag: "订单标签",
            productionTag: "生产标签",
            addNewTag: "新建标签",
            name: "标签",
        },
    },
    color: {
        whiteEmbryo: "白胚",
        red: "红",
        blue: "蓝",
        yellow: "黄",
        grey: "灰",
        black: "黑",
        purple: "紫",
        addNew: "添加颜色",
    },
    size: {
        uniqueSize: "均码",
        XS: "XS",
        S: "S",
        M: "M",
        L: "L",
        XL: "XL",
        XXL: "XXL",
        addNew: "添加尺码",
    },
    tag: {
        addNew: "添加新的标签",
    },
    unitOfMeasurement: {
        meter: "米",
    },
    generic: {
        paid: "付",
        unpaid: "欠",
        customerToPay: "客户待付",
        customerPaid: "客户已付",
        orderPage: "订单信息",
        skipProcedures: "跳过生产流程，直接入库",
        importHistoricalColors: "导入历史订单颜色",
        need: "需要",
        dontNeed: "不需要",
        stockDetailsUpdate: "修改库存",
        stockDetails: "库存",
        hasStock: "有备货",
        notDelivered: "未发货",
        orderedVolume: "预订量",
        partiallyDelivered: "部分发货",
        delivered: "已发",
        customerWaiting: "欠客人",
        analyticsTable: "分析",
        excludeArrived: "不显示已到货",
        changeColor: "修改配色方案",
        addColorRule: "添加颜色规则",
        specialColorRules: "特别颜色规则",
        ifUseColor: "如果下单颜色是:",
        thanUseColor: "该部位颜色就用:",
        dragAndDrop: "拖放图片到此上传",
        or: "或者",
        browserFiles: "浏览本地图片",
        supportFileExtension: "支持图片格式",
        accessoryPrice: "价格(€/单位)",
        hasModels: "共 {number} 款模特",
        main: "主",
        secondary: "次",
        loadDraft: "未完成的订单",
        byDays: "按天",
        byMonths: "按月",
        graphic: "生产数量总览",
        placeTimestamp: "下单日期",
        deliveryTimestamp: "发货日期",
        expirationTimestamp: "截止日期",
        createPrintPicturePlanTimestamp: "开排印单",
        createColorPlanTimestamp: "开颜色单",
        inProductionTimestamp: "安排生产",
        completeCropTimestamp: "完成裁剪",
        addProductToProduction: "追加模特",
        selectPreorderModel: "安排生产计划",
        replan: "重新安排",
        infoForProduction: "加工厂沟通表",
        inProduction: "生产中",
        notPlanned: "未安排",
        notCropped: "所有未裁",
        waitingTally: "等待理货",
        printPictureOrderId: "排印单号",
        dyeingOrderId: "颜色单号",
        completeCropDate: "裁剪完成日",
        createDyeingOrderDate: "开颜色单日",
        createPrintPictureOrderDate: "开排印单日",
        orderArrived: "订单到货",
        incomplete: "未发完（后续还需再给这单发货）",
        shippingDate: "发货日期",
        arriveDate: "到货时间",
        customerTag: "预订单标签",
        auto: "自动",
        placeOrder: "下单",
        shippingAndArriving: "预订单到货情况",
        arrivedProductsModal: "到货详情",
        productionNote: "生产备注",
        customerNote: "订单备注",
        deliveryNote: "发货备注",
        orderAmount: "订单数量",
        arrivedAmounts: "到货数量",
        waittingToShipAmounts: "待交货数量",
        difference: "差异",
        completeAll: "到齐",
        fixTop: "置顶",
        cancelFixTop: "取消置顶",
        arriving: "到货情况",
        arrived: "到货",
        arrivedToShip: "已到货",
        toShip: "待发货",
        preorder: "预订",
        notArrived: "未到货",
        ironFactoryTable: "烫厂沟通表",
        printIronFactoryTable: "打印烫厂沟通表",
        deliveryInAClick: "一键到货",
        shipped: "已发货",
        unshipped: "未发货",
        progress: "进展",
        noMoreResults: "已显示全部结果",
        logo: "LOGO",
        warning: "注意",
        archive: "移到垃圾桶",
        unarchive: "恢复",
        all: "全部",
        default: "默认",
        close: "关闭",
        undo: "取消",
        confirm: "确定",
        selected: "已选中",
        search: "查找",
        pages: "总页数",
        total: "总计",
        trash: "回收站",
        suppliers: "供应商",
        supplier: "供应商",
        customers: "客户",
        requiredFieldMsg: "带*号为必填项",
        uneditable: "不可修改",
        updatedSuccessfully: "修改成功",
        createdSuccessfully: "添加成功",
        deletedSuccessfully: "删除成功",
        restoredSuccessfully: "恢复成功",
        name: "名称",
        type: "类型",
        photo: "照片",
        unitPrice: "单价(€/米)",
        weight: "克重",
        width: "门幅",
        shrinkage: "缩水率",
        lastUpdate: "最近更新",
        whiteEmbryo: "白胚",
        yarnDyedFabric: "色布",
        essentialInformation: "基本信息",
        category: "分类",
        unitOfMeasurement: "单位",
        notes: "备注",
        startDate: "从",
        endDate: "至",
        procedure: "工艺",
        piece: "价格",
        addNew: "新建",
        model: "模特",
        tissuesAndAccessory: "布料与辅料",
        required: "必填",
        colorGroup: "颜色组",
        categories: "类别",
        productTemplates: "版型",
        emptyForAutomaticSelection: "留空自动选择默认",
        sizes: "尺寸",
        tags: "标签",
        visibleOnOrder: "下单时显示",
        description: "描述",
        tissues: "布料",
        material: "材料",
        notesForCut: "裁剪备注",
        printPattern: "花版",
        color: "颜色",
        accessories: "辅料",
        ingredients: "原材料",
        preorderDefaultColor: "预订单颜色",
        clickToSelect: "点击选择",
        selectAll: "全选",
        noResult: "没有符合选项",
        noData: "没有信息",
        quantity: "数量",
        price: "价格",
        subtotal: "小计",
        otherCost: "其他费用",
        totalCost: "总费用",
        scrapRate: "损坏率",
        profitRate: "利润率",
        priceToSell: "定价",
        pricing: "定价",
        profit: "利润",
        procedures: "工艺",
        autoPriceToSell: "自动计算定价",
        costManager: "定价管理",
        useProductWeight: "使用模特重量",
        colorManager: "色彩管理",
        operation: "操作",
        selectedColors: "已选择颜色",
        removedColors: "已移除颜色",
        removeSelectedColors: "移除选中颜色",
        restoreSelectedColors: "复原选择颜色",
        remove: "移除",
        restore: "复原",
        models: "模特",
        tissuesAndAccessories: "布料与辅料",
        cost: "定价",
        other: "其他",
        totalPrice: "总价",
        loss: "损耗",
        gain: "利润",
        listPrice: "价格",
        select: "选择",
        startsWith: "从",
        endsWith: "至",
        noItem: "找不到相关结果",
        tissue: "布料",
        printPatternAbbrv: "印",
        tissueAbbrv: "布",
        importPreorderColor: "导入默认颜色组",
        importPreorderSize: "导入默认尺码",
        template: "版型",
        number: "单号",
        date: "日期",
        moreOptions: "更多设置",
        discountAmount: "减价",
        urgent: "加急",
        preorderPrice: "预订价",
        colors: "颜色",
        creationDate: "创建日期",
        colorRule: "颜色方案",
        printPatternColorRule: "印花颜色方案",
        customer: "客户",
        create: "创建",
        read: "读取",
        update: "修改",
        permission: "权限",
        allMatch: "完全匹配",
        includeTags: "包含标签",
        excludeTags: "不含标签",
        noTag: "无标签",
        customerOrder: "客人订单",
        inventoryOrder: "备货订单",
        otherFilter: "其他筛选",
        size: "尺码",
        mainTissue: "主布料",
        tissuesCombination: "布料组合",
        preorderDate: "下单日期",
        deliveryDate: "交货日期",
        state: "状态",
        last7DaysPreorders: "最后7天下单量",
        stock: "备货量",
        details: "详情",
        reset: "归零",
        setAmount: "修正数量",
        createNew: "新建",
        tissueColor: "布料颜色",
        printPatternPhoto: "印花照片",
        printPatternName: "印花名称",
        printPatternColor: "印花颜色",
        consumption: "用料(米)",
        accessoryColor: "辅料颜色",
        noColor: "不区分颜色",
        usePreorderColor: "使用下单色",
        accessoryConsumption: "用量",
        byColor: "按颜色",
        bySize: "按尺码",
        continue: "继续",
        noRecord: "暂无数据",
        save: "保存",
        orderExpirationInNDays: "距交期剩 {days} 天",
        orderExpiredForNdays: "已过期 {days} 天",
        draftRequest: "您在{datetime}有未完成的订单，是否找回",
        none: "无",
        cancel: "取消",
        setInProduction: "改为需生产",
        setWaitingProduction: "改为等待安排生产",
        notProduce: "改为不生产",
        changeFromStock: "改为从备货出",
        duplicateName: "已存在相同名称",
        setWaitingConfirmation: "改为生产待定",
        updateCategory: "修改分类",
        options: "选项",
        print: "打印",
        changeTag: "设置标签",
        changeNote: "设置备注",
        delete: "删除",
        inventoryOrderLog: "新增备货订单 {orderId} ，余量 +{amount}",
        setLog: "余量被订正为 {amount}",
        customerOrderLog: "预订单 {orderId} 消耗备货，余量 -{amount}",
        inventoryOrderCancelledLog: "备货订单 {orderId} 被删除，余量 -{amount}",
        customerOrderCancelledLog: "预订单 {orderId} 被删除，余量 +{amount}",
        customerReminder: "客户催单",
        productionTag: "生产标签",
        orderTag: "订单标签",
        setTag: "设置标签",
        cropPlan: "裁剪单",
        hotGlue: "烫胶",
        tissueCode: "布料序号",
        tissueColorRule: "布料颜色方案",
        unionProductionAmount: "合并生产量",
        detailedProductionAmount: "明细生产量",
        printPatternsAndColors: "颜色或印花",
        finishedPiecesNumber: "裁剪完成件数",
        wastedAmount: "废料数",
        hasPrintPattern: "带印花",
        modelName: "预订模特编号",
        preorderColors: "下单色",
        preorderAmounts: "预订件数",
        tissueCombination: "布料组合",
        totalPreorderAmount: "预订总件数",
        patternCode: "纸样图序号",
        amountPerLayer: "手数",
        layer: "层数",
        producedAmount: "产出数",
        deviation: "偏差",
        deviationPercentage: "偏差百分比",
        calculatedFinalProducedAmount1: "计算最终产出件数",
        calculatedFinalProducedAmount2: "(竖向查看, 版型+布料组合、颜色组合、尺码)",
        expand: "展开",
        hide: "收起",
        pattern: "排版图",
        tissuePavingPlan: "铺布方案",
        tissuePavingMeters: "铺布米数",
        totalAmounts: "总件数",
        cropNotes: "裁剪备注",
        createCropPlan: "开裁剪单",
        completeCrop: "完成裁剪",
        quickAccess: "快捷操作",
        sepcialRequestsNote: "特殊需求注意",
        modelInfo: "模特信息",
        tissueInfo: "布料信息",
        customerInfo: "客户信息",
        productionInfo: "生产信息",
        cutScheme: "裁剪方案",
        croppedAmount: "裁剪量",
        hemmingDetails: "包边明细",
        greaterFontSize: "大号字体展示裁剪信息",
        specialInfo: "加工厂沟通信息（有特殊要求时显示）",
        preferencesToProduction: "加工厂沟通偏好设置",
        qrCodeForTissueImage: "二维码查看布料图片",
        qrCodeForPrintPatternImage: "二维码查看花版图片",
        selectPrinter: "选择打印机",
        urgentPreorder: "预订单加急",
        preorderLogo: "预订单LOGO",
        preorderComposition: "预订单成分标",
        preorderProductProductionNote: "预订单模特生产要求",
        backToWaitingProduction: "返回等待安排",
        printCropPlan: "打印裁剪单",
        productionId: "生产单号",
        printPatternImages: "印花图案",
        crop: "裁剪",
        add: "添加",
        clearAll: "清空",
        preorderColor: "下单色",
        specialColor: "使用色",
        noColorRules: "无颜色规则",
        hasColorRules: "特殊颜色",
    },
    production: {
        meaningOfTotalOutstandingPieces: "滞留总件数: 昨天与更早之前下单但至今仍未安排生产，以及{today}与更早之前开出裁剪单但至今仍未完成裁剪，相加的总件数。",
        meaningOfOutstandingCutting: "裁剪滞留: 昨天与更早之前开出的裁剪单，但至今仍未完成裁剪的总件数。",
        addNew: "新建样品/备货生产单",
        sepcialRequestsNote: "特殊要求注意",
        number: "生产编号",
        cropDate: "裁剪日期",
        byProduct: "按模特",
        byTemplate: "按版型",
        byMainTissue: "按主布料",
        byTissues: "按所有布料",
        todayTotalOrders: "已安排总件数（今日）",
        todayTotalCutPieces: "已裁剪总件数（今日）",
        totalOutstandingPieces: "滞留总件数",
        outstandingCutting: "裁剪滞留",
        needAccessories: "需要辅料",
        includePrintPatternsNoDyeing: "包含印布，不染洗",
        includePrintPatternsWithDyeing: "包含印布，染洗",
        includeColoredTissue: "包含色布",
        whiteEmbryo: "白胚布",
        closeToExpiration: "交期将至",
        outstanding: "滞留",
        productProperties: "模特特征",
        placeTimestamp: "下单日期",
        earliestExpirationTimestamp: "最早交期",
        productTemplateId: "版型",
        productName: "模特名",
        mainTissue: "主布料",
        printPattern: "印布花版",
        accessory: "辅料",
        customer: "客户",
        arrange: "安排",
        calculateConsumption: "合计耗料预估",
        logoAndComposition: "LOGO&成分标",
        compositionImages: "成分标图片",
        logo: "LOGO",
        compositionTag: "成分标",
        orderNote: "订单备注",
        confirmImportedProducts: "确认预订量",
        calculatedConsumption: "耗料预估",
        relatedOrders: "关联的预订模特",
        productionNote: "生产备注",
        preorderAmounts: "预订量",
        croppedAmounts: "裁剪量",
        finalCompleteAmounts: "最终产量",
        timelineLogging: "时间记录",
        printCropPlan: "开裁剪单",
        inProduction: "加工中",
        productionTable: "加工厂沟通表",
        delivery: "发货",
        plannedProductionAmounts: "计划生产量",
        setProductionTag: "设置生产标签",
        creationTimestamp: "下单日期",
        inProductionTimestamp: "安排日期",
        deliveredTimestamp: "发货日期",
        lastExpirationTimestamp: "最近交期",
        completeCropTimestamp: "完成裁剪",
    },
    permissionControl: {
        granted: "已有权限",
        permissionControl: "权限管理",
        chooseAnAccount: "请选择账号",
        id: "编号",
        username: "用户名",
        fullName: "名称",
        email: "邮箱",
        operation: "操作",
        changePassword: "修改账号信息",
        permission: "修改权限",
        status: "状态",
        refresh: "刷新",
        update: "更新权限",
    },
    user: {
        updateNote: "需要重新登录账号",
        username: "用户名",
        fullName: "姓名",
        update: "更新用户资料",
        password: "密码",
        newPassword: "新的密码",
        repeatPassword: "重新输入密码",
    },
    date: {
        today: "今天",
        yesterday: "昨天",
        last7Days: "最后七天",
        last14Days: "近14天",
        last30Days: "最后30天",
        thisMonth: "本月",
        lastMonth: "上个月",
        xMinutesAgo: "刚刚 | 一分钟前 | {time}分钟前",
        xHoursAgo: "一小时前 | {time}小时前",
        xDaysAgo: "一天前 | {time}天前",
        xMonthsAgo: "一月前 | {time}月前",
        xYearsAgo: "一年前 | {time}年前",
        xDaysToExpiration: "{days} 天后交期到",
        expiredXDaysAgo: "已超期 {days} 天",
    },
    error: {
        generic: {
            duplicateName: "名称已存在",
            alreadyInUseError: "无法删除，因为正在使用",
            duplicateProduct: "模特已存在, 请修改已有模特或者更换布料或印花",
            existProductTemplateId: "版型已存在，请更改版型，或者使用复制功能",
            unknownError: "未知错误",
            timeout: "连接超时！",
        },
    },
    default: {
        procedure: {
            cutting: "裁剪",
            fasoning: "加工",
            dyeing: "染洗",
            buttoning: "纽扣",
            ironing: "熨烫",
            other: "其他",
            printing: "印布",
            clothePrinting: "前后排印",
        },
        procedureType: {
            cutting: "裁剪 TAGLIO",
            fasoning: "加工 FASON",
            dyeing: "染洗 TINTORIA",
            buttoning: "纽扣 BOTTONE",
            ironing: "熨烫 STIRO",
            other: "其他 ALTRI",
            printing: "印布 STAMPERIA",
            clothePrinting: "前后排印 MAGLIA STAMPERIA",
        },
        tag: {
            noImage: "无图片",
            toComplete: "信息不全",
            emptyTissue: "缺少布料",
            notVisibleOnOrder: "下单不显示",
            noConsumption: "缺少用料",
            noWeight: "染洗缺少重量",
        },
        customer: "默认客人",
        supplier: "默认供应商",
        order: {
            state: {
                unshipped: "未发货",
                partiallyShipped: "部分发货",
                shipped: "已发货",
                cancelled: "已取消",
            },
        },
        orderProduct: {
            state: {
                waitingConfirmation: "生产待定",
                noProduction: "不生产",
                getFromInventory: "从备货出",
                createdProduction: "安排生产",
                waitingProduction: "等待安排生产",
                createCropPlanTimestamp: "开裁剪单",
                completeCropTimestamp: "完成裁剪",
                receivedTimestamp: "到货",
                deliveredTimestamp: "发货",
                waitingCropPlan: "等待开裁剪单",
                waitingCutting: "等待裁剪",
                inProduction: "加工中",
                waitingColorPlan: "待开颜色单",
                waitingPrintPicturePlan: "待开排印单",
                tallying: "理货中",
                waitingShipping: "等待发货",
                unshipped: "不发货",
                partiallyShipped: "部分发货",
                shipped: "已发货",
                cancelled: "已取消",
                cutProductFromStorage: "已裁备货",
                cutComplete: "完成裁剪",
                amountChange: "订单数变化",
            },
        },
    },
    component: {
        productCopyModal: "模特复制窗口",
        confirmProductionModal: "确认生产窗口",
        customerUpdateModal: "客户编辑窗口",
        productionTablePreferences: "加工厂沟通",
    },
    notification: {
        cropPlanCreated: "已创建生产计划",
        createOrder: "新的订单",
        updateOrder: "订单发生改动",
        enabled: "已开启通知",
    },
    dashboard: {
        welcome: "你好",
        workToDo: "今天需要处理的事务如下",
        nothingToDo: "无待办事项",

        shipping: "发货",
        shippingExpiration: "{length} 个交期将至订单待发货",
        waitingShipping: "{length} 个订单待发货",

        productionPlan: "生产计划",
        waitingProductionExpired: "{length} 交期将至模特等待安排生产",
        waitingCuttingExpired: "{length} 交期将至生产计划等待裁剪",
        waitingCropPlanExpired: "{length} 交期将至生产计划等待开裁剪单",
        waitingProductionSuspended: "{length} 个滞留订单模特等待安排生产",
        waitingCropPlanSuspended: "{length} 滞留生产计划等待开裁剪单",
        waitingCropSuspended: "{length} 滞留生产计划等待裁剪",
        waitingProduction: "{length} 个模特等待安排生产计划",
        waitingCutting: "{length} 个生产计划等待裁剪",
        waitingCropPlan: "{length} 个生产计划等待开裁剪单",

        preorders: "预订单",
        unpaid: "{length} 个订单客户待付款",
        // waitingConfirmationExpired: "{length} prenotazioni in attesa di accettazione in ritardo",
        waitingConfirmation: "{length} 个生产待定订单模特等待处理",

        models: "模特库",
        infoToUpdate: "{length} 个模特信息需要完善",
        noImage: "{length} 个模特图片未完善",
    },
    analytics: {
        preorderTotalAmounts: "预订单总件数",
        croppedTotalAmounts: "裁剪总件数",
        shippedTotalAmounts: "发货总件数",
        wastedPercentage: "裁剪损耗比",
        analyticsTable: "排行榜",
        top100Products: "模特TOP100",
        top20Colors: "颜色TOP20",
        byPreorderAmount: "按预定量",
        byShippedAmount: "按发货量",
        byOrderTimes: "按下单次数",
        byPopularity: "按热度",
        rank: "排名",
        trend: "走势",
        preorderAmount: "预定量",
        shippedAmount: "发货量",
        orderTimes: "预订单数",
        orderedTimes: "下单次数",
        popularity: "热度",
        photo: "照片",
        model: "模特",
        mainTissue: "主要布料",
        creationDate: "新建时间",
        price: "定价",
        tags: "标签",
        moreThan1OrderFromSameCustomer: "跟单",
        date: "新建时间",
        color: "颜色",
        trendTooltip: "{startDate} - {endDate} 比 {startPreqDate} - {endPreqDate} {subject}{toward}了{trend}%",
        toward: "减少了 | 增长了",
    },
    login: {
        login: "登录",
        welcome: "欢迎使用WeTEX智能系统",
        companyId: "企业号",
        userId: "用户名",
        password: "密码",
        clickToLogin: "一键登录",
        error: "系统错误",
        companyError: "企业号错误",
        passwordError: "密码错误",
        credentialError: "用户名错误",
        timeout: "连接超时！ 请刷新页面重试",
        close: "关闭",
        logout: "登出",
        accountInfo: "帐号信息",
    },
};

module.exports = {
    zh,
};

