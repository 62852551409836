import {
    defineComponent,
    h,
    onMounted,
    ref,
    resolveComponent,
    watch,
} from "vue";
import {
    RouterLink,
    useRoute,
    useRouter,
} from "vue-router";

import {
    CBadge,
    CSidebarNav,
    CNavItem,
    CNavGroup,
    CNavTitle,
} from "@coreui/vue-pro";
import nav from "@/_nav.js";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { user } from "@/user";

const normalizePath = (path) => decodeURI(path)
    .replace(/#.*$/, "")
    .replace(/(index)?\.(html)$/, "");

const isActiveLink = (route, link) => {
    if (link === undefined) {
        return false;
    }

    if (route.hash === link) {
        return true;
    }

    const currentPath = normalizePath(route.path);
    const targetPath = normalizePath(link);

    return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
    if (isActiveLink(route, item.to)) {
        return true;
    }

    if (item.items) {
        return item.items.some((child) => isActiveItem(route, child));
    }

    return false;
};

const AppSidebarNav = defineComponent({
    name: "AppSidebarNav",
    components: {
        CNavItem,
        CNavGroup,
        CNavTitle,
    },
    setup () {
        const { t, locale, } = useI18n();
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const firstRender = ref(true);
        const firstItem = ref({});
        onMounted(() => {
            firstRender.value = false;
        });
        watch(firstItem, async (currentValue, oldValue) => {
            if (!route.path.startsWith(`/${ currentValue.to.split("/")[1] }`)) { // change parent tab
                await router.push(currentValue.to);
            }
        });

        const renderItem = (item) => {
            if (item.to && item.to.startsWith("/language")) {
                if (item.items) {
                    return h(CNavGroup, {
                    }, {
                        togglerContent: () => [ h(resolveComponent("CIcon"), {
                            customClassName: "nav-icon",
                            name: item.icon,
                        }), t(item.name), ],
                        default: () => item.items.map((child) => renderItem(child)),
                    });
                }
                return h(RouterLink,
                    {
                        to: "#",
                        custom: true,
                    },
                    {
                        default: (props) => h(resolveComponent(item.component),
                            {
                                active: props.isActive,
                                href: props.href,
                                onClick: () => {
                                    store.commit("setLang", item.language);
                                    locale.value = item.language;
                                    nav.map((item) => renderItem(item));
                                },
                            },
                            {
                                default: () => [
                                    item.icon &&
                                        h(resolveComponent("CIcon"), {
                                            customClassName: "nav-icon",
                                            name: item.icon,
                                        }),
                                    t(item.name),
                                    item.badge &&
                                        h(CBadge,
                                            {
                                                class: "ms-auto",
                                                color: item.badge.color,
                                            },
                                            {
                                                default: () => item.badge.text,
                                            }),
                                ],
                            }),
                    });
            }
            else if (user?.descriptor.isAdmin || user?.descriptor?.permissions.includes(`permission.access.${item.name}`)) {
                if (item.items) {
                    return h(CNavGroup,
                        {
                            ...firstRender.value && {
                                visible: item.items.some((child) => isActiveItem(route, child)),
                            },
                        },
                        {
                            togglerContent: () => [ h(resolveComponent("CIcon"), {
                                customClassName: "nav-icon",
                                name: item.icon,
                            }), t(item.name), ],
                            default: () => {
                                firstItem.value = item.items[0];
                                return item.items.map((child) => renderItem(child));
                            },
                        });
                }
                return item.to
                    ? h(RouterLink,
                        {
                            to: item.to,
                            custom: true,
                        },
                        {
                            default: (props) => h(resolveComponent(item.component),
                                {
                                    active: props.isActive,
                                    href: props.href,
                                    onClick: () => {
                                        firstItem.value = item;
                                        props.navigate();
                                    },
                                },
                                {
                                    default: () => [
                                        item.icon &&
                                        h(resolveComponent("CIcon"), {
                                            customClassName: "nav-icon",
                                            name: item.icon,
                                        }),
                                        t(item.name),
                                        item.badge &&
                                        h(CBadge,
                                            {
                                                class: "ms-auto",
                                                color: item.badge.color,
                                            },
                                            {
                                                default: () => item.badge.text,
                                            }),
                                    ],
                                }),
                        })
                    : h(resolveComponent(item.component),
                        {},
                        {
                            default: () => t(item.name),
                        });
            }
        };

        return () => h(CSidebarNav,
            {},
            {
                default: () => nav.map((item) => renderItem(item)),
            });
    },
});
export { AppSidebarNav };
