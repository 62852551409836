<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <CForm @submit.prevent="submitCreate">
            <CModalHeader :close-button="false">
                <CModalTitle class="ms-3">{{ t("ingredient.tissue.addNew") }}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CNav variant="pills" layout="justified" role="tablist">
                    <CNavItem>
                        <CNavLink
                            href="javascript:void(0);"
                            :active="activeModalTab===0"
                            @click="activeModalTab=0"
                        >
                            {{ t("generic.essentialInformation") }}
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink
                            href="javascript:void(0);"
                            :active="activeModalTab===1"
                            @click="activeModalTab=1"
                        >
                            {{ t("generic.photo") }}
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane role="tabpanel" aria-labelledby="essentials-information-tab" :visible="activeModalTab === 0">
                        <CRow>
                            <CCol :xs="12">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient w-50">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("ingredient.tissue.category") }}</CFormLabel>
                                    <CFormSelect v-model:model-value="newElement.categoryId">
                                        <option v-for="category in tissueCategories" :key="category.id.toString()" :value="category.id.toString()">{{ category.isDefault ? t(category.name) : category.name }}</option>
                                    </CFormSelect>
                                </CInputGroup>
                            </CCol>
                            <CCol :xs="6">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.name") }} *</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="newElement.name" :invalid="errorMessage.length > 0" required/>
                                    <CFormFeedback invalid>
                                        {{ errorMessage }}
                                    </CFormFeedback>
                                </CInputGroup>
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.unitPrice") }}</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="newElement.price"/>
                                </CInputGroup>
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.width") }}</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="newElement.width"/>
                                </CInputGroup>
                            </CCol>
                            <CCol :xs="6">
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.suppliers") }}</CFormLabel>
                                    <search-select
                                        :options="suppliers"
                                        v-model:model-value="newElement.supplierId"
                                    ></search-select>
<!--                                    <CFormSelect v-model:model-value="newElement.supplierId">-->
<!--                                        <option v-for="supplier in suppliers" :key="supplier.id.toString()" :value="supplier.id.toString()">{{ supplier.isDefault ? t(supplier.name) : supplier.name }}</option>-->
<!--                                    </CFormSelect>-->
                                </CInputGroup>
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.weight") }} (g/m)</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="newElement.weight"/>
                                </CInputGroup>
                                <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                                    <CFormLabel class="w-100 fs-5 mb-1">{{ t("generic.shrinkage") }}</CFormLabel>
                                    <CFormInput type="text" v-model:model-value="newElement.shrinkage"/>
                                </CInputGroup>
                            </CCol>
                        </CRow>
                        <CInputGroup class="mt-3 p-1 bg-body bg-gradient">
                            <CFormLabel class="w-100 fs-5 mb-1">{{ t("ingredient.ingredientAndNote") }}</CFormLabel>
                            <CFormTextarea type="text" v-model:model-value="newElement.ingredientAndNote" />
                        </CInputGroup>
                        <CCol class="mt-3 p-1 bg-body bg-gradient">
                            <h5 class="text-info">{{ t("generic.requiredFieldMsg") }}</h5>
                        </CCol>
                    </CTabPane>
                    <CTabPane role="tabpanel" aria-labelledby="upload-photos-tab" :visible="activeModalTab === 1">
                        <CCol class="mt-3 p-1 bg-body bg-gradient">
                            <CFormLabel class="w-100 fs-5 mb-1">{{ t("ingredient.tissue.images") }}</CFormLabel>
                            <BaseImageUploader @upload="saveImages"/>
                            <CRow class="mt-4">
                                <CCol v-for="image in images" :key="image">
                                    <div class="my-3 t-col-4 cursor-pointer" style="position: relative;">
                                        <CImage class="t-col-4" thumbnail align="start" :src="image" @click="openLightbox(image)"></CImage>
                                        <button class="button-remove-image" @click.prevent="deleteImage(image)">
                                            <span class="icon-cross"></span>
                                        </button>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CTabPane>
                </CTabContent>
            </CModalBody>
            <CModalFooter>
                <CButton type="button" variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
                <CLoadingButton :loading="isConfirmButtonLoading" :disabled="isConfirmButtonLoading" type="submit" color="primary">{{ t("generic.confirm") }}</CLoadingButton>
            </CModalFooter>
        </CForm>
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="selectedImageIndex"
            :images="lightBoxImages"
            @hide="closeLightbox"
        ></BaseLightbox>
    </CModal>
</template>

<script>
import {
    onMounted,
    ref,
    watch,
} from "vue";
import { user } from "@/user";
import { readImage } from "@/views/utilities";
import { useI18n } from "vue-i18n";
import BaseLightbox from "@/components/base/BaseLightbox";
import SearchSelect from "@/components/pickers/SearchSelect";
import BaseImageUploader from "@/components/base/BaseImageUploader";

export default {
    name: "TissueAddModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: "",
        },
        suppliersArray: {
            type: Array,
            default: null,
        },
        categories: {
            type: Array,
            default: null,
        },
    },
    emits: [
        "close",
        "submit",
        "update:isVisible",
        "update:name",
    ],
    components: {
        BaseLightbox,
        SearchSelect,
        BaseImageUploader,
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const errorMessage = ref("");
        const activeModalTab = ref(0);
        const tissueCategories = ref([]);
        const suppliers = ref([]);
        const newElement = ref({
            name: "",
            price: "",
            weight: "",
            width: "",
            shrinkage: "",
            ingredientAndNote: "",
            lastUpdateTimeStamp: "",
            creationTimeStamp: "",
            images: [],
            categoryId: "",
            supplierId: "",
        });
        const images = ref([]);
        const lightBoxImages = ref([]);
        const selectedImageIndex = ref(0);
        const isLightboxVisible = ref(false);

        const isConfirmButtonLoading = ref(false);

        function closeModal () {
            emit("update:name", "");
            emit("update:isVisible", false);
        }
        function openLightbox (image) {
            selectedImageIndex.value = images.value.findIndex((base64ToFind) => base64ToFind === image);
            lightBoxImages.value = images.value;
            isLightboxVisible.value = true;
        }
        function closeLightbox () {
            lightBoxImages.value = [];
            isLightboxVisible.value= false;
        }
        function resetNewElement () {
            images.value = [];
            for (const key of Object.keys(newElement.value)) {
                if (Array.isArray(newElement[key])) {
                    newElement.value[key] = [];
                }
                else {
                    newElement.value[key] = "";
                }
            }
            newElement.value.images = [];
            newElement.value.supplierId = suppliers.value.find((s) => s.isDefault).id.toString();
            newElement.value.categoryId = tissueCategories.value.find((c) => c.isDefault).id.toString();
        }
        async function submitCreate () {
            isConfirmButtonLoading.value = true;

            const formData = new FormData();

            for (const image of newElement.value.images) {
                formData.append("images", image);
            }

            for (const [ key, value, ] of Object.entries(newElement.value)) {
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            }

            try {
                const response = await user.createNewTissue(formData);
                if (response) {
                    resetNewElement();
                    closeModal();
                    emit("submit", response);
                }
            }
            catch {}

            isConfirmButtonLoading.value = false;
        }

        async function deleteImage (imageDataUrl) {
            newElement.value.images = newElement.value.images.filter((imageToRemove) => imageToRemove.url !== imageDataUrl);
            images.value = images.value.filter((imageToRemove) => imageToRemove !== imageDataUrl);
        }

        async function saveImages (files) {
            newElement.value.images = [ ...newElement.value.images, ...files, ];

            images.value = [];

            for (let i=0; i<newElement.value.images.length; i++) {
                newElement.value.images[i].url = await readImage(newElement.value.images[i]);
                images.value = [ ...images.value, newElement.value.images[i].url, ];
            }
        }

        async function init () {
            tissueCategories.value = props.categories ? props.categories : await user.getAllTissueCategories();
            suppliers.value = props.suppliersArray ? props.suppliersArray : (await user.getAllSuppliers({
                archived: false,
            }))?.suppliers ?? [];

            console.log(suppliers.value);
        }

        watch(() => props.categories, async (newValue) => {
            tissueCategories.value = props.categories ? props.categories : await user.getAllTissueCategories();
        });

        watch(() => props.suppliersArray, async (newValue) => {
            suppliers.value = props.suppliersArray ? props.suppliersArray : (await user.getAllSuppliers({
                archived: false,
            }))?.suppliers ?? [];
        });

        watch(() => props.isVisible, async (newValue) => {
            if (newValue) {
                await init();
                resetNewElement();
                if (props.name) {
                    newElement.value.name = props.name;
                }
            }
        });

        return {
            t,
            newElement,
            images,
            isLightboxVisible,
            lightBoxImages,
            selectedImageIndex,
            errorMessage,
            activeModalTab,
            tissueCategories,
            suppliers,
            isConfirmButtonLoading,
            submitCreate,
            closeModal,
            openLightbox,
            closeLightbox,
            deleteImage,
            saveImages,
            resetNewElement,
        };
    },
};
</script>

<style scoped>

</style>
