const { server, } = require("./server");

export const AUTHENTICATION_TOKEN_KEY = "WeTEX::AuthenticationToken";
export const USER_DESCRIPTOR_STORAGE_KEY = "WeTEX::User";
export const USER_LANG_KEY = "WeTEX::UserLang";

export const user = Object.freeze({
    get authenticationToken () {
        return localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
    },

    set authenticationToken (token) {
        localStorage.setItem(AUTHENTICATION_TOKEN_KEY, token);
    },

    setAuthenticationToken (token) {
        this.authenticationToken = token;
    },

    setPermissions (permissions) {
        this.descriptor.user.permissions = permissions;
        localStorage.setItem(USER_DESCRIPTOR_STORAGE_KEY, JSON.stringify(this.descriptor));
    },

    setLang (locale) {
        this.lang = locale;
    },

    get descriptor () {
        return JSON.parse(localStorage.getItem(USER_DESCRIPTOR_STORAGE_KEY));
    },

    set descriptor (descriptor) {
        localStorage.setItem(USER_DESCRIPTOR_STORAGE_KEY, JSON.stringify(descriptor));
    },

    get lang () {
        console.log("local: ", localStorage.getItem(USER_LANG_KEY));
        return localStorage.getItem(USER_LANG_KEY);
    },

    set lang (locale) {
        localStorage.setItem(USER_LANG_KEY, locale);
    },

    get isLoggedIn () {
        return this.authenticationToken !== null;
    },

    get isAnonymous () {
        return !this.isLoggedIn;
    },

    get authenticationHeaders () {
        const headers = {};

        if (this.authenticationToken) {
            headers["Authentication-Token"] = this.authenticationToken;
        }

        return headers;
    },

    async signup (userDescriptor) {
        const response = await server.post("user/signup", userDescriptor);
        const responseData = response.data;

        if (responseData?.result?.token) {
            this.setAuthenticationToken(responseData.result.token);
            this.descriptor = responseData.result.user;
        }

        return responseData;
    },

    async login ({
        username,
        companyId,
        password,
    }) {
        const response = await server.post("user/login", {
            username,
            companyId,
            password,
        });
        const responseData = response.data;

        if (responseData?.result?.token) {
            this.setAuthenticationToken(responseData.result.token);
            this.descriptor = responseData.result.user;
        }

        return responseData;
    },

    async getAllSuppliers (request) {
        return (await server.get("suppliers", request)).data?.result;
    },

    async createNewSupplier (newSupplier) {
        return (await server.post("supplier/create", newSupplier)).data?.result?.supplier;
    },

    async updateSupplier (supplierToUpdate) {
        return (await server.post("supplier/update", supplierToUpdate)).data?.result?.supplier;
    },

    async archiveSuppliers (suppliers) {
        return (await server.post("supplier/archive", suppliers)).data?.result;
    },

    async unarchiveSuppliers (suppliers) {
        return (await server.post("supplier/unarchive", suppliers)).data?.result;
    },

    async updateSuppliersCategory (descriptor) {
        return (await server.post("supplier/update-category", descriptor)).data?.result;
    },

    async getAllCustomers (request) {
        return (await server.get("customers", request)).data?.result;
    },

    async createNewCustomer (newCustomer) {
        return (await server.post("customer/create", newCustomer)).data?.result?.customer;
    },

    async updateCustomer (customerToUpdate) {
        return (await server.post("customer/update", customerToUpdate)).data?.result?.customer;
    },

    async archiveCustomers (customers) {
        return (await server.post("customer/archive", customers)).data?.result;
    },

    async unarchiveCustomers (customers) {
        return (await server.post("customer/unarchive", customers)).data?.result;
    },

    async updateCustomersCategory (descriptor) {
        return (await server.post("customer/update-category", descriptor)).data?.result;
    },

    async deleteCompositionImage (descriptor) {
        return (await server.post("customer/delete-composition-image", descriptor)).data?.result;
    },

    async getAllColors (descriptor) {
        return (await server.get("colors", descriptor)).data?.result;
    },

    async getAllSizes (descriptor) {
        return (await server.get("sizes", descriptor)).data?.result;
    },

    async getAllTags (descriptor) {
        return (await server.get("tags", descriptor)).data?.result;
    },

    async updateColor (colorToUpdate) {
        return (await server.post("color/update", colorToUpdate)).data?.result?.color;
    },

    async createColor (newColor) {
        return (await server.post("color/create", newColor)).data?.result?.color;
    },

    async updateSize (sizeToUpdate) {
        return (await server.post("size/update", sizeToUpdate)).data?.result?.size;
    },

    async createSize (newSize) {
        return (await server.post("size/create", newSize)).data?.result?.size;
    },

    async getAllProductCategories () {
        return (await server.get("product/categories")).data?.result;
    },

    async updateProductCategoriesOrder (descriptor) {
        return (await server.post("product/categories/update-order", descriptor)).data?.result;
    },

    async updateColorsOrder (descriptor) {
        return (await server.post("color/update-order", descriptor)).data?.result;
    },

    async updateSizesOrder (descriptor) {
        return (await server.post("size/update-order", descriptor)).data?.result;
    },

    async createProductCategory (descriptor) {
        return (await server.post("product/category/create", descriptor)).data?.result;
    },

    async updateProductCategory (descriptor) {
        return (await server.post("product/category/update", descriptor)).data?.result;
    },

    async updateProductsCategory (descriptor) {
        return (await server.post("product/update-category", descriptor)).data?.result;
    },

    async deleteProductCategory (descriptor) {
        return (await server.post("product/category/delete", descriptor)).data?.result;
    },

    async updateAccessoryCategoriesOrder (descriptor) {
        return (await server.post("accessory/categories/update-order", descriptor)).data?.result;
    },

    async createAccessoryCategory (descriptor) {
        return (await server.post("accessory/category/create", descriptor)).data?.result;
    },

    async updateAccessoryCategory (descriptor) {
        return (await server.post("accessory/category/update", descriptor)).data?.result;
    },

    async deleteAccessoryCategory (descriptor) {
        return (await server.post("accessory/category/delete", descriptor)).data?.result;
    },

    async updatePrintPatternCategoriesOrder (descriptor) {
        return (await server.post("print-pattern/categories/update-order", descriptor)).data?.result;
    },

    async createPrintPatternCategory (descriptor) {
        return (await server.post("print-pattern/category/create", descriptor)).data?.result;
    },

    async updatePrintPatternCategory (descriptor) {
        return (await server.post("print-pattern/category/update", descriptor)).data?.result;
    },

    async deletePrintPatternCategory (descriptor) {
        return (await server.post("print-pattern/category/delete", descriptor)).data?.result;
    },

    async getAllCustomerCategories () {
        return (await server.get("customer/categories")).data?.result;
    },

    async updateCustomerCategoriesOrder (descriptor) {
        return (await server.post("customer/categories/update-order", descriptor)).data?.result;
    },

    async createCustomerCategory (descriptor) {
        return (await server.post("customer/category/create", descriptor)).data?.result;
    },

    async updateCustomerCategory (descriptor) {
        return (await server.post("customer/category/update", descriptor)).data?.result;
    },

    async deleteCustomerCategory (descriptor) {
        return (await server.post("customer/category/delete", descriptor)).data?.result;
    },

    async getAllSupplierCategories () {
        return (await server.get("supplier/categories")).data?.result;
    },

    async updateSupplierCategoriesOrder (descriptor) {
        return (await server.post("supplier/categories/update-order", descriptor)).data?.result;
    },

    async createSupplierCategory (descriptor) {
        return (await server.post("supplier/category/create", descriptor)).data?.result;
    },

    async updateSupplierCategory (descriptor) {
        return (await server.post("supplier/category/update", descriptor)).data?.result;
    },

    async deleteSupplierCategory (descriptor) {
        return (await server.post("supplier/category/delete", descriptor)).data?.result;
    },

    async getAllTissues (descriptor) {
        return (await server.get("tissues", descriptor)).data?.result;
    },

    async getAllTissueCategories () {
        return (await server.get("tissue/categories")).data?.result;
    },

    async updateTissue (descriptor) {
        return (await server.post("tissue/update", descriptor)).data?.result?.tissue;
    },

    async updateTissuesCategory (descriptor) {
        return (await server.post("tissue/update-category", descriptor)).data?.result;
    },

    async createNewTissue (descriptor) {
        return (await server.post("tissue/create", descriptor)).data?.result?.tissue;
    },

    async deleteTissueImage (descriptor) {
        return (await server.post("tissue/delete-image", descriptor)).data?.result;
    },

    async archiveTissues (descriptor) {
        return (await server.post("tissue/archive", descriptor)).data?.result;
    },

    async unarchiveTissues (descriptor) {
        return (await server.post("tissue/unarchive", descriptor)).data?.result;
    },

    async getAllAccessories (descriptor) {
        return (await server.get("accessories", descriptor)).data?.result;
    },

    async getAllAccessoryCategories () {
        return (await server.get("accessory/categories")).data?.result;
    },

    async updateAccessory (descriptor) {
        return (await server.post("accessory/update", descriptor)).data?.result?.accessory;
    },

    async updateAccessoriesCategory (descriptor) {
        return (await server.post("accessory/update-category", descriptor)).data?.result;
    },

    async createNewAccessory (descriptor) {
        return (await server.post("accessory/create", descriptor)).data?.result?.accessory;
    },

    async deleteAccessoryImage (descriptor) {
        return (await server.post("accessory/delete-image", descriptor)).data?.result;
    },

    async archiveAccessories (descriptor) {
        return (await server.post("accessory/archive", descriptor)).data?.result;
    },

    async unarchiveAccessories (descriptor) {
        return (await server.post("accessory/unarchive", descriptor)).data?.result;
    },

    async getAllPrintPatterns (descriptor) {
        return (await server.get("print-patterns", descriptor)).data?.result;
    },

    async getAllPrintPatternCategories () {
        return (await server.get("print-pattern/categories")).data?.result;
    },

    async updatePrintPatternsCategory (descriptor) {
        return (await server.post("print-pattern/update-category", descriptor)).data?.result;
    },

    async updatePrintPicturesCategory (descriptor) {
        return (await server.post("print-picture/update-category", descriptor)).data?.result;
    },

    async updatePrintPattern (descriptor) {
        return (await server.post("print-pattern/update", descriptor)).data?.result?.printPattern;
    },

    async createNewPrintPattern (descriptor) {
        return (await server.post("print-pattern/create", descriptor)).data?.result?.printPattern;
    },

    async deletePrintPatternImage (descriptor) {
        return (await server.post("print-pattern/delete-image", descriptor)).data?.result;
    },

    async archivePrintPatterns (descriptor) {
        return (await server.post("print-pattern/archive", descriptor)).data?.result;
    },

    async unarchivePrintPatterns (descriptor) {
        return (await server.post("print-pattern/unarchive", descriptor)).data?.result;
    },

    async getAllPrintPictures (descriptor) {
        return (await server.get("print-pictures", descriptor)).data?.result;
    },

    async getAllPrintPictureCategories () {
        return (await server.get("print-picture/categories")).data?.result;
    },

    async updatePrintPicture (descriptor) {
        return (await server.post("print-picture/update", descriptor)).data?.result?.printPicture;
    },

    async createNewPrintPicture (descriptor) {
        return (await server.post("print-picture/create", descriptor)).data?.result?.printPicture;
    },

    async deletePrintPictureImage (descriptor) {
        return (await server.post("print-picture/delete-image", descriptor)).data?.result;
    },

    async archivePrintPictures (descriptor) {
        return (await server.post("print-picture/archive", descriptor)).data?.result;
    },

    async unarchivePrintPictures (descriptor) {
        return (await server.post("print-picture/unarchive", descriptor)).data?.result;
    },

    async createNewProcedure (descriptor) {
        return (await server.post("procedure/create", descriptor)).data?.result?.procedure;
    },

    async updateProcedure (descriptor) {
        return (await server.post("procedure/update", descriptor)).data?.result?.procedure;
    },

    async deleteProcedure (descriptor) {
        return (await server.post("procedure/delete", descriptor)).data?.result;
    },

    async getAllProcedures (descriptor) {
        return (await server.get("procedures", descriptor)).data?.result;
    },

    async getAllProcedureTypes () {
        return (await server.get("procedure/types")).data?.result;
    },

    async createNewProduct (descriptor) {
        return (await server.post("product/create", descriptor)).data?.result?.product;
    },

    async updateProduct (descriptor) {
        return (await server.post("product/update", descriptor)).data?.result?.product;
    },

    async deleteProduct (descriptor) {
        return (await server.post("product/delete", descriptor)).data?.result;
    },

    async getAllProducts (descriptor) {
        return (await server.post("products", descriptor, true)).data?.result;
    },

    async archiveProducts (descriptor) {
        return (await server.post("product/archive", descriptor)).data;
    },

    async unarchiveProducts (descriptor) {
        return (await server.post("product/unarchive", descriptor)).data;
    },

    async getAllProductTemplates (descriptor) {
        return (await server.get("templates", descriptor)).data?.result;
    },

    async getProductById (descriptor) {
        return (await server.get("product", descriptor)).data?.result?.product;
    },

    async getProductTemplateById (descriptor) {
        return (await server.get("template", descriptor)).data?.result?.productTemplate;
    },

    async updateProductVisibleOnOrder (descriptor) {
        return (await server.post("product/set-visible-on-order", descriptor)).data;
    },

    async addTagToProduct (descriptor) {
        return (await server.post("product/add-tags", descriptor)).data?.result;
    },

    async createTag (descriptor) {
        return (await server.post("tag/create", descriptor)).data?.result?.tag;
    },

    async bulkChangeTags (descriptor) {
        return (await server.post("product/change-tags", descriptor)).data?.result;
    },

    async getProductPage () {
        return (await server.get("product-page")).data?.result;
    },

    async getOrderPage () {
        return (await server.get("order-page")).data?.result;
    },

    async getProductionPage () {
        return (await server.get("production-page")).data?.result;
    },

    async plannedProductionOrderList () {
        return (await server.get("planned-production-order-list")).data?.result;
    },

    async waitingProductionOrderList () {
        return (await server.get("waiting-production-order-list")).data?.result;
    },

    async preorderByProduct () {
        return (await server.get("preorder-by-product")).data?.result;
    },

    async getShippingPage () {
        return (await server.get("shipping-page")).data?.result;
    },

    async createNewOrder (descriptor) {
        return (await server.post("order/create", descriptor)).data?.result?.order;
    },

    async getHistoricalOrderColors (descriptor) {
        return (await server.post("order/historical-colors", descriptor)).data?.result;
    },

    async getAllOrdersByProduct (descriptor) {
        return (await server.post("orders/by-product", descriptor, true)).data?.result;
    },

    async getAllOrdersByOrder (descriptor) {
        return (await server.post("orders/by-order", descriptor, true)).data?.result;
    },

    async getAllOrdersByOrderForTable (descriptor) {
        return (await server.post("orders/by-order-table", descriptor, true)).data?.result;
    },

    async getAllUsers () {
        return (await server.get("users")).data?.result;
    },

    async updateUser (descriptor) {
        return (await server.post("user/update", descriptor)).data;
    },

    async updateUserPermissions (descriptor) {
        return (await server.post("user/update-permissions", descriptor)).data;
    },

    async getAllOrderTags (descriptor) {
        return (await server.get("order/tags", descriptor)).data?.result;
    },

    async bulkChangeOrderTags (descriptor) {
        return (await server.post("order/change-tags", descriptor)).data?.result;
    },

    async createOrderTag (descriptor) {
        return (await server.post("order/tag/create", descriptor)).data?.result?.orderTag;
    },

    async getAllProductionTags (descriptor) {
        return (await server.get("production/tags", descriptor)).data?.result;
    },

    async bulkChangeProductionTags (descriptor) {
        return (await server.post("production/tag/update", descriptor)).data?.result;
    },

    async createProductionTag (descriptor) {
        return (await server.post("production/tag/create", descriptor)).data?.result?.productionTag;
    },

    async getAllOrderProductsByProduct (descriptor) {
        return (await server.post("production/waiting/by-product", descriptor, true)).data?.result;
    },

    async getAllOrderProductsByMainTissue (descriptor) {
        return (await server.post("production/waiting/by-main-tissue", descriptor, true)).data?.result;
    },

    async getAllOrderProductsByTissues (descriptor) {
        return (await server.post("production/waiting/by-tissues", descriptor, true)).data?.result;
    },

    async getAllOrderProductsByTemplate (descriptor) {
        return (await server.post("production/waiting/by-template", descriptor, true)).data?.result;
    },

    async setProductInventoryAmountsById (descriptor) {
        return (await server.post("product/inventory/set", descriptor)).data?.result;
    },

    async resetProductInventoryAmountsById (descriptor) {
        return (await server.post("product/inventory/reset", descriptor)).data?.result;
    },

    async cancelOrder (descriptor) {
        return (await server.post("order/cancel", descriptor)).data?.result;
    },

    async getAllProductsReadyToOrder (descriptor) {
        return (await server.post("products/ready-to-order", descriptor, true)).data?.result;
    },

    async getOrderById (descriptor) {
        return (await server.get("order", descriptor)).data?.result?.order;
    },

    async updateOrder (descriptor) {
        return (await server.post("order/update", descriptor)).data?.result;
    },

    async deleteOrder (descriptor) {
        return (await server.post("order/delete", descriptor)).data?.result;
    },

    async deleteOrderRequest (descriptor) {
        return (await server.post("order-request/delete", descriptor)).data?.result;
    },

    async cancelOrderRequest (descriptor) {
        return (await server.post("order-request/cancel", descriptor)).data?.result;
    },

    async createProduction (descriptor) {
        return (await server.post("production/create", descriptor)).data?.result?.production;
    },

    async updateProduction (descriptor) {
        return (await server.post("production/update", descriptor)).data?.result?.production;
    },

    async getAllProductions (descriptor) {
        return (await server.post("productions", descriptor, true)).data?.result;
    },

    async makeOrderDraft (descriptor) {
        return (await server.post("order/draft/create", descriptor)).data?.result;
    },

    async getOrderDraft (descriptor) {
        return (await server.get("order/draft", descriptor)).data?.result.draft;
    },

    async getOrderProductById (descriptor) {
        return (await server.get("order-product", descriptor)).data?.result.orderProduct;
    },

    async updateOrderNote (descriptor) {
        return (await server.post("order/update-note", descriptor)).data?.result;
    },

    async changeOrderProductState (descriptor) {
        return (await server.post("order-product/update-state", descriptor)).data?.result;
    },

    async restoreOrder (descriptor) {
        return (await server.post("order/restore", descriptor)).data?.result;
    },

    async restoreOrderRequest (descriptor) {
        return (await server.post("order-request/restore", descriptor)).data?.result;
    },

    async getProductionById (descriptor) {
        return (await server.get("production", descriptor)).data?.result?.production;
    },

    async bulkUpdateOrderTags (descriptor) {
        return (await server.post("order-product/update-tag", descriptor)).data?.result;
    },

    async updateProductionNote (descriptor) {
        return (await server.post("production/update-note", descriptor)).data?.result;
    },

    async createCropPlan (descriptor) {
        return (await server.post("crop-plan/create", descriptor)).data?.result?.cropPlan;
    },

    async getCropPlan (descriptor) {
        return (await server.get("crop-plan", descriptor)).data?.result?.cropPlan;
    },

    async completeCropPlan (descriptor) {
        return (await server.post("crop-plan/complete", descriptor)).data?.result?.cropPlan;
    },

    async getProductionTablePreference () {
        return (await server.get("preference", {
            name: "productionTablePreference",
        })).data?.result?.preference;
    },

    async updateProductionTablePreference (preference) {
        return (await server.post("preference/update", {
            name: "productionTablePreference",
            preference,
        })).data?.result?.preference;
    },

    async getPrintReceiptPreference () {
        return (await server.get("preference", {
            name: "printReceiptPreference",
        })).data?.result?.preference;
    },

    async updatePrintReceiptPreference (preference) {
        return (await server.post("preference/update", {
            name: "printReceiptPreference",
            preference,
        })).data?.result?.preference;
    },

    async backToWaitingProduction (descriptor) {
        return (await server.post("production/back-to-waiting", descriptor)).data?.result;
    },

    async getProductionSummary () {
        return (await server.get("production/summary")).data?.result;
    },

    async getProductionPrintPattern (descriptor) {
        return (await server.get("production/print-pattern", descriptor)).data?.result?.printPattern;
    },

    async getProductionTissue (descriptor) {
        return (await server.get("production/tissue", descriptor)).data?.result?.tissue;
    },

    async deleteProductionTag (descriptor) {
        return (await server.post("production/tag/delete", descriptor)).data?.result;
    },

    async deleteOrderTag (descriptor) {
        return (await server.post("order/tag/delete", descriptor)).data?.result;
    },

    async deleteTag (descriptor) {
        return (await server.post("tag/delete", descriptor)).data?.result;
    },

    async getPrintBuffer (descriptor) {
        return (await server.get("print", descriptor)).data?.result;
    },

    async getShippingProducts (descriptor) {
        return (await server.post("shipping/products", descriptor)).data?.result;
    },

    async getShippingCustomers (descriptor) {
        return (await server.post("shipping/customers", descriptor)).data?.result;
    },

    async updateRequestShipped (descriptor) {
        return (await server.post("shipping/update-shipped", descriptor)).data?.result;
    },

    async fixTopCustomer (descriptor) {
        return (await server.post("shipping/fixed-top-customer", descriptor)).data?.result;
    },

    async fixTopProduct (descriptor) {
        return (await server.post("shipping/fixed-top-product", descriptor)).data?.result;
    },

    async updateRequestArrive (descriptor) {
        return (await server.post("shipping/update-arrive", descriptor)).data?.result;
    },

    async completeShippingByRequestIds (descriptor) {
        return (await server.post("shipping/complete-all", descriptor)).data?.result;
    },

    async getAllOrderProductStates () {
        return (await server.get("order/product/states")).data?.result?.states;
    },

    async getDailyAnalytics () {
        return (await server.post("analytics/daily")).data?.result;
    },

    async getAnalyticsByDateRange (descriptor) {
        return (await server.post("analytics/range", descriptor)).data?.result;
    },

    async getAnalyticsGraphic (descriptor) {
        return (await server.post("analytics/graphic", descriptor)).data?.result;
    },

    async getTodoList (descriptor) {
        return (await server.post("todo-list", descriptor)).data?.result;
    },

    async getIronFactoryProduct (descriptor) {
        return (await server.post("shipping/iron-factory", descriptor)).data?.result;
    },

    async archiveColor (descriptor) {
        return (await server.post("color/archive", descriptor)).data?.result;
    },

    async archiveSize (descriptor) {
        return (await server.post("size/archive", descriptor)).data?.result;
    },

    async getAllShipping (descriptor) {
        return (await server.post("shipping", descriptor, true)).data?.result;
    },

    async getTop100Product (descriptor) {
        return (await server.post("analytics/top-100-product", descriptor)).data?.result;
    },

    async getTop20Color (descriptor) {
        return (await server.post("analytics/top-20-color", descriptor)).data?.result;
    },

    async updateOrderShippingAmounts (descriptor) {
        return (await server.post("order-request/update-shipping-amounts", descriptor)).data?.result;
    },

    async historicalColorMultiSelect (descriptor) {
        return (await server.get("historical-color-multi-select", descriptor)).data?.result;
    },

    async getAllReplanRequests (descriptor) {
        return (await server.post("production/replan-requests", descriptor, true)).data?.result;
    },


    logout () {
        localStorage.removeItem(AUTHENTICATION_TOKEN_KEY);
        localStorage.removeItem(USER_DESCRIPTOR_STORAGE_KEY);
    },
});
