<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal" scrollable>

        <CModalHeader>
            <CModalTitle>{{ t("color.addNew") }}</CModalTitle>
        </CModalHeader>

        <CModalBody>
            <CRow class="py-3 ps-4">
                <CCol xs="auto" class="p-0">
                    <CFormInput
                        class="t-col-4"
                        v-model:model-value="searchValue"
                        type="search"
                        :placeholder="t('generic.search')"
                        @input="onInputSearchValue"
                    />
                </CCol>
            </CRow>
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem component="button" v-for="size in sizes" :key="size.id" @click="toggleSelectSize(size)">
                        <CRow class="ps-0 pb-0">
                            <CCol class="ps-0">
                                <CRow class="ps-3">
                                    <span class="fs-4 py-2">{{ size.isDefault ? t(size.name) : size.name }}</span>
                                </CRow>
                            </CCol>
                            <CCol class="d-flex align-items-center justify-content-end pe-3">
                                <CIcon v-if="isSelectedSize(size.id)" size="xl" icon="cil-check-alt" class="text-info"></CIcon>
                            </CCol>
                        </CRow>
                    </CListGroupItem>
                    <CListGroupItem component="button" v-if="sizes.length === 0" @click="openSizeAddModal">{{ t("size.addNew") }}</CListGroupItem>
                </CListGroup>
            </CRow>
        </CModalBody>

        <CModalFooter>
            <CButton class="me-3" color="secondary" variant="outline" @click="closeModal">{{ t("generic.undo") }}</CButton>
            <CButton color="primary" @click="confirmSelectedSizes">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>

        <size-add-modal
            ref="sizeAddModal"
            v-model:is-visible="isSizeAddModalVisible"
            @submit="toggleSelectSize"
        ></size-add-modal>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    ref, watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";
import SizeAddModal from "@/components/modals/SizeAddModal";

export default {
    name: "SizeMultiSelect",
    components: {
        SizeAddModal,
    },
    emits: [ "selectSizes", "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        selectedSizes: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const sizes = ref([]);

        const searchValue = ref("");
        const searchField = ref("name");

        const selectedSizesCopy = ref([]);

        const sizeAddModal = ref(null);
        const isSizeAddModalVisible = ref(false);

        function openSizeAddModal () {
            sizeAddModal.value.preselectName(searchValue.value);
            isSizeAddModalVisible.value = true;
        }

        function closeModal () {
            emit("update:isVisible", false);
        }


        function toggleSelectSize (size) {
            if (!isSelectedSize(size.id)) {
                selectedSizesCopy.value = [ ...selectedSizesCopy.value, size, ];
            }
            else {
                selectedSizesCopy.value = selectedSizesCopy.value.filter((p) => p.id !== size.id);
            }
            searchValue.value = "";
            onInputSearchValue();
        }

        function isSelectedSize (sizeId) {
            return Boolean(selectedSizesCopy.value.find((p) => p.id === sizeId));
        }

        function confirmSelectedSizes () {
            emit("selectSizes", selectedSizesCopy.value.sort((a, b) => a.id - b.id));
            closeModal();
        }

        watch(async () => props.isVisible, async (newValue, oldValue) => {
            if (newValue) {
                selectedSizesCopy.value = [ ...props.selectedSizes, ];

                sizes.value = (await user.getAllSizes()).filter((s) => !s.isArchived || selectedSizesCopy.value.find((sS) => s.id === sS.id));
            }
        });

        const onInputSearchValue = debounce(async () => {
            const descriptor = {};
            descriptor.filterField = searchField.value;
            descriptor.filterValue = searchValue.value;
            sizes.value = (await user.getAllSizes()).filter((s) => !s.isArchived || selectedSizesCopy.value.find((sS) => s.id === sS.id));
        }, 50);

        return {
            t,
            sizes,
            searchValue,
            searchField,
            onInputSearchValue,
            isSizeAddModalVisible,
            sizeAddModal,
            closeModal,
            toggleSelectSize,
            isSelectedSize,
            confirmSelectedSizes,
            openSizeAddModal,
        };
    },
};
</script>

<style scoped>

</style>
