import { createI18n } from "vue-i18n";
import { it } from "@/assets/i18n/it";
import { zh } from "@/assets/i18n/zh";

const localData = {
    legacy: false, // Use Composition API
    globalInjection: true,
    locale: "zh",
    messages: {
        it,
        zh,
    },
};

export const i18n = createI18n(localData);

export const setupI18n = {
    install (app) {
        app.use(i18n);
    },
};

