<template>
    <CModal :visible="isVisible" @close="close">
        <CForm @submit.prevent="createProperty">
            <CModalHeader  :close-button="false">
                <CModalTitle>{{ t("settings.productProperty.addNewColor") }}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CContainer>
                    <CCol class="mt-0 p-1 bg-body bg-gradient">
                        <h5 class="text-info">{{ t("generic.requiredFieldMsg") }}</h5>
                    </CCol>
                    <CInputGroup class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("settings.productProperty.name") }} *</CFormLabel>
                        <CFormInput autofocus type="text" v-model:model-value="newProperty"></CFormInput>
                    </CInputGroup>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton type="button" color="secondary" @click="close">{{ t("generic.undo") }}</CButton>
                <CButton type="submit" color="primary">{{ t("generic.confirm") }}</CButton>
            </CModalFooter>
        </CForm>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { user } from "@/user";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
    name: "ColorAddModal",
    emit: [ "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const store = useStore();

        const newProperty = ref("");

        function close () {
            emit("update:isVisible", false);
        }

        async function createProperty () {
            try {
                const response = await user.createColor({ name: newProperty.value, });
                newProperty.value = "";
                if (response) {
                    emit("submit", response);
                }
                else {
                    store.dispatch("sendNotification", {
                        header: t("component.colorAddModal"),
                        body: t("error.generic.duplicate"),
                    }).then();
                }
                close();
            }
            catch {}
        }

        function preselectName (name) {
            newProperty.value = name;
        }

        return {
            t,
            newProperty,
            close,
            createProperty,
            preselectName,
        };
    },
};
</script>

<style scoped>

</style>
