import { createStore } from "vuex";
import { WSKeepAlive, hasNotification } from "@/views/utilities";
import { WS_API_URI } from "@/server";
import { user } from "@/user";
import router from "@/router";
import { i18n } from "@/i18n";
const { t, } = i18n.global;

export default createStore({
    state: {
        locale: user?.lang ?? "zh",
        sidebarVisible: "",
        sidebarUnfoldable: false,
        tabLoading: 0,
        globalNotifications: [],
        isVisibleProductAddModal: false,
        isVisiblePreorderCreateModal: false,
        isVisibleCreateCropPlanModal: false,
        isVisiblePrintReceiptModal: false,
        selectedProductionId: null,
        ws: null,
        lightboxImages: [],
        isLightboxVisible: false,
        selectedImageIndex: 0,
        filter: {},
        toReloadPage: false,
        previousSelectedElements: {},
        onError: false,
    },
    mutations: {
        setLang (state, lang) {
            user.setLang(lang);
            state.locale = lang;
        },
        toggleSidebar (state) {
            state.sidebarVisible = !state.sidebarVisible;
        },
        toggleUnfoldable (state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable;
        },
        updateSidebarVisible (state, payload) {
            state.sidebarVisible = payload.value;
        },
        enableTabLoading (state) {
            state.tabLoading++;
        },
        disableTabLoading (state) {
            if (state.tabLoading > 0) {
                state.tabLoading--;
            }
        },
        showError (state) {
            state.tabLoading++;
            state.onError = true;
        },
        hideError (state) {
            if (state.tabLoading > 0) {
                state.tabLoading--;
            }
            state.onError = false;
        },
        pushNotification (state, payload) {
            state.globalNotifications.push(payload);
        },
        popNotification (state) {
            state.globalNotifications.pop();
        },
        deleteNotificationByFunctionName (state, payload) {
            state.globalNotifications = state.globalNotifications.splice(state.globalNotifications.findIndex((n) => n.functionName === payload.name), 1);
        },
        clearNotifications (state) {
            state.globalNotifications = [];
        },
        openProductAddModal (state) {
            state.isVisibleProductAddModal = true;
        },
        openPreorderCreateModal (state) {
            state.isVisiblePreorderCreateModal = true;
        },
        openCreateCropPlanModal (state, selectedProductionId) {
            state.isVisibleCreateCropPlanModal = true;
            state.selectedProductionId = selectedProductionId;
        },
        openPrintReceiptModal (state, selectedProductionId) {
            state.isVisiblePrintReceiptModal = true;
            state.selectedProductionId = selectedProductionId;
        },
        closeProductAddModal (state) {
            state.isVisibleProductAddModal = false;
        },
        closePreorderCreateModal (state) {
            state.isVisiblePreorderCreateModal = false;
        },
        closeCreateCropPlanModal (state) {
            state.isVisibleCreateCropPlanModal = false;
        },
        closePrintReceiptModal (state) {
            state.isVisiblePrintReceiptModal = false;
        },
        createWs (state) {
            try {
                state.ws = new WSKeepAlive(WS_API_URI);
            }
            catch {
                // Silence is golden.
            }
        },
        closeWs (state) {
            state.ws?.close();
        },
        openLightbox (state, { images, index, }) {
            state.isLightboxVisible = true;
            state.lightboxImages = images;
            state.selectedImageIndex = index;
        },
        closeLightbox (state) {
            state.isLightboxVisible = false;
        },
        setFilter (state, filter) {
            state.filter = filter;
        },
        reloadPage (state) {
            state.toReloadPage = true;
        },
        setPreviousSelectedElements (state, payload) {
            state.previousSelectedElements[payload.name] = payload.content;
            console.log(state.previousSelectedElements);
        },
    },
    actions: {
        sendNotification (context, payload) {
            const dt = new Date();
            payload.timestamp = `${(dt.getMonth() + 1).toString().padStart(2, "0")}/${dt.getDate().toString().padStart(2, "0")}/${dt.getFullYear().toString().padStart(4, "0")} ${dt.getHours().toString().padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt.getSeconds().toString().padStart(2, "0")}`;
            payload.isConfirmButtonLoading = false;
            payload.onHover = false;
            return new Promise((resolve) => {
                context.commit("pushNotification", payload);
                setTimeout(() => {
                    const myInterval = setInterval(() => {
                        if (!payload.onHover) {
                            context.commit("popNotification");
                            clearInterval(myInterval);
                            resolve();
                        }
                    }, 50);
                }, 3000);
            });
        },
        connectWs ({ commit, state, }) {
            if (state.ws && user.isLoggedIn) {
                return;
            }
            commit("createWs");
            state.ws.addEventListener("open", () => {
                state.ws.send(JSON.stringify({
                    authenticationToken: user.authenticationToken,
                    permissions: user.descriptor.permissions,
                }));
            });
            state.ws.addEventListener("message", async (event) => {
                let message;

                try {
                    message = JSON.parse(event.data);

                    if (message.type === "DuplicateConnection" || message.type === "ConnectionRefused") {
                        user.logout();
                        await router.replace({ name: "Login", });
                        commit("closeWs");
                    }

                    if (message.type === "UserPermissionChange") {
                        user.setPermissions(message.result);
                    }

                    if (message.type === "UserNotification") {
                        if (message.result.title === "notification.createOrder") {
                            const order = message.result.body;
                            const image = order.products.find((r) => r.product.images.length > 0)?.product.images[0].compress;
                            if (hasNotification) {
                                const notification = new Notification(`${t(message.result.title)} #${order.orderId} ${t(order.customer.fullName)}`, {
                                    body: `${order.products.flatMap((r) => `${r.product.productTemplateId}(${r.product.name}): ${r.totalAmounts}`).join(", ")}`,
                                    image,
                                    icon: image,
                                });

                                notification.onclick = () => router.push("/production/waiting-production");
                            }
                        }
                        if (message.result.title === "notification.updateOrder") {
                            const order = message.result.body;
                            const image = order.products.find((r) => r.product.images.length > 0)?.product.images[0].compress;
                            if (hasNotification) {
                                const notification = new Notification(`${t(message.result.title)} #${order.orderId} ${t(order.customer.fullName)}`, {
                                    body: `${order.products.flatMap((r) => `${r.product.productTemplateId}(${r.product.name}): ${r.totalAmounts}`).join(", ")}`,
                                    image: image,
                                    icon: image,
                                });

                                notification.onclick = () => router.push("/production/waiting-production");
                            }
                        }
                    }
                }
                catch {
                    return;
                }
            });
            state.ws.keepAlive();
        },
        sendWs ({ commit, state, }, data) {
            let message;

            try {
                message = JSON.stringify(data);
                state.ws.send(message);
            }
            catch {
                return;
            }
        },
        setLanguage ({ commit, }, langs) {
            if (typeof langs === "string") {
                commit("setLang", langs);
            }
            else {
                const supportedLanguages = [ "zh", "it", ];
                const language = langs.find((l) => supportedLanguages.find((sl) => l === sl));
                commit("setLang", language);
            }
        },
        setFilter ({ commit, }, filter) {
            commit("setFilter", filter);
        },
        reloadPage ({ commit, }) {
            commit("reloadPage");
        },
        setPreviousSelectedElements ({ commit, }, payload) {
            commit("setPreviousSelectedElements", payload);
        },
    },
});
