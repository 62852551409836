<template>
    <CDropdown>
        <CDropdownToggle placement="bottom-end" class="py-2 text-white" :caret="false">
            {{ username }}
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                {{ t("login.accountInfo") }}
            </CDropdownHeader>
            <CDropdownItem @click="openUserUpdateModal" class="cursor-pointer">
                <CIcon name="cil-lock-locked" />
                <span class="ms-2">
                    {{ t("permissionControl.changePassword") }}
                </span>
            </CDropdownItem>
            <CDropdownDivider />
            <CDropdownItem @click="logout" class="cursor-pointer">
                <CIcon name="cil-power-standby" class="text-danger"></CIcon>
                <span class="ms-2">
                     {{ t("login.logout") }}
                </span>
            </CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
    <UserUpdateModal
        ref="userUpdateModal"
        v-model:is-visible="isUserUpdateModalVisible"
    ></UserUpdateModal>
</template>

<script>
import avatar from "@/assets/images/avatars/8.jpg";
import { user } from "@/user";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import UserUpdateModal from "@/components/modals/UserUpdateModal";

export default {
    name: "AppHeaderDropdownAccnt",
    components: {
        UserUpdateModal,
    },
    setup () {
        const { t, } = useI18n();
        const username = user.descriptor.username;
        const router = useRouter();
        const store = useStore();
        const logout = async () => {
            user.logout();
            store.commit("closeWs");
            await router.replace({ name: "Login", });
        };
        const permissionControl = async () => {
            await router.replace({ name: "account.permissionControl", });
        };

        // User update modal
        const isUserUpdateModalVisible = ref(false);
        const userUpdateModal = ref(null);

        function openUserUpdateModal () {
            userUpdateModal.value.bindElementToRef(user.descriptor);
            isUserUpdateModalVisible.value = true;
        }

        return {
            t,
            logout,
            permissionControl,
            avatar: avatar,
            itemsCount: 42,
            username,
            isUserUpdateModalVisible,
            userUpdateModal,
            openUserUpdateModal,
        };
    },
};
</script>
<style lang="scss" scoped>
.dropdown-menu {
    color: white;
}
</style>
