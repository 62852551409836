<template>
    <CModal :visible="isVisible" @close="closeModal">
        <CModalHeader>
            <CModalTitle>{{ t("generic.setTag") }}</CModalTitle>
        </CModalHeader>

        <CModalBody  class="modal-height">
            <CRow class="my-2" v-if="!disableOrderTag">
                <CCol>
                    <CFormText>{{ t("generic.orderTag") }}</CFormText>
                    <base-multi-select
                        type="orderTag"
                        :options="formattedOrderTags"
                        @change="selectOrderTags"
                    ></base-multi-select>
                </CCol>
            </CRow>
            <CRow class="my-2" v-if="order.productionIds?.length > 0 && !disableProductionTag">
                <CCol>
                    <CFormText>{{ t("generic.productionTag") }}</CFormText>
                    <base-multi-select
                        type="productionTag"
                        :options="formattedProductionTags"
                        @change="selectProductionTags"
                    ></base-multi-select>
                </CCol>
            </CRow>
            <CRow class="mt-3 align-items-end">
                <CCol :xl="8">
                    <CRow class="mt-2">
                        <CCol :xl="4">
                            <div @click="() => isSpeeded = !isSpeeded" class="rounded-pill d-flex align-items-center justify-content-center cursor-pointer" :class="getTagColor('speeded')">
                                <span class="px-2 py-1 text-white">{{ t("generic.urgent") }}</span>
                            </div>
                        </CCol>
                        <CCol :xl="4">
                            <div @click="() => isCustomerReminder = !isCustomerReminder" class="rounded-pill d-flex align-items-center justify-content-center cursor-pointer" :class="getTagColor('customer')">
                                <span class="px-2 py-1 text-white">{{ t("generic.customerReminder") }}</span>
                            </div>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol :xl="4" v-if="isBulkChange">
                    <CRow class="flex-nowrap">
                        <CCol class="justify-content-end p-0">
                            <span class="text-nowrap text-danger">{{ t("generic.delete") }}</span>
                        </CCol>
                        <CCol class="justify-content-center p-0">
                            <CFormSwitch @change="toggleAddRemoveNewTags" checked></CFormSwitch>
                        </CCol>
                        <CCol class="justify-content-start ps-2">
                            <span class="text-nowrap text-success">{{ t("generic.add") }}</span>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        </CModalBody>

        <CModalFooter>
            <CButton color="secondary" @click="closeModal">{{ t("generic.close") }}</CButton>
            <CButton color="primary" @click="confirm">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    computed, ref, watch,
} from "vue";
import { user } from "@/user";
import BaseMultiSelect from "@/components/base/BaseMultiSelect";

export default {
    name: "TagChangeModal",
    components: {
        BaseMultiSelect,
    },
    emits: [ "update:isVisible", "submit", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        disableOrderTag: {
            type: Boolean,
            default: false,
        },
        disableProductionTag: {
            type: Boolean,
            default: false,
        },
        order: {
            type: Object,
            default: () => {},
        },
        isBulkChange: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const orderTags = ref([]);
        const productionTags = ref([]);
        const selectedOrderTags = ref([]);
        const selectedProductionTags = ref([]);
        const isSpeeded = ref(false);
        const isCustomerReminder = ref(false);
        const formattedOrderTags = computed(() => {
            let formatted = [];

            formatted = orderTags.value.map((t) => ({
                text: t.name,
                selected: !props.isBulkChange ? props.order.selectedOrderTags.find((t2) => t2.id === t.id) : false,
                value: t.id,
            }));

            return formatted;
        });
        const formattedProductionTags = computed(() => {
            let formatted = [];

            formatted = productionTags.value.map((t) => ({
                text: t.name,
                selected: !props.isBulkChange ? props.order.selectedProductionTags.find((t2) => t2.id === t.id) : false,
                value: t.id,
            }));

            return formatted;
        });

        const toAdd = ref(true);
        const toRemove = ref(false);


        function closeModal () {
            emit("update:isVisible", false);
        }

        function selectOrderTags (selectedOptions) {
            selectedOrderTags.value = selectedOptions.map((t) => t.value);
        }

        function selectProductionTags (selectedOptions) {
            selectedProductionTags.value = selectedOptions.map((t) => t.value);
        }

        async function confirm () {
            const descriptor = {
                ids: [ ...props.order.ids, ],

                orderIds: !props.disableOrderTag ? [ ...props.order.orderIds, ] : undefined,
                productionIds: !props.disableProductionTag ? [ ...props.order.productionIds, ]?.filter((a) => a) : undefined,

                orderTagIds: !props.disableOrderTag ? [ ...selectedOrderTags.value, ] : undefined,
                productionTagIds: !props.disableProductionTag ? [ ...selectedProductionTags.value, ] : undefined,

                // newOrderTags: [ ...selectedOrderTags.value, ],
                // newProductionTags: [ ...selectedProductionTags.value, ],

                urgent: isSpeeded.value,
                customerReminder: isCustomerReminder.value,

                toAdd: toAdd.value,
                toRemove: toRemove.value,
            };

            const response = await user.bulkUpdateOrderTags(descriptor);

            if (response) {
                emit("submit", {
                    orderTags: selectedOrderTags.value,
                    productionTags: selectedProductionTags.value,
                    urgent: isSpeeded.value,
                    customerReminder: isCustomerReminder.value,
                });
                isSpeeded.value = false;
                isCustomerReminder.value = false;
                closeModal();
            }
        }

        function getTagColor (type) {
            if (type === "speeded") {
                return isSpeeded.value ? "bg-danger" : "bg-dark";
            }
            if (type === "customer") {
                return isCustomerReminder.value ? "bg-warning" : "bg-dark";
            }

            return "bg-dark";
        }

        function toggleAddRemoveNewTags (event) {
            toAdd.value = event.target.checked;
            toRemove.value = !event.target.checked;
        }

        async function init () {
            orderTags.value = props.order.orderTags ?? await user.getAllOrderTags();
            productionTags.value = props.order.productionTags ?? await user.getAllProductionTags();
            isCustomerReminder.value = props.order.customerReminder;
            isSpeeded.value = props.order.urgent;
            if (!props.isBulkChange) {
                toAdd.value = undefined;
                toRemove.value = undefined;
            }
        }

        watch(() => props.isVisible, async (newValue) => {
            if (newValue) {
                await init();
            }
        });

        return {
            t,
            formattedOrderTags,
            formattedProductionTags,
            isSpeeded,
            isCustomerReminder,
            closeModal,
            confirm,
            selectOrderTags,
            selectProductionTags,
            getTagColor,
            toggleAddRemoveNewTags,
        };
    },
};
</script>

<style scoped>
.modal-height {
    min-height: 600px;
}
</style>
