<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal" scrollable>

        <CModalHeader>
            <CModalTitle>{{ t("color.addNew") }}</CModalTitle>
        </CModalHeader>

        <CModalBody>
            <CRow class="py-3 ps-4">
                <CCol xs="auto" class="p-0">
                    <CFormInput
                        class="t-col-4"
                        v-model:model-value="searchValue"
                        type="search"
                        :placeholder="t('generic.search')"
                        @input="onInputSearchValue"
                    />
                </CCol>
            </CRow>
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem component="button" v-if="searchValue.length > 0 && colors.findIndex((c) => c.name === searchValue) === -1"
                                    @click="openColorAddModal">{{ t("color.addNew")}}</CListGroupItem>
                    <CListGroupItem component="button" v-for="color in colors" :key="color.id" @click="toggleSelectColor(color)">
                        <CRow class="ps-0 pb-0">
                            <CCol class="ps-0">
                                <CRow class="ps-3">
                                    <span class="fs-4 py-2">{{ color.isDefault ? t(color.name) : color.name }}</span>
                                </CRow>
                            </CCol>
                            <CCol class="d-flex align-items-center justify-content-end pe-3">
                                <CIcon v-if="isSelectedColor(color.id)" size="xl" icon="cil-check-alt" class="text-info"></CIcon>
                            </CCol>
                        </CRow>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
        </CModalBody>

        <CModalFooter>
            <CButton class="me-3" color="secondary" variant="outline" @click="closeModal">{{ t("generic.undo") }}</CButton>
            <CButton color="primary" @click="confirmSelectedColors">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>

        <color-add-modal
            ref="colorAddModal"
            v-model:is-visible="isColorAddModalVisible"
            @submit="toggleSelectColor"
        ></color-add-modal>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    ref, watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";
import ColorAddModal from "@/components/modals/ColorAddModal";

export default {
    name: "ColorMultiSelect",
    components: { ColorAddModal, },
    emits: [ "selectColors", "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        selectedColors: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const colors = ref([]);

        const searchValue = ref("");
        const searchField = ref("name");

        const selectedColorsCopy = ref([]);

        const colorAddModal = ref(null);
        const isColorAddModalVisible = ref(false);

        function openColorAddModal () {
            colorAddModal.value.preselectName(searchValue.value);
            isColorAddModalVisible.value = true;
        }

        function closeModal () {
            searchValue.value = "";
            emit("update:isVisible", false);
        }


        function toggleSelectColor (color) {
            if (!isSelectedColor(color.id)) {
                selectedColorsCopy.value = [ ...selectedColorsCopy.value, color, ];
            }
            else {
                selectedColorsCopy.value = selectedColorsCopy.value.filter((p) => p.id !== color.id);
            }
            searchValue.value = "";
            onInputSearchValue();
        }

        function isSelectedColor (colorId) {
            return Boolean(selectedColorsCopy.value.find((p) => p.id === colorId));
        }

        function confirmSelectedColors () {
            emit("selectColors", selectedColorsCopy.value.sort((a, b) => a.id - b.id));
            closeModal();
        }

        watch(async () => props.isVisible, async (newValue) => {
            if (newValue) {
                selectedColorsCopy.value = [ ...props.selectedColors, ];

                colors.value = (await user.getAllColors()).filter((c) => !c.isArchived || selectedColorsCopy.value.find((sC) => c.id === sC.id));
            }
        });

        const onInputSearchValue = debounce(async () => {
            const descriptor = {};
            descriptor.filterField = searchField.value;
            descriptor.filterValue = searchValue.value;
            colors.value = (await user.getAllColors(descriptor)).filter((c) => !c.isArchived || selectedColorsCopy.value.find((sC) => c.id === sC.id));
        }, 50);

        return {
            t,
            colors,
            searchValue,
            searchField,
            onInputSearchValue,
            isColorAddModalVisible,
            colorAddModal,
            closeModal,
            toggleSelectColor,
            isSelectedColor,
            confirmSelectedColors,
            openColorAddModal,
        };
    },
};
</script>

<style scoped>

</style>
