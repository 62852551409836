<template>
    <div role="status" :class="{ loader: true, fadeout: !isLoading, error: onError }">
        <span class="visually-hidden">Loading ...</span>
        <div class="centered">
            <CSpinner v-if="!onError"/>
            <div v-else class="error-box">
                <span class="error-box__text">{{ t("error.generic.timeout") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
    name: "AppLoadingScreen",
    props: {
        isLoading: {
            type: Number,
            default: 0,
        },
        onError: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        const { t, } = useI18n();
        return {
            t,
        };
    },
};
</script>

<style lang="scss" scoped>
.loader {
    background-color: var(--cui-dark);
    bottom: 0;
    color: white;
    display: block;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    position: absolute;
    opacity: 0.5;
    right: 0;
    text-align: center;
    top: 0;
    span {
        font-size: 32px;
    }
}

.fadeout {
    animation: fadeout 0.3s forwards;
}

.error {
    opacity: 0.9;
}

@keyframes fadeout {
    to {
        opacity: 0;
        visibility: hidden;
    }
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.error-box {
    background: white;
    opacity: 1;
    border: 2px solid red;

    &__text {
        font-size: 3rem;
        font-weight: 500;
        color: red;
        padding: 30px 50px;
    }
}
</style>
