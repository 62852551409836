<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">

        <CModalHeader>
            <CModalTitle>{{ t("procedure.addNew") }}</CModalTitle>
        </CModalHeader>

        <CContainer>
            <CRow class="py-3 ps-4">
                <CCol :xs="3" class="p-0">
                    <CFormSelect v-model:model-value="searchField" @change="onInputSearchValue">
                        <option value="">{{ t("generic.all") }}</option>
                        <option v-for="field in Object.keys(proceduresSearchFields)" :key="field" :value="field">{{ t(proceduresSearchFields[field]) }}</option>
                    </CFormSelect>
                </CCol>
                <CCol xs="auto" class="p-0">
                    <CFormInput
                        class="t-col-4"
                        v-model:model-value="searchValue"
                        type="search"
                        :placeholder="t('generic.search')"
                        @input="onInputSearchValue"
                    />
                </CCol>
            </CRow>
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem component="button" v-for="procedure in procedures" :key="procedure.id" @click="selectProcedure(procedure)">
                        <CRow class="ps-0 pb-0">
                            <CCol>
                                <CRow>
                                    {{ procedure.isDefault ? t(procedure.name) : procedure.name }}
                                </CRow>
                                <CRow>
                                    {{ t(procedure.procedureType.name) }}
                                </CRow>
                            </CCol>
                        </CRow>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
        </CContainer>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    ref, watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";

export default {
    name: "ProcedurePicker",
    emits: [ "selectProcedure", "update:isVisible", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const procedures = ref([]);
        const proceduresSearchFields = Object.freeze({
            name: "generic.name",
            procedureType: "procedure.type",
        });
        const searchValue = ref("");
        const searchField = ref("");

        function closeModal () {
            searchValue.value = "";
            searchField.value = "";
            emit("update:isVisible", false);
        }

        function selectProcedure (procedure) {
            emit("selectProcedure", procedure);

            closeModal();
        }

        watch(async () => props.isVisible, async (newValue, oldValue) => {
            if (newValue) {
                procedures.value = await user.getAllProcedures();
            }
        });

        const onInputSearchValue = debounce(async () => {
            const descriptor = {};
            descriptor.filterField = searchField.value;
            descriptor.filterValue = searchValue.value;
            procedures.value = await user.getAllProcedures(descriptor);
        }, 50);

        return {
            t,
            procedures,
            searchValue,
            searchField,
            proceduresSearchFields,
            closeModal,
            selectProcedure,
            onInputSearchValue,
        };
    },
};
</script>

<style scoped>

</style>
