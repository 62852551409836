export default [
    {
        component: "CNavItem",
        name: "sidebar.todoList",
        to: "/todo-list",
        icon: "cil-flag-alt",
    },
    {
        component: "CNavItem",
        name: "sidebar.analytics",
        to: "/analytics",
        icon: "cil-chart-line",
    },
    {
        component: "CNavItem",
        name: "sidebar.preorderList",
        to: "/preorder",
        icon: "cil-envelope-letter",
        onClickFn: () => alert("createOrder"),
        items: [
            {
                component: "CNavItem",
                name: "sidebar.byProduct",
                to: "/preorder/product",
            },
            {
                component: "CNavItem",
                name: "sidebar.byOrder",
                to: "/preorder/order",
            },
            {
                component: "CNavItem",
                name: "sidebar.storage",
                to: "/preorder/stock",
            },
        ],
    },
    {
        component: "CNavItem",
        name: "sidebar.productionPlanning",
        to: "/production",
        icon: "cil-task",
        items: [ {
            component: "CNavItem",
            name: "sidebar.productionOrderWaitingOrderList",
            to: "/production/waiting-production",
        }, {
            component: "CNavItem",
            name: "sidebar.productionOrderPlannedOrderList",
            to: "/production/planned-production",
        }, ],
    },
    // {
    //     component: "CNavGroup",
    //     name: "sidebar.dyeingOrder",
    //     to: "/dyeing",
    //     icon: "cil-color-palette",
    //     items: [ {
    //         component: "CNavItem",
    //         name: "sidebar.dyeingOrderWaitingOrderList",
    //         to: "/dyeing/waiting-dyeing",
    //     }, {
    //         component: "CNavItem",
    //         name: "sidebar.dyeingOrderPlannedOrderList",
    //         to: "/dyeing/planned-dyeing",
    //     }, ],
    // },
    // {
    //     component: "CNavGroup",
    //     name: "sidebar.frontBehindPrint",
    //     to: "/front-behind-print",
    //     icon: "cil-flower",
    //     items: [ {
    //         component: "CNavItem",
    //         name: "sidebar.frontBehindPrintOrderWaitingList",
    //         to: "/front-behind-print/waiting",
    //     }, {
    //         component: "CNavItem",
    //         name: "sidebar.frontBehindPrintOrderPlannedList",
    //         to: "/front-behind-print/planned",
    //     }, ],
    // },
    {
        component: "CNavItem",
        name: "sidebar.shipping",
        to: "/shipping",
        icon: "cil-truck",
    },
    {
        component: "CNavItem",
        name: "sidebar.productList",
        to: "/product-list",
        icon: "cil-tag",
        onClickFn: () => alert("createProduct"),
        items: [ {
            component: "CNavItem",
            name: "sidebar.products",
            to: "/product-list/product",
        }, {
            component: "CNavItem",
            name: "sidebar.productTemplates",
            to: "/product-list/template",
        }, ],
    },
    {
        component: "CNavGroup",
        name: "sidebar.ingredients",
        to: "/ingredient",
        icon: "cil-puzzle",
        items: [
            {
                component: "CNavItem",
                name: "sidebar.tissues",
                to: "/ingredient/tissue",
            },
            {
                component: "CNavItem",
                name: "sidebar.accessories",
                to: "/ingredient/accessory",
            },
            {
                component: "CNavItem",
                name: "sidebar.printPatterns",
                to: "/ingredient/print-pattern",
            },
            {
                component: "CNavItem",
                name: "sidebar.printPictures",
                to: "/ingredient/print-picture",
            },
        ],
    },
    {
        component: "CNavItem",
        name: "sidebar.procedures",
        to: "/procedure",
        icon: "cil-briefcase",
    },
    {
        component: "CNavItem",
        name: "sidebar.contacts",
        to: "/contact",
        icon: "cil-people",
        items: [ {
            component: "CNavItem",
            name: "sidebar.suppliers",
            to: "/contact/supplier",
        }, {
            component: "CNavItem",
            name: "sidebar.customers",
            to: "/contact/customer",
        }, ],
    },
    {
        component: "CNavGroup",
        name: "sidebar.settings",
        to: "/setting",
        icon: "cil-settings",
        items: [
            {
                component: "CNavItem",
                name: "sidebar.productProperties",
                to: "/setting/product-property",
            },
            {
                component: "CNavItem",
                name: "sidebar.categories",
                to: "/setting/category",
            },
            {
                component: "CNavItem",
                name: "sidebar.tags",
                to: "/setting/tag",
            },
            // {
            //     component: "CNavItem",
            //     name: "sidebar.wifiPrinters",
            //     to: "/setting/wifi-printer",
            // },
            {
                component: "CNavItem",
                name: "sidebar.permissionControl",
                to: "/setting/permission-control",
            },
        ],
    },
    {
        component: "CNavGroup",
        name: "sidebar.languages",
        to: "/language",
        icon: "cil-language",
        items: [ {
            component: "CNavItem",
            name: "Italiano",
            to: "/language/it",
            language: "it",
        }, {
            component: "CNavItem",
            name: "中文",
            to: "/language/zh",
            language: "zh",
        }, ],
    },
];
