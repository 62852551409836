<template>
    <CModal :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <CForm @submit.prevent="submitCreate">
            <CModalHeader :close-button="false">
                <CModalTitle>{{ t("contact.customer.addNew") }}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CContainer>
                    <CCol class="mt-0 p-1 bg-body bg-gradient">
                        <h5 class="text-info">{{ t("generic.requiredFieldMsg") }}</h5>
                    </CCol>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.fullName") }} *</CFormLabel>
                        <CFormInput type="text" v-model:model-value="newElement.fullName" :invalid="errorMessage.length > 0" required/>
                        <CFormFeedback invalid>
                            {{ errorMessage }}
                        </CFormFeedback>
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.companyName") }}</CFormLabel>
                        <CFormInput type="text" v-model:model-value="newElement.companyName"/>
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.mobile") }}</CFormLabel>
                        <CFormInput type="text" v-model:model-value="newElement.mobile"/>
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.defaultLogo") }}</CFormLabel>
                        <CFormInput type="text" v-model:model-value="newElement.defaultLogo"/>
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.defaultTag") }}</CFormLabel>
                        <CFormInput type="text" v-model:model-value="newElement.defaultTag"/>
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.defaultComposition") }}</CFormLabel>
                        <CFormInput type="text" v-model:model-value="newElement.defaultComposition"/>
                    </div>
                    <CCol class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.customer.defaultCompositionImage") }}</CFormLabel>
                        <BaseImageUploader @upload="saveImages"/>
                        <CTable responsive borderless class="mt-1">
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell v-for="base64 in images" :key="base64">
                                        <div class="t-col-3" style="position: relative;">
                                            <CImage  class="t-col-3" thumbnail align="start" :src="base64" @click="openLightbox(base64)"></CImage>
                                            <button class="button-remove-image" @click.prevent="deleteImage(base64)">
                                                <span class="icon-cross"></span>
                                            </button>
                                        </div>
                                    </CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        </CTable>
                    </CCol>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.notes") }}</CFormLabel>
                        <CFormTextarea type="text" v-model:model-value="newElement.note" />
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormLabel class="w-100 fs-5 mb-1">{{ t("contact.category") }}</CFormLabel>
                        <CFormSelect v-model:model-value="newElement.categoryId">
                            <option v-for="category in categories" :key="category.id" :value="category.id" :selected="isPreselectedCategoryOnCreate(category)">{{ category.isDefault ? t(category.name) : category.name }}</option>
                        </CFormSelect>
                    </div>
                    <div class="mt-1 p-1 bg-body bg-gradient">
                        <CFormCheck v-model:model-value="newElement.isPreorderDefaultCustomer"/>
                        <CFormLabel class="ps-2 fs-4 col-10">{{ t("contact.customer.isPreorderDefaultCustomer") }}</CFormLabel>
                    </div>
                    <div class="mt-0 p-1 bg-body bg-gradient">
                        <CFormCheck v-model:model-value="newElement.isStockOrderDefaultCustomer"/>
                        <CFormLabel class="ps-2 fs-4 col-10">{{ t("contact.customer.isStockOrderDefaultCustomer") }}</CFormLabel>
                    </div>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton type="button" variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
                <CLoadingButton :loading="isConfirmButtonLoading" :disabled="isConfirmButtonLoading" type="submit" color="primary">{{ t("generic.confirm") }}</CLoadingButton>
            </CModalFooter>
        </CForm>
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="selectedImageIndex"
            :images="lightBoxImages"
            @hide="closeLightbox"
        ></BaseLightbox>
    </CModal>
</template>

<script>
import {
    ref, watch,
} from "vue";
import { user } from "@/user";
import { readImage } from "@/views/utilities";
import { useI18n } from "vue-i18n";
import BaseLightbox from "@/components/base/BaseLightbox";
import BaseImageUploader from "@/components/base/BaseImageUploader";

export default {
    name: "CustomerAddModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        // Customer categories
        customerCategories: {
            type: Object,
        },
        name: {
            type: String,
            default: "",
        },
    },
    emits: [
        "close",
        "submit",
        "update:isVisible",
    ],
    components: {
        BaseLightbox,
        BaseImageUploader,
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const errorMessage = ref("");
        const activeModalTab = ref(0);
        const categories = ref([]);
        const newElement = ref({
            fullName: "",
            companyName: "",
            mobile: "",
            defaultLogo: "",
            defaultTag: "",
            defaultComposition: "",
            note: "",
            categoryId: "",
            isArchived: false,
            isPreorderDefaultCustomer: false,
            isStockOrderDefaultCustomer: false,
            defaultCompositionImages: [],
        });
        const images = ref([]);
        const lightBoxImages = ref([]);
        const selectedImageIndex = ref(0);
        const isLightboxVisible = ref(false);

        const isConfirmButtonLoading = ref(false);

        function closeModal () {
            emit("update:isVisible", false);
        }
        function openLightbox (image) {
            selectedImageIndex.value = images.value.findIndex((base64ToFind) => base64ToFind === image);
            lightBoxImages.value = images.value;
            isLightboxVisible.value = true;
        }
        function closeLightbox () {
            lightBoxImages.value = [];
            isLightboxVisible.value= false;
        }
        function resetNewElement () {
            images.value = [];
            for (const key of Object.keys(newElement.value)) {
                if (Array.isArray(newElement[key])) {
                    newElement.value[key] = [];
                }
                else {
                    newElement.value[key] = "";
                }
            }
            newElement.value.categoryId = categories.value[0].id;
            newElement.value.isArchived = false;
            newElement.value.isPreorderDefaultCustomer = false;
            newElement.value.isStockOrderDefaultCustomer = false;
            newElement.value.defaultCompositionImages = [];
            newElement.value.categoryId = categories.value.find((c) => c.isDefault).id.toString();
        }
        async function submitCreate () {
            isConfirmButtonLoading.value = true;

            const formData = new FormData();

            for (const image of newElement.value.defaultCompositionImages) {
                formData.append("images", image);
            }

            for (const [ key, value, ] of Object.entries(newElement.value)) {
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            }

            try {
                const response = await user.createNewCustomer(formData);
                if (response) {
                    resetNewElement();
                    closeModal();
                    emit("submit", response);
                }
            }
            catch {}

            isConfirmButtonLoading.value = false;
        }

        async function deleteImage (imageDataUrl) {
            newElement.value.defaultCompositionImages = newElement.value.defaultCompositionImages.filter((imageToRemove) => imageToRemove.url !== imageDataUrl);
            images.value = images.value.filter((imageToRemove) => imageToRemove !== imageDataUrl);
        }

        async function saveImages (files) {
            newElement.value.defaultCompositionImages = [ ...newElement.value.defaultCompositionImages, ...files, ];

            images.value = [];

            for (let i=0; i<newElement.value.defaultCompositionImages.length; i++) {
                newElement.value.defaultCompositionImages[i].url = await readImage(newElement.value.defaultCompositionImages[i]);
                images.value = [ ...images.value, newElement.value.defaultCompositionImages[i].url, ];
            }
        }

        function isPreselectedCategoryOnCreate (category) {
            return category.id.toString() === newElement.value.categoryId;
        }

        async function init () {
            categories.value = props.customerCategories ? props.customerCategories : await user.getAllCustomerCategories();
        }

        watch(() => props.customerCategories, async (newValue) => {
            categories.value = props.customerCategories ? props.customerCategories : await user.getAllCustomerCategories();
        });

        watch(() => props.isVisible, async (newValue) => {
            if (newValue) {
                await init();
                resetNewElement();
                if (props.name) {
                    newElement.value.fullName = props.name;
                }
            }
        });

        return {
            t,
            newElement,
            images,
            isLightboxVisible,
            lightBoxImages,
            selectedImageIndex,
            errorMessage,
            activeModalTab,
            categories,
            isConfirmButtonLoading,
            submitCreate,
            closeModal,
            openLightbox,
            closeLightbox,
            deleteImage,
            saveImages,
            resetNewElement,
            isPreselectedCategoryOnCreate,
        };
    },
};
</script>

<style scoped>

</style>
