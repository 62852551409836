import moment from "moment";
import { computed, ref } from "vue";
import Fuse from "fuse.js";
import { useStore } from "vuex";
import { debounce } from "debounce";
import { user } from "@/user";
import { useI18n } from "vue-i18n";

export const isMobile = window.innerWidth <= 448;

export const hasNotification = "Notification" in window;

export function datesDayDiff (timestamp) {
    return Math.ceil((timestamp - Date.now()) / (1000 * 60 * 60 * 24));
}

export const ProductionMode = Object.freeze({
    TO_PRODUCE: "toProduce",
    NO_PRODUCE: "noProduce",
    FROM_INVENTORY: "fromInventory",
    TBD: "tbd",
});

export const ProductInventoryLogType = Object.freeze({
    SET: "set",
    CONSUMED: "consumed",
    MADE: "made",
});

export function hasPermission (permission) {
    return user.descriptor?.permissions.includes(permission);
}

export function getFormattedDate (creationTimestamp, format) {
    if (format) {
        return moment(creationTimestamp).format(format);
    }
    return moment(creationTimestamp).format("DD/MM/YYYY");
}

export function doubleInputNormalization (number) {
    if (number) {
        if (Number.isFinite(number)) {
            return number;
        }
        if (number === "") {
            return undefined;
        }
        return parseFloat(number.replace(",", "."));
    }
    return number;
}

export function doubleOutputNormalization (number) {
    const reg = /^-?\d+\.?\d*$/;
    if (number === 0) {
        return "0,000";
    }
    if (Number.isFinite(number)) {
        return parseFloat(number).toFixed(3).replace(".", ",");
    }
    if (reg.test(number)) {
        return parseFloat(number).toFixed(3).replace(".", ",");
    }
    return number;
}

export function firstCharacterToUpperCase (string) {
    if (string && string.length > 0) {
        let newString = [ ...string, ];
        newString[0] = newString[0].toUpperCase();

        newString = newString.toString().replaceAll(",", "");

        return newString;
    }
}

export async function readImage (file) {
    if (file && file instanceof File) {
        const fileReader = new FileReader();
        return new Promise((resolve) => {
            fileReader.onload = (event) => resolve(event.target.result);

            fileReader.readAsDataURL(file);
        });
    }
}

export function scrollToTop () {
    window.scrollTo(0, 0);
}

export function isString (x) {
    return Object.prototype.toString.call(x) === "[object String]";
}

export function isObject (x) {
    return Object.prototype.toString.call(x) === "[object Object]";
}

export function normalization (toNormalization) {
    if (isString(toNormalization)) {
        return toNormalization.length > 0 ? toNormalization : null;
    }
    else if (isObject(toNormalization)) {
        for (const [ k, v, ] of Object.entries(toNormalization)) {
            toNormalization[k] = normalization(v);
        }
    }
    else if (Array.isArray(toNormalization)) {
        for (let i = 0; i < toNormalization.length; i++) {
            normalization(toNormalization[i]);
        }
    }
    return toNormalization;
}

export function CRUD ({ }) {

}

export function getTimeDifferenceText (lastUpdateTimestamp) {
    const timeDifference = new Date().getTime() - lastUpdateTimestamp;
    const { t, } = useI18n();

    if (timeDifference/60000 < 60) { // Minutes till 59
        return t("date.xMinutesAgo", { time: Math.floor(timeDifference / 60000), }, Math.floor(timeDifference / 60000));
    }

    if (timeDifference/60000 < 1440) { // Hours till 23
        return t("date.xHoursAgo", { time: Math.floor(timeDifference / 3600000), }, Math.floor(timeDifference / 3600000));
    }

    if (timeDifference/60000 < 44640) { // Days till 30
        return t("date.xDaysAgo", { time: Math.floor(timeDifference / 86400000), }, Math.floor(timeDifference / 86400000));
    }

    if (timeDifference/60000 < 525600) { // Months till 11
        return t("date.xMonthsAgo", { time: Math.floor(timeDifference / 2678400000), }, Math.floor(timeDifference / 2678400000));
    }

    return t("date.xYearsAgo", { time: Math.floor(timeDifference / 31536000000), }, Math.floor(timeDifference / 31536000000));
}

export function getDaysToFutureDate (futureDate) {
    const formattedFutureDate = new Date(futureDate);
    formattedFutureDate.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return Math.floor((formattedFutureDate.getTime() - now.getTime()) / 86400000);
}

export function getElementName (element) {
    const { t, } = useI18n();
    return element?.isDefault ? t(element.name) : element?.name;
}

export function searchBarManager ({
    elements, // all elements to search
    fnGetAll, // function used to get all
}) {
    const searchField = ref("");
    const searchValue = ref("");

    const onInputSearchValue = debounce(async () => {
        const descriptor = {};
        descriptor.filterField = searchField.value;
        descriptor.filterValue = searchValue.value;
        elements.value = await fnGetAll(descriptor);
    }, 50);

    return {
        searchValue, // value to search
        searchField, // field to apply search on
        onInputSearchValue, // array of elements after search applied
    };
}

export function paginationManager ({
    elements, // all elements
}) {
    const currentPage = ref(1);
    const pageSize = ref(40);
    const totalPages = computed(() => Math.ceil(elements.value.length / pageSize.value));
    const pageRange = computed(() => {
        const range = [];
        let start = currentPage.value - 2 > 0 ? currentPage.value - 2 : 1;
        const end = currentPage.value + 2 < totalPages.value ? currentPage.value + 2 : totalPages.value;
        if (end <= start) {
            range.push(1);
        }
        else {
            while (start <= end) {
                range.push(start);
                start++;
            }
        }
        return range;
    });

    const currentPageElements = computed(() => {
        if (elements.value.slice) {
            return elements.value.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value);
        }

        return elements.value;
    });

    const gotoPage = (goto) => {
        if (goto > totalPages.value || goto <= 0) {
            return;
        }
        currentPage.value = goto;
    };

    return {
        currentPage,
        pageSize,
        totalPages,
        pageRange,
        currentPageElements,
        gotoPage,
    };
}

export function paginationManager2 ({
    elements, // all elements
    fnGetAllFilteredElements,
}) {
    const currentPage = ref(1);
    const totalItems = ref(0);
    const pageSize = ref(40);
    const totalPages = ref(1);
    const pageRange = computed(() => {
        const range = [];
        let start = currentPage.value - 2 > 0 ? currentPage.value - 2 : 1;
        const end = currentPage.value + 2 < totalPages.value ? currentPage.value + 2 : totalPages.value;
        if (end <= start) {
            range.push(1);
        }
        else {
            while (start <= end) {
                range.push(start);
                start++;
            }
        }
        return range;
    });

    const currentPageElements = computed(() => elements.value);

    const gotoPage = async (goto) => {
        if (goto > totalPages.value || goto <= 0) {
            return;
        }
        currentPage.value = goto;
        await fnGetAllFilteredElements();
    };

    return {
        currentPage,
        pageSize,
        totalItems,
        totalPages,
        pageRange,
        currentPageElements,
        gotoPage,
    };
}

export function elementsToDisplayManager ({
    elements, // array of elements to display or not to
    activeElementCriteria, // criteria that decides what elements display
}) {
    const isShowArchived = ref(false);
    const isSelectedAllCriteria = computed(() => getSelectedCriteria.value.length === activeElementCriteria.value.length);
    const getSelectedCriteria = computed(() => activeElementCriteria.value.filter((c) => c.selected));
    const selectedElements = computed(() => elements.value.filter((c) => c.selected));
    const isSelectedAllElements = computed(() => selectedElements.value.length === elements.value.length);
    const isSelectedAnyElements = computed(() => selectedElements.value.length > 0);

    const activeCriterion = (criterion) => {
        isShowArchived.value = false;
        setSelectAllElements(false);

        if (criterion) {
            setSelectAllCriteria(false);
            selectElementCriterion(criterion);
        }
        else { // Select All
            setSelectAllCriteria(true);
        }
    };

    const showArchived = () => {
        isShowArchived.value = true;
        setSelectAllCriteria(false);
        setSelectAllElements(false);
    };

    const setSelectAllElements = (value) => {
        elements.value.forEach((e) => {
            e.selected = value;
        });
    };

    const setSelectAllCriteria = (value) => {
        activeElementCriteria.value.forEach((c) => {
            c.selected = value;
        });
    };

    const selectElementCriterion = (criterion) => {
        console.log(criterion);
        getElementCriterionById(criterion.id).selected = true;
    };

    const getElementCriterionById = (id) => activeElementCriteria.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(id, 10));

    return {
        isShowArchived,
        isSelectedAllCriteria,
        isSelectedAllElements,
        getSelectedCriteria,
        isSelectedAnyElements,
        selectedElements,
        activeCriterion,
        showArchived,
        setSelectAllElements,
    };
}

export function manager ({
    elementFields,
    elementSearchFields,
    elementCollectionName,
    fnGetAllElements,
    fnGetAllCategories,
    fnArchiveSelectedElements,
    fnUnarchiveSelectedElements,
    fnCreateNewElement,
    fnUpdateElement,
    fnUpdateElementsCategory,
}) {
    const store = useStore();
    const fuse = computed(() => new Fuse(elements.value, {
        includeScore: true,
        keys: searchField.value === "" ? elementSearchFields : [ searchField.value, ],
    }));
    const searchField = ref("");
    const searchValue = ref("");
    const currentPage = ref(1);
    const pageSize = ref(20);
    const totalPages = computed(() => Math.ceil(filteredElements.value.length / pageSize.value));
    const pageRange = computed(() => {
        const range = [];
        let start = currentPage.value - 2 > 0 ? currentPage.value - 2 : 1;
        const end = currentPage.value + 2 < totalPages.value ? currentPage.value + 2 : totalPages.value;
        if (end <= start) {
            range.push(1);
        }
        else {
            while (start <= end) {
                range.push(start);
                start++;
            }
        }
        return range;
    });
    const bulkUpdateCategorySelectedId = ref(null);
    const isShowArchived = ref(false);
    const elements = ref([]);
    const categories = ref([]);
    const errorMessage = ref("");
    const newElement = ref({});
    const defaultCategory = ref(null);
    elementFields.forEach((field) => {
        newElement.value[field.name] = field.defaultValue;
    });
    const elementToUpdate = ref({ id: -1, });
    elementFields.forEach((field) => {
        elementToUpdate.value[field.name] = field.defaultValue;
    });
    const isSelectedAllFilteredElements = computed(() => selectedElements.value.length === filteredElements.value.length);
    const isSelectedAnyElements = computed(() => selectedElements.value.length > 0);
    const isSelectedAllCategories = computed(() => selectedCategories.value.length === categories.value.length);
    const selectedElements = computed(() => elements.value.filter((e) => e.selected));
    const selectedCategories = computed(() => categories.value.filter((c) => c.selected));
    const inUseElements = computed(() => [ ...elements.value, ].filter((e) => !e.isArchived));
    const archivedElements = computed(() => [ ...elements.value, ].filter((e) => e.isArchived));
    const filteredElements = computed(() => {
        let filtered = [];
        if (isShowArchived.value) {
            filtered = [ ...archivedElements.value, ];
        }
        else if (isSelectedAllCategories.value) {
            filtered = [ ...inUseElements.value, ];
        }
        else {
            for (const c of selectedCategories.value) {
                filtered = [ ...filtered, ...inUseElements.value.filter((e) => e.categoryId === c.id), ];
            }
        }
        if (searchValue.value.length > 0) {
            filtered = [ ...searchingElements.value, ].filter((e) => filtered.includes(e));
        }
        return filtered;
    });
    const currentPageElements = computed(() => filteredElements.value.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value));
    const searchingElements = computed(() => [ ...fuse.value.search(searchValue.value).map((value) => value.item), ]);
    const sortElementsByNameAscending = () => {
        elements.value.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 0);
    };
    const sortElementsByNameDescending = () => {
        elements.value.sort((a, b) => b.name.toUpperCase() < a.name.toUpperCase() ? -1 : 0);
    };
    const sortElementsByCreationTimeAscending = () => {
        elements.value.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
    };
    const sortElementsByCreationTimeDescending = () => {
        elements.value.sort((a, b) => a.creationTimestamp - b.creationTimestamp);
    };
    const getElementById = (id) => elements.value.find((e) => Number.parseInt(e.id, 10) === Number.parseInt(id, 10));
    const getElementCategoryById = (id) => categories.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(id, 10));
    const toggleSelectElement = (element) => {
        getElementById(element.id).selected = !element.selected;
    };
    const toggleSelectElementCategory = (category) => {
        getElementCategoryById(category.id).selected = true;
    };
    const toggleSelectAllElements = (value) => {
        elements.value.forEach((e) => {
            e.selected = value;
        });
    };
    const toggleSelectAllFilteredElements = (value) => {
        for (const e of filteredElements.value) {
            getElementById(e.id).selected = value;
        }
    };
    const toggleSelectAllCategories = (value) => {
        categories.value.forEach((c) => {
            c.selected = value;
        });
    };
    const archiveSelectedElements = async () => {
        const requestBody = {};
        requestBody[elementCollectionName] = selectedElements.value.map((e) => e.id);
        await fnArchiveSelectedElements(requestBody);
        selectedElements.value.forEach((e) => {
            e.isArchived = true;
        });
        toggleSelectAllElements(false);
    };
    const archiveElement = async () => {
        const requestBody = {};
        requestBody[elementCollectionName] = [ elementToUpdate.value.id, ];
        await fnArchiveSelectedElements(requestBody);
        elements.value
            .find((e) => Number.parseInt(e.id, 10) === Number.parseInt(elementToUpdate.value.id, 10)).isArchived = true;
    };
    const unarchiveElement = async () => {
        const requestBody = {};
        requestBody[elementCollectionName] = [ elementToUpdate.value.id, ];
        await fnUnarchiveSelectedElements(requestBody);
        elements.value
            .find((e) => Number.parseInt(e.id, 10) === Number.parseInt(elementToUpdate.value.id, 10)).isArchived = false;
    };
    const unarchiveSelectedElements = async () => {
        const requestBody = {};
        requestBody[elementCollectionName] = selectedElements.value.map((e) => e.id);
        await fnUnarchiveSelectedElements(requestBody);
        selectedElements.value.forEach((e) => {
            e.isArchived = false;
        });
        toggleSelectAllElements(false);
    };
    const sync = async () => {
        elements.value = await fnGetAllElements();
        categories.value = await fnGetAllCategories();
        elements.value.forEach((e) => {
            e.selected = false;
        });
        categories.value.forEach((c) => {
            c.selected = true;
        });
        sortElementsByCreationTimeAscending();
        defaultCategory.value = categories.value.find((c) => c.isDefault);
        newElement.value.categoryId = defaultCategory.value.id.toString();
        bulkUpdateCategorySelectedId.value = defaultCategory.value.id.toString();
        store.commit("disableTabLoading");
    };
    const gotoPage = (goto) => {
        if (goto > totalPages.value || goto <= 0) {
            return;
        }
        currentPage.value = goto;
    };
    const activeCategory = (category) => {
        isShowArchived.value = false;
        toggleSelectAllElements(false);

        if (category) {
            toggleSelectAllCategories(false);
            toggleSelectElementCategory(category);
        }
        else { // Select All
            toggleSelectAllCategories(true);
        }
    };

    const showArchived = () => {
        isShowArchived.value = true;
        toggleSelectAllCategories(false);
        toggleSelectAllElements(false);
    };

    const createNewElement = async (customBody) => {
        const response = await fnCreateNewElement(customBody ?? newElement.value);

        if (response.id) {
            elements.value.push(response);

            elementFields.forEach((field) => {
                newElement.value[field] = "";
            });
            sortElementsByCreationTimeAscending();
        }
        else {
            errorMessage.value = t("error.generic.duplicateName");
        }
    };

    const updateElement = async (customBody) => {
        await fnUpdateElement(customBody ?? elementToUpdate.value);

        const element = getElementById(elementToUpdate.value.id);
        elementFields.forEach((field) => {
            element[field.name] = elementToUpdate.value[field.name];
        });
        element.id = elementToUpdate.value.id;
    };

    const updateElementsCategory = async () => {
        const selectedElementsIds = selectedElements.value.map((element) => element.id);

        const response = await fnUpdateElementsCategory({
            ids: selectedElementsIds,
            categoryId: bulkUpdateCategorySelectedId.value,
        });

        if (!response.type) {
            selectedElementsIds.forEach((selectedElementId) => {
                elements.value
                    .find((element) => element.id === selectedElementId).categoryId = Number.parseInt(bulkUpdateCategorySelectedId.value, 10);
            });
            toggleSelectAllElements(false);
        }
        else {
            errorMessage.value = t("error.generic.duplicateName");
        }
    };

    return {
        currentPage,
        pageSize,
        isShowArchived,
        elements,
        categories,
        elementSearchFields,
        isSelectedAllFilteredElements,
        isSelectedAnyElements,
        isSelectedAllCategories,
        selectedElements,
        selectedCategories,
        filteredElements,
        archivedElements,
        inUseElements,
        searchValue,
        searchField,
        totalPages,
        currentPageElements,
        pageRange,
        bulkUpdateCategorySelectedId,
        errorMessage,
        newElement,
        elementToUpdate,
        defaultCategory,
        elementFields,
        sortElementsByNameAscending,
        sortElementsByNameDescending,
        sortElementsByCreationTimeAscending,
        sortElementsByCreationTimeDescending,
        getElementById,
        getElementCategoryById,
        toggleSelectElement,
        toggleSelectElementCategory,
        toggleSelectAllCategories,
        archiveSelectedElements,
        unarchiveSelectedElements,
        sync,
        toggleSelectAllElements,
        toggleSelectAllFilteredElements,
        gotoPage,
        activeCategory,
        showArchived,
        createNewElement,
        updateElement,
        updateElementsCategory,
        archiveElement,
        unarchiveElement,
    };
}

export class WSKeepAlive {
    apiURI;
    ws;
    alive;
    eventListenerMap;

    constructor (apiURI) {
        this.ws = new WebSocket(apiURI);
        this.apiURI = apiURI;
        this.eventListenerMap = new Map();
    }

    get ws () {
        return this.ws;
    }

    get readyState () {
        return this.ws.readyState;
    }

    send (message) {
        this.ws.send(message);
    }

    addEventListener (event, handler) {
        this.ws.addEventListener(event, handler);
        this.eventListenerMap.set(event, handler);
    }

    close () {
        clearInterval(this.alive);
        this.ws.close();
    }

    keepAlive (socketConnectedHandler) {
        this.alive = setInterval(() => {
            this.reconnect(socketConnectedHandler);
        }, 1000 * 5);
    }

    reconnect () {
        if (!this.ws || this.ws && (this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING)) {
            this.ws = new WebSocket(this.apiURI);

            for (const [ event, handler, ] of this.eventListenerMap.entries()) {
                this.ws.addEventListener(event, handler);
            }
        }
    }
}

// Only White embryo
export function isOnlyWhiteEmbryo (tissueDescriptor) {
    return (
        tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
tissueDescriptor.printPattern === null
    );
}

// White embryo with print pattern
export function isWhiteEmbryoWithPrintPatternUsePreorderColor (tissueDescriptor) {
    return (
        tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
tissueDescriptor.printPattern !== null &&
tissueDescriptor.printPatternUsePreorderColor
    );
}

export function isWhiteEmbryoWithPrintPatternFixedColor (tissueDescriptor) {
    return (
        tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
tissueDescriptor.printPattern !== null &&
tissueDescriptor.printPatternColorId !== null
    );
}

export function isWhiteEmbryoWithPrintPatternNoColor (tissueDescriptor) {
    return (
        tissueDescriptor.tissue?.category.name === "generic.whiteEmbryo" &&
tissueDescriptor.printPattern !== null &&
tissueDescriptor.printPatternNoColor
    );
}

// Only Yarn Dyed Fabric
export function isOnlyYarnDyedFabricUsePreorderColor (tissueDescriptor) {
    return (
        tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
tissueDescriptor.printPattern === null &&
tissueDescriptor.tissueUsePreorderColor
    );
}
export function isOnlyYarnDyedFabricFixedColor (tissueDescriptor) {
    return (
        tissueDescriptor.tissue?.category.name === "generic.yarnDyedFabric" &&
tissueDescriptor.printPattern === null &&
tissueDescriptor.tissueColorId !== null
    );
}

export function getTissueDescriptorUseColor (colors, ppt, colorObj) {
    let appliedRuleColor = colorObj;
    switch (true) {
        case isOnlyWhiteEmbryo(ppt):
            appliedRuleColor = colors.find((c) => c.name === "color.whiteEmbryo");
            break;
        case isOnlyYarnDyedFabricFixedColor(ppt):
            if (ppt.tissueColorRules) {
                const specialColor = ppt.tissueColorRules.find((rule) => rule.orderColorId === colorObj.id);
                if (specialColor) {
                    appliedRuleColor = colors.find((c) => c.id === specialColor.useColorId);
                    break;
                }
            }
            appliedRuleColor = colors.find((c) => c.id === ppt.tissueColorId);
            break;
        case isOnlyYarnDyedFabricUsePreorderColor(ppt):
            if (ppt.tissueColorRules) {
                const specialColor = ppt.tissueColorRules.find((rule) => rule.orderColorId === colorObj.id);
                if (specialColor) {
                    appliedRuleColor = colors.find((c) => c.id === specialColor.useColorId);
                }
            }
            break;
        case isWhiteEmbryoWithPrintPatternNoColor(ppt):
            if (ppt.printPatternColorRules) {
                const specialColor = ppt.printPatternColorRules.find((rule) => rule.orderColorId === colorObj.id);
                if (specialColor) {
                    appliedRuleColor = colors.find((c) => c.id === specialColor.useColorId);
                    break;
                }
            }
            appliedRuleColor = {
                name: "generic.noColor",
                id: 0,
                colorId: 0,
                isDefault: true,
            };
            break;
        case isWhiteEmbryoWithPrintPatternFixedColor(ppt):
            if (ppt.printPatternColorRules) {
                const specialColor = ppt.printPatternColorRules.find((rule) => rule.orderColorId === colorObj.id);
                if (specialColor) {
                    appliedRuleColor = colors.find((c) => c.id === specialColor.useColorId);
                    break;
                }
            }
            appliedRuleColor = colors.find((c) => c.id === ppt.printPatternColorId);
            break;
        case isWhiteEmbryoWithPrintPatternUsePreorderColor(ppt):
            if (ppt.printPatternColorRules) {
                const specialColor = ppt.printPatternColorRules.find((rule) => rule.orderColorId === colorObj.id);
                if (specialColor) {
                    appliedRuleColor = colors.find((c) => c.id === specialColor.useColorId);
                }
            }
            break;
    }
    return appliedRuleColor;
}

export function getAccessoryDescriptorUseColor (colors, pa, colorObj) {
    let appliedRuleColor = colorObj;
    if (pa.colorRules) {
        const specialColor = pa.colorRules.find((rule) => rule.orderColorId === colorObj.id);
        if (specialColor) {
            return colors.find((c) => c.id === specialColor.useColorId);
        }
    }
    switch (true) {
        case pa.usePreorderColor:
            appliedRuleColor = colorObj;
            break;
        case pa.colorId !== null:
            appliedRuleColor = colors.find((c) => c.id === pa.colorId);
            break;
        case pa.noColor:
            appliedRuleColor = {
                name: "generic.noColor",
                id: 0,
                colorId: 0,
                isDefault: true,
            };
            break;
    }
    return appliedRuleColor;
}
