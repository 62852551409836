<template>
    <div class="form-multi-select-selection-tags d-flex rounded-2" :class="disabled ? 'bg-body' : 'form-multi-select'" @click="setShowOptions(true)" ref="msBox">
        <div v-if="!disabled" :class="getInputBoxWidthClass()" class="rounded rounded-2 d-flex flex-wrap start-0 me-auto" :ref="(el) => savedElementsRefs.push(el)">
            <div class="bg-light mx-1 my-1 rounded-2 shadow-sm" v-for="option in selectedOptions" :key="option.value" :ref="(el) => savedElementsRefs.push(el)">
                <div class="px-1 d-flex align-middle h-100" :ref="(el) => savedElementsRefs.push(el)">
                    <div>
                        <CFormLabel class="m-0">{{ option.isDefault ? t(option.text) : option.text }}</CFormLabel>
                    </div>
                    <div class="ps-1 cursor-pointer d-flex align-items-center" @click="removeOption(option)">
                        <CIcon icon="cilX"></CIcon>
                    </div>
                </div>
            </div>
            <div class="ms-1" :class="getSearchBarWidthClass()" :ref="(el) => savedElementsRefs.push(el)">
                <input
                    type="text"
                    class="form-multi-select-search py-1 w-100"
                    autocomplete="off"
                    :placeholder="placeHolderText"
                    v-model="searchValue"
                    @keydown="onDeleteKeyDown"
                    @compositionstart="onCompositionstart"
                    @compositionend="onCompositionend"
                    @input="selectedFromKeyboard = 0"
                    ref="searchInput"
                >
            </div>
        </div>
        <div  class="align-self-center justify-content-end end-0 me-1" v-if="selectedOptions.length > 0 && !disabled">
            <CIcon v-if="clearAll" icon="cilX" size="lg" class="cursor-pointer" @click="emptyOptions"></CIcon>
        </div>
        <div v-if="!disabled" v-show="isOptionsVisible" ref="scrollContainer" class="multiselect-dropdown mt-1 py-2 px-3 position-absolute top-100 start-0 shadow bg-body rounded-2">
            <CListGroup ref="msOptions">
                <CListGroupItem
                  v-if="searchingElements.length === 0 && searchValue.length > 0 && (optionsCopy[0]?.type || type)"
                  class="border-0 d-flex align-items-center"
                >
                    <CFormLabel class="ps-2 mb-0 text-center text-info cursor-pointer" @click="openCreateModal">{{ t("generic.createNew") }}</CFormLabel>
                </CListGroupItem>
                <CListGroupItem
                  v-if="searchingElements.length === 0"
                  class="border-0 d-flex align-items-center"
                >
                    <CFormLabel class="ps-2 mb-0 text-center">{{ t("generic.noResult") }}</CFormLabel>
                </CListGroupItem>
                <CListGroupItem
                    v-for="(option, index) in searchingElements"
                    :key="option.value"
                    class="border-0 d-flex align-items-center ps-2 rounded-2 dropdown-item"
                    :ref="(el) => { if (el) savedElementsRefs.push(el.$el) }"
                    component="button"
                    type="button"
                    @click="selectOption(option)"
                    :class="index === selectedFromKeyboard ? 'bg-light' : ''"
                >
                    <CFormCheck class="mt-0" hit-area="full" :ref="(el) => { if (el) savedElementsRefs.push(el.$el) }" v-model:model-value="option.selected"></CFormCheck>
                    <CFormLabel class="ps-2 mb-0 text-center dropdown-item__text" :ref="(el) => { if (el) savedElementsRefs.push(el.$el) }">{{ option.isDefault ? t(option.text) : option.text }}</CFormLabel>
                </CListGroupItem>
            </CListGroup>
        </div>
        <color-add-modal
            ref="colorAddModal"
            v-model:is-visible="isColorAddModalVisible"
            @submit="addOption"
        ></color-add-modal>
        <size-add-modal
            ref="sizeAddModal"
            v-model:is-visible="isSizeAddModalVisible"
            @submit="addOption"
        ></size-add-modal>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    computed, ref, watch,
} from "vue";
import Fuse from "fuse.js";
import { user } from "@/user";
import ColorAddModal from "@/components/modals/ColorAddModal";
import SizeAddModal from "@/components/modals/SizeAddModal";

export default {
    name: "BaseMultiSelect",
    components: {
        SizeAddModal,
        ColorAddModal,
    },
    emits: [ "change", ],
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        clearAll: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: () => null,
        },
        maxOneSelection: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const isOptionsVisible = ref(false);
        const optionsCopy = ref([ ...props.options, ]);
        const selectedOptions = computed(() => optionsCopy.value.filter((o) => o.selected));
        const searchValue = ref("");
        const searchInput = ref(null);
        const enableRemoveTagByBackspace = ref(true);
        const fuse = computed(() => new Fuse(optionsCopy.value, {
            includeScore: true,
            keys: [ "text", ],
            threshold: 0.6,
        }));
        const msBox = ref(null);
        const searchingElements = computed(() => searchValue.value.length > 0 ? fuse.value.search(searchValue.value).map((s) => s.item) : optionsCopy.value);
        const savedElementsRefs = ref([]);

        const colorAddModal = ref(null);
        const sizeAddModal = ref(null);

        const isColorAddModalVisible = ref(false);
        const isSizeAddModalVisible = ref(false);

        const selectedFromKeyboard = ref(0);
        const scrollContainer = ref(null);

        document.addEventListener("click", (event) => {
            const insideElement = event.target === searchInput.value
            || savedElementsRefs.value.findIndex((e) => e === event.target) !== -1
            || event.target === msBox.value;
            if (!insideElement) {
                setShowOptions(false);
                // selectedFromKeyboard.value = 0; // reset position
            }
        });

        function setShowOptions (set) {
            if (props.disabled) {
                return;
            }

            isOptionsVisible.value = set;
            if (set) {
                searchInput.value.focus();
            }

            if (isOptionsVisible.value) {
                msBox.value?.$el?.classList.add("show");
            }
            else {
                msBox.value?.$el?.classList.remove("show");
            }
        }

        function selectOption (option) {
            if (props.maxOneSelection) {
                optionsCopy.value.forEach((opt) => {
                    opt.selected = false;
                });
            }
            option.selected = !option.selected;
            searchValue.value = "";
            searchInput.value.focus();
        }

        function removeOption (option) {
            optionsCopy.value.find((o) => o.value === option.value).selected = false;
            option.selected = false;
            searchInput.value.focus();
        }

        function emptyOptions () {
            optionsCopy.value.forEach((o) => {
                o.selected = false;
            });
            searchInput.value.focus();
        }

        function getSearchBarWidthClass () {
            return {
                "w-100": selectedOptions.value.length === 0,
                "compact-input": selectedOptions.value.length > 0,
            };
        }

        function getInputBoxWidthClass () {
            return {
                "w-100": selectedOptions.value.length === 0,
            };
        }


        function onDeleteKeyDown (event) {
            if (event.key === "ArrowUp" && selectedFromKeyboard.value > 0) {
                selectedFromKeyboard.value--;
                const el = scrollContainer.value;
                if (el && el.scrollTop > 0 && selectedFromKeyboard.value < optionsCopy.value.length - 3) {
                    el.scrollTop = el.scrollTop - 38;
                }
            }
            if (event.key === "ArrowDown" && selectedFromKeyboard.value < optionsCopy.value.length - 1) {
                selectedFromKeyboard.value++;
                const el = scrollContainer.value;
                if (el && selectedFromKeyboard.value > 2) {
                    el.scrollTop = el.scrollTop + 38;
                }
            }
            if (event.key === "Enter") {
                selectOption(searchingElements.value[selectedFromKeyboard.value]);
            }
            if (enableRemoveTagByBackspace.value && event.key === "Backspace" && searchValue.value.length === 0 && selectedOptions.value.length > 0) {
                removeOption(selectedOptions.value[selectedOptions.value.length - 1]);
            }
            if (searchValue.value.length > 0) {
                event.target.parentElement.style.width = `${(searchValue.value.length + 4) * 2}ch`;
            }
        }

        function onCompositionstart (event) {
            enableRemoveTagByBackspace.value = false;
            event.target.parentElement.style.width = `${(searchValue.value.length + 10) * 2}ch`;
        }

        function onCompositionend (event) {
            enableRemoveTagByBackspace.value = true;
            event.target.parentElement.style.width = `${(searchValue.value.length + 4) * 2}ch`;
        }

        async function openCreateModal () {
            if (optionsCopy.value[0]?.type === "color") {
                colorAddModal.value.preselectName(searchValue.value);
                isColorAddModalVisible.value = true;
            }
            else if (optionsCopy.value[0]?.type === "size") {
                sizeAddModal.value.preselectName(searchValue.value);
                isSizeAddModalVisible.value = true;
            }
            else if (props.type === "orderTag") {
                const response = await user.createOrderTag({ name: searchValue.value, });
                if (response) {
                    addOption(response);
                }
            }
            else if (props.type === "productionTag") {
                const response = await user.createProductionTag({ name: searchValue.value, });
                if (response) {
                    addOption(response);
                }
            }
            searchValue.value = "";
        }

        function addOption (response) {
            const option = {};

            option.value = response.id;
            option.text = response.name;
            option.selected = true;
            option.isDefault = response.isDefault;

            if (optionsCopy.value[0]?.type === "color") {
                option.toSwap = false;
                option.enable = true;
                option.type = "color";
            }

            optionsCopy.value = [ ...optionsCopy.value, option, ];
        }

        const placeHolderText = computed(() => selectedOptions.value.length > 0 ? "" : `${t("generic.select")}...`);

        watch(() => selectedOptions.value, (newValue, oldValue) => {
            let selectedOptionsCopy = newValue;

            if (props.maxOneSelection) {
                selectedOptionsCopy = undefined;

                selectedOptionsCopy = newValue.filter((newOpt) => !oldValue.find((oldOpt) => oldOpt.value === newOpt.value))[0];
            }
            emit("change", selectedOptionsCopy);
        });

        watch(() => props.options, (newValue) => {
            optionsCopy.value = [ ...newValue, ];
        });

        return {
            t,
            isOptionsVisible,
            optionsCopy,
            selectedOptions,
            searchValue,
            searchingElements,
            searchInput,
            placeHolderText,
            enableRemoveTagByBackspace,
            savedElementsRefs,
            msBox,
            isColorAddModalVisible,
            isSizeAddModalVisible,
            colorAddModal,
            sizeAddModal,
            selectedFromKeyboard,
            scrollContainer,
            onCompositionstart,
            onCompositionend,
            getSearchBarWidthClass,
            getInputBoxWidthClass,
            setShowOptions,
            selectOption,
            removeOption,
            emptyOptions,
            onDeleteKeyDown,
            openCreateModal,
            addOption,
        };
    },
};
</script>

<style lang="scss" scoped>
.form-multi-select {
    display: flex;
}
.multiselect-dropdown {
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    width: 100%;
}
.dropdown-item {
    height: 38px;
    &__text {
        font-size: 20px;
        font-size: 0.8vw;
    }
}
.compact-input {
  width: 2ch;
}
</style>
