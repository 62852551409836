import axios from "axios";
import { user } from "./user";
const { configuration, } = require("../configuration");
import { i18n } from "@/i18n";
import { hasNotification } from "./views/utilities";
const { t, } = i18n.global;
// import store from "./store/index";
export const HTTP_API_HOST = configuration.httpApi.host;
export const HTTP_API_URI = configuration.httpApi.uri;
export const WS_API_URI = configuration.wsApi.uri;

export const ERROR_RESPONSE_STATUS = "error";
const maxTries = 1;
const requestsMap = new Map();

export const server = Object.freeze({
    isErrorResponse (data) {
        return data.status === ERROR_RESPONSE_STATUS;
    },

    async get (path, parameters, abortIfDuplicate = false, headers = {}) {
        let count = 0;
        // store.commit("hideError");
        while (true) {
            try {
                let controller;
                let id;
                if (abortIfDuplicate) {
                    if (requestsMap.has(`get ${path}`)) {
                        const desc = requestsMap.get(`get ${path}`);
                        controller = desc.controller;
                        id = desc.id + 1;
                        // save last id
                        requestsMap.set(`get ${path}`, {
                            controller,
                            id,
                        });
                    }
                    else {
                        controller = new AbortController();
                        id = 1;
                        requestsMap.set(`get ${path}`, {
                            controller,
                            id,
                        });
                    }
                }
                const response = await axios.get(`${HTTP_API_URI}/${path}`, {
                    params: parameters,
                    headers: {
                        ...headers,
                        ...user.authenticationHeaders,
                    },
                    timeout: 20000,
                    signal: controller?.signal,
                });
                if (abortIfDuplicate) {
                    // last request
                    if (requestsMap.get(`get ${path}`).id === id) {
                        controller.abort();
                        requestsMap.delete(`get ${path}`);
                    }
                }
                return response;
            }
            catch (error) {
                if (error.code === "ECONNABORTED") {
                    if (hasNotification) {
                        new Notification(t("error.generic.timeout"));
                    }
                    if (++count === maxTries) {
                        // store.commit("disableTabLoading");
                        // store.commit("showError");
                        break;
                    }
                }
                else {
                    console.log(error);
                    break;
                }
            }
        }
        throw "cancelled";
    },

    async post (path, parameters, abortIfDuplicate = false, headers = {}) {
        let count = 0;
        // store.commit("hideError");
        while (true) {
            try {
                let controller;
                let id;
                if (abortIfDuplicate) {
                    if (requestsMap.has(`post ${path}`)) {
                        const desc = requestsMap.get(`post ${path}`);
                        controller = desc.controller;
                        id = desc.id + 1;
                        // save last id
                        requestsMap.set(`post ${path}`, {
                            controller,
                            id,
                        });
                    }
                    else {
                        controller = new AbortController();
                        id = 1;
                        requestsMap.set(`post ${path}`, {
                            controller,
                            id,
                        });
                    }
                }
                const response = await axios.post(`${HTTP_API_URI}/${path}`, parameters, {
                    headers: {
                        ...headers,
                        ...user.authenticationHeaders,
                    },
                    signal: controller?.signal,
                    timeout: 60000,
                });
                if (abortIfDuplicate) {
                    // last request
                    if (requestsMap.get(`post ${path}`).id === id) {
                        controller.abort();
                        requestsMap.delete(`post ${path}`);
                    }
                }
                return response;
            }
            catch (error) {
                if (error.code === "ECONNABORTED") {
                    if (hasNotification) {
                        new Notification(t("error.generic.timeout"));
                    }
                    if (++count === maxTries) {
                        // store.commit("disableTabLoading");
                        // store.commit("showError");
                        break;
                    }
                }
                else {
                    console.log(error);
                    break;
                }
            }
        }
        throw "cancelled";
    },

    async postWithProgressEvent (path, parameters, onUploadProgress, headers = {}) {
        return axios.post(`${HTTP_API_URI}/${path}`, parameters, {
            headers: {
                ...headers,
                ...user.authenticationHeaders,
            },
            onUploadProgress,
            timeout: 60000,
        });
    },
});
