<template>
    <VMenu placement="right">
        <div ref="popoverToggler">
            <slot name="toggler"></slot>
        </div>
        <template #popper>
            <CContainer class="p-2 popover-container" ref="popoverContainer">
                <CRow class="m-0 d-inline-flex flex-nowrap">
                    <CCol class="d-flex my-2 p-0 me-2">
                        <CImage
                            @click="store.commit('openLightbox', { images: tissue.images.map((i) => i.compress), index: 0, })"
                            v-if="tissue.images?.length > 0"
                            class="t-col-photo rounded cursor-pointer"
                            :src="tissue.images[0].tiny"
                          >
                        </CImage>
                        <CIcon v-else icon="cil-image" size="4xl"></CIcon>
                    </CCol>
                    <CCol xs="auto" class="mt-1 mb-2 my-2 border-start">
                        <CRow>
                            <CCol class="d-flex">
                                <span class="fs-4 fw-bold text-start text-nowrap">{{ tissue.name ?? "-" }}</span>
                            </CCol>
                        </CRow>
                        <CRow class="flex-nowrap">
                            <CCol :xl="6">
                                <CRow class="mt-2">
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.lastUpdate") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow class="mt-2">
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.type") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.supplier") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.unitPrice") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.weight") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("generic.width") }}:</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start fw-light text-nowrap">{{ t("ingredient.ingredientAndNote") }}:</span>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol :xl="6">
                                <CRow class="mt-2">
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-nowrap">{{ tissue.lastUpdateTimestamp ? getTimeDifferenceText(tissue.lastUpdateTimestamp) : "-" }}</span>
                                    </CCol>
                                </CRow>
                                <CRow class="mt-2">
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-nowrap">{{ tissue.category.isDefault ? t(tissue.category.name) : tissue.category.name }}</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-nowrap">{{ tissue.supplier.isDefault ? t(tissue.supplier.name) : tissue.supplier.name }}</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-nowrap">{{ tissue.price ?? "-"}}</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-nowrap">{{ tissue.weight ?? "-"}}</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-nowrap">{{ tissue.width ?? "-"}}</span>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="d-flex">
                                        <span class="fs-5 text-start text-wrap" style="max-width: 200px">{{ tissue.ingredientAndNote ?? "-"}}</span>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CContainer>
        </template>
    </VMenu>
</template>

<script>
import { useI18n } from "vue-i18n";
import { getTimeDifferenceText } from "@/views/utilities";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
    name: "TissuePopover",
    props: {
        tissue: {
            type: Object,
        },
        hoverble: {
            type: Boolean,
            default: true,
        },
    },
    setup (props) {
        const { t, } = useI18n();
        const contentMouseover = ref(false);
        const popoverContainer = ref(null);
        const popoverToggler = ref(null);
        const store = useStore();
        let element = null;

        function showPopover (e) {
            if (props.hoverble && !contentMouseover.value && e.target.id === "popoverToggler" && !element) {
                const evt = new Event("click", { bubbles: true, });
                e.target.dispatchEvent(evt);
                element = e.target;
            }
        }

        function hidePopover (e) {
            if (props.hoverble && e.fromElement === popoverToggler.value) {
                setTimeout(() => {
                    if (!contentMouseover.value && element) {
                        element.click();
                        element = null;
                    }
                }, 50);
            }
        }

        function holdPopover () {
            if (props.hoverble) {
                contentMouseover.value = true;
            }
        }

        function leavePopoverContent (e) {
            if (props.hoverble && e.fromElement === popoverContainer.value.$el && element) {
                contentMouseover.value = false;
                element.click();
                element = null;
            }
        }

        return {
            t,
            getTimeDifferenceText,
            contentMouseover,
            showPopover,
            hidePopover,
            holdPopover,
            leavePopoverContent,
            popoverContainer,
            popoverToggler,
            store,
        };
    },
};
</script>

<style scoped>

</style>
