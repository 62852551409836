<template>
    <CHeader position="sticky" class="m-0 py-0">
        <CContainer class="py-0" fluid>
            <CHeaderToggler class="py-0 ps-1" @click="$store.commit('toggleSidebar')">
                <CIcon class="text-white" icon="cil-menu" size="lg" />
            </CHeaderToggler>
            <CHeaderBrand class="py-0 mx-auto d-lg-none" to="/">
                <CIcon :icon="logo" height="12" alt="Logo" />
            </CHeaderBrand>
            <CHeaderNav class="py-0 d-none d-md-flex me-auto">
            </CHeaderNav>
            <CHeaderNav>
                <AppHeaderDropdownAccnt />
            </CHeaderNav>
        </CContainer>
    </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from "./AppHeaderDropdownAccnt";
import { logo } from "@/assets/brand/logo";
export default {
    name: "AppHeader",
    components: {
        AppHeaderDropdownAccnt,
    },
    setup () {
        return {
            logo,
        };
    },
};
</script>
