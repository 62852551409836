<template>
    <CRow>
        <CCol :xs="11" class="pe-0">
            <div class="form-multi-select d-flex ps-1 pe-0 py-1 rounded-2 h-100" @click="setShowOptions(true)" id="ms-box">
                <div :class="getInputBoxWidthClass()" class="rounded rounded-2 d-flex flex-wrap start-0 me-auto" id="ms-tags">
                    <div class="bg-light mx-1 my-1 rounded-2 shadow-sm" v-for="option in selectedOptions" :key="option.value" id="ms-1">
                        <div class="px-3 d-flex text-nowrap align-middle h-100" id="ms-2">
                            <div>
                                <CFormLabel class="m-0 w-75">{{ option.text }}</CFormLabel>
                            </div>
                            <div class="ps-2 cursor-pointer d-flex align-items-center" @click="removeOption(option)">
                                <CIcon icon="cilX"></CIcon>
                            </div>
                        </div>
                    </div>
                    <div class="ps-2" :class="getSearchBarWidthClass()" id="ms-input-container">
                        <input
                            type="text"
                            class="form-multi-select-search py-1 w-100"
                            autocomplete="off"
                            :placeholder="placeHolderText"
                            id="ms-input"
                            v-model="searchValue"
                            @keydown="onDeleteKeyDown"
                            @compositionstart="onCompositionstart"
                            @compositionend="onCompositionend"
                            ref="searchInput"
                        >
                    </div>
                </div>
                <div class="align-self-center justify-content-end end-0 me-5" v-if="selectedOptions.length > 0">
                    <CIcon icon="cilX" size="lg" class="cursor-pointer" @click="emptyOptions"></CIcon>
                </div>
                <div v-if="isOptionsVisible" class="multiselect-dropdown mt-1 py-2 px-3 position-absolute w-100 top-100 start-0 shadow bg-body rounded-2">
                    <CListGroup id="ms-options">
                        <CListGroupItem
                        v-if="searchingElements.length === 0"
                        class="border-0 d-flex align-items-center"
                        >
                            <CFormLabel class="ps-2 mb-0 text-center">{{ t("generic.noItem") }}</CFormLabel>
                        </CListGroupItem>
                        <CListGroupItem
                            v-for="option in searchingElements"
                            :key="option.value"
                            class="border-0 d-flex align-items-center ps-2 rounded-2"
                            id="ms-option"
                            component="button"
                            type="button"
                            @click="selectOption(option)"
                        >
                            <CFormCheck class="mt-0" hit-area="full" :id="`ms-${option.value}`" v-model:model-value="option.selected"></CFormCheck>
                            <CFormLabel class="ps-2 mb-0 text-center" id="ms-label">{{ option.text }}</CFormLabel>
                        </CListGroupItem>
                    </CListGroup>
                </div>
            </div>
        </CCol>
        <CCol :xs="1" class="ps-2 h-100" v-if="searchValue.length > 0 && options.findIndex((so) => so.text === searchValue) === -1">
            <div>
                <CButton variant="outline" color="success" class="rounded-2 d-flex py-2" @click="createTag">
                    <CIcon icon="cilPlus" class="my-1"></CIcon>
                </CButton>
            </div>
        </CCol>
    </CRow>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    computed, ref, watch,
} from "vue";
import { debounce } from "debounce";
import Fuse from "fuse.js";
import {user} from "@/user";

export default {
    name: "TagMultiSelect",
    emits: [ "change", ],
    props: {
        options: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const isOptionsVisible = ref(false);
        const optionsCopy = ref([ ...props.options, ]);
        const selectedOptions = computed(() => optionsCopy.value.filter((o) => o.selected));
        const searchValue = ref("");
        const searchInput = ref(null);
        const enableRemoveTagByBackspace = ref(true);
        const fuse = computed(() => new Fuse(optionsCopy.value, {
            includeScore: true,
            keys: [ "text", ],
            threshold: 0,
        }));
        const searchingElements = computed(() => searchValue.value.length > 0 ? fuse.value.search(searchValue.value).map((s) => s.item) : optionsCopy.value);

        const aa = computed(() => console.log(searchingElements.value));


        window.onclick = (event) => {
            if (!event.target.id.includes("ms")) {
                setShowOptions(false);
            }
        };

        function setShowOptions (set) {
            isOptionsVisible.value = set;

            if (isOptionsVisible.value) {
                document.getElementById("ms-box")?.classList.add("show");
            }
            else {
                document.getElementById("ms-box")?.classList.remove("show");
            }
        }

        function selectOption (option) {
            option.selected = !option.selected;
            searchInput.value.focus();
        }

        function removeOption (option) {
            optionsCopy.value.find((o) => o.value === option.value).selected = false;
            searchInput.value.focus();
        }

        async function createTag () {
            if (searchValue.value.length > 0 && !optionsCopy.value.find((o) => o.text === searchValue.value)) {
                const tag = await user.createTag({ name: searchValue.value, });

                if (tag.id) {
                    optionsCopy.value = [ ...optionsCopy.value, {
                        value: tag,
                        text: tag.name,
                        selected: true,
                    }, ];
                }

                searchValue.value = "";
            }
            searchInput.value.focus();
        }

        function emptyOptions () {
            optionsCopy.value.forEach((o) => {
                o.selected = false;
            });
            searchInput.value.focus();
        }

        function getSearchBarWidthClass () {
            return {
                "w-100": selectedOptions.value.length === 0,
                "compact-input": selectedOptions.value.length > 0,
            };
        }

        function getInputBoxWidthClass () {
            return {
                "w-100": selectedOptions.value.length === 0,
            };
        }

        function onDeleteKeyDown (event) {
            if (enableRemoveTagByBackspace.value && event.key === "Backspace" && searchValue.value.length === 0 && selectedOptions.value.length > 0) {
                removeOption(selectedOptions.value[selectedOptions.value.length - 1]);
            }
            if (searchValue.value.length > 0) {
                event.target.parentElement.style.width = `${(searchValue.value.length + 4) * 2}ch`;
            }
        }

        function onCompositionstart (event) {
            enableRemoveTagByBackspace.value = false;
            event.target.parentElement.style.width = `${(searchValue.value.length + 10) * 2}ch`;
        }

        function onCompositionend (event) {
            enableRemoveTagByBackspace.value = true;
            event.target.parentElement.style.width = `${(searchValue.value.length + 4) * 2}ch`;
        }

        const placeHolderText = computed(() => selectedOptions.value.length > 0 ? "" : `${t("generic.select")}...`);

        watch(() => selectedOptions.value, (newValue, oldValue) => {
            emit("change", newValue);
        });

        watch(() => props.options, (newValue, oldValue) => {
            optionsCopy.value = [ ...newValue, ];
        });

        return {
            t,
            isOptionsVisible,
            optionsCopy,
            selectedOptions,
            searchValue,
            searchingElements,
            aa,
            searchInput,
            placeHolderText,
            enableRemoveTagByBackspace,
            onCompositionstart,
            onCompositionend,
            getSearchBarWidthClass,
            getInputBoxWidthClass,
            setShowOptions,
            selectOption,
            removeOption,
            createTag,
            emptyOptions,
            onDeleteKeyDown,
        };
    },
};
</script>

<style lang="scss" scoped>
.multiselect-dropdown {
    max-height: 200px;
    overflow-y: auto;
}
.compact-input {
    width: 5ch;
}
</style>
