<template>
    <CModal :visible="isVisible" @close="closeModal" class="fixed-height" scrollable>
        <CModalHeader :close-button="false">
            <CModalTitle>
                {{ t("generic.changeColor") }}
                {{ descriptor.tissueName ?? "" }}
            </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <template v-if="descriptor.tissueName">
                <CRow class="m-3">
                    <span class="fs-5">{{ t("generic.tissue") }}</span>
                </CRow>
                <CRow class="mx-3">
                    <template v-if="descriptor.selectedTissue.category.name === 'generic.yarnDyedFabric'">
                        <CCol :xs="10" class="pe-1 ps-0 m-0">
                            <search-select :options="colorsCopy" v-model:model-value="selectedTissueColor"></search-select>
                        </CCol>
                        <CCol :xs="1" class="m-0">
                            <CTooltip>
                                <template #toggler="{ on, }">
                                    <CButton v-on="on" @click="openSpecialColorRuleAddModal('tissue')">{{ getTissueColorRuleButtonText() }}</CButton>
                                </template>
                                <template #content>
                                    <CRow>
                                        <CCol>{{ t("generic.preorderColor") }}</CCol>
                                        <CCol>
                                            <CIcon icon="cilArrowRight"></CIcon>
                                        </CCol>
                                        <CCol>{{ t("generic.specialColor") }}</CCol>
                                    </CRow>
                                    <CRow class="my-2">
                                        <CCol>
                                            <div class="border border-top"></div>
                                        </CCol>
                                    </CRow>
                                    <CRow v-for="rule in descriptor.tissueColorRules" :key="rule.key">
                                        <CCol>{{ getElementName(getColorById(rule.orderColorId)) }}</CCol>
                                        <CCol>
                                            <CIcon icon="cilArrowRight"></CIcon>
                                        </CCol>
                                        <CCol>{{ getElementName(getColorById(rule.useColorId)) }}</CCol>
                                    </CRow>
                                    <CRow  v-if="descriptor.tissueColorRules?.length === 0">
                                        <CCol>{{ t("generic.noColorRules") }}</CCol>
                                    </CRow>
                                </template>
                            </CTooltip>
                        </CCol>
                    </template>
                    <template v-else>
                        <CCol class="m-0">
                            <span>{{ t("ingredient.tissue.noColorForWhiteEmbryo") }}</span>
                        </CCol>
                    </template>
                </CRow>
            </template>

            <template v-if="descriptor.printPatternName">
                <CRow class="m-3 mt-4">
                    <span class="fs-5">{{ t("generic.printPattern") }}</span>
                </CRow>
                <CRow class="mx-3">
                    <CCol :xs="10" class="pe-1 ps-0 m-0">
                        <search-select
                            :options="colorsCopy"
                            v-model:model-value="selectedPrintPatternColor"
                        ></search-select>
                    </CCol>
                    <CCol :xs="1" class="m-0">
                        <CTooltip>
                            <template #toggler="{ on, }">
                                <CButton v-on="on" @click="openSpecialColorRuleAddModal('printPattern')">{{ getPrintPatternColorRuleButtonText() }}</CButton>
                            </template>
                            <template #content>
                                <CRow>
                                    <CCol>{{ t("generic.preorderColor") }}</CCol>
                                    <CCol>
                                        <CIcon icon="cilArrowRight"></CIcon>
                                    </CCol>
                                    <CCol>{{ t("generic.specialColor") }}</CCol>
                                </CRow>
                                <CRow class="my-2">
                                    <CCol>
                                        <div class="border border-top"></div>
                                    </CCol>
                                </CRow>
                                <CRow v-for="rule in descriptor.printPatternColorRules" :key="rule.key">
                                    <CCol>{{ getElementName(getColorById(rule.orderColorId)) }}</CCol>
                                    <CCol>
                                        <CIcon icon="cilArrowRight"></CIcon>
                                    </CCol>
                                    <CCol>{{ getElementName(getColorById(rule.useColorId)) }}</CCol>
                                </CRow>
                                <CRow  v-if="descriptor.printPatternColorRules?.length === 0">
                                    <CCol>{{ t("generic.noColorRules") }}</CCol>
                                </CRow>
                            </template>
                        </CTooltip>
                    </CCol>
                </CRow>
            </template>

            <template v-if="descriptor.name">
                <CRow class="m-3 mt-4">
                    <span class="fs-5">{{ t("generic.procedure") }} {{ descriptor.name }}</span>
                </CRow>
                <CRow class="mx-3">
                    <CCol :xs="10" class="pe-1 ps-0 m-0">
                        <search-select
                            :options="colorsCopy"
                            v-model:model-value="selectedDyeingColor"
                        ></search-select>
                    </CCol>
                    <CCol :xs="1" class="m-0">
                        <CTooltip>
                            <template #toggler="{ on, }">
                                <CButton v-on="on" @click="openSpecialColorRuleAddModal('dyeing')">{{ getProcedureColorRuleButtonText() }}</CButton>
                            </template>
                            <template #content>
                                <CRow>
                                    <CCol>{{ t("generic.preorderColor") }}</CCol>
                                    <CCol>
                                        <CIcon icon="cilArrowRight"></CIcon>
                                    </CCol>
                                    <CCol>{{ t("generic.specialColor") }}</CCol>
                                </CRow>
                                <CRow class="my-2">
                                    <CCol>
                                        <div class="border border-top"></div>
                                    </CCol>
                                </CRow>
                                <CRow v-for="rule in descriptor.dyeingColorRules" :key="rule.key">
                                    <CCol>{{ getElementName(getColorById(rule.orderColorId)) }}</CCol>
                                    <CCol>
                                        <CIcon icon="cilArrowRight"></CIcon>
                                    </CCol>
                                    <CCol>{{ getElementName(getColorById(rule.useColorId)) }}</CCol>
                                </CRow>
                                <CRow  v-if="descriptor.dyeingColorRules?.length === 0">
                                    <CCol>{{ t("generic.noColorRules") }}</CCol>
                                </CRow>
                            </template>
                        </CTooltip>
                    </CCol>
                </CRow>
            </template>
        </CModalBody>

        <CModalFooter>
            <CButton variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
            <CButton color="primary" @click="confirm">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>

        <special-color-rule-add-modal
            v-model:is-visible="isSpecialColorRuleAddModalVisible"
            :colors="colors"
            :color-rules="colorRulesCopy"
            @confirm="updateColorRules"
        ></special-color-rule-add-modal>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    watch,
    ref,
    computed,
} from "vue";
import SearchSelect from "@/components/pickers/SearchSelect";
import { user } from "@/user";
import SpecialColorRuleAddModal from "@/components/modals/SpecialColorRuleAddModal";
import { getElementName } from "@/views/utilities";

export default {
    name: "SpecialColorRuleModal",
    components: {
        SearchSelect,
        SpecialColorRuleAddModal,
    },
    emits: [ "update:isVisible", "confirm", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        descriptor: {
            type: Object,
            default: null,
        },
        colors: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const descriptorCopy = ref({});
        const selectedTissueColor = ref({});
        const selectedPrintPatternColor = ref({});
        const selectedDyeingColor = ref({});

        const colorsCopy = ref([]);
        const colorRulesCopy = computed(() => {
            if (typeOfLastOpened.value === "tissue") {
                return descriptorCopy.value.tissueColorRules;
            }

            if (typeOfLastOpened.value === "printPattern") {
                return descriptorCopy.value.printPatternColorRules;
            }

            if (typeOfLastOpened.value === "dyeing") {
                return descriptorCopy.value.dyeingColorRules;
            }

            return [];
        });

        const isSpecialColorRuleAddModalVisible = ref(false);
        const typeOfLastOpened = ref("");

        function closeModal () {
            emit("update:isVisible", false);
        }

        function confirm () {
            if (descriptorCopy.value.selectedTissue) {
                descriptorCopy.value.selectedTissue.tissueColorId = selectedTissueColor.value > 0 ? selectedTissueColor.value : "";
                descriptorCopy.value.selectedTissue.printPatternColorId = selectedPrintPatternColor.value > 0 ? selectedPrintPatternColor.value : "";
            }

            if (descriptorCopy.value.procedure) {
                descriptorCopy.value.procedure.colorId = selectedDyeingColor.value > 0 ? selectedDyeingColor.value : "";
            }

            if (!descriptorCopy.value.tissueColorRules && typeOfLastOpened.value === "tissue" || !descriptorCopy.value.printPatternColorRules && typeOfLastOpened.value === "printPattern") {
                updateColorRules([]);
            }

            if (!descriptorCopy.value.dyeingColorRules && typeOfLastOpened.value === "dyeing") {
                updateColorRules([]);
            }
            emit("confirm", descriptorCopy.value);
            closeModal();
        }

        function openSpecialColorRuleAddModal (type) {
            typeOfLastOpened.value = type;
            isSpecialColorRuleAddModalVisible.value = true;
        }

        function updateColorRules (rules) {
            if (typeOfLastOpened.value === "tissue") {
                descriptorCopy.value.tissueColorRules = rules;
            }

            if (typeOfLastOpened.value === "printPattern") {
                descriptorCopy.value.printPatternColorRules = rules;
            }

            if (typeOfLastOpened.value === "dyeing") {
                descriptorCopy.value.dyeingColorRules = rules;
            }
        }

        function getTissueColorRuleButtonText () {
            if (descriptorCopy.value.tissueColorRules?.length > 0) {
                return t("generic.hasColorRules");
            }

            return "...";
        }

        function getPrintPatternColorRuleButtonText () {
            if (descriptorCopy.value.printPatternColorRules?.length > 0) {
                return t("generic.hasColorRules");
            }

            return "...";
        }

        function getProcedureColorRuleButtonText () {
            if (descriptorCopy.value.dyeingColorRules?.length > 0) {
                return t("generic.hasColorRules");
            }

            return "...";
        }

        function getColorById (colorId) {
            return colorsCopy.value.find((c) => c.id === colorId);
        }

        watch(() => props.isVisible, (isVisible) => {
            if (isVisible) {
                descriptorCopy.value = props.descriptor;
                if (descriptorCopy.value.tissueColorRule === t("model.followOrderColor")) {
                    selectedTissueColor.value = -2;
                }
                else {
                    selectedTissueColor.value = descriptorCopy.value.selectedTissue?.tissueColorId;
                }

                if (descriptorCopy.value.printPatternColorRule === t("model.followOrderColor")) {
                    selectedPrintPatternColor.value = -2;
                }
                else {
                    selectedPrintPatternColor.value = descriptorCopy.value.selectedTissue?.printPatternColorId;
                }

                if (descriptorCopy.value.procedure && descriptorCopy.value.colorRule === t("model.followOrderColor")) {
                    selectedDyeingColor.value = -2;
                }
                else if (descriptorCopy.value.procedure) {
                    selectedDyeingColor.value = descriptorCopy.value.procedure?.colorId;
                }

                colorsCopy.value = [ {
                    name: "model.followOrderColor",
                    isDefault: true,
                    id: -2,
                }, ...props.colors, ];
            }
        });

        return {
            t,
            colorsCopy,
            selectedTissueColor,
            selectedPrintPatternColor,
            isSpecialColorRuleAddModalVisible,
            colorRulesCopy,
            selectedDyeingColor,
            closeModal,
            confirm,
            openSpecialColorRuleAddModal,
            updateColorRules,
            getTissueColorRuleButtonText,
            getPrintPatternColorRuleButtonText,
            getProcedureColorRuleButtonText,
            getElementName,
            getColorById,
        };
    },
};
</script>

<style scoped>

</style>
