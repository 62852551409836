<template>
    <CModal :visible="isVisible" @close="close">
        <CModalHeader>{{ t("component.productionTablePreferences") }}</CModalHeader>

        <CModalBody>
            <CRow>
                <CCol>
                    <CFormCheck :label="t('generic.urgentPreorder')" v-model:model-value="preferences.urgentPreorder">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.preorderLogo')" v-model:model-value="preferences.preorderLogo">
                    </CFormCheck>
                    <CFormCheck :label="t('generic.preorderComposition')"
                        v-model:model-value="preferences.preorderComposition"></CFormCheck>
                    <CFormCheck :label="t('generic.preorderProductProductionNote')"
                        v-model:model-value="preferences.preorderProductProductionNote"></CFormCheck>
                    <CFormCheck :label="t('order.note')" v-model:model-value="preferences.preorderNote"></CFormCheck>
                    <CFormCheck :label="t('production.customer')" v-model:model-value="preferences.customerName">
                    </CFormCheck>
                    <CFormCheck :label="t('model.note')" v-model:model-value="preferences.modelNote"></CFormCheck>
                </CCol>
            </CRow>
        </CModalBody>

        <CModalFooter>
            <CButton type="button" variant="outline" color="secondary" @click="close">{{ t("generic.undo") }}
            </CButton>
            <CButton type="button" color="primary" @click="submitUpdate">{{ t("generic.confirm") }}
            </CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    ref, watch, toRaw,
} from "vue";
import { user } from "@/user";

export default {
    name: "PreferencesModal",
    emits: [ "update:isVisible", "change", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();

        const preferences = ref({
            urgentPreorder: true,
            preorderLogo: true,
            preorderComposition: true,
            preorderProductProductionNote: true,
            preorderNote: true,
            customerName: false,
            modelNote: false,
        });

        function close () {
            emit("update:isVisible", false);
        }

        async function submitUpdate () {
            await user.updateProductionTablePreference(JSON.stringify(preferences.value));
            emit("change");
            close();
        }

        async function init () {
            const productionTablePreference = await user.getProductionTablePreference();
            if (!productionTablePreference) {
                await user.updateProductionTablePreference(JSON.stringify(preferences.value));
            }
            else {
                preferences.value = JSON.parse(productionTablePreference.preference);
            }
        }

        watch(() => props.isVisible, async (newValue) => {
            if (newValue) {
                await init();
            }
        });

        return {
            t,
            preferences,
            close,
            submitUpdate,
        };
    },
};
</script>

<style scoped>

</style>
