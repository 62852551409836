<template>
    <CModal :visible="isVisible" @close="closeModal" scrollable>
        <CModalHeader :close-button="false">
            <CModalTitle>{{ t("generic.specialColorRules") }}</CModalTitle>
        </CModalHeader>

        <CModalBody>
            <CTable bordered class="bg-light mb-5">
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell></CTableHeaderCell>
                        <CTableHeaderCell class="t-col-4">{{ t("generic.ifUseColor") }}</CTableHeaderCell>
                        <CTableHeaderCell>
                            <CIcon icon="cilArrowRight"></CIcon>
                        </CTableHeaderCell>
                        <CTableHeaderCell class="t-col-4">{{ t("generic.thanUseColor") }}</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>

                <CTableBody>
                    <CTableRow class="align-middle" v-for="(rule, ruleIndex) in colorRulesCopy" :key="rule.key">
                        <CTableDataCell>
                            <CIcon icon="cilXCircle" class="text-danger cursor-pointer" size="xl" @click="removeColorRule(ruleIndex)"></CIcon>
                        </CTableDataCell>
                        <CTableDataCell>
                            <SearchSelect :options="colors" v-model:model-value="rule.orderColorId"></SearchSelect>
                        </CTableDataCell>
                        <CTableDataCell>
                            <CIcon icon="cilArrowRight"></CIcon>
                        </CTableDataCell>
                        <CTableDataCell>
                            <SearchSelect :options="colors" v-model:model-value="rule.useColorId"></SearchSelect>
                        </CTableDataCell>
                    </CTableRow>
                </CTableBody>
            </CTable>
        </CModalBody>

        <CModalFooter class="position-relative">
            <div class="position-absolute start-0 top-0 m-3">
                <span class="cursor-pointer text-info user-select-none" @click="addColorRule">{{ t("generic.addColorRule") }}</span>
            </div>
            <CButton variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
            <CButton color="primary" @click="confirm">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import SearchSelect from "@/components/pickers/SearchSelect";
import { watch, ref } from "vue";

export default {
    name: "SpecialColorRuleAddModal",
    components: {
        SearchSelect,
    },
    emits: [ "update:isVisible", "confirm", ],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        colors: {
            type: Array,
            default: () => [],
        },
        colorRules: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const colorRulesCopy = ref([]);
        let ruleKey = 0;

        function closeModal () {
            emit("update:isVisible", false);
        }

        function addColorRule () {
            colorRulesCopy.value = [ ...colorRulesCopy.value, {
                orderColorId: "",
                useColorId: "",
                key: ruleKey,
            }, ];

            ruleKey++;
        }

        function removeColorRule (index) {
            colorRulesCopy.value.splice(index, 1);
        }

        function confirm () {
            colorRulesCopy.value = colorRulesCopy.value.filter((rule) => rule.orderColorId && rule.useColorId);
            emit("confirm", colorRulesCopy.value);
            closeModal();
        }

        watch(() => props.isVisible, () => {
            colorRulesCopy.value = props.colorRules.map((color) => {
                ruleKey++;
                return {
                    ...color,
                    key: ruleKey,
                };
            });
        });

        return {
            t,
            colorRulesCopy,
            closeModal,
            addColorRule,
            removeColorRule,
            confirm,
        };
    },
};
</script>

<style scoped>

</style>
