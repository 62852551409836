<template>
    <CModal :scrollable="true" :visible="isVisible" backdrop="static" alignment="center" :fullscreen="true" @close="closeModal">
        <AppLoadingScreen :isLoading="isLoading"/>
        <CForm v-if="!isLoading" @submit.prevent="submitUpdate">

            <CModalHeader :close-button="false" class="p-0 justify-content-start">
                <div class="image-bar">
                    <h3 class="fs-5 p-3">{{ t("model.images") }}</h3>
                </div>
                <div class="form-container">
                    <CRow>
                        <CCol xs="auto" class="me-auto">
                            <div>
                                <CModalTitle>{{ t("model.updateModel") }}</CModalTitle>
                                <span class="fs-5">{{ newProduct.productTemplateId }}({{ newProduct.name }})</span>
                            </div>
                        </CCol>
                        <CCol xs="auto" class="pe-4 me-1">
                            <CButton class="me-2" type="button" variant="outline" color="secondary" @click="closeModal">{{ t("generic.undo") }}</CButton>
                            <CLoadingButton :loading="isConfirmButtonLoading" :disabled="isConfirmButtonLoading" type="submit" color="primary">{{ t("generic.confirm") }}</CLoadingButton>
                        </CCol>
                    </CRow>
                </div>
            </CModalHeader>
            <CModalBody>
                <CRow class="modal-content-container flex-nowrap">

                    <!-- Image row -->
                    <div class="border-end image-bar">
                        <CCol class="mt-3 bg-body bg-gradient">
                            <BaseImageUploader @upload="saveImages"/>
                            <CRow class="mt-3 image-bar-gallery">
                                <CCol class="mb-3" v-for="(image, index) in images" :key="index">
                                    <div class="t-col-4 cursor-pointer mb-2" style="position: relative;">
                                        <CImage v-if="image?.substring('wetex.me')" class="t-col-4" thumbnail align="start" :src="image.replace('/images/', '/images/compress/')" @click="openLightbox(index)"></CImage>
                                        <CImage v-else class="t-col-4" thumbnail align="start" :src="image" @click="openLightbox(index)"></CImage>
                                        <button class="button-remove-image" @click.prevent="deleteImage(index)">
                                            <span class="icon-cross"></span>
                                        </button>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                    </div>

                    <div class="px-0 form-container">
                        <!-- NavBar row -->
                        <CNav variant="underline" role="tablist">
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    :active="activeModalTab===0"
                                    @click="activeModalTab=0"
                                >
                                    {{ t("generic.essentialInformation") }}
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    :active="activeModalTab===1"
                                    @click="activeModalTab=1"
                                >
                                    {{ t("generic.tissuesAndAccessory") }}
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    :active="activeModalTab===2"
                                    @click="activeModalTab=2"
                                >
                                    {{ t("generic.procedure") }}
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    :active="activeModalTab===3"
                                    @click="activeModalTab=3"
                                >
                                    {{ t("generic.colorManager") }}
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    :active="activeModalTab===4"
                                    @click="activeModalTab=4"
                                >
                                    {{ t("generic.costManager") }}
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>

                            <!-- Essentials row -->
                            <CTabPane role="tabpanel" aria-labelledby="essentials-information-tab" :visible="activeModalTab === 0">
                                <CContainer fluid>
                                    <CRow class="pt-4">
                                        <CCol :xs="3">
                                            <CFormLabel>{{ t("generic.productTemplates") }} *</CFormLabel>
                                            <BaseInput :placeholder="t('generic.required')" v-model="newProduct.productTemplateId" required readonly></BaseInput>
                                        </CCol>
                                        <CCol :xs="4">
                                            <CFormLabel>{{ t("generic.model") }}</CFormLabel>
                                            <BaseInput :placeholder="autoGeneratedName.length > 0 ? autoGeneratedName : t('generic.emptyForAutomaticSelection')" v-model="newProduct.name"></BaseInput>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-4">
                                        <CCol :xs="3">
                                            <CFormLabel>{{ t("generic.weight") }} (kg)</CFormLabel>
                                            <BaseInput placeholder="0,000" v-model="newProduct.weight"></BaseInput>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-5">
                                        <CCol :xs="8">
                                            <CFormLabel>{{ t("generic.colorGroup") }}</CFormLabel>
                                            <BaseMultiSelect
                                                ref="colorMultiselect"
                                                :options="formattedColors"
                                                @change="selectColor"
                                            ></BaseMultiSelect>
                                            <span class="cursor-pointer px-0 py-2 text-info" @click="importPreorderColor">{{ t("generic.importPreorderColor") }}</span>
                                            <span class="cursor-pointer px-0 py-2 text-danger ms-3" @click="clearAllColors">{{ t("generic.clearAll") }}</span>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-4">
                                        <CCol :xs="8">
                                            <CFormLabel>{{ t("generic.sizes") }}</CFormLabel>
                                            <BaseMultiSelect
                                                ref="sizeMultiselect"
                                                :options="formattedSizes"
                                                @change="selectSize"
                                            />
                                            <span class="cursor-pointer px-0 py-2 text-info" @click="importPreorderSize">{{ t("generic.importPreorderSize") }}</span>
                                            <span class="cursor-pointer px-0 py-2 text-danger ms-3" @click="clearAllSizes">{{ t("generic.clearAll") }}</span>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-4">
                                        <CCol :xs="4">
                                            <CFormSwitch :label="t('generic.visibleOnOrder')" @change="toggleVisibleOnOrder" :checked="newProduct.isVisibleOnOrder"></CFormSwitch>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-4">
                                        <CCol :xs="8">
                                            <CFormLabel>{{ t("generic.tags") }}</CFormLabel>
                                            <TagMultiSelect
                                                :options="formattedTags"
                                                @change="onTagChange"
                                            ></TagMultiSelect>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-4">
                                        <CCol :xs="3">
                                            <CFormLabel>{{ t("generic.categories") }}</CFormLabel>
                                            <CFormSelect @change="selectProductCategory">
                                                <option v-for="category in categories" :key="category.id" :value="category.id" :label="category.isDefault ? t(category.name) : category.name"></option>
                                            </CFormSelect>
                                        </CCol>
                                    </CRow>
                                    <CRow class="pt-4 pb-3">
                                        <CCol :xs="8">
                                            <CFormLabel>{{ t("generic.notes") }}</CFormLabel>
                                            <CFormTextarea :placeholder="t('generic.description') + '...'" v-model="newProduct.note"></CFormTextarea>
                                        </CCol>
                                    </CRow>
                                </CContainer>
                            </CTabPane>

                            <!-- Tissues and Accessories row -->
                            <CTabPane role="tabpanel" aria-labelledby="tissues-accessories-tab" :visible="activeModalTab === 1">
                                <CRow class="m-4">
                                    <CTable class="rounded-2 border-top border-bottom border-start border-end" responsive>
                                        <CTableCaption class="bg-light border border-top-0">
                                            <CRow>
                                                <CCol class="ps-4 t-col-6" :xs="3">
                                                    <CButton color="primary" @click="openTissuePicker">{{ t("ingredient.tissue.addNew") }}</CButton>
                                                </CCol>
                                                <CCol :xs="3">
                                                    <CFormLabel class="pt-2 text-info cursor-pointer" @click="openTissueAddModal()">{{ t("ingredient.tissue.new") }}...</CFormLabel>
                                                </CCol>
                                                <CCol :xs="3">
                                                    <CFormLabel class="pt-2 text-info cursor-pointer" @click="openPrintPatternAddModal()">{{ t("ingredient.printPattern.new") }}...</CFormLabel>
                                                </CCol>
                                                <CCol :xs="3"></CCol>
                                            </CRow>
                                        </CTableCaption>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell class="py-3 t-col-7 ps-3">{{ t("generic.tissues") }} *</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-3">{{ t("model.waste") }} (m)</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-3">{{ t("model.hemming") }} (cm)</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-4">{{ t("generic.notesForCut") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-6">{{ t("generic.printPattern") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-1"></CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-7">{{ t("generic.color") }}</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            <CTableRow v-if="selectedTissues.length === 0">
                                                <CTableDataCell class="py-5 no-data-text" colspan="8">{{ t("generic.noData") }}</CTableDataCell>
                                            </CTableRow>
                                            <CTableRow v-for="(tissue, index) in selectedTissues" :key="index">
                                                <CTableDataCell class="py-3">
                                                    <CRow>
                                                        <CCol :xs="2" class="pt-2">
                                                            <CTooltip placement="top">
                                                                <template #content>
                                                                    <span>TS{{ index + 1 }}: {{ index === 0 ? t("model.primaryTissue") : t("model.secondaryTissue") }}</span>
                                                                </template>
                                                                <template #toggler="{ on, }">
                                                                    <CRow>
                                                                        <h4>
                                                                            <CBadge color="info" v-on="on">
                                                                                {{ index + 1 }}
                                                                            </CBadge>
                                                                        </h4>
                                                                    </CRow>
                                                                </template>
                                                            </CTooltip>
                                                            <CIcon class="cursor-pointer" name="cil-x" style="color: black;" @click="deleteTissue(index)" size="xl"></CIcon>
                                                        </CCol>
                                                        <CCol class="bg-light rounded rounded-3" :xs="9">
                                                            <CRow class="py-2">
                                                                <CCol class="ps-3 pe-0">
                                                                    <CImage
                                                                        class="t-col-photo cursor-pointer"
                                                                        v-if="tissue.images?.length > 0" :src="tissue.images[0].tiny"
                                                                        @click="openTissueLightbox(tissue.images)"
                                                                    ></CImage>
                                                                    <div v-else class="t-col-photo">
                                                                        <CIcon size="4xl" name="cil-image"></CIcon>
                                                                    </div>
                                                                </CCol>
                                                                <CCol class="d-flex flex-column justify-content-around">
                                                                    <CRow class="fs-4">
                                                                        {{ tissue.name }}({{ getTissueCategoryName(tissue.category) }})
                                                                    </CRow>
                                                                    <CRow class="fs-5">
                                                                        {{ getSupplierName(tissue.supplier) }}
                                                                    </CRow>
                                                                </CCol>
                                                                <CCol :xs="2">
                                                                    <CDropdown>
                                                                        <CDropdownToggle color="light" class="shadow-none" split/>
                                                                        <CDropdownMenu>
                                                                            <CDropdownItem class="text-info cursor-pointer" @click="openTissueUpdateModal(tissue)">{{ t("ingredient.tissue.update") }}</CDropdownItem>
                                                                            <CDropdownItem class="text-info cursor-pointer" @click="changeTissue(index)">{{ t("ingredient.tissue.change") }}</CDropdownItem>
                                                                            <CDropdownDivider/>
                                                                            <CDropdownItem class="text-danger cursor-pointer" @click="deleteTissue(index)">{{ t("ingredient.tissue.delete") }}</CDropdownItem>
                                                                        </CDropdownMenu>
                                                                    </CDropdown>
                                                                </CCol>
                                                            </CRow>
                                                        </CCol>
                                                    </CRow>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3">
                                                    <BaseInput placeholder="0,000" class="text-end mt-4" v-model:model-value="tissue.consumption"></BaseInput>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3">
                                                    <BaseInput placeholder="0,000" class="text-end mt-4" v-model:model-value="tissue.hemming"></BaseInput>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3">
                                                    <CFormCheck :label="t('model.hotGlueRequest')" v-model="tissue.hotGlue"></CFormCheck>
                                                    <CFormTextarea :placeholder="t('model.noteForCutPlaceholder')" v-model:model-value="tissue.cropNotes"></CFormTextarea>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3">
                                                    <CRow v-if="getPrintPattern(tissue.printPatternId) === undefined">
                                                        <CCol>
                                                            <CButton class="p-2 w-100" style="min-height: 86px;" size="lg" color="primary" @click="openPrintPatternPicker(index)">{{ t("ingredient.printPattern.addNew") }}</CButton>
                                                        </CCol>
                                                    </CRow>
                                                    <CRow v-else class="ps-2" id="printPatternRow">
                                                        <CRow>
                                                            <CCol class="bg-light rounded rounded-3" :xs="12">
                                                                <CRow class="py-2">
                                                                    <CCol class="ps-3 pe-0">
                                                                        <CImage
                                                                            class="t-col-photo cursor-pointer"
                                                                            v-if="getPrintPattern(tissue.printPatternId).images?.length > 0" :src="getPrintPattern(tissue.printPatternId).images[0].tiny"
                                                                            @click="openTissueLightbox(getPrintPattern(tissue.printPatternId).images)"
                                                                        ></CImage>
                                                                        <div v-else class="t-col-photo">
                                                                            <CIcon size="4xl" name="cil-image"></CIcon>
                                                                        </div>
                                                                    </CCol>
                                                                    <CCol>
                                                                        <CRow>
                                                                            {{ getPrintPattern(tissue.printPatternId).name }}
                                                                        </CRow>
                                                                        <CRow>
                                                                            {{ getSupplierName(getPrintPattern(tissue.printPatternId).supplier) }}
                                                                        </CRow>
                                                                    </CCol>
                                                                    <CCol :xs="2">
                                                                        <CDropdown>
                                                                            <CDropdownToggle color="light" class="shadow-none" split/>
                                                                            <CDropdownMenu>
                                                                                <CDropdownItem class="text-info cursor-pointer" @click="openPrintPatternUpdateModal(tissue.printPatternId)">{{ t("ingredient.printPattern.update") }}</CDropdownItem>
                                                                                <CDropdownItem class="text-info cursor-pointer" @click="openPrintPatternPicker(index)">{{ t("ingredient.printPattern.change") }}</CDropdownItem>
                                                                                <CDropdownDivider/>
                                                                                <CDropdownItem class="text-danger cursor-pointer" @click="deletePrintPattern(index)">{{ t("ingredient.printPattern.delete") }}</CDropdownItem>
                                                                            </CDropdownMenu>
                                                                        </CDropdown>
                                                                    </CCol>
                                                                </CRow>
                                                            </CCol>
                                                        </CRow>
                                                    </CRow>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3">
                                                    <CRow>
                                                        <CCol>
                                                            <CButton class="mt-1" shape="rounded-0" variant="ghost" @click="swapTop(index)">
                                                                <CIcon name="cil-arrow-top" size="sm" class="cursor-pointer" ></CIcon>
                                                            </CButton>
                                                            <CButton class="mt-2" shape="rounded-0" variant="ghost" @click="swapBottom(index)">
                                                                <CIcon name="cil-arrow-bottom" size="sm" class="cursor-pointer"></CIcon>
                                                            </CButton>
                                                        </CCol>
                                                    </CRow>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3">
                                                    <CRow class="ps-2">
                                                        <CRow>
                                                            <CCol class="d-inline-flex flex-nowrap">
                                                                <CFormLabel class="ps-0 py-2 mb-0 pe-3 text-nowrap">{{ t("ingredient.tissue.color") }}</CFormLabel>
                                                                <CFormLabel class="py-2 text-nowrap" v-if="getTissueCategoryName(tissue.category) === t('color.whiteEmbryo')">{{ t("ingredient.tissue.noColorForWhiteEmbryo") }}</CFormLabel>
                                                                <template v-else>
                                                                    <CFormSelect
                                                                        class="t-col-4" size="sm"
                                                                        @change="selectTissueColor($event, index)"
                                                                        :model-value="tissueColorSelectValue[index]"
                                                                    >
                                                                        <option value="preorder">{{ t("model.followOrderColor") }}</option>
                                                                        <option v-for="color in colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))" :key="color.id" :value="color.id">{{ color.isDefault ? t(color.name) : color.name }}</option>
                                                                    </CFormSelect>
                                                                    <CTooltip>
                                                                        <template #toggler="{ on, }">
                                                                            <CButton class="text-nowrap" v-on="on" @click="openTissueSpecialColorRuleAddModal(index)">{{ getTissueColorRuleButtonText(tissue) }}</CButton>
                                                                        </template>
                                                                        <template #content>
                                                                            <CRow>
                                                                                <CCol class="text-nowrap">{{ t("generic.preorderColor") }}</CCol>
                                                                                <CCol>
                                                                                    <CIcon icon="cilArrowRight"></CIcon>
                                                                                </CCol>
                                                                                <CCol class="text-nowrap">{{ t("generic.specialColor") }}</CCol>
                                                                            </CRow>
                                                                            <CRow class="my-2">
                                                                                <CCol>
                                                                                    <div class="border border-top"></div>
                                                                                </CCol>
                                                                            </CRow>
                                                                            <CRow v-for="rule in tissue.tissueColorRules" :key="rule.key">
                                                                                <CCol class="text-nowrap">{{ getElementName(getColorById(rule.orderColorId)) }}</CCol>
                                                                                <CCol>
                                                                                    <CIcon icon="cilArrowRight"></CIcon>
                                                                                </CCol>
                                                                                <CCol class="text-nowrap">{{ getElementName(getColorById(rule.useColorId)) }}</CCol>
                                                                            </CRow>
                                                                            <CRow  v-if="!tissue.tissueColorRules || tissue.tissueColorRules.length === 0">
                                                                                <CCol class="text-nowrap">{{ t("generic.noColorRules") }}</CCol>
                                                                            </CRow>
                                                                        </template>
                                                                    </CTooltip>
                                                                    <special-color-rule-add-modal
                                                                        v-model:is-visible="isTissueSpecialColorRuleAddModalVisible[index]"
                                                                        :colors="colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))"
                                                                        :color-rules="tissue.tissueColorRules"
                                                                        @confirm="updateTissueColorRules($event, tissue, index)"
                                                                    ></special-color-rule-add-modal>
                                                                </template>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow v-if="tissue.printPatternId !== ''" class="pt-2">
                                                            <CCol class="d-inline-flex flex-nowrap">
                                                                <CFormLabel class="ps-0 py-2 mb-0 pe-3 text-nowrap">{{ t("ingredient.printPattern.color") }}</CFormLabel>
                                                                <CFormSelect
                                                                    class="t-col-4"
                                                                    size="sm"
                                                                    @change="selectPrintPatternColor($event, index)"
                                                                    :model-value="printPatternColorSelectValue[index]"
                                                                >
                                                                    <option value="preorder" selected>{{ t("model.followOrderColor") }}</option>
                                                                    <option value="none">{{ t("model.noColor") }}</option>
                                                                    <option v-for="color in colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))" :key="color.id" :value="color.id">{{ color.isDefault ? t(color.name) : color.name }}</option>
                                                                </CFormSelect>
                                                                <CTooltip>
                                                                    <template #toggler="{ on, }">
                                                                        <CButton class="text-nowrap" v-on="on" @click="openPrintPatternSpecialColorRuleAddModal(index)">{{ getPrintPatternColorRuleButtonText(tissue) }}</CButton>
                                                                    </template>
                                                                    <template #content>
                                                                        <CRow>
                                                                            <CCol class="text-nowrap">{{ t("generic.preorderColor") }}</CCol>
                                                                            <CCol>
                                                                                <CIcon icon="cilArrowRight"></CIcon>
                                                                            </CCol>
                                                                            <CCol class="text-nowrap">{{ t("generic.specialColor") }}</CCol>
                                                                        </CRow>
                                                                        <CRow class="my-2">
                                                                            <CCol>
                                                                                <div class="border border-top"></div>
                                                                            </CCol>
                                                                        </CRow>
                                                                        <CRow v-for="rule in tissue.printPatternColorRules" :key="rule.key">
                                                                            <CCol class="text-nowrap">{{ getElementName(getColorById(rule.orderColorId)) }}</CCol>
                                                                            <CCol>
                                                                                <CIcon icon="cilArrowRight"></CIcon>
                                                                            </CCol>
                                                                            <CCol class="text-nowrap">{{ getElementName(getColorById(rule.useColorId)) }}</CCol>
                                                                        </CRow>
                                                                        <CRow  v-if="!tissue.printPatternColorRules || tissue.printPatternColorRules.length === 0">
                                                                            <CCol class="text-nowrap">{{ t("generic.noColorRules") }}</CCol>
                                                                        </CRow>
                                                                    </template>
                                                                </CTooltip>
                                                                <special-color-rule-add-modal
                                                                    v-model:is-visible="isPrintPatternSpecialColorRuleAddModalVisible[index]"
                                                                    :colors="colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))"
                                                                    :color-rules="tissue.printPatternColorRules"
                                                                    @confirm="updatePrintPatternColorRules($event, tissue, index)"
                                                                ></special-color-rule-add-modal>
                                                            </CCol>
                                                        </CRow>
                                                    </CRow>
                                                </CTableDataCell>
                                            </CTableRow>
                                        </CTableBody>
                                    </CTable>
                                </CRow>


                                <CRow class="m-4">
                                    <CTable class="rounded-2 border-top border-bottom border-start border-end" responsive>
                                        <CTableCaption class="bg-light border border-top-0">
                                            <CRow>
                                                <CCol class="ps-4 t-col-6" :xs="3">
                                                    <CButton color="primary" @click="openAccessoryPicker">{{ t("ingredient.accessory.addNew") }}</CButton>
                                                </CCol>
                                                <CCol :xs="3">
                                                    <CFormLabel class="pt-2 text-info cursor-pointer" @click="openAccessoryAddModal()">{{ t("ingredient.accessory.new") }}...</CFormLabel>
                                                </CCol>
                                                <CCol :xs="3"></CCol>
                                                <CCol :xs="3"></CCol>
                                            </CRow>
                                        </CTableCaption>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell class="py-3 t-col-7 ps-3">{{ t("generic.accessories") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-3">{{ t("model.waste") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-7">{{ t("model.separateFromStorage") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-5">{{ t("generic.color") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="col-auto"></CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            <CTableRow v-if="selectedAccessories.length === 0">
                                                <CTableDataCell class="py-5 no-data-text" colspan="4">{{ t("generic.noData") }}</CTableDataCell>
                                            </CTableRow>
                                            <CTableRow v-for="(accessory, index) in selectedAccessories" :key="index">
                                                <CTableDataCell class="py-3">
                                                    <CRow>
                                                        <CCol :xs="2" class="pt-4">
                                                            <CIcon class="cursor-pointer" name="cil-x" style="color: black;" @click="deleteAccessory(index)" size="xl"></CIcon>
                                                        </CCol>
                                                        <CCol class="bg-light rounded rounded-3" :xs="9">
                                                            <CRow class="py-2">
                                                                <CCol class="ps-3 pe-0">
                                                                    <CImage
                                                                        class="t-col-photo cursor-pointer"
                                                                        v-if="accessory.images?.length > 0"
                                                                        :src="accessory.images[0].tiny"
                                                                        @click="openTissueLightbox(accessory.images)"
                                                                    ></CImage>
                                                                    <div v-else class="t-col-photo">
                                                                        <CIcon size="4xl" name="cil-image"></CIcon>
                                                                    </div>
                                                                </CCol>
                                                                <CCol class="d-flex flex-column justify-content-around">
                                                                    <CRow class="fs-4">
                                                                        {{ accessory.name }}
                                                                    </CRow>
                                                                    <CRow class="fs-5">
                                                                        {{ t("generic.unitOfMeasurement") }}: {{ accessory.unitOfMeasurement }}
                                                                    </CRow>
                                                                    <CRow class="fs-5">
                                                                        {{ getSupplierName(accessory.supplier) }}
                                                                    </CRow>
                                                                </CCol>
                                                                <CCol :xs="2">
                                                                    <CDropdown>
                                                                        <CDropdownToggle color="light" class="shadow-none" split/>
                                                                        <CDropdownMenu>
                                                                            <CDropdownItem class="text-info cursor-pointer" @click="openAccessoryUpdateModal(accessory)">{{ t("ingredient.accessory.update") }}</CDropdownItem>
                                                                            <CDropdownDivider/>
                                                                            <CDropdownItem class="text-danger cursor-pointer" @click="deleteAccessory(index)">{{ t("ingredient.accessory.delete") }}</CDropdownItem>
                                                                        </CDropdownMenu>
                                                                    </CDropdown>
                                                                </CCol>
                                                            </CRow>
                                                        </CCol>
                                                    </CRow>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3 align-middle">
                                                    <BaseInput class="text-end" placeholder="0,000" v-model="accessory.consumption"></BaseInput>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3 align-middle">
                                                    <CFormSwitch size="xl" @change="toggleDifferentiateStorage($event, index)" :checked="accessory.differentiateStorage"></CFormSwitch>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-3 align-middle">
                                                    <CFormSelect
                                                        class="t-col-4"
                                                        size="sm"
                                                        @change="selectAccessoryColor($event, index)"
                                                        :model-value="accessory.colorId ? accessory.colorId.toString() : (accessory.noColor ? 'none' : 'preorder')"
                                                    >
                                                        <option value="none">{{ t("model.noColor") }}</option>
                                                        <option value="preorder">{{ t("model.followOrderColor") }}</option>
                                                        <option v-for="color in colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))" :key="color.id" :value="color.id">{{ color.isDefault ? t(color.name) : color.name }}</option>
                                                    </CFormSelect>
                                                </CTableDataCell>
                                            </CTableRow>
                                        </CTableBody>
                                    </CTable>
                                </CRow>
                            </CTabPane>

                            <!-- Procedures row -->
                            <CTabPane role="tabpanel" aria-labelledby="procdures-tab" :visible="activeModalTab === 2">
                                <CRow class="m-4">
                                    <CTable caption="top" responsive class="p-0 rounded-2 border">
                                        <CTableCaption class="bg-light border border-top-0">
                                            <CRow>
                                                <CCol :xs="3" class="py-2 ps-4">
                                                    <CButton color="primary" @click="openProcedurePicker">{{ t("procedure.addNew") }}</CButton>
                                                </CCol>
                                                <CCol :xs="3" class="py-2">
                                                    <CFormLabel class="pt-2 text-info cursor-pointer" @click="openProcedureAddModal">{{ t("procedure.new") }}...</CFormLabel>
                                                </CCol>
                                            </CRow>
                                        </CTableCaption>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell class="py-3 t-col-1"></CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-5">{{ t("generic.procedure") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-6">{{ t("generic.notes") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-4">{{ t("generic.weight") }}/{{ t("generic.quantity") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-5">{{ t("generic.color") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-5">{{ t("model.separateFromStorage") }}</CTableHeaderCell>
                                                <CTableHeaderCell class="py-3 t-col-auto">
                                                    <span></span>
                                                </CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            <CTableRow v-for="(procedure, index) in selectedProcedures" :key="index">
                                                <CTableDataCell class="py-2 align-middle">
                                                    <!-- v-if="!procedure.isDefault" -->
                                                    <CIcon class="cursor-pointer" name="cil-x" style="color: black;" @click="deleteProcedure(procedure.id)" size="xl"></CIcon>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-2 align-middle">
                                                    {{ procedure.isDefault ? t(procedure.name) : procedure.name }}
                                                </CTableDataCell>
                                                <CTableDataCell class="py-2">
                                                    <CFormTextarea class="textarea-sm" v-if="index !== 0" v-model="procedure.note"></CFormTextarea>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-2 align-middle">
                                                    <template v-if="procedure.isFixedPrice">
                                                        <BaseInput class="text-end input-sm" :placeholder="t('generic.useProductWeight')" v-model:model-value="procedure.customWeight" v-if="showProcedureCustomWeightInput(index)"></BaseInput>
                                                        <BaseInput class="text-end input-sm" placeholder="0,000" v-model:model-value="procedure.quantity" v-if="showProcedureQuantityInput(index)"></BaseInput>
                                                    </template>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-2 align-middle">
                                                    <div class="d-flex">
                                                        <CFormSelect v-model:model-value="dyeingProcedureColorSelectValue[index]" v-if="showProcedureColorPicker(index)" @change="selectProcedureColor($event, index)">
                                                            <option value="preorder">{{ t("model.followOrderColor") }}</option>
                                                            <option v-for="color in colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))" :key="color.id" :value="color.id">{{ color.isDefault ? t(color.name) : color.name }}</option>
                                                        </CFormSelect>
                                                        <CTooltip v-if="showProcedureColorPicker(index)">
                                                            <template #toggler="{ on, }">
                                                                <CButton v-on="on" @click="openProcedureSpecialColorRuleAddModal(index)">{{ getProcedureColorRuleButtonText(procedure) }}</CButton>
                                                            </template>
                                                            <template #content>
                                                                <CRow>
                                                                    <CCol>{{ t("generic.preorderColor") }}</CCol>
                                                                    <CCol>
                                                                        <CIcon icon="cilArrowRight"></CIcon>
                                                                    </CCol>
                                                                    <CCol>{{ t("generic.specialColor") }}</CCol>
                                                                </CRow>
                                                                <CRow class="my-2">
                                                                    <CCol>
                                                                        <div class="border border-top"></div>
                                                                    </CCol>
                                                                </CRow>
                                                                <CRow v-for="rule in procedure.dyeingColorRules" :key="rule.key">
                                                                    <CCol>{{ getElementName(getColorById(rule.orderColorId)) }}</CCol>
                                                                    <CCol>
                                                                        <CIcon icon="cilArrowRight"></CIcon>
                                                                    </CCol>
                                                                    <CCol>{{ getElementName(getColorById(rule.useColorId)) }}</CCol>
                                                                </CRow>
                                                                <CRow  v-if="!procedure.dyeingColorRules || procedure.dyeingColorRules.length === 0">
                                                                    <CCol>{{ t("generic.noColorRules") }}</CCol>
                                                                </CRow>
                                                            </template>
                                                        </CTooltip>
                                                        <special-color-rule-add-modal
                                                            v-model:is-visible="isProcedureSpecialColorRuleAddModalVisible[index]"
                                                            :colors="colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))"
                                                            :color-rules="procedure.dyeingColorRules"
                                                            @confirm="updateProcedureColorRules($event, procedure)"
                                                        ></special-color-rule-add-modal>
                                                    </div>
                                                </CTableDataCell>
                                                <CTableDataCell class="py-2 align-middle">
                                                    <CRow>
                                                        <CCol class="ps-5">
                                                            <CFormSwitch v-if="showProcedureSeparateFromStorage(index)" @change="toggleDifferentiateStorageProcedure($event, index)" :checked="procedure.differentiateStorage"></CFormSwitch>
                                                        </CCol>
                                                    </CRow>
                                                </CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                            </CTableRow>
                                        </CTableBody>
                                    </CTable>
                                </CRow>
                            </CTabPane>

                            <!-- Color manager row -->
                            <CTabPane role="tabpanel" aria-labelledby="color-manager-tab" :visible="activeModalTab === 3">
                                <CRow class="pt-4 ps-4 pe-4">
                                    <CCol :xs="6">
                                        <CFormLabel>{{ t("generic.color") }}</CFormLabel>
                                        <BaseMultiSelect
                                            :options="formattedColors"
                                            @change="selectColor"
                                        ></BaseMultiSelect>
                                        <CNavLink class="cursor-pointer px-0 py-2" @click="importPreorderColor">{{ t("generic.importPreorderColor") }}</CNavLink>
                                    </CCol>
                                </CRow>
                                <CRow v-if="selectedTissues.length > 0">
                                    <CCol>
                                        <CNav variant="underline" role="tablist">
                                            <CNavItem>
                                                <CNavLink
                                                    href="javascript:void(0);"
                                                    :active="colorManagerTab===0"
                                                    @click="colorManagerTab=0"
                                                >
                                                    {{ t("generic.selectedColors") }}({{ enableColors.length }})
                                                </CNavLink>
                                            </CNavItem>
                                            <CNavItem>
                                                <CNavLink
                                                    href="javascript:void(0);"
                                                    :active="colorManagerTab===1"
                                                    @click="colorManagerTab=1"
                                                >
                                                    {{ t("generic.removedColors") }}({{ disableColors.length }})
                                                </CNavLink>
                                            </CNavItem>
                                        </CNav>
                                        <CTabContent>
                                            <CRow class="mt-4">
                                                <CCol :xs="6">
                                                    <CTabPane role="tabpanel" aria-labelledby="selected-color-tab" :visible="colorManagerTab === 0">
                                                        <CFormLabel class="ms-3 mb-3 text-danger cursor-pointer" @click="removeSelectedColors">{{ t("generic.removeSelectedColors") }}</CFormLabel>
                                                        <CTable class="rounded-2 border-end border-top">
                                                            <CTableHead class="align-middle">
                                                                <CTableRow>
                                                                    <CTableHeaderCell class="ps-3 py-4 t-col-1">
                                                                        <CFormCheck aria-label="..." v-if="enableColors.length > 0" @change="selectAllColors($event, enableColors)" :model-value="isSelectedAllEnableColors"/>
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell class="py-4 t-col-3">{{ t("generic.name") }}</CTableHeaderCell>
                                                                    <CTableHeaderCell
                                                                        class="py-4 t-col-5 cursor-pointer"
                                                                        style="white-space:pre;"
                                                                        v-for="(descriptor, index) in colorManagementTableHeaders"
                                                                        :key="descriptor.selectedTissue.id"
                                                                        @click="openSpecialColorRuleModal(descriptor, index)"
                                                                    >
                                                                        <span class="w-100 fs-4 fw-lighter">TS{{ index + 1 }}: {{ descriptor.tissueName }}</span><br>
                                                                        <div v-if="descriptor.printPatternName">
                                                                            <span class="w-100 fs-4 fw-lighter">{{ t("generic.printPatternAbbrv") }}: {{ descriptor.printPatternName }}</span><br>
                                                                        </div>
                                                                        <span class="w-100 fs-5 fw-light">({{ t("generic.tissue") }}: {{ descriptor.tissueColorRule }})</span><br>
                                                                        <span class="w-100 fs-5 fw-light" v-if="descriptor.printPatternColorRule">({{ t("generic.printPattern") }}: {{ descriptor.printPatternColorRule }})</span>
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell
                                                                        class="py-4 t-col-5 cursor-pointer"
                                                                        style="white-space:pre;"
                                                                        v-for="(descriptor, index) in procedureColorManagementTableHeaders"
                                                                        :key="descriptor.procedure.id"
                                                                        @click="openSpecialColorRuleModal(descriptor, index)"
                                                                    >
                                                                        <span class="w-100 fs-4 fw-lighter">{{ descriptor.name }}</span><br>
                                                                        <span class="w-100 fs-5 fw-light">({{ descriptor.colorRule }})</span><br>
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell class="py-4 t-col-3">{{ t("generic.operation") }}</CTableHeaderCell>
                                                                </CTableRow>
                                                            </CTableHead>
                                                            <CTableBody>
                                                                <CTableRow v-for="(color, index) in enableColors" :key="index">
                                                                    <CTableDataCell class="ps-3 py-3">
                                                                        <CFormCheck v-model:model-value="color.toSwap"></CFormCheck>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell class="py-3">
                                                                        {{ color.text }}
                                                                    </CTableDataCell>
                                                                    <CTableDataCell
                                                                        class="py-3"
                                                                        v-for="descriptor in colorManagementTableHeaders"
                                                                        :key="descriptor.selectedTissue.id"
                                                                    >
                                                                        <span class="fs-4" v-if="!descriptor.printPatternName">{{ getTissueColorValue(descriptor.selectedTissue, color.text) }}</span>
                                                                        <div v-else>
                                                                            <span class="fs-4">{{ t("generic.tissueAbbrv") }}: {{ getTissueColorValue(descriptor.selectedTissue, color.text) }}</span><br>
                                                                            <span class="fs-4">{{ t("generic.printPatternAbbrv") }}: {{ getPrintPatternColorValue(descriptor.selectedTissue, color.text) }}</span>
                                                                        </div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell
                                                                        class="py-3"
                                                                        v-for="descriptor in procedureColorManagementTableHeaders"
                                                                        :key="descriptor.procedure.id"
                                                                    >
                                                                        <span class="fs-4">{{ getProcedureColorValue(descriptor.procedure, color.text) }}</span>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell class="py-3 text-danger cursor-pointer" @click="removeColor(color)">
                                                                        {{ t("generic.remove") }}
                                                                    </CTableDataCell>
                                                                </CTableRow>
                                                            </CTableBody>
                                                        </CTable>
                                                    </CTabPane>

                                                    <CTabPane role="tabpanel" aria-labelledby="removed-color-tab" :visible="colorManagerTab === 1">
                                                        <CFormLabel class="ms-3 mb-3 text-success cursor-pointer" @click="restoreSelectedColors">{{ t("generic.restoreSelectedColors") }}</CFormLabel>
                                                        <CTable class="rounded-2 border-end border-top">
                                                            <CTableHead>
                                                                <CTableRow class="align-middle">
                                                                    <CTableHeaderCell class="ps-3 py-4 t-col-1">
                                                                        <CFormCheck aria-label="..." v-if="disableColors.length > 0" @change="selectAllColors($event, disableColors)" :model-value="isSelectedAllDisableColors"/>
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell class="py-4 t-col-3">{{ t("generic.name") }}</CTableHeaderCell>
                                                                    <CTableHeaderCell
                                                                        class="py-4 t-col-5"
                                                                        style="white-space:pre;"
                                                                        v-for="(descriptor, index) in colorManagementTableHeaders"
                                                                        :key="descriptor.selectedTissue.id"
                                                                    >
                                                                        <span class="w-100 fs-4 fw-lighter">TS{{ index + 1 }}: {{ descriptor.tissueName }}</span><br>
                                                                        <div v-if="descriptor.printPatternName">
                                                                            <span class="w-100 fs-4 fw-lighter">{{ t("generic.printPatternAbbrv") }}: {{ descriptor.printPatternName }}</span><br>
                                                                        </div>
                                                                        <span class="w-100 fs-5 fw-light">({{ t("generic.tissue") }}: {{ descriptor.tissueColorRule }})</span><br>
                                                                        <span class="w-100 fs-5 fw-light" v-if="descriptor.printPatternColorRule">({{ t("generic.printPattern") }}: {{ descriptor.printPatternColorRule }})</span>
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell
                                                                        class="py-4 t-col-5"
                                                                        style="white-space:pre;"
                                                                        v-for="descriptor in procedureColorManagementTableHeaders"
                                                                        :key="descriptor.procedure.id"
                                                                    >
                                                                        <span class="w-100 fs-4 fw-lighter">{{ descriptor.name }}</span><br>
                                                                        <span class="w-100 fs-5 fw-light">({{ descriptor.colorRule }})</span><br>
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell class="py-4 t-col-3">{{ t("generic.operation") }}</CTableHeaderCell>
                                                                </CTableRow>
                                                            </CTableHead>
                                                            <CTableBody>
                                                                <CTableRow v-for="(color, index) in disableColors" :key="index">
                                                                    <CTableDataCell class="ps-3 py-3">
                                                                        <CFormCheck v-model:model-value="color.toSwap"></CFormCheck>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell class="py-3">
                                                                        {{ color.text }}
                                                                    </CTableDataCell>
                                                                    <CTableDataCell
                                                                        class="py-3"
                                                                        v-for="descriptor in colorManagementTableHeaders"
                                                                        :key="descriptor.selectedTissue.id"
                                                                    >
                                                                        <span class="fs-4" v-if="!descriptor.printPatternName">{{ getTissueColorValue(descriptor.selectedTissue, color.text) }}</span>
                                                                        <div v-else>
                                                                            <span class="fs-4">{{ t("generic.tissueAbbrv") }}: {{ getTissueColorValue(descriptor.selectedTissue, color.text) }}</span><br>
                                                                            <span class="fs-4">{{ t("generic.printPatternAbbrv") }}: {{ getPrintPatternColorValue(descriptor.selectedTissue, color.text) }}</span>
                                                                        </div>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell
                                                                        class="py-3"
                                                                        v-for="descriptor in procedureColorManagementTableHeaders"
                                                                        :key="descriptor.procedure.id"
                                                                    >
                                                                        <span class="fs-4">{{ getProcedureColorValue(descriptor.procedure, color.text) }}</span>
                                                                    </CTableDataCell>
                                                                    <CTableDataCell class="py-3 text-success cursor-pointer" @click="restoreColor(color)">
                                                                        {{ t("generic.restore") }}
                                                                    </CTableDataCell>
                                                                </CTableRow>
                                                            </CTableBody>
                                                        </CTable>
                                                    </CTabPane>
                                                </CCol>
                                            </CRow>
                                        </CTabContent>
                                    </CCol>
                                </CRow>
                            </CTabPane>

                            <!-- Cost manager row -->
                            <CTabPane role="tabpanel" aria-labelledby="cost-manager-tab" :visible="activeModalTab === 4">
                                <CRow>
                                    <CCol class="ms-4 me-3 my-4">
                                        <CTable responsive class="rounded-2 border-end border-start border-top">
                                            <CTableHead>
                                                <CTableRow>
                                                    <CTableHeaderCell class="py-3 col-auto ps-3">{{ t("generic.name") }}</CTableHeaderCell>
                                                    <CTableHeaderCell class="py-3 t-col-3 text-end">{{ t("generic.quantity") }}</CTableHeaderCell>
                                                    <CTableHeaderCell class="py-3 t-col-3 text-end">{{ t("generic.price") }}</CTableHeaderCell>
                                                    <CTableHeaderCell class="py-3 t-col-3 text-end">{{ t("generic.subtotal") }}</CTableHeaderCell>
                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody>
                                                <CTableRow v-if="selectedTissues.length > 0" class="align-middle" color="primary">
                                                    <CTableDataCell class="py-1 ps-3 fs-5 fw-light">{{ t("generic.tissues") }}</CTableDataCell>
                                                    <CTableDataCell class="py-1 ps-3 fs-5 fw-light text-end">{{ t("unitOfMeasurement.meter") }}</CTableDataCell>
                                                    <CTableDataCell class="py-1 ps-3 fs-5 fw-light text-end">€ EUR</CTableDataCell>
                                                    <CTableDataCell class="py-1 ps-3 fs-5 fw-light text-end">€ EUR</CTableDataCell>
                                                </CTableRow>
                                                <template v-for="(tissueDescriptor, index) in selectedTissues" :key="index">
                                                    <CTableRow class="align-middle">
                                                        <CTableDataCell class="py-2 ps-3 fw-semibold">
                                                            TS {{ index + 1 }}: {{ tissueDescriptor.name }} {{ tissueDescriptor.printPatternId ? getPrintPattern(tissueDescriptor.printPatternId).name : `(${getTissueCategoryName(tissueDescriptor.category)}) (${getSupplierName(tissueDescriptor.supplier)})`}}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ doubleOutputNormalization(tissueDescriptor.consumption) }}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ tissueDescriptor.printPatternId ? " " : tissueDescriptor.price }}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end fw-semibold">
                                                            {{ getItemSubtotal(tissueDescriptor.consumption, tissueDescriptor.printPatternId ? addDoubles(tissueDescriptor.price, getPrintPattern(tissueDescriptor.printPatternId).price) : tissueDescriptor.price) }}
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                    <CTableRow v-if="tissueDescriptor.printPatternId" class="align-middle">
                                                        <CTableDataCell class="py-2 ps-3">
                                                            {{ tissueDescriptor.name }} ({{ getTissueCategoryName(tissueDescriptor.category) }}) ({{ getSupplierName(tissueDescriptor.supplier) }})
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ doubleOutputNormalization(tissueDescriptor.consumption) }}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ tissueDescriptor.price }}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ getItemSubtotal(tissueDescriptor.consumption, tissueDescriptor.price) }}
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                    <CTableRow v-if="selectedTissues[index].printPatternId" class="align-middle">
                                                        <CTableDataCell class="py-2 ps-3">
                                                            {{ getPrintPattern(tissueDescriptor.printPatternId).name }} ({{ getSupplierName(getPrintPattern(tissueDescriptor.printPatternId).supplier) }})
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ doubleOutputNormalization(tissueDescriptor.consumption) }}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ getPrintPattern(tissueDescriptor.printPatternId).price }}
                                                        </CTableDataCell>
                                                        <CTableDataCell class="py-2 text-end">
                                                            {{ getItemSubtotal(tissueDescriptor.consumption, getPrintPattern(tissueDescriptor.printPatternId).price) }}
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                </template>
                                                <CTableRow v-if="selectedAccessories.length > 0" class="align-middle">
                                                    <CTableDataCell color="primary" colspan="4" class="py-1 ps-3 fs-5 fw-light">{{ t("generic.accessories") }}</CTableDataCell>
                                                </CTableRow>
                                                <CTableRow v-for="accessory in selectedAccessories" :key="accessory.id" class="align-middle">
                                                    <CTableDataCell class="py-2 ps-3">
                                                        {{ accessory.name }} ({{ t("generic.unitOfMeasurement") }}: {{ accessory.unitOfMeasurement }})
                                                    </CTableDataCell>
                                                    <CTableDataCell class="py-2 text-end">
                                                        {{ doubleOutputNormalization(accessory.consumption) }}
                                                    </CTableDataCell>
                                                    <CTableDataCell class="py-2 text-end">
                                                        {{ accessory.price }}
                                                    </CTableDataCell>
                                                    <CTableDataCell class="py-2 text-end">
                                                        {{ getItemSubtotal(accessory.consumption, accessory.price) }}
                                                    </CTableDataCell>
                                                </CTableRow>
                                                <CTableRow v-if="selectedProcedures.length > 0" class="align-middle">
                                                    <CTableDataCell color="primary" colspan="4" class="py-1 ps-3 fs-5 fw-light">{{ t("generic.procedures") }}</CTableDataCell>
                                                </CTableRow>
                                                <CTableRow v-for="(procedureDescriptor, index) in selectedProcedures" :key="index" class="align-middle">
                                                    <CTableDataCell class="py-2 ps-3">
                                                        {{ procedureDescriptor.isDefault ? t(procedureDescriptor.name) : procedureDescriptor.name }}
                                                    </CTableDataCell>
                                                    <CTableDataCell class="py-2 text-end">
                                                        {{ getProductProcedureQuantity(procedureDescriptor) }}
                                                    </CTableDataCell>
                                                    <CTableDataCell class="py-2 text-end">
                                                        <BaseInput
                                                            class="text-end input-sm"
                                                            v-if="!procedureDescriptor.isFixedPrice"
                                                            v-model:model-value="procedureDescriptor.customPrice"
                                                            placeholder="0,000"
                                                        />
                                                        <span v-else>{{ procedureDescriptor.price }}</span>
                                                    </CTableDataCell>
                                                    <CTableDataCell class="py-2 text-end">
                                                        {{ getProductProcedureSubtotal(procedureDescriptor) }}
                                                    </CTableDataCell>
                                                </CTableRow>
                                            </CTableBody>
                                        </CTable>
                                        <CRow class="justify-content-end">
                                            <CCol :xs="4">
                                                <CTable responsive class="rounded-2 border-end border-start border-top">
                                                    <CTableHead>
                                                        <CTableRow>
                                                            <CTableHeaderCell class="py-3 t-col-3 ps-3">{{ t("generic.pricing") }}</CTableHeaderCell>
                                                            <CTableHeaderCell class="py-3 t-col-3">
                                                            </CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    <CTableBody>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.autoPriceToSell") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="py-2">
                                                                <CFormSwitch @change="toggleFixedPrice" v-model:checked="isFixedPriceInverted"></CFormSwitch>
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.otherCost") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="p-2">
                                                                <BaseInput class="text-end input-sm" v-model:model-value="newProduct.cost.otherCost" placeholder="0,000"/>
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.totalCost") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="p-2">
                                                                <BaseInput class="text-end input-sm" :model-value="totalCost" readonly placeholder="0,000"/>
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.scrapRate") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="p-2">
                                                                <BaseInput class="text-end input-sm" v-model:model-value="newProduct.cost.scrapRate" placeholder="0,000"/>
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.profitRate") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="p-2">
                                                                <BaseInput
                                                                    class="text-end input-sm"
                                                                    v-model:model-value="newProduct.cost.profitRate"
                                                                    v-if="!newProduct.cost.isFixedPrice"
                                                                    placeholder="0,000"
                                                                />
                                                                <BaseInput
                                                                    class="text-end input-sm"
                                                                    :model-value="profitRateCalculated"
                                                                    readonly
                                                                    v-else
                                                                />
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.profit") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="p-2">
                                                                <BaseInput class="text-end input-sm input-sm" :model-value="profit" readonly placeholder="0,000"/>
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow class="align-middle">
                                                            <CTableDataCell class="ps-3">
                                                                {{ t("generic.priceToSell") }}
                                                            </CTableDataCell>
                                                            <CTableDataCell class="p-2">
                                                                <BaseInput
                                                                    class="text-end input-sm"
                                                                    v-model:model-value="newProduct.cost.price"
                                                                    v-if="newProduct.cost.isFixedPrice"
                                                                    placeholder="0,000"
                                                                />
                                                                <BaseInput
                                                                    class="text-end input-sm"
                                                                    :model-value="priceToSellCalculated"
                                                                    readonly
                                                                    v-else
                                                                />
                                                            </CTableDataCell>
                                                        </CTableRow>
                                                    </CTableBody>
                                                </CTable>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CTabPane>
                        </CTabContent>
                    </div>
                </CRow>
            </CModalBody>
        </CForm>


        <!-- Modals -->
        <tissue-add-modal v-model:is-visible="isTissueAddModalVisible" @submit="addSelectedTissue"
            v-model:name="pickerInputValue" :suppliers-array="suppliers" :categories="tissueCategories"></tissue-add-modal>
        <tissue-update-modal ref="tissueUpdateModal" v-model:is-visible="isTissueUpdateModalVisible"
            @submit="submitUpdateTissue" :suppliers-array="suppliers" :categories="tissueCategories"></tissue-update-modal>

        <print-pattern-add-modal v-model:is-visible="isPrintPatternAddModalVisible" @submit="addSelectedPrintPattern"
            v-model:name="pickerInputValue" :suppliers-array="suppliers" :categories="printPatternCategories">
        </print-pattern-add-modal>
        <print-pattern-update-modal ref="printPatternUpdateModal" v-model:is-visible="isPrintPatternUpdateModalVisible"
            @submit="submitUpdatePrintPattern" :suppliers-array="suppliers" :categories="printPatternCategories">
        </print-pattern-update-modal>

        <accessory-add-modal v-model:is-visible="isAccessoryAddModalVisible" @submit="addSelectedAccessory"
            v-model:name="pickerInputValue" :suppliers-array="suppliers" :categories="accessoryCategories">
        </accessory-add-modal>
        <accessory-update-modal ref="accessoryUpdateModal" v-model:is-visible="isAccessoryUpdateModalVisible"
            @submit="submitUpdateAccessory" :suppliers-array="suppliers" :categories="accessoryCategories">
        </accessory-update-modal>
        <procedure-add-modal v-model:is-visible="isProcedureAddModalVisible"></procedure-add-modal>

        <tissue-picker
            v-model:is-visible="isTissuePickerVisible"
            @select-tissue="addSelectedTissue"
            @createNew="openTissueAddModal"
        ></tissue-picker>

        <print-pattern-picker
            v-model:is-visible="isPrintPatternPickerVisible"
            @select-print-pattern="addSelectedPrintPattern"
            @createNew="openPrintPatternAddModal"
        ></print-pattern-picker>

        <accessory-picker
            v-model:is-visible="isAccessoryPickerVisible"
            @select-accessory="addSelectedAccessory"
            @createNew="openAccessoryAddModal"
        ></accessory-picker>

        <procedure-picker v-model:is-visible="isProcedurePickerVisible" @select-procedure="addSelectedProcedure"></procedure-picker>
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="selectedImageIndex"
            :images="lightBoxImages"
            @hide="closeLightbox"
        ></BaseLightbox>

        <special-color-rule-modal
            v-model:is-visible="isSpecialColorRuleModalVisible"
            :descriptor="selectedDescriptorForSpecialColorRule"
            :colors="colorsWithoutWhiteEmbryo.filter((c) => !c.isArchived || elementRef?.colors?.find((tC) => tC.id === c.id))"
            @confirm="applySpecialColorRule"
        ></special-color-rule-modal>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    computed,
    onMounted,
    ref,
    toRaw,
    watch,
} from "vue";
import { user } from "@/user";
import AccessoryAddModal from "@/components/modals/AccessoryAddModal";
import TissueAddModal from "@/components/modals/TissueAddModal";
import ProcedureAddModal from "@/components/modals/ProcedureAddModal";
import TissuePicker from "@/components/pickers/TissuePicker";
import PrintPatternAddModal from "@/components/modals/PrintPatternAddModal";
import PrintPatternPicker from "@/components/pickers/PrintPatternPicker";
import PrintPatternUpdateModal from "@/components/modals/PrintPatternUpdateModal";
import TissueUpdateModal from "@/components/modals/TissueUpdateModal";
import AccessoryPicker from "@/components/pickers/AccessoryPicker";
import AccessoryUpdateModal from "@/components/modals/AccessoryUpdateModal";
import {
    doubleInputNormalization,
    doubleOutputNormalization,
    normalization,
    readImage,
    getElementName,
} from "@/views/utilities";
import BaseLightbox from "@/components/base/BaseLightbox";
import ProcedurePicker from "@/components/pickers/ProcedurePicker";
import AppLoadingScreen from "@/components/AppLoadingScreen";
import TagMultiSelect from "@/components/pickers/TagMultiSelect";
import BaseMultiSelect from "@/components/base/BaseMultiSelect";
import BaseInput from "@/components/base/BaseInput";
import { debounce } from "debounce";
import { useStore } from "vuex";
import BaseImageUploader from "@/components/base/BaseImageUploader";
import SpecialColorRuleModal from "@/components/modals/SpecialColorRuleModal";
import SpecialColorRuleAddModal from "@/components/modals/SpecialColorRuleAddModal";

export default {
    name: "ProductUpdateModal",
    components: {
        BaseMultiSelect,
        TagMultiSelect,
        ProcedurePicker,
        AccessoryUpdateModal,
        AccessoryPicker,
        TissueUpdateModal,
        PrintPatternUpdateModal,
        PrintPatternPicker,
        PrintPatternAddModal,
        TissuePicker,
        ProcedureAddModal,
        TissueAddModal,
        AccessoryAddModal,
        BaseLightbox,
        AppLoadingScreen,
        BaseInput,
        BaseImageUploader,
        SpecialColorRuleModal,
        SpecialColorRuleAddModal,
    },
    emits: [ "update:isVisible", "submit", ],
    props: {
        isVisible: {
            type: Boolean,
            default: () => false,
        },
        copyMode: {
            type: Boolean,
        },
        productToBind: {
            type: Object,
        },
        presentation: {
            type: Object,
        },
    },
    setup (props, { emit, }) {
        const store = useStore();
        const isLoading = ref(true);
        const { t, } = useI18n();
        const elementRef = ref({});
        const activeModalTab = ref(0);
        const colorManagerTab = ref(0);
        const colorsWithoutWhiteEmbryo = ref([]);
        const isTissueAddModalVisible = ref(false);
        const isTissueUpdateModalVisible = ref(false);
        const isPrintPatternAddModalVisible = ref(false);
        const isPrintPatternUpdateModalVisible = ref(false);
        const isAccessoryAddModalVisible = ref(false);
        const isAccessoryUpdateModalVisible = ref(false);
        const isProcedureAddModalVisible = ref(false);
        const isTissuePickerVisible = ref(false);
        const isPrintPatternPickerVisible = ref(false);
        const isAccessoryPickerVisible = ref(false);
        const isProcedurePickerVisible = ref(false);
        const tissueUpdateModal = ref(null);
        const printPatternUpdateModal = ref(null);
        const accessoryUpdateModal = ref(null);
        const selectedTissues = ref([]);
        const selectedTissueIndex = ref(0);
        const selectedPrintPatterns = ref([]);
        const selectedAccessories = ref([]);
        const selectedProcedures = ref([]);
        const suppliers = ref([]);
        const tissueCategories = ref([]);
        const accessoryCategories = ref([]);
        const printPatternCategories = ref([]);
        const procedures = ref([]);
        const indexOfTissueToChange = ref(-1);
        const enableColors = computed(() => selectedColors.value.filter((sc) => sc.enable));
        const disableColors = computed(() => selectedColors.value.filter((sc) => !sc.enable));
        const imagesToDelete = ref([]);
        const isConfirmButtonLoading = ref(false);
        const isSelectedAllEnableColors = computed(() => {
            let i = 0;
            selectedColors.value.forEach((c) => {
                if (c.toSwap && c.enable) {
                    i++;
                }
            });

            return i === enableColors.value.length;
        });
        const isSelectedAllDisableColors = computed(() => {
            let i = 0;
            selectedColors.value.forEach((c) => {
                if (c.toSwap && !c.enable) {
                    i++;
                }
            });

            return i === disableColors.value.length;
        });
        const newProduct = ref({
            productTemplateId: "",
            name: "",
            weight: "",
            price: "",
            isVisibleOnOrder: true,
            categoryId: "1",
            note: "",
            cost: {
                isFixedPrice: false,
                price: "0,000",
                otherCost: "0,000",
                scrapRate: "0,030",
                profitRate: "0,300",
            },
        });
        const isFixedPriceInverted = computed({
            get () {
                return !newProduct.value.cost?.isFixedPrice;
            },
            set (newVal) {
                newProduct.value.cost.isFixedPrice = !newProduct.value.cost.isFixedPrice;
            },
        });
        const priceToSellCalculated = computed(() => {
            const scrapRate = doubleInputNormalization(newProduct.value.cost.scrapRate) ?? 0;
            const normalizedTotalCost = doubleInputNormalization(totalCost.value) ?? 0;
            const useFixedPrice = newProduct.value.cost.isFixedPrice;
            const profitRate = doubleInputNormalization(newProduct.value.cost.profitRate) ?? 0;
            return useFixedPrice ? newProduct.value.cost.price : doubleOutputNormalization((profitRate + scrapRate) * normalizedTotalCost + normalizedTotalCost);
        });
        const profit = computed(() => {
            const useFixedPrice = newProduct.value.cost.isFixedPrice;
            const total = doubleInputNormalization(totalCost.value) ?? 0;
            const scrapRate = doubleInputNormalization(newProduct.value.cost.scrapRate) ?? 0;
            const priceToSell = useFixedPrice ? doubleInputNormalization(newProduct.value.cost.price) ?? 0 : doubleInputNormalization(priceToSellCalculated.value) ?? 0;
            return doubleOutputNormalization(priceToSell - total - total * scrapRate);
        });

        const profitRateCalculated = computed(() => {
            const normalizedProfit = doubleInputNormalization(profit.value) ?? 0;
            const normalizedTotalCost = doubleInputNormalization(totalCost.value);
            const profitRate = normalizedProfit / normalizedTotalCost;

            return doubleOutputNormalization(Number.isNaN(profitRate) ? 0 : profitRate);
        });

        const totalCost = computed(() => {
            const normalizedTotalCost = doubleInputNormalization(selectedAccessoriesTotalCost.value) +
                doubleInputNormalization(selectedProceduresTotalCost.value) +
                doubleInputNormalization(selectedTissuesTotalCost.value) +
                doubleInputNormalization(newProduct.value.cost.otherCost) ?? 0;
            return doubleOutputNormalization(normalizedTotalCost);
        });

        const selectedTissuesTotalCost = computed(() => doubleOutputNormalization(selectedTissues.value.reduce((pre, cur) => {
            const normalizedTissueConsumption = doubleInputNormalization(cur.consumption) ?? 0;
            const normalizedTissuePrice = doubleInputNormalization(cur.price) ?? 0;
            let normalizedTissuePatternPrice = 0;
            if (cur.printPatternId) {
                const printPatternData = getPrintPattern(cur.printPatternId);
                normalizedTissuePatternPrice = doubleInputNormalization(printPatternData.price) ?? 0;
            }
            return pre + (normalizedTissuePrice + normalizedTissuePatternPrice) * normalizedTissueConsumption;
        }, 0)));

        const selectedAccessoriesTotalCost = computed(() => doubleOutputNormalization(selectedAccessories.value.reduce((pre, cur) => {
            const normalizedAccessoryConsumption = doubleInputNormalization(cur.consumption) ?? 0;
            const normalizedAccessoryPrice = doubleInputNormalization(cur.price) ?? 0;
            return pre + normalizedAccessoryPrice * normalizedAccessoryConsumption;
        }, 0)));

        const selectedProceduresTotalCost = computed(() => doubleOutputNormalization(selectedProcedures.value.reduce((pre, cur) => {
            const procedureTypeName = cur.procedureType.name;
            const weight = doubleInputNormalization(newProduct.value.weight?.length > 0 ? newProduct.value.weight : cur.customWeight) ?? 0;
            const quantity = doubleInputNormalization(cur.quantity) ?? 0;
            const userInputPrice = doubleInputNormalization(cur.customPrice);
            const procedurePrice = doubleInputNormalization(cur.price) ?? 0;
            const useProcedurePrice = cur.isFixedPrice ?? true;
            let price = useProcedurePrice ? procedurePrice : userInputPrice;
            if (typeof price === "string") {
                price = 0;
            }
            switch (procedureTypeName) {
                case "default.procedureType.printing":
                    return pre + price;
                case "default.procedureType.cutting":
                    return pre + price;
                case "default.procedureType.fasoning":
                    return pre + price;
                case "default.procedureType.dyeing":
                    if (!useProcedurePrice) {
                        return pre + price;
                    }
                    return pre + weight * price;
                case "default.procedureType.ironing":
                    return pre + price;
                case "default.procedureType.clothePrinting":
                    return pre + price;
                case "default.procedureType.buttoning":
                    return pre + (useProcedurePrice ? quantity * price : price);
                case "default.procedureType.other":
                    return pre + (quantity > 0 ? quantity * price : price);
            }
        }, 0)));
        const colors = ref([]);
        const selectedColors = ref([]);
        const formattedColors = ref([]);

        const sizes = ref([]);
        const selectedSizes = ref([]);
        const formattedSizes = ref([]);

        const tags = ref([]);
        const selectedTags = ref([]);
        const formattedTags = ref([]);

        const categories = ref([]);

        const images = ref([]);
        const selectedImages = ref([]);
        const isLightboxVisible = ref(false);
        const selectedImageIndex = ref(0);
        const lightBoxImages = ref([]);

        const defaultPrintingProcedure = ref(null);

        const pickerInputValue = ref("");

        const colorManagementTableHeaders = computed(() => {
            const temp = [];
            for (const tissueDescriptor of selectedTissues.value) {
                temp.push({
                    tissueName: tissueDescriptor.name,
                    printPatternName: Number.isFinite(tissueDescriptor.printPatternId) ? getPrintPattern(tissueDescriptor.printPatternId).name : undefined,
                    tissueColorRule: getTissueColorRule(tissueDescriptor),
                    printPatternColorRule: Number.isFinite(tissueDescriptor.printPatternId) ? getPrintPatternColorRule(tissueDescriptor) : undefined,
                    selectedTissue: tissueDescriptor,
                    tissueColorRules: tissueDescriptor.tissueColorRules ?? [],
                    printPatternColorRules: tissueDescriptor.printPatternColorRules ?? [],
                });
            }
            return temp;
        });

        const procedureColorManagementTableHeaders = computed(() => {
            const temp = [];
            const dyeingProcedures = getDyeingTypeProcedures();
            for (const dyeingProcedure of dyeingProcedures) {
                temp.push({
                    name: dyeingProcedure.isDefault ? t(dyeingProcedure.name) : dyeingProcedure.name,
                    colorRule: getProcedureColorRule(dyeingProcedure),
                    procedure: dyeingProcedure,
                    dyeingColorRules: dyeingProcedure.dyeingColorRules,
                });
            }
            return temp;
        });

        function getDyeingTypeProcedures () {
            return selectedProcedures.value.filter((sp) => sp.procedureType.name === "default.procedureType.dyeing");
        }

        function getProcedureColorValue (procedure, rowColor) {
            if (procedure.dyeingColorRules?.length > 0) {
                const rowColorDescriptor = colors.value.find((c) => c.name === rowColor);
                const rule = procedure.dyeingColorRules.find((rule) => Number.parseInt(rule.orderColorId, 10) === Number.parseInt(rowColorDescriptor.id, 10));
                if (rule) {
                    return colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(rule.useColorId, 10)).name;
                }
                const procedureCopy = { ...procedure, };
                procedureCopy.dyeingColorRules = [];

                return getProcedureColorValue(procedureCopy, rowColor);
            }
            if (procedure.usePreorderColor) {
                return rowColor;
            }
            if (procedure.colorId > 0) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(procedure.colorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR";
            }
            return "ERROR NO MATCH";
        }

        function getProcedureColorRule (procedure) {
            if (procedure.usePreorderColor) {
                return t("model.followOrderColor");
            }
            else if (procedure.colorId > 0) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(procedure.colorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR";
            }
            return "ERROR NO MATCH";
        }

        const elementFields = Object.keys(newProduct.value);

        function bindElementToUpdate () {
            const element = props.productToBind;
            element.categoryId = element.category.id;

            // Binding
            elementRef.value = element;
            images.value = [];
            activeModalTab.value = 0;
            elementFields.forEach((field) => {
                newProduct.value[field] = Number.isInteger(element[field]) ? element[field].toString() : element[field];
            });
            newProduct.value.id = element.id;

            newProduct.value.cost.price = element.cost.price ?? "0,000";
            newProduct.value.cost.scrapRate = element.cost.scrapRate ?? "0,000";
            newProduct.value.cost.profitRate = element.cost.profitRate ?? "0,000";
            newProduct.value.cost.otherCost = element.cost.otherCost ?? "0,000";
            newProduct.value.cost.isFixedPrice = element.cost.isFixedPrice;

            newProduct.value.images = element.images;

            if (props.copyMode) {
                newProduct.value.name = "";
            }

            // Binding tissues and printPatterns to element to update
            element.tissues.forEach((t, index) => {
                selectedTissues.value = [ ...selectedTissues.value, {
                    // QUI C'è STATO UN MERGE
                    ...t.tissue,
                    consumption: t.consumption,
                    cropNotes: t.cropNotes,
                    hemming: t.hemming,
                    hotGlue: t.hotGlue,
                    printPatternColorId: t.printPatternColorId,
                    printPatternNoColor: t.printPatternNoColor,
                    printPatternUsePreorderColor: t.printPatternUsePreorderColor,
                    tissueColorId: t.tissueColorId,
                    tissueUsePreorderColor: t.tissueUsePreorderColor,
                    printPatternId: t.printPattern ? t.printPattern.id : "",
                    tissueColorRules: t.tissueColorRules ?? [],
                    printPatternColorRules: t.printPatternColorRules ?? [],
                }, ];
                tissueColorSelectValue.value[index] = t.tissueColorId ?? "preorder";
                printPatternColorSelectValue.value[index] = t.printPatternColorId ?? t.printPatternUsePreorderColor ? "preorder" : "";
                if (t.printPattern) {
                    selectedPrintPatterns.value = [ ...selectedPrintPatterns.value, t.printPattern, ];
                }
            });

            // Binding accessories to element to update
            element.accessories.forEach((a) => {
                selectedAccessories.value = [ ...selectedAccessories.value, {
                    ...a.accessory,
                    colorId: a.colorId,
                    consumption: a.consumption,
                    differentiateStorage: a.differentiateStorage,
                    noColor: a.noColor,
                    usePreorderColor: a.usePreorderColor,
                }, ];
            });

            // Building formatted colors and sizes
            formattedColors.value = colors.value.filter((c) => !c.isArchived || elementRef.value.colors.find((color) => color.id === c.id)).map((c) => ({
                value: c.id,
                text: c.name,
                toSwap: false,
                selected: false,
                enable: true,
                type: "color",
                isDefault: c.isDefault,
            }));

            formattedSizes.value = sizes.value.filter((s) => !s.isArchived || elementRef.value.sizes.find((size) => size.id === s.id)).map((s) => ({
                value: s.id,
                text: s.name,
                selected: false,
                type: "size",
                isDefault: s.isDefault,
            }));

            // Binding sizes to element to update
            element.sizes.forEach((s) => {
                selectedSizes.value = [ ...selectedSizes.value, s, ];
            });

            selectedSizes.value.forEach((s) => {
                const size = formattedSizes.value.find((fs) => fs.value === s.id);
                if (size) {
                    size.selected = true;
                }
            });

            // Binding tags to element to update
            element.tags.filter((t) => !t.isDefault).forEach((t) => {
                selectedTags.value = [ ...selectedTags.value, t, ];
            });

            selectedTags.value.forEach((t) => {
                const tag = formattedTags.value.find((ft) => ft.value === t.id);
                if (tag) {
                    tag.selected = true;
                }
            });

            // Binding colors to element to update
            element.colors.forEach((c) => {
                selectedColors.value = [ ...selectedColors.value, c, ];
            });

            selectedColors.value.forEach((c) => {
                const color = formattedColors.value.find((fc) => fc.value === c.id);
                if (color) {
                    color.selected = true;
                }
            });

            console.log("HERE X!", formattedColors.value);

            // Binding procedures to element to update
            element.procedures.forEach((p, index) => {
                selectedProcedures.value = [ ...selectedProcedures.value, {
                    ...p.procedure,
                    colorId: p.colorId,
                    customPrice: p.customPrice,
                    customWeight: p.customWeight,
                    differentiateStorage: p.differentiateStorage,
                    note: p.note,
                    quantity: p.quantity,
                    usePreorderColor: p.usePreorderColor,
                    useProductWeight: p.useProductWeight,
                    dyeingColorRules: p.dyeingColorRules,
                }, ];
                if (p.procedure.procedureType.name.includes("dyeing")) {
                    dyeingProcedureColorSelectValue.value[index] = p.colorId ?? "preorder";
                }
            });

            for (const image of element.images) {
                images.value = [ ...images.value, image.url, ];
            }
        }

        const isSpecialColorRuleModalVisible = ref(false);
        const selectedDescriptorForSpecialColorRule = ref(null);

        function openSpecialColorRuleModal (descriptor, index) {
            descriptor.index = index;
            selectedDescriptorForSpecialColorRule.value = descriptor;
            isSpecialColorRuleModalVisible.value = true;
        }

        function getTissueColorValue (selectedTissue, rowColor) {
            if (selectedTissue.category.name === "generic.whiteEmbryo") {
                return t("color.whiteEmbryo");
            }
            if (selectedTissue.tissueColorRules?.length > 0) {
                const rowColorDescriptor = colors.value.find((c) => c.name === rowColor);
                const rule = selectedTissue.tissueColorRules.find((rule) => Number.parseInt(rule.orderColorId, 10) === Number.parseInt(rowColorDescriptor.id, 10));
                if (rule) {
                    return colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(rule.useColorId, 10)).name;
                }
                const tissueCopy = { ...selectedTissue, };
                tissueCopy.tissueColorRules = [];

                return getTissueColorValue(tissueCopy, rowColor);
            }
            if (selectedTissue.tissueUsePreorderColor) {
                return rowColor;
            }
            if (selectedTissue.tissueColorId > 0) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(selectedTissue.tissueColorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR";
            }
            return "ERROR NO MATCH";
        }

        function getTissueColorRule (selectedTissue) {
            if (selectedTissue.category.name === "generic.whiteEmbryo") {
                return t("ingredient.tissue.noColorForWhiteEmbryo");
            }
            if (selectedTissue.tissueUsePreorderColor) {
                return t("model.followOrderColor");
            }
            if (selectedTissue.tissueColorId > 0) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(selectedTissue.tissueColorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR";
            }
            return "ERROR NO MATCH";
        }

        function getPrintPatternColorValue (selectedTissue, rowColor) {
            if (selectedTissue.printPatternColorRules?.length > 0) {
                const rowColorDescriptor = colors.value.find((c) => c.name === rowColor);
                const rule = selectedTissue.printPatternColorRules.find((rule) => Number.parseInt(rule.orderColorId, 10) === Number.parseInt(rowColorDescriptor.id, 10));
                if (rule) {
                    return colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(rule.useColorId, 10)).name;
                }
                const tissueCopy = { ...selectedTissue, };
                tissueCopy.printPatternColorRules = [];

                return getPrintPatternColorValue(tissueCopy, rowColor);
            }
            if (selectedTissue.printPatternUsePreorderColor) {
                return rowColor;
            }
            else if (selectedTissue.printPatternNoColor) {
                return "-";
            }
            else if (selectedTissue.printPatternColorId?.length > 0) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(selectedTissue.printPatternColorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR ID";
            }
            return "ERROR NO MATCH";
        }

        function getPrintPatternColorRule (selectedTissue) {
            if (selectedTissue.printPatternUsePreorderColor) {
                return t("model.followOrderColor");
            }
            if (selectedTissue.printPatternNoColor) {
                return t("model.noColor");
            }
            if (selectedTissue.printPatternColorId?.length > 0) {
                const color = colors.value.find((c) => Number.parseInt(c.id, 10) === Number.parseInt(selectedTissue.printPatternColorId, 10));
                if (color) {
                    return color.isDefault ? t(color.name) : color.name;
                }
                return "ERROR COLOR ID";
            }
            return "ERROR NO MATCH";
        }

        function importPreorderColor () {
            const defaultColors = colors.value.filter((c) => c.isPreorderDefault);
            formattedColors.value.forEach((c) => {
                c.selected = defaultColors.findIndex((dc) => dc.id === c.value) !== -1;
            });
        }

        function importPreorderSize () {
            const defaultSizes = sizes.value.filter((s) => s.isPreorderDefault);
            formattedSizes.value.forEach((s) => {
                s.selected = defaultSizes.findIndex((ds) => ds.id === s.value) !== -1;
            });
        }

        function closeLightbox () {
            lightBoxImages.value = [];
            isLightboxVisible.value= false;
        }

        function openLightbox (index) {
            selectedImageIndex.value = index;
            lightBoxImages.value = images.value;
            isLightboxVisible.value = true;
        }

        function openTissueLightbox (images) {
            selectedImageIndex.value = 0;
            lightBoxImages.value = images.map((img) => img.url);
            isLightboxVisible.value = true;
        }

        async function deleteImage (index) {
            const imageToDelete = elementRef.value.images.find((i) => i.url === images.value[index]);
            images.value.splice(index, 1);
            newProduct.value.images.splice(index, 1);
            if (imageToDelete) {
                imagesToDelete.value = [ ...imagesToDelete.value, imageToDelete.id, ];
            }
            else {
                selectedImages.value.splice(index, 1);
            }
        }

        async function saveImages (files) {
            selectedImages.value = [ ...selectedImages.value, ...files, ];

            images.value = newProduct.value.images.map((image) => image.url);

            for (let i=0; i<selectedImages.value.length; i++) {
                selectedImages.value[i].url = await readImage(selectedImages.value[i]);
                images.value = [ ...images.value, selectedImages.value[i].url, ];
            }
        }

        function closeModal () {
            emit("update:isVisible", false);

            emptyNewProduct();
        }

        function emptyNewProduct () {
            newProduct.value = {
                productTemplateId: "",
                name: "",
                weight: "",
                price: "",
                isVisibleOnOrder: true,
                categoryId: "1",
                note: "",
                cost: {
                    isFixedPrice: true,
                    price: "0,000",
                    otherCost: "0,000",
                    scrapRate: "0,030",
                    profitRate: "0,300",
                    totalCost: "",
                },
                images: [],
            };

            selectedTissues.value = [];
            selectedPrintPatterns.value = [];
            selectedSizes.value = [];
            selectedTags.value = [];
            selectedColors.value = [];
            selectedProcedures.value = [];
            selectedAccessories.value = [];
            selectedImages.value = [];
            elementRef.value = null;
            tags.value.forEach((t) => {
                t.selected = false;
            });
            // duplicateNameHelper.value = "";
        }

        // const duplicateNameHelper = ref("");
        const autoGeneratedName = computed(() => {
            let name = "";
            let usePrintPatternName = false;
            if (selectedTissues.value.length > 0) {
                name = selectedTissues.value[0]?.name;
                if (selectedTissues.value[0].printPatternId) {
                    usePrintPatternName = true;
                    name = getPrintPattern(selectedTissues.value[0].printPatternId).name;
                }
            }
            const dyeing = selectedProcedures.value.find((sp) => sp.procedureType.name === "default.procedureType.dyeing");
            if (dyeing) {
                const dyeingProcedureName = dyeing.isDefault ? t(dyeing.name) : dyeing.name;
                if (usePrintPatternName) {
                    name += ` + ${ dyeingProcedureName }`;
                }
                else {
                    name = dyeingProcedureName;
                }
            }
            return name;
            // return name + duplicateNameHelper.value;
        });

        function selectColor (event) {
            selectedColors.value.forEach((sc) => {
                if (!event.find((e) => e === sc)) {
                    sc.enable = true;
                }
            });
            selectedColors.value = event.map((c) => ({
                ...c,
                id: c.value,
            }));

        }

        function selectSize (event) {
            selectedSizes.value = event.map((s) => ({
                ...s,
                id: s.value,
            }));
        }

        function selectProductCategory (event) {
            newProduct.value.categoryId = event.target.value;
        }

        function toggleVisibleOnOrder (event) {
            newProduct.value.isVisibleOnOrder = event.target.checked;
        }

        function openAccessoryAddModal (name) {
            pickerInputValue.value = name;
            isAccessoryAddModalVisible.value = true;
        }

        function openAccessoryUpdateModal (accessory) {
            accessoryUpdateModal.value.bindElementToUpdate(toRaw(accessory));
            isAccessoryUpdateModalVisible.value = true;
        }

        function openTissueAddModal (name) {
            pickerInputValue.value = name;
            isTissueAddModalVisible.value = true;
        }

        function openTissueUpdateModal (tissue) {
            tissueUpdateModal.value.bindElementToUpdate(toRaw(tissue));
            isTissueUpdateModalVisible.value = true;
        }

        function openProcedureAddModal () {
            isProcedureAddModalVisible.value = true;
        }

        function openTissuePicker () {
            isTissuePickerVisible.value = true;
        }

        function openPrintPatternPicker (index) {
            selectedTissueIndex.value = index;
            isPrintPatternPickerVisible.value = true;
        }

        function openAccessoryPicker () {
            isAccessoryPickerVisible.value = true;
        }

        function openProcedurePicker () {
            isProcedurePickerVisible.value = true;
        }

        function openPrintPatternAddModal (name) {
            pickerInputValue.value = name;
            isPrintPatternAddModalVisible.value = true;
        }

        function openPrintPatternUpdateModal (printPatternId) {
            const printPattern = getPrintPattern(printPatternId);
            printPatternUpdateModal.value.bindElementToUpdate(toRaw(printPattern));
            isPrintPatternUpdateModalVisible.value = true;
        }

        function getTissueCategoryName (category) {
            if (category.isDefault) {
                return t(category.name);
            }

            return category.name;
        }

        function getPrintPattern (printPatternId) {
            return selectedPrintPatterns.value.find((p) => p.id === printPatternId);
        }

        function toggleDifferentiateStorage (event, index) {
            const accessory = selectedAccessories.value[index];

            accessory.differentiateStorage = event.target.checked;
        }

        function getSupplierName (supplier) {
            if (supplier.isDefault) {
                return t(supplier.name);
            }

            return supplier.name;
        }

        function submitUpdateTissue ({ response, element, }) {
            if (response) {
                for (const key of Object.keys(response)) {
                    element[key] = response[key];
                }
            }
        }

        function submitUpdatePrintPattern ({ response, element, }) {
            if (response) {
                for (const key of Object.keys(element)) {
                    element[key] = response[key];
                }
            }
        }

        function submitUpdateAccessory ({ response, element, }) {
            if (response) {
                for (const key of Object.keys(response)) {
                    element[key] = response[key];
                }
            }
        }

        const tissueColorSelectValue = ref({});
        function selectTissueColor (event, index) {
            const tissue = selectedTissues.value[index];

            if (event.target.value === "preorder") {
                tissue.tissueUsePreorderColor = true;
                tissue.tissueColorId = "";
            }
            else {
                tissue.tissueUsePreorderColor = false;
                tissue.tissueColorId = event.target.value;
            }
        }

        const printPatternColorSelectValue = ref({});
        function selectPrintPatternColor (event, index) {
            const tissue = selectedTissues.value[index];

            if (event.target.value === "preorder") {
                tissue.printPatternUsePreorderColor = true;
                tissue.printPatternNoColor = false;
                tissue.printPatternColorId = "";
            }
            else if (event.target.value === "none") {
                tissue.printPatternUsePreorderColor = false;
                tissue.printPatternNoColor = true;
                tissue.printPatternColorId = "";
            }
            else {
                tissue.printPatternUsePreorderColor = false;
                tissue.printPatternNoColor = false;
                tissue.printPatternColorId = event.target.value;
            }
        }

        function selectAccessoryColor (event, index) {
            const accessory = selectedAccessories.value[index];

            if (event.target.value === "preorder") {
                accessory.usePreorderColor = true;
                accessory.noColor = false;
                accessory.colorId = "";
            }
            else if (event.target.value === "none") {
                accessory.usePreorderColor = false;
                accessory.noColor = true;
                accessory.colorId = "";
            }
            else {
                accessory.usePreorderColor = false;
                accessory.noColor = false;
                accessory.colorId = event.target.value;
            }
        }

        function swapTop (index) {
            if (index !== 0) {
                const tissueOfSelectedTissues = { ...selectedTissues.value[index], };

                selectedTissues.value[index] = selectedTissues.value[index-1];
                selectedTissues.value[index-1] = tissueOfSelectedTissues;
            }
        }

        function swapBottom (index) {
            if (index !== selectedTissues.value.length-1) {
                const tissueOfSelectedTissues = { ...selectedTissues.value[index], };

                selectedTissues.value[index] = selectedTissues.value[index+1];
                selectedTissues.value[index+1] = tissueOfSelectedTissues;
            }
        }

        async function addSelectedTissue (tissueDescriptor) {
            if (indexOfTissueToChange.value !== -1) {
                tissueDescriptor.printPatternId = selectedTissues.value[indexOfTissueToChange.value].printPatternId;
                tissueDescriptor.consumption = "";
                tissueDescriptor.hemming = selectedTissues.value[indexOfTissueToChange.value].hemming;
                tissueDescriptor.cropNotes = selectedTissues.value[indexOfTissueToChange.value].cropNotes;
                tissueDescriptor.hotGlue = selectedTissues.value[indexOfTissueToChange.value].hotGlue;
                tissueDescriptor.tissueUsePreorderColor = selectedTissues.value[indexOfTissueToChange.value].tissueUsePreorderColor;
                tissueDescriptor.printPatternUsePreorderColor = selectedTissues.value[indexOfTissueToChange.value].printPatternUsePreorderColor;
                tissueDescriptor.printPatternNoColor = selectedTissues.value[indexOfTissueToChange.value].printPatternNoColor;
                tissueDescriptor.tissueColorId = selectedTissues.value[indexOfTissueToChange.value].tissueColorId;
                tissueDescriptor.printPatternColorId = selectedTissues.value[indexOfTissueToChange.value].printPatternColorId;

                selectedTissues.value.splice(indexOfTissueToChange.value, 1);
                selectedTissues.value.splice(indexOfTissueToChange.value, 0, toRaw(tissueDescriptor));
                indexOfTissueToChange.value = -1;
            }
            else {
                tissueDescriptor.printPatternId = "";
                tissueDescriptor.consumption = "";
                tissueDescriptor.hemming = "";
                tissueDescriptor.cropNotes = "";
                tissueDescriptor.hotGlue = false;
                tissueDescriptor.tissueUsePreorderColor = true;
                tissueDescriptor.printPatternUsePreorderColor = true;
                tissueDescriptor.printPatternNoColor = false;
                tissueDescriptor.tissueColorId = "";
                tissueDescriptor.printPatternColorId = "";

                selectedTissues.value = [ ...selectedTissues.value, toRaw(tissueDescriptor), ];
            }
        }

        function deleteTissue (index) {
            deletePrintPattern(index);
            selectedTissues.value.splice(index, 1);
        }

        function changeTissue (index) {
            indexOfTissueToChange.value = index;

            openTissuePicker();
        }

        async function addSelectedPrintPattern (printPattern) {
            selectedPrintPatterns.value = [ ...selectedPrintPatterns.value, toRaw(printPattern), ];
            const reference = selectedTissues.value[selectedTissueIndex.value];

            if (reference) {
                reference.printPatternId = printPattern.id;
            }

            if (!selectedProcedures.value.find((p) => p.id === defaultPrintingProcedure.value.id)) {
                selectedProcedures.value = [ ...selectedProcedures.value, defaultPrintingProcedure.value, ];
            }

            if (printPattern.colors.length > 0) {
                formattedColors.value.forEach((c) => {
                    c.selected = printPattern.colors.findIndex((dc) => dc.id === c.value) !== -1;
                });
            }
        }

        function deletePrintPattern (index) {
            const tissue = selectedTissues.value[index];
            const printPatternIndex = selectedPrintPatterns.value.findIndex((p) => p.id === tissue.printPatternId);

            if (printPatternIndex !== -1) {
                selectedPrintPatterns.value.splice(printPatternIndex, 1);
            }

            tissue.printPatternId = "";

            if (selectedPrintPatterns.value.length === 0) {
                const existedProcedureIndex = selectedProcedures.value.findIndex((p) => p.id === defaultPrintingProcedure.value.id);
                if (existedProcedureIndex !== -1) {
                    selectedProcedures.value.splice(existedProcedureIndex, 1);
                }
            }
        }

        function addSelectedAccessory (accessory) {
            if (!selectedAccessories.value.find((a) => a.id === accessory.id)) {
                accessory.differentiateStorage = false;
                accessory.consumption = "";
                accessory.usePreorderColor = false;
                accessory.noColor = true;
                accessory.colorId = "";

                selectedAccessories.value = [ ...selectedAccessories.value, accessory, ];
            }
            else {
                alert(t("error.existingAccessory"));
            }
        }

        function deleteAccessory (index) {
            selectedAccessories.value.splice(index, 1);
        }

        function addSelectedProcedure (procedure) {
            if (!selectedProcedures.value.find((p) => p.id === procedure.id)) {
                if (procedure.procedureTypeId === 4) {
                    procedure.usePreorderColor = true;
                }
                procedure.note = "";
                procedure.quantity = "";
                procedure.customWeight = "";
                procedure.customPrice = "0,000";
                selectedProcedures.value = [ ...selectedProcedures.value, procedure, ];
            }
            else {
                alert(t("error.existingProcedure"));
            }

        }

        function deleteProcedure (procedureId) {
            selectedProcedures.value.splice(selectedProcedures.value.findIndex((p) => p.id === procedureId), 1);
        }

        function getItemSubtotal (amount, price) {
            const normalizedAmount = doubleInputNormalization(amount);
            const normalizedPrice = doubleInputNormalization(price);
            if (normalizedAmount && normalizedPrice) {
                return doubleOutputNormalization(normalizedAmount * normalizedPrice);
            }

            return "0,000";
        }

        function addDoubles (op1, op2) {
            const normalizedOp1 = doubleInputNormalization(op1);
            const normalizedOp2 = doubleInputNormalization(op2);
            if (normalizedOp1 && normalizedOp2) {
                return doubleOutputNormalization(normalizedOp1 + normalizedOp2);
            }
            return "0,000";
        }

        function getProductProcedureQuantity (procedureDescriptor) {
            const procedureTypeName = procedureDescriptor.procedureType.name;
            const weight = doubleOutputNormalization(procedureDescriptor.customWeight?.length > 0 ? procedureDescriptor.customWeight : newProduct.value.weight);
            const quantity = doubleOutputNormalization(procedureDescriptor.quantity);
            const useProcedurePrice = procedureDescriptor.isFixedPrice ?? true;
            let fieldValue = "-";
            switch (procedureTypeName) {
                case "default.procedureType.printing":
                    break;
                case "default.procedureType.cutting":
                    break;
                case "default.procedureType.fasoning":
                    break;
                case "default.procedureType.dyeing":
                    if (useProcedurePrice && weight !== "") {
                        if (weight !== null) {
                            fieldValue = weight;
                        }
                        else {
                            fieldValue = "0,000";
                        }
                    }
                    break;
                case "default.procedureType.ironing":
                    break;
                case "default.procedureType.clothePrinting":
                    break;
                case "default.procedureType.buttoning":
                    if (useProcedurePrice) {
                        fieldValue = quantity;
                    }
                    break;
                case "default.procedureType.other":
                    if (quantity !== "0,000") {
                        fieldValue = quantity;
                    }
                    break;
            }
            return fieldValue;
        }

        // eslint-disable-next-line complexity
        function getProductProcedureSubtotal (procedureDescriptor) {
            const procedureTypeName = procedureDescriptor.procedureType.name;
            const weight = doubleInputNormalization(procedureDescriptor.customWeight?.length > 0 ? procedureDescriptor.customWeight : newProduct.value.weight) ?? 0;
            const quantity = doubleInputNormalization(procedureDescriptor.quantity) ?? 0;
            const userInputPrice = doubleInputNormalization(procedureDescriptor.customPrice) ?? 0;
            const procedurePrice = doubleInputNormalization(procedureDescriptor.price) ?? 0;
            const useProcedurePrice = procedureDescriptor.isFixedPrice ?? true;
            let price = useProcedurePrice ? procedurePrice : userInputPrice;
            if (typeof price === "string") {
                price = 0;
            }
            let subtotal = 0;
            switch (procedureTypeName) {
                case "default.procedureType.printing":
                    subtotal = price;
                    break;
                case "default.procedureType.cutting":
                    subtotal = price;
                    break;
                case "default.procedureType.fasoning":
                    subtotal = price;
                    break;
                case "default.procedureType.dyeing":
                    subtotal = useProcedurePrice ? weight * price : price;
                    break;
                case "default.procedureType.ironing":
                    subtotal = price;
                    break;
                case "default.procedureType.clothePrinting":
                    subtotal = price;
                    break;
                case "default.procedureType.buttoning":
                    subtotal = useProcedurePrice ? quantity * price : price;
                    break;
                case "default.procedureType.other":
                    subtotal = quantity > 0 ? quantity * price : price;
                    break;
            }
            return doubleOutputNormalization(subtotal);
        }

        function toggleFixedPrice () {
            newProduct.value.cost.price = "0,000";
            newProduct.value.cost.isFixedPrice = !newProduct.value.cost.isFixedPrice;
        }

        function toggleDifferentiateStorageProcedure (event, index) {
            const procedure = selectedProcedures.value[index];

            procedure.differentiateStorage = event.target.checked;
        }

        function showProcedureCustomWeightInput (index) {
            const procedureType = selectedProcedures.value[index].procedureType;

            return procedureType?.name === "default.procedureType.dyeing";
        }

        function showProcedureQuantityInput (index) {
            const procedureType = selectedProcedures.value[index].procedureType;

            return procedureType?.name === "default.procedureType.buttoning" || procedureType?.name === "default.procedureType.other";
        }

        function showProcedureSeparateFromStorage (index) {
            const procedureType = selectedProcedures.value[index].procedureType;

            return procedureType?.name === "default.procedureType.buttoning" || procedureType?.name === "default.procedureType.other";
        }

        function showProcedureColorPicker (index) {
            const procedureType = selectedProcedures.value[index].procedureType;

            return procedureType?.name === "default.procedureType.dyeing";
        }

        const dyeingProcedureColorSelectValue = ref({});
        function selectProcedureColor (event, index) {
            const procedure = selectedProcedures.value[index];

            if (event.target.value === "preorder") {
                procedure.usePreorderColor = true;
                procedure.colorId = "";
            }
            else {
                procedure.usePreorderColor = false;
                procedure.colorId = event.target.value;
            }

            dyeingProcedureColorSelectValue.value[index] = event.target.value;
        }

        function selectAllColors (event, array) {
            array.forEach((c) => {
                c.toSwap = event.target.checked;
            });
        }

        function removeSelectedColors () {
            selectedColors.value.forEach((c) => {
                if (c.toSwap) {
                    c.toSwap = false;
                    c.enable = false;
                }
            });
        }

        function restoreSelectedColors () {
            selectedColors.value.forEach((c) => {
                if (c.toSwap) {
                    c.toSwap = false;
                    c.enable = true;
                }
            });
        }

        function removeColor (color) {
            color.toSwap = false;
            color.enable = false;
        }

        function restoreColor (color) {
            color.toSwap = false;
            color.enable = true;
        }

        watch(() => store.state.toReloadPage, async (toReload) => {
            if (toReload) {
                await loadData();
            }
        });

        async function loadData () {
            const presentation = await user.getProductPage();
            categories.value = presentation.productCategories;
            tags.value = presentation.tags.filter((t) => !t.isDefault);
            colors.value = presentation.colors;
            sizes.value = presentation.sizes;
            procedures.value = presentation.procedures;
            suppliers.value = presentation.suppliers;
            tissueCategories.value = presentation.tissueCategories;
            accessoryCategories.value = presentation.accessoryCategories;
            printPatternCategories.value = presentation.printPatternCategories;
            colorsWithoutWhiteEmbryo.value = [ ...colors.value, ];
            colorsWithoutWhiteEmbryo.value = colorsWithoutWhiteEmbryo.value.filter((c) => c.name !== "color.whiteEmbryo");
        }

        async function init () {
            isLoading.value = true;
            const presentation = await user.getProductPage();
            categories.value = presentation.productCategories;
            tags.value = presentation.tags.filter((t) => !t.isDefault);
            colors.value = presentation.colors;
            sizes.value = presentation.sizes;
            procedures.value = presentation.procedures;
            suppliers.value = presentation.suppliers;
            tissueCategories.value = presentation.tissueCategories;
            accessoryCategories.value = presentation.accessoryCategories;
            printPatternCategories.value = presentation.printPatternCategories;

            colorsWithoutWhiteEmbryo.value = [ ...colors.value, ];
            colorsWithoutWhiteEmbryo.value = colorsWithoutWhiteEmbryo.value.filter((c) => c.name !== "color.whiteEmbryo");

            defaultPrintingProcedure.value = procedures.value.find((p) => p.name === "default.procedure.printing");
            defaultPrintingProcedure.value = {
                ...defaultPrintingProcedure.value,
                note: "",
                quantity: "",
                customWeight: "",
                customPrice: "0,000",
            };

            formattedTags.value = tags.value.map((t) => ({
                value: t.id,
                text: t.name,
                selected: false,
                type: "tag",
                isDefault: false,
            }));

            isLoading.value = false;
        }

        async function submitUpdate () {
            const formData = new FormData();

            isConfirmButtonLoading.value = true;

            if (!newProduct.value.cost.isFixedPrice) {
                newProduct.value.cost.price = priceToSellCalculated.value;
            }

            const emptyStringToZero = (n) => typeof n === "string" && n.length === 0 ? "0,000" : n;
            newProduct.value.cost.totalCost = totalCost.value;
            newProduct.value.cost.scrapRate = emptyStringToZero(newProduct.value.cost.scrapRate);
            newProduct.value.cost.otherCost = emptyStringToZero(newProduct.value.cost.otherCost);
            newProduct.value.cost.profitRate = emptyStringToZero(newProduct.value.cost.profitRate);
            newProduct.value.cost.price = emptyStringToZero(newProduct.value.cost.price);

            for (const [ key, value, ] of Object.entries(newProduct.value)) {
                if (key === "cost") {
                    formData.append(key, JSON.stringify(normalization(value)));
                }
                else if (key === "name") {
                    if (value.length > 0) {
                        formData.append(key, normalization(value));
                    }
                    else if (autoGeneratedName.value.length > 0) {
                        formData.append(key, normalization(autoGeneratedName.value));
                    }
                }
                else if (key === "isVisibleOnOrder") {
                    formData.append(key, value);
                }
                else if (value) {
                    formData.append(key, normalization(value));
                }
            }

            formData.append("imagesToDelete", JSON.stringify(imagesToDelete.value));

            formData.append("tissues", JSON.stringify(normalization(selectedTissues.value.map((t) => ({
                id: t.id,
                printPatternId: t.printPatternId,
                consumption: t.consumption,
                hemming: t.hemming,
                cropNotes: t.cropNotes,
                hotGlue: t.hotGlue,
                tissueUsePreorderColor: t.tissueUsePreorderColor,
                printPatternUsePreorderColor: t.printPatternUsePreorderColor,
                printPatternNoColor: t.printPatternNoColor,
                tissueColorId: t.tissueColorId,
                printPatternColorId: t.printPatternColorId,
                tissueColorRules: t.tissueColorRules,
                printPatternColorRules: t.printPatternColorRules,
            })))));

            formData.append("tissuesOrder", JSON.stringify(normalization(selectedTissues.value.map((t, index) => {
                if (t.printPatternId) {
                    return `${t.id}+${t.printPatternId}-${index}`;
                }

                return `${t.id}-${index}`;
            }))));

            formData.append("accessories", JSON.stringify(normalization(selectedAccessories.value.map((a) => ({
                id: a.id,
                differentiateStorage: a.differentiateStorage,
                consumption: a.consumption,
                usePreorderColor: a.usePreorderColor,
                noColor: a.noColor,
                colorId: a.colorId,
            })))));

            formData.append("procedures", JSON.stringify(normalization(selectedProcedures.value.map((p) => ({
                note: p.note,
                procedureTypeId: p.procedureTypeId,
                differentiateStorage: p.differentiateStorage,
                quantity: p.quantity,
                useProductWeight: newProduct.value.weight ? newProduct.value.weight.length > 0 : false,
                customWeight: p.customWeight,
                customPrice: p.customPrice,
                id: p.id,
                usePreorderColor: p.usePreorderColor,
                colorId: p.colorId,
                dyeingColorRules: p.dyeingColorRules,
            })))));

            for (const i of selectedImages.value) {
                formData.append("images", i);
            }

            formData.append("colors", JSON.stringify(normalization(selectedColors.value.map((c) => ({
                id: c.id,
                enable: c.enable,
            })))));

            formData.append("sizes", JSON.stringify(normalization(selectedSizes.value.map((s) => s.id))));

            formData.append("tags", JSON.stringify(normalization(selectedTags.value.map((t) => t.id))));

            let response = {};

            if (!props.copyMode) {
                response = await user.updateProduct(formData);
            }
            else {
                response = await user.createNewProduct(formData);
            }

            if (response) {
                emit("submit", { response, element: elementRef.value, });
                closeModal();
            }
            else {
                store.dispatch("sendNotification", {
                    header: t("component.productCopyModal"),
                    body: t("error.generic.duplicateProduct"),
                }).then();

                // duplicateNameHelper.value = ` (${t("generic.duplicateName")})`;
            }

            isConfirmButtonLoading.value = false;
        }

        function onTagChange (selectedOptions) {
            selectedTags.value = selectedOptions.map((t) => ({
                ...t,
                id: t.value,
            }));
        }

        watch(() => props.presentation, async (newValue) => {
            const presentation = props.presentation ? props.presentation : await user.getProductPage();
            categories.value = presentation.productCategories;
            tags.value = presentation.tags.filter((t) => !t.isDefault);
            colors.value = presentation.colors;
            sizes.value = presentation.sizes;
            procedures.value = presentation.procedures;
            suppliers.value = presentation.suppliers;
            tissueCategories.value = presentation.tissueCategories;
            accessoryCategories.value = presentation.accessoryCategories;
            printPatternCategories.value = presentation.printPatternCategories;
            const newColors = colors.value.map((c) => ({
                value: c.id,
                text: c.name,
                toSwap: false,
                selected: false,
                enable: true,
                type: "color",
                isDefault: c.isDefault,
            }));
            newColors.forEach((nc) => {
                if (!formattedColors.value.find((fc) => fc.value === nc.value)) {
                    formattedColors.value = [ ...formattedColors.value, nc, ];
                }
            });

            const newSizes = sizes.value.map((s) => ({
                value: s.id,
                text: s.name,
                selected: false,
                type: "size",
                isDefault: s.isDefault,
            }));

            newSizes.forEach((nc) => {
                if (!formattedSizes.value.find((fc) => fc.value === nc.value)) {
                    formattedSizes.value = [ ...formattedSizes.value, nc, ];
                }
            });
        });

        watch(() => props.isVisible, async (newValue) => {
            if (newValue) {
                await init();
                bindElementToUpdate();
            }
        });

        const colorMultiselect = ref(null);
        const sizeMultiselect = ref(null);

        function clearAllColors () {
            colorMultiselect.value.emptyOptions();
            selectedColors.value = [];
        }

        function clearAllSizes () {
            sizeMultiselect.value.emptyOptions();
            selectedSizes.value = [];
        }

        const isTissueSpecialColorRuleAddModalVisible = ref({});

        function openTissueSpecialColorRuleAddModal (index) {
            isTissueSpecialColorRuleAddModalVisible.value[index] = true;
        }

        function updateTissueColorRules (rules, tissue, index) {
            const descriptor = {
                selectedTissue: tissue,
                tissueColorRules: rules,
                printPatternColorRules: tissue.printPatternColorRules ?? [],
                index: index,
            };
            applySpecialColorRule(descriptor);
        }

        function getTissueColorRuleButtonText (tissue) {
            if (tissue.tissueColorRules && tissue.tissueColorRules.length > 0) {
                return t("generic.hasColorRules");
            }

            return "...";
        }

        const isPrintPatternSpecialColorRuleAddModalVisible = ref({});

        function openPrintPatternSpecialColorRuleAddModal (index) {
            isPrintPatternSpecialColorRuleAddModalVisible.value[index] = true;
        }

        function updatePrintPatternColorRules (rules, tissue, index) {
            const descriptor = {
                selectedTissue: tissue,
                tissueColorRules: tissue.tissueColorRules ?? [],
                printPatternColorRules: rules,
                index: index,
            };
            applySpecialColorRule(descriptor);
        }

        function getPrintPatternColorRuleButtonText (tissue) {
            if (tissue.printPatternColorRules && tissue.printPatternColorRules.length > 0) {
                return t("generic.hasColorRules");
            }

            return "...";
        }

        function getColorById (id) {
            return colors.value.find((c) => c.id === id);
        }

        function applySpecialColorRule (descriptor) {
            if (descriptor.selectedTissue) {
                const index = descriptor.index;
                const tissue = selectedTissues.value[index];

                tissue.tissueColorRules = [ ...descriptor.tissueColorRules, ];
                tissue.printPatternColorRules = [ ...descriptor.printPatternColorRules, ];

                if (descriptor.selectedTissue?.tissueColorId > 0) {
                    tissue.tissueColorId = JSON.stringify(Number.parseInt(descriptor.selectedTissue.tissueColorId, 10));
                    tissue.tissueUsePreorderColor = false;
                    tissueColorSelectValue.value[index] = descriptor.selectedTissue.tissueColorId;
                }
                else {
                    tissue.tissueColorId = "";
                    tissue.tissueUsePreorderColor = true;
                    tissueColorSelectValue.value[index] = "preorder";
                }

                if (descriptor.selectedTissue?.printPatternColorId > 0) {
                    tissue.printPatternColorId = JSON.stringify(Number.parseInt(descriptor.selectedTissue.printPatternColorId, 10));
                    tissue.printPatternUsePreorderColor = false;
                    printPatternColorSelectValue.value[index] = descriptor.selectedTissue.printPatternColorId;
                }
                else {
                    tissue.printPatternColorId = "";
                    tissue.printPatternUsePreorderColor = true;
                    printPatternColorSelectValue.value[index] = "preorder";
                }
            }

            if (descriptor.procedure) {
                const dyeingProcedures = getDyeingTypeProcedures();
                const index = dyeingProcedures.findIndex((procedure) => procedure.id === descriptor.procedure.id);
                const procedure = dyeingProcedures[index];
                const indexInAllProcedures = selectedProcedures.value.findIndex((procedure) => procedure.id === descriptor.procedure.id);

                procedure.dyeingColorRules = [];
                if (descriptor.dyeingColorRules) {
                    procedure.dyeingColorRules = [ ...descriptor.dyeingColorRules, ];
                }

                if (descriptor.procedure.colorId) {
                    procedure.usePreorderColor = false;
                    procedure.colorId = JSON.stringify(Number.parseInt(descriptor.procedure.colorId, 10));
                    dyeingProcedureColorSelectValue.value[indexInAllProcedures] = descriptor.procedure.colorId;
                }
                else {
                    procedure.usePreorderColor = true;
                    procedure.colorId = "";
                    dyeingProcedureColorSelectValue.value[indexInAllProcedures] = "preorder";
                }
            }
        }

        const isProcedureSpecialColorRuleAddModalVisible = ref({});

        function openProcedureSpecialColorRuleAddModal (index) {
            isProcedureSpecialColorRuleAddModalVisible.value[index] = true;
        }

        function updateProcedureColorRules (rules, procedure) {
            const descriptor = {
                procedure: procedure,
                dyeingColorRules: rules,
            };
            applySpecialColorRule(descriptor);
        }

        function getProcedureColorRuleButtonText (procedure) {
            if (procedure.dyeingColorRules?.length > 0) {
                return t("generic.hasColorRules");
            }

            return "...";
        }

        return {
            t,
            isLoading,
            activeModalTab,
            colorManagerTab,
            isTissueAddModalVisible,
            isTissueUpdateModalVisible,
            isPrintPatternAddModalVisible,
            isPrintPatternUpdateModalVisible,
            isAccessoryAddModalVisible,
            isAccessoryUpdateModalVisible,
            isProcedureAddModalVisible,
            isTissuePickerVisible,
            isPrintPatternPickerVisible,
            isAccessoryPickerVisible,
            formattedColors,
            formattedSizes,
            tags,
            suppliers,
            tissueCategories,
            printPatternCategories,
            accessoryCategories,
            categories,
            newProduct,
            selectedTissues,
            selectedPrintPatterns,
            selectedAccessories,
            colors,
            tissueUpdateModal,
            printPatternUpdateModal,
            accessoryUpdateModal,
            selectedProcedures,
            images,
            isLightboxVisible,
            selectedImageIndex,
            lightBoxImages,
            colorsWithoutWhiteEmbryo,
            isProcedurePickerVisible,
            selectedColors,
            profit,
            totalCost,
            selectedTissuesTotalCost,
            selectedAccessoriesTotalCost,
            selectedProceduresTotalCost,
            priceToSellCalculated,
            profitRateCalculated,
            formattedTags,
            isFixedPriceInverted,
            isSelectedAllEnableColors,
            isSelectedAllDisableColors,
            colorManagementTableHeaders,
            disableColors,
            enableColors,
            autoGeneratedName,
            procedureColorManagementTableHeaders,
            pickerInputValue,
            isConfirmButtonLoading,
            saveImages,
            deleteImage,
            openLightbox,
            closeLightbox,
            closeModal,
            openTissueAddModal,
            openTissueUpdateModal,
            openAccessoryAddModal,
            openAccessoryUpdateModal,
            openProcedureAddModal,
            openTissuePicker,
            openPrintPatternAddModal,
            openPrintPatternUpdateModal,
            selectColor,
            selectSize,
            selectProductCategory,
            toggleVisibleOnOrder,
            deleteTissue,
            deleteAccessory,
            getSupplierName,
            getTissueCategoryName,
            openPrintPatternPicker,
            openAccessoryPicker,
            addSelectedTissue,
            addSelectedPrintPattern,
            addSelectedAccessory,
            getPrintPattern,
            deletePrintPattern,
            submitUpdateTissue,
            submitUpdatePrintPattern,
            submitUpdateAccessory,
            selectTissueColor,
            selectPrintPatternColor,
            selectAccessoryColor,
            changeTissue,
            swapTop,
            swapBottom,
            deleteProcedure,
            getItemSubtotal,
            doubleInputNormalization,
            doubleOutputNormalization,
            openProcedurePicker,
            addSelectedProcedure,
            addDoubles,
            getProductProcedureQuantity,
            getProductProcedureSubtotal,
            toggleFixedPrice,
            showProcedureQuantityInput,
            showProcedureCustomWeightInput,
            showProcedureSeparateFromStorage,
            showProcedureColorPicker,
            selectProcedureColor,
            selectAllColors,
            removeSelectedColors,
            removeColor,
            restoreColor,
            restoreSelectedColors,
            submitUpdate,
            bindElementToUpdate,
            toggleDifferentiateStorage,
            toggleDifferentiateStorageProcedure,
            onTagChange,
            getTissueColorValue,
            getPrintPatternColorValue,
            importPreorderColor,
            importPreorderSize,
            getProcedureColorValue,
            colorMultiselect,
            sizeMultiselect,
            clearAllColors,
            clearAllSizes,
            openTissueLightbox,
            tissueColorSelectValue,
            printPatternColorSelectValue,
            openTissueSpecialColorRuleAddModal,
            openPrintPatternSpecialColorRuleAddModal,
            updateTissueColorRules,
            updatePrintPatternColorRules,
            getColorById,
            getTissueColorRuleButtonText,
            getPrintPatternColorRuleButtonText,
            openSpecialColorRuleModal,
            selectedDescriptorForSpecialColorRule,
            isSpecialColorRuleModalVisible,
            applySpecialColorRule,
            isTissueSpecialColorRuleAddModalVisible,
            isPrintPatternSpecialColorRuleAddModalVisible,
            getElementName,
            dyeingProcedureColorSelectValue,
            isProcedureSpecialColorRuleAddModalVisible,
            openProcedureSpecialColorRuleAddModal,
            updateProcedureColorRules,
            getProcedureColorRuleButtonText,
            elementRef,
        };
    },
};
</script>

<style lang="scss" scoped>
$modal-height: 92vh;
.modal-body {
    height: $modal-height;
    padding: 0;
    overflow-x: hidden;
}
.modal-content-container {
    min-height: $modal-height;
}
.nav-underline {
    border-bottom: 1px solid var(--cui-nav-underline-border-color, #c4c9d0);
}
.image-bar {
    min-width: 50px * 4;
    width: 20vw;
    margin-left: 9px;
    overflow-y: auto;
    overflow-x: hidden;
}
.image-bar-gallery {
    max-height: 92vh;
}
.form-container {
    width: 80vw;
}
.no-data-text {
    padding-left: 30vw;
}

</style>
