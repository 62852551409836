import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./i18n";
import CoreuiVuePro from "@coreui/vue-pro";
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";
import "moment/locale/it"; // moment library italian
import "moment/locale/zh-cn"; // moment library chinese
import VueSplide from "@splidejs/vue-splide";
import VueEasyLightbox from "vue-easy-lightbox";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import "floating-vue/dist/style.css";
import FloatingVue from "floating-vue";
FloatingVue.options.themes.menu.delay.hide = 100;
// import ECharts modules manually to reduce bundle size
import {
    CanvasRenderer,
} from "echarts/renderers";
import {
    LineChart,
} from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
} from "echarts/components";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
use([
    CanvasRenderer,
    LineChart,
    GridComponent,
    TooltipComponent,
]);

const app = createApp(App);
app.use(i18n);
app.use(store);
app.use(router);
app.use(CoreuiVuePro);
app.use(VueSplide);
app.use(VueEasyLightbox);
app.use(FloatingVue);
app.component("Datepicker", Datepicker);
// register globally (or you can do it locally)
app.component("v-chart", ECharts);
app.provide("icons", icons);
app.component("CIcon", CIcon);

app.mount("#app");
