<template>
    <CModal scrollable :visible="isVisible" backdrop="static" alignment="center" @close="closeModal">
        <!-- Lightbox Row-->
        <BaseLightbox
            :is-lightbox-visible="isLightboxVisible"
            :selected-image-index="0"
            :images="lightboxImages"
            @hide="closeLightbox"
        ></BaseLightbox>

        <CModalHeader>
            <CModalTitle>{{ t("model.addNew") }}</CModalTitle>
        </CModalHeader>

        <CModalBody @scroll="getNewElements">
            <CRow class="py-3 ps-4">
                <CCol :xs="3" class="p-0">
                    <CFormSelect v-model:model-value="searchField" @change="onInputSearchValue">
                        <option value="">{{ t("generic.all") }}</option>
                        <option v-for="field in Object.keys(productsSearchFields)" :key="field" :value="field">{{ t(productsSearchFields[field]) }}</option>
                    </CFormSelect>
                </CCol>
                <CCol xs="auto" class="p-0">
                    <CFormInput
                        class="t-col-4"
                        v-model:model-value="searchValue"
                        type="search"
                        :placeholder="t('generic.search')"
                        @input="onInputSearchValue"
                    />
                </CCol>
            </CRow>
            <CRow class="py-3 ps-4 pe-4">
                <CListGroup>
                    <CListGroupItem component="button" v-for="product in productsToDisplay" :key="product.id" @click="toggleSelectProduct(product)">
                        <ProductPopover :product="product" :colors-array="requiredParams.colors" :hoverble="false">
                            <template #toggler>
                                <CRow class="ps-0 pb-0">
                                    <CCol class="pe-0">
                                        <CImage
                                            class="t-col-photo rounded cursor-pointer"
                                            v-if="product.images?.length > 0" :src="product.images[0].tiny"
                                            @click.stop="openLightbox(product)"
                                        ></CImage>
                                        <div v-else class="t-col-photo">
                                            <CIcon size="4xl" name="cil-image"></CIcon>
                                        </div>
                                    </CCol>
                                    <CCol class="ps-0">
                                        <CRow>
                                            <span class="text-info">{{ product.productTemplateId }}</span>
                                        </CRow>
                                        <CRow>
                                            <span class="fs-4">{{ product.name }}</span>
                                        </CRow>
                                    </CCol>
                                    <CCol class="d-flex align-items-center">
                                        <CFormLabel>{{ product.tissues[0].tissue.name }}</CFormLabel>
                                    </CCol>
                                    <CCol class="d-flex align-items-center justify-content-end pe-3">
                                        <CIcon v-if="isSelectedProduct(product.id)" size="xl" icon="cil-check-alt" class="text-info"></CIcon>
                                    </CCol>
                                </CRow>
                            </template>
                        </ProductPopover>
                    </CListGroupItem>
                    <CListGroupItem v-if="isLoadingNewContent" class="d-flex justify-content-center p-5">
                        <CSpinner color="primary"></CSpinner>
                    </CListGroupItem>
                </CListGroup>
            </CRow>
            <CRow class="justify-content-center align-content-center">
                <span class="text-center text-nowrap" v-if="products.length === totalItems && !isLoadingNewContent">{{ t("generic.noMoreResults") }}</span>
            </CRow>
        </CModalBody>

        <CModalFooter>
            <CButton class="me-3" color="secondary" variant="outline" @click="closeModal">{{ t("generic.undo") }}</CButton>
            <CButton color="primary" @click="confirmSelectedProducts">{{ t("generic.confirm") }}</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
    computed,
    onMounted,
    reactive,
    ref,
    watch,
} from "vue";
import { user } from "@/user";
import { debounce } from "debounce";
import BaseLightbox from "@/components/base/BaseLightbox";
import { getTimeDifferenceText } from "@/views/utilities";
import ProductPopover from "@/components/popovers/ProductPopover";

export default {
    name: "ProductMultiSelect",
    emits: [ "update:selectedProducts", "update:isVisible", ],
    components: { ProductPopover, BaseLightbox, },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        selectedProducts: {
            type: Array,
            default: () => [],
        },
        requiredParams: {
            type: Object,
            default: undefined,
        },
        showExisting: {
            type: Boolean,
            default: true,
        },
    },
    setup (props, { emit, }) {
        const { t, } = useI18n();
        const isLightboxVisible = ref(false);
        const selectedImageIndex = ref(0);
        const lightboxImages = ref([]);
        const products = ref([]);
        const productsToDisplay = computed(() => {
            if (!props.showExisting) {
                console.log("COMPUTED", selectedProductsCopy.value);
                return products.value.filter((p) => !isExistingProduct(p.id));
            }

            return products.value;
        });

        // Dynamic scroll
        const currentPage = ref(0);
        const totalItems = ref(0);
        const isLoadingNewContent = ref(false);
        const previousScrollTop = ref(0);

        const productsSearchFields = Object.freeze({
            name: "generic.name",
            productTemplateId: "generic.template",
            printPatterns: "generic.printPattern",
            primaryTissue: "model.mainTissue",
            accessory: "generic.accessories",
            note: "generic.notes",
            listPrice: "model.listPrice",
            procedure: "generic.procedures",
        });
        const searchValue = ref("");
        const searchField = ref("");

        const selectedProductsCopy = ref([]);

        function closeModal () {
            searchValue.value = "";
            searchField.value = "";
            emit("update:isVisible", false);
        }

        function openLightbox (printPattern) {
            selectedImageIndex.value = 0;
            lightboxImages.value = printPattern.images.map((i) => i.compress);
            isLightboxVisible.value = true;
        }

        function closeLightbox () {
            lightboxImages.value = [];
            isLightboxVisible.value = false;
        }

        function getCategoryName (category) {
            if (category.isDefault) {
                return t(category.name);
            }

            return category.name;
        }

        function toggleSelectProduct (product) {
            if (!isSelectedProduct(product.id)) {
                selectedProductsCopy.value = [ ...selectedProductsCopy.value, product, ];
            }
            else {
                selectedProductsCopy.value = selectedProductsCopy.value.filter((p) => p.id !== product.id);
            }

        }

        function isSelectedProduct (productId) {
            return Boolean(selectedProductsCopy.value.find((p) => p.id === productId));
        }

        function isExistingProduct (productId) {
            const product = selectedProductsCopy.value.find((p) => p.id === productId);
            if (product) {
                return product.existing;
            }
        }

        function confirmSelectedProducts () {
            emit("update:selectedProducts", [ ...selectedProductsCopy.value, ]);
            closeModal();
        }

        async function getNewElements (event) {
            if (event.target.scrollTop > previousScrollTop.value) {
                previousScrollTop.value = event.target.scrollTop;
                if (Math.round(event.target.scrollTop + event.target.offsetHeight + 1) >= event.target.scrollHeight) {
                    isLoadingNewContent.value = true;
                    await newElementsRequest();
                }
            }
        }

        const newElementsRequest = debounce(async () => {
            try {
                currentPage.value++;
                const response = await user.getAllProducts({
                    archived: false,
                    filterField: searchField.value,
                    filterValue: searchValue.value,
                    page: currentPage.value,
                    includeRequests: true,
                    size: 20,
                    otherFilters: [ "alsoHasStock", ],
                    byPicker: true,
                });
                totalItems.value = response.totalItems;

                products.value = [ ...products.value, ...response.products ?? [], ];
            }
            catch { }
            isLoadingNewContent.value = false;
        }, 500);

        async function getAllFilteredElements () {
            try {
                const response = await user.getAllProducts({
                    archived: false,
                    filterField: searchField.value,
                    filterValue: searchValue.value,
                    page: currentPage.value,
                    includeRequests: true,
                    size: 20,
                    otherFilters: [ "alsoHasStock", ],
                    byPicker: true,
                });
                totalItems.value = response.totalItems;

                products.value = response.products ?? [];
            }
            catch { }
        }

        watch(() => props.isVisible, async (newValue) => {
            isLoadingNewContent.value = true;
            if (newValue) {
                currentPage.value = 0;
                previousScrollTop.value = 0;
                await getAllFilteredElements();
                selectedProductsCopy.value = [ ...props.selectedProducts, ];
            }
            isLoadingNewContent.value = false;
        });

        const onInputSearchValue = debounce(async () => {
            isLoadingNewContent.value = true;
            currentPage.value = 0;
            previousScrollTop.value = 0;
            await getAllFilteredElements();
            isLoadingNewContent.value = false;
        }, 100);

        return {
            t,
            products,
            productsToDisplay,
            searchValue,
            searchField,
            isLightboxVisible,
            lightboxImages,
            productsSearchFields,
            onInputSearchValue,
            selectedImageIndex,
            isLoadingNewContent,
            totalItems,
            closeModal,
            openLightbox,
            closeLightbox,
            getCategoryName,
            toggleSelectProduct,
            getTimeDifferenceText,
            isSelectedProduct,
            confirmSelectedProducts,
            getNewElements,
        };
    },
};
</script>

<style scoped>

</style>
